/* global window */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { Alert, Typography } from '@mui/material'

import { Button } from 'containers/themed'
import AppBar from 'containers/content_desk/AppBar'
import NewButton from 'containers/content_desk/NewButton'
import ActiveFilterList from 'containers/content_desk/ActiveFilterList'
import ContentNewDialog from 'containers/content_desk/ContentNewDialog'
import ContentEditDialog from 'containers/content_desk/ContentEditDialog'
import ContentNotesDialog from 'containers/content_desk/ContentNotesDialog'
import CampaignForm from 'containers/content_desk/CampaignForm'
import CampaignsItem from 'containers/content_desk/CampaignsItem'
import CalendarDialog from 'containers/content_desk/CalendarDialog'
import ContentView from 'containers/content_desk/ContentView'
import ContentsEditBlockDialog from 'containers/content_desk/ContentsEditBlockDialog'
import DeleteDialog from 'containers/content_desk/DeleteDialog'

import { StaggeredListItem } from 'components/staggered_list'

import { ContentDeskSearchFields } from 'static/constants'

const useStyles = makeStyles()(theme => ({
  title: {
    position: 'relative',
    color: !theme.isDark && '#404040'
  },
  total: {
    marginBottom: '1em'
  },
  noHits: {
    marginBottom: '1em'
  },
  wrapper: {
    padding: '2em'
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  circularLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh'
  },
  loadMoreButton: {
    float: 'right',
    marginBottom: '1em'
  }
}))

function CampaignsShowAll({
  ccdCampaigns,
  page,
  loading,
  hasSearchQueries,
  setPage,
  setSelectedQueryType,
  searchCampaignsStart
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  let remaining = ccdCampaigns.get('total') - ccdCampaigns.get('items').size
  remaining = remaining > 0 ? remaining : 0

  useEffect(() => {
    window.scrollTo(0, 0)
    setSelectedQueryType(ContentDeskSearchFields.CAMPAIGN_NAME)
    searchCampaignsStart()
  }, [searchCampaignsStart])

  const onLoadMoreClick = _e => {
    setPage({ key: 'campaignPage', value: page + 1 })
    searchCampaignsStart()
  }

  const renderCampaignItems = () => {
    if (ccdCampaigns.get('items').size) {
      return ccdCampaigns.get('items').map((el, index) => (
        <StaggeredListItem
          delay={index * 50}
          key={index}
        >
          <CampaignsItem
            campaign={el}
            endDate={el.get('endDate')}
            name={el.get('name')}
            startDate={el.get('startDate')}
            summary={el.get('summary')}
          />
        </StaggeredListItem>
      ))
    }

    if (hasSearchQueries && !loading) {
      return (
        <Alert
          severity="warning"
          className={classes.noHits}
        >
          {i18n.get('no_hits_title')}
        </Alert>
      )
    }

    return null
  }

  return (
    <div className={classes.wrapper}>
      <AppBar />
      <ActiveFilterList />
      <div className={classes.menuWrapper}>
        <NewButton />
      </div>
      <Typography
        variant="h5"
        className={classes.title}
      >
        {i18n.get('campaigns')}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.total}
      >
        {i18n.get('total')}: {ccdCampaigns.get('total')}
      </Typography>
      {renderCampaignItems()}
      <Button
        className={classes.loadMoreButton}
        variant="contained"
        color="primary"
        onClick={onLoadMoreClick}
        loading={loading}
        disabled={remaining === 0}
      >
        {i18n.get('load_more_remaining', { remaining })}
      </Button>
      <ContentNewDialog />
      <ContentEditDialog />
      <ContentNotesDialog />
      <CalendarDialog />
      <CampaignForm />
      <DeleteDialog entity="campaign" />
      <ContentsEditBlockDialog />
      <ContentView />
    </div>
  )
}

CampaignsShowAll.propTypes = {
  ccdCampaigns: PropTypes.instanceOf(Map).isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  hasSearchQueries: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  setSelectedQueryType: PropTypes.func.isRequired,
  searchCampaignsStart: PropTypes.func.isRequired
}

export default CampaignsShowAll
