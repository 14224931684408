// import * as Sentry from '@sentry/react'
import * as Sentry from '@sentry/browser'
import { isUnauthorized } from 'utils/error'

export const init = () => {
  Sentry.init({
    dsn: 'https://50ef5e06dbdb4656bb9e1df1c0dd6c50@o102697.ingest.sentry.io/235442',
    release: `groot-newsradar@${process.env.VERSION}`,
    sampleRate: 0.25
  })
}

export const logException = exception => {
  if (!isUnauthorized(exception)) {
    console.error(exception) // eslint-disable-line

    if (exception.request) {
      Sentry.captureException(exception, {
        extra: {
          request: exception.request,
          response: exception.response
        }
      })
    } else {
      Sentry.captureException(exception)
    }
  }
}

export const setUser = user => {
  Sentry.configureScope(scope => {
    scope.setUser(user)
  })
}
export const setTag = (key, value) => {
  Sentry.configureScope(scope => {
    scope.setTag(key, value)
  })
}
