import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Typography, Paper } from '@mui/material'

import { Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    padding: '1em'
  },
  text: {
    color: !theme.isDark && '#404040'
  },
  button: {
    marginTop: '1.5em'
  }
}))

const ContentsNoData = ({ onClick }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Paper className={classes.paper}>
      <Typography
        className={classes.text}
        variant="h5"
      >{i18n.get('create_first_content')}
      </Typography>
      <Button
        className={classes.button}
        onClick={onClick}
        variant="contained"
      >{i18n.get('new_content')}
      </Button>
    </Paper>
  )
}

ContentsNoData.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default ContentsNoData
