export const canvasToPdf = (canvas, dataUrl, filename) => {
  import(/* webpackChunkName: "jspdf" */ 'jspdf').then(({ jsPDF }) => {
    const pdf = new jsPDF({
      unit: 'px',
      hotfixes: ['px_scaling'],
      orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
      format: [canvas.width, canvas.height]
    })

    pdf.addImage(dataUrl, 'JPEG', 0, 0)
    pdf.save(filename)
  })
}
