import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useBeforeUnload from 'hooks/useBeforeUnload'
import { useSnackbar } from 'notistack'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Alert,
  Dialog,
  Typography,
  Toolbar,
  AppBar,
  LinearProgress,
  Slide,
  MenuItem,
  Tooltip
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CancelIcon from '@mui/icons-material/Cancel'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import { orange, blue, green, grey } from '@mui/material/colors'

import CloseConfirmDialog from 'components/content_desk/close_confirm_dialog'
import PublishConfirmDialog from 'components/content_desk/publish_confirm_dialog'

import Navigation from 'containers/content_desk/content_edit_dialog/Navigation'
import ContentForm from 'containers/content_desk/content_edit_dialog/ContentForm'
import SelectRecipientsForm from 'containers/content_desk/content_edit_dialog/SelectRecipientsForm'
import DistributeForm from 'containers/content_desk/content_edit_dialog/DistributeForm'
import ContentEditor from 'containers/content_desk/content_edit_dialog/ContentEditor'
import ContentPlanDialog from 'containers/content_desk/content_edit_dialog/ContentPlanDialog'
import ContentScheduleDialog from 'containers/content_desk/content_edit_dialog/ContentScheduleDialog'
import ContentTestMailDialog from 'containers/content_desk/content_edit_dialog/ContentTestMailDialog'
import Attachments from 'containers/content_desk/content_edit_dialog/Attachments'
import { Button, IconButton, Menu } from 'containers/themed'

import { validEmail } from 'utils/regex'

const useStyles = makeStyles()(theme => ({
  formContainer: {
    marginBottom: '4em',
    paddingLeft: '3em',
    paddingRight: '3em'
  },
  button: {
    marginLeft: '1em'
  },
  buttonDisabled: {
    color: `${theme.palette.grey[300]} !important`,
    cursor: 'default !important'
  },
  overlay: {
    position: 'fixed',
    top: 69,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#D3D3D3',
    opacity: '.60',
    zIndex: 9999999
  },
  linearProgress: {
    width: '100vw',
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0
  },
  warning: {
    margin: '0 3em'
  },
  status: {
    display: 'flex',
    alignItems: 'center'
  },
  statusButton: {
    marginLeft: '1em',
    marginRight: '1em'
  },
  statusView: {
    marginLeft: '1em',
    marginRight: '1em',
    pointerEvents: 'none'
  },
  footerToolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  footerRightActions: {
    display: 'flex'
  }
}))

const TransitionComponent = forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
))

TransitionComponent.displayName = 'Transition'

export default function ContentEditDialog({
  open,
  view,
  isLoading,
  isInCloneMode,
  contentFormData,
  onUnlock,
  onRelock,
  onClose,
  onSave,
  onTestMail,
  openScheduleDialog,
  openPlanDialog,
  duplicateError,
  onChange
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [confDialogOpen, setConfDialogOpen] = useState(false)
  const [publishConfDialogOpen, setPublishConfDialogOpen] = useState(false)
  const contentFormType = contentFormData.getIn(['type', 'value'])
  const status = contentFormData.get('status') || contentFormData.get('approvalStatus')

  useEffect(() => { // eslint-disable-line
    if (open) {
      const interval = setInterval(() => onRelock(), 30000)

      return () => clearInterval(interval)
    }
  }, [open])

  useBeforeUnload(e => {
    e.preventDefault()
    onUnlock()
  }, open)

  const handleExit = () => onClose({ closeEditContentDialog: true })

  const handleClose = () => {
    if (contentFormData.get('isDirty')) {
      setConfDialogOpen(true)
    } else {
      onClose({ closeEditContentDialog: true })
    }
  }

  const requiredFields = ['title', 'type']

  if (contentFormType === 1) {
    requiredFields.push('body')
    requiredFields.push('subject')
  }

  if (contentFormType === 2) {
    requiredFields.push('linkedInPost')
  }

  const isFormValid = ({ action }) => {
    if (requiredFields.map(field => !!contentFormData.get(field)).some(value => !value)) {
      requiredFields.map(field => {
        let translationKey = field

        if (field === 'body' || field === 'linkedInPost') {
          translationKey = 'ccd_editor_content'
        }

        if (!contentFormData.get(field)) {
          enqueueSnackbar(
            `${i18n.get(translationKey)} ${i18n.get('is_required')}`,
            { variant: 'error', preventDuplicate: true }
          )
        }

        return true
      })

      return false
    }

    if ((contentFormType === 1)) {
      if (contentFormData.get('subject').length > 255) {
        enqueueSnackbar(
          `${i18n.get('email_subject_too_long')}`,
          { variant: 'error', preventDuplicate: true }
        )

        return false
      }

      if (!validEmail(contentFormData.get('senderMail'))) {
        enqueueSnackbar(
          `${i18n.get('email_invalid')}`,
          { variant: 'error', preventDuplicate: true }
        )

        return false
      }
    }

    if ((contentFormType === 2) && (action !== 'save')) {
      if (!contentFormData.getIn(['linkedInPost', 'author']) || contentFormData.getIn(['linkedInPost', 'author']) === '') {
        enqueueSnackbar(
          `${i18n.get('linked_in_organization')} ${i18n.get('is_required')}`,
          { variant: 'error', preventDuplicate: true }
        )

        return false
      }

      if (
        contentFormData.getIn(['linkedInPost', 'type']) !== 'link' && (contentFormData.getIn(['linkedInPost', 'content'])
          && !contentFormData.getIn(['linkedInPost', 'content', 'media'])
          && !contentFormData.getIn(['linkedInPost', 'content', 'multiImage', 'images']))
      ) {
        enqueueSnackbar(
          `${i18n.get('linked_in_media_files_missing')}`,
          { variant: 'error', preventDuplicate: true }
        )

        return false
      }

      if (
        contentFormData.getIn(['linkedInPost', 'type']) !== 'link' && (contentFormData.getIn(['linkedInPost', 'content'])
          && !contentFormData.getIn(['linkedInPost', 'content', 'multiImage', 'images'])
          && !contentFormData.getIn(['linkedInPost', 'content', 'media', 'altText'])
          && !contentFormData.getIn(['linkedInPost', 'content', 'media', 'title']))

      ) {
        enqueueSnackbar(
          `${i18n.get('linked_in_title_missing')}`,
          { variant: 'error', preventDuplicate: true }
        )

        return false
      }
    }

    if ((contentFormType === 3) && (action !== 'save')) {
      if (contentFormData.getIn(['facebookPost', 'pageId']) === '') {
        enqueueSnackbar(
          `${i18n.get('page')} ${i18n.get('is_required')}`,
          { variant: 'error', preventDuplicate: true }
        )

        return false
      }
    }

    return true
  }

  const onScheduleClick = ({ releaseNow = false }) => {
    if (contentFormData.get('recipients').size < 1 && contentFormType === 1) {
      enqueueSnackbar(
        `${i18n.get('recipients')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    } else if (isFormValid({ action: 'schedule' }) && !releaseNow) {
      return openScheduleDialog()
    } else if (isFormValid({ action: 'publishNow' })) {
      return setPublishConfDialogOpen(true)
    }

    return null
  }

  let dialogTitle = i18n.get('edit_content')

  if (isInCloneMode) {
    dialogTitle = i18n.get('duplicate_content')
  }

  const handleSave = () => {
    if (isFormValid({ action: 'save' })) {
      return onSave({ closeEditContentDialog: false })
    }

    return null
  }

  const handleStatusChange = newStatus => {
    onChange({ key: 'approvalStatus', value: newStatus })
  }

  const renderCloseConfirmDialog = () => {
    if (!contentFormData.get('isDirty')) {
      return null
    }

    return (
      <CloseConfirmDialog
        open={confDialogOpen}
        onBack={() => setConfDialogOpen(false)}
        onClose={handleExit}
      />
    )
  }

  const renderPublishConfirmDialog = () => (
    <PublishConfirmDialog
      open={publishConfDialogOpen}
      status={status}
      onChange={onChange}
      onCloseDialog={() => setPublishConfDialogOpen(false)}
      onSave={onSave}
    />
  )

  const renderDuplicateError = () => {
    if (!duplicateError) {
      return null
    }

    return (
      <Alert
        severity="warning"
        className={classes.warning}
      >
        {i18n.get('ccd_content_duplicate_error')}
      </Alert>
    )
  }

  const renderStatusIcon = () => {
    switch (status) {
      case 'failed':
        return <CancelIcon />
      case 'released':
        return <CheckCircleIcon />
      case 'scheduled':
        return <AccessTimeIcon />
      case 'draft':
        return <PendingOutlinedIcon />
      case 'review_pending':
        return <AlarmOnIcon />
      case 'revisions_needed':
        return <ChangeCircleIcon />
      case 'approved':
        return <CheckCircleIcon />
      default:
        return <HelpOutlineOutlinedIcon />
    }
  }

  const renderStatusView = () => (
    <Button
      className={classes.statusView}
      startIcon={renderStatusIcon()}
      autoFocus
      variant="outlined"
      color="inherit"
    >{i18n.get(status)}
    </Button>
  )

  const renderStatusMenu = () => (
    <Menu
      handle={(
        <Button
          className={classes.statusButton}
          startIcon={renderStatusIcon()}
          autoFocus
          variant="outlined"
          color="inherit"
        >{i18n.get(status)}
        </Button>
      )}
    >
      <MenuItem onClick={() => handleStatusChange('draft')}>
        <PendingOutlinedIcon style={{ color: grey[500], marginRight: '0.3em' }} />
        {i18n.get('draft')}
      </MenuItem>
      <MenuItem onClick={() => handleStatusChange('review_pending')}>
        <AlarmOnIcon style={{ color: blue[500], marginRight: '0.3em' }} />
        {i18n.get('review_pending')}
      </MenuItem>
      <MenuItem onClick={() => handleStatusChange('revisions_needed')}>
        <ChangeCircleIcon style={{ color: orange[500], marginRight: '0.3em' }} />
        {i18n.get('revisions_needed')}
      </MenuItem>
      <MenuItem onClick={() => handleStatusChange('approved')}>
        <CheckCircleIcon style={{ color: green[500], marginRight: '0.3em' }} />
        {i18n.get('approved')}
      </MenuItem>
    </Menu>
  )

  const renderStatus = () => {
    const releasedStatuses = ['released', 'scheduled', 'failed']

    if (releasedStatuses.includes(status)) {
      return renderStatusView()
    }

    return renderStatusMenu()
  }

  const testMailDisabled = isLoading || contentFormData.get('body') === null || contentFormData.get('isDirty')

  const testMailTooltipTitle = () => {
    if (!testMailDisabled) {
      return ''
    }

    if (contentFormData.get('body') === null) {
      return (
        <div>{i18n.get('test_email_no_content')}</div>
      )
    }

    return (
      <div>{i18n.get('test_email_dirty')}</div>
    )
  }

  let classNameButton = ''

  if (testMailDisabled) {
    classNameButton = classes.buttonDisabled
  }

  return (
    <>
      {renderCloseConfirmDialog()}
      {renderPublishConfirmDialog()}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionComponent}
      >
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              disabled={isLoading}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {dialogTitle}
            </Typography>
          </Toolbar>

          {isLoading && <LinearProgress className={classes.linearProgress} />}
        </AppBar>

        {isLoading && <div className={classes.overlay} />}

        <Navigation />

        {renderDuplicateError()}

        <div className={classes.formContainer}>
          {view === 'create_content' && <ContentForm />}
          <div style={{ display: view === 'content_editor' ? 'block' : 'none' }}>
            <ContentEditor />
          </div>
          {view === 'attachments' && <Attachments />}
          {view === 'select_recipients' && <SelectRecipientsForm />}
          {view === 'distribute' && <DistributeForm />}
        </div>
        <AppBar
          position="fixed"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Toolbar className={classes.footerToolbar}>
            <div>
              {contentFormType === 1 && (
                <Tooltip
                  title={testMailTooltipTitle()}
                  placement="top-start"
                >
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    <Button
                      color="inherit"
                      variant="outlined"
                      disabled={testMailDisabled}
                      onClick={() => onTestMail()}
                      autoFocus
                      className={classNameButton}
                    >
                      {i18n.get('test_email')}
                    </Button>
                  </Typography>
                </Tooltip>
              )}
            </div>
            <div className={classes.footerRightActions}>
              {!isLoading
                && (
                  <div className={classes.status}>
                    <Typography>
                      {`${i18n.get('content')} ${i18n.get('status')}`}
                    </Typography>
                    {renderStatus()}
                  </div>
                )}
              <Button
                autoFocus
                color="inherit"
                onClick={handleClose}
                disabled={isLoading}
              >
                {i18n.get('cancel')}
              </Button>
              <Button
                autoFocus
                variant="outlined"
                color="inherit"
                onClick={handleSave}
                className={classes.button}
                disabled={isLoading}
              >
                {i18n.get('save')}
              </Button>
              <Button
                autoFocus
                variant="outlined"
                color="inherit"
                onClick={() => openPlanDialog()}
                className={classes.button}
                disabled={isLoading}
              >
                {i18n.get('plan')}
              </Button>
              <Button
                autoFocus
                variant="outlined"
                color="inherit"
                onClick={onScheduleClick}
                className={classes.button}
                disabled={isLoading}
              >
                {i18n.get('schedule')}
              </Button>
              <Button
                autoFocus
                variant="outlined"
                color="inherit"
                onClick={() => onScheduleClick({ releaseNow: true })}
                className={classes.button}
                disabled={isLoading}
              >
                {`${contentFormType === 1 ? i18n.get('send_now') : i18n.get('publish_now')}`}
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </Dialog>
      <ContentPlanDialog key={`plan_${contentFormData.get('id')}`} />
      <ContentScheduleDialog key={`schedule_${contentFormData.get('id')}`} />
      <ContentTestMailDialog />
    </>
  )
}

ContentEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInCloneMode: PropTypes.bool.isRequired,
  contentFormData: PropTypes.instanceOf(Map).isRequired,

  onClose: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired,
  onRelock: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTestMail: PropTypes.func.isRequired,
  openScheduleDialog: PropTypes.func.isRequired,
  openPlanDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  duplicateError: PropTypes.bool.isRequired
}
