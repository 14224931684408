import { call, put, select, takeEvery, all, take } from 'redux-saga/effects'

import { retriable } from 'utils/sagas'
import { updateColorInMeta } from 'utils/dom'
import { verifyTheme } from 'utils/themes'

import * as Actions from 'actions/config'
import * as Api from 'api/bff'
import { changeDate } from 'actions/filter'
import {
  genericErrorMessage,
  genericSuccessMessage,
  setAppReady,
  setAppBusy,
  loadFromLocalStorage,
  exception,
  showAppMessage,
  showAppSnackbarMessage
} from 'actions/app'
import * as Selectors from 'selectors'
import { setExportConfig } from 'actions/export'
import { setUser, reset as resetUser } from 'actions/user'
import { setSubscriptions } from 'actions/subscriptions'
import { setCustomTags } from 'actions/custom_tags'
import { setIdentitySets } from 'actions/identity_sets'
import { setCharts } from 'actions/charts'
import { setMediaReviews } from 'actions/media_reviews'
import { setMailingLists } from 'actions/mailing_lists'
import { setSavedSearches } from 'actions/saved_searches'
import { setDashboards } from 'actions/dashboard'
import { setAssignees, setCampaigns, setContents, setUploadedMediaFilesTotalSize } from 'actions/content_desk'
import { setContactTags } from 'actions/contact_management'
// import { setOption } from 'actions/ga'
import { navigate } from 'actions/navigation'
import { setNotifications, setLastPage } from 'actions/notifications'
import * as CookieHelper from 'utils/cookieHelper'
import { CookieNames } from 'static/constants'

export function* fetchConfigTry() {
  yield put(setAppBusy())
  const firstConfigLoaded = yield select(Selectors.getFirstConfigLoaded)
  const startedWithDeepLink = yield select(Selectors.getStartedWithDeepLink)
  const newsradarId = yield select(Selectors.getNewsradarId)
  const accessToken = yield select(Selectors.getAccessToken)
  const timezone = yield select(Selectors.getTimezone)
  const currentPath = yield select(Selectors.getCurrentPath)
  const themes = yield select(Selectors.getThemes)

  if (accessToken && newsradarId) {
    // yield put(setOption({ key: 'dimension3', value: newsradarId }))

    const result = yield call(Api.fetchConfig, timezone)

    const {
      ccdAssignees,
      ccdContactTags,
      ccdCampaigns,
      ccdContents,
      ccdMediaFilesTotalSize,
      config,
      statics,
      user,
      subscriptions,
      customTags,
      identitySets,
      charts,
      mediaReviews,
      mailingLists,
      savedSearches,
      dashboards,
      notifications
    } = result

    const selectedTheme = verifyTheme(themes, (user.darkMode ? 'dark' : 'light'), config.colors)

    yield put(Actions.configRequestSuccess(result))
    yield put(setUser(user))
    yield put(Actions.setStatics(statics))
    yield put(Actions.setConfig({ ...config, selectedTheme }))
    yield put(setMailingLists(mailingLists))
    yield put(setSavedSearches(savedSearches))
    yield put(setDashboards(dashboards))
    yield put(setExportConfig(config))
    yield put(setSubscriptions({
      subscriptions,
      topics: statics.subscriptionsSelectableCodes
    }))
    yield put(setCustomTags(customTags))
    yield put(setIdentitySets(identitySets))
    yield put(setCharts(charts))
    yield put(setMediaReviews(mediaReviews))
    yield put(setAssignees(ccdAssignees))
    yield put(setContactTags(ccdContactTags))
    yield put(setCampaigns({ total: null, ccdCampaigns }))
    yield put(setContents({ total: null, ccdContents }))
    yield put(setNotifications(notifications.notificationsList))
    yield put(setUploadedMediaFilesTotalSize(ccdMediaFilesTotalSize))

    if (!notifications.hasMoreResults) {
      yield put(setLastPage())
    }

    if (config.initialMediareviewDate) {
      const { from, to } = config.initialMediareviewDate

      if (from && to) {
        yield put(changeDate({
          dateFrom: new Date(from),
          dateTo: new Date(to)
        }))
      } else {
        yield put(changeDate({
          dateFrom: null,
          dateTo: null
        }))
      }
    }

    if (config.initialRoute) {
      yield call(CookieHelper.saveCookie, CookieNames.INITIAL_ROUTE, config.initialRoute)

      if (!firstConfigLoaded && !startedWithDeepLink && currentPath !== config.initialRoute) {
        yield put(navigate(config.initialRoute))
      }
    } else {
      yield call(CookieHelper.deleteCookie, CookieNames.INITIAL_ROUTE)
    }

    yield put(setAppReady())
    yield put(loadFromLocalStorage())
  } else {
    yield put(resetUser())
  }
}

export function* fetchConfigFail(error) {
  yield put(Actions.configRequestError(error))
  yield put(resetUser())

  if (!error.response || (error.response && error.response.statusCode !== 401)) {
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export const retryConfigFun = error => !(error.response && error.response.statusCode === 401)

export function* fetchConfig() {
  yield call(retriable, fetchConfigTry, fetchConfigFail, null, { retryCount: 1, retryFun: retryConfigFun })
}

export function* updateConfig() {
  try {
    const body = yield select(Selectors.getConfigUpdateBody)
    const newsradarId = yield select(Selectors.getNewsradarId)

    yield call(Api.updateConfig, body, newsradarId)
    yield call(CookieHelper.deleteCookie, CookieNames.INITIAL_ROUTE)
    yield put(Actions.updateConfigRequestSuccess())
    yield put(genericSuccessMessage())
  } catch (error) {
    yield put(Actions.updateConfigRequestError(error))
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export function* updateUserConfig() {
  try {
    const userConfig = yield select(Selectors.getUserConfig)

    const body = {
      id: userConfig.get('id'),
      data: {
        news_saved_search_id: userConfig.get('newsSavedSearchId'),
        profile_monitoring_saved_search_id: userConfig.get('profileMonitoringSavedSearchId'),
        news_pool_saved_search_id: userConfig.get('newsPoolSavedSearchId'),
        analysis_saved_search_id: userConfig.get('analysisSavedSearchId'),
        dashboard_id: userConfig.get('dashboardId'),
        translate_search_results: userConfig.get('translateSearchResults'),
        translate_search_results_target_lang: userConfig.get('translateSearchResultsTargetLang')
      }
    }

    const { id } = yield call(Api.updateUserConfig, body)
    yield put(Actions.updateUserConfigRequestSuccess(id))
    yield put(genericSuccessMessage())
  } catch (error) {
    yield put(Actions.updateUserConfigRequestError(error))
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export function* refreshContentDeskPlanUsageAndLimits() {
  const i18n = yield select(Selectors.getI18n)

  try {
    yield put(Actions.fetchCDeskConfigRequestStart())
    yield take(Actions.fetchCDeskConfigRequestSuccess)

    yield put(showAppSnackbarMessage({
      text: i18n.get('ccd_config_plan_usage_and_limits_refresh_success'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(showAppSnackbarMessage({
      text: i18n.get('ccd_config_plan_usage_and_limits_refresh_error'),
      variant: 'error'
    }))
  }
}

export function* fetchContentDeskConfig() {
  try {
    const result = yield call(Api.fetchContentDeskConfig)
    yield put(Actions.setCDeskConfig(result))
    yield put(Actions.fetchCDeskConfigRequestSuccess())
  } catch (error) {
    yield put(Actions.fetchCDeskConfigRequestError())
  }
}

export function* createContentDeskConfig() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const contentDeskConfig = yield select(Selectors.getCDeskConfig)
    const newsradarId = yield select(Selectors.getNewsradarId)

    const body = {
      sender_email: contentDeskConfig.get('senderEmail'),
      sender_name: contentDeskConfig.get('senderName'),
      domain: contentDeskConfig.get('domain'),
      domain_region: contentDeskConfig.get('domainRegion'),
      register_domain: contentDeskConfig.get('useCustomDomain')
    }

    const result = yield call(Api.createContentDeskConfig, body, newsradarId)
    yield put(Actions.createCDeskConfigRequestSuccess())
    yield put(Actions.setCDeskConfig(result))
    yield put(genericSuccessMessage())
  } catch (error) {
    yield put(Actions.createCDeskConfigRequestError())

    if (error.response && error.response.statusCode === 409) {
      yield put(showAppMessage({
        text: i18n.get('domain_already_exists'),
        success: false
      }))
    } else {
      yield put(genericErrorMessage())
    }

    yield put(exception(error))
  }
}

export function* updateContentDeskConfig() {
  try {
    const contentDeskConfig = yield select(Selectors.getCDeskConfig)
    const user = yield select(Selectors.getUser)

    const id = contentDeskConfig.get('id')

    const body = {
      sender_email: contentDeskConfig.get('senderEmail'),
      sender_name: contentDeskConfig.get('senderName')
    }

    if (user.get('isEmployee')) {
      body.domain = contentDeskConfig.get('domain')
      body.domain_region = contentDeskConfig.get('domainRegion')
    }

    const result = yield call(Api.updateContentDeskConfig, id, body)

    yield put(Actions.updateCDeskConfigRequestSuccess())
    yield put(Actions.setCDeskConfig(result))
    yield put(genericSuccessMessage())
  } catch (error) {
    yield put(Actions.updateCDeskConfigRequestError())
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export function* verifyContentDeskDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const contentDeskConfig = yield select(Selectors.getCDeskConfig)
    const newsradarId = yield select(Selectors.getNewsradarId)

    const body = {
      id: contentDeskConfig.get('id'),
      domain: contentDeskConfig.get('domain'),
      domain_region: contentDeskConfig.get('domainRegion')
    }

    const result = yield call(Api.verifyContentDeskDomain, body, newsradarId)
    yield put(Actions.verifyCDeskDomainRequestSuccess())

    if (result.state === 'active') {
      yield put(Actions.setCDeskConfig(result.config))
      yield put(showAppMessage({
        text: i18n.get('domain_is_active'),
        success: true
      }))
    } else {
      yield put(showAppMessage({
        text: i18n.get('domain_is_not_verified'),
        success: false
      }))
    }
  } catch (error) {
    yield put(Actions.verifyCDeskDomainRequestError())
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export function* colorChanges() {
  const colors = yield select(Selectors.getColors)

  yield call(updateColorInMeta, colors.get('primary'))
}

export function* orderPaidVersion({ payload: id }) {
  try {
    const result = yield call(Api.createOrder, { paid_feature_id: id })

    yield put(Actions.orderPaidVersionSuccess())
    yield put(Actions.updatePaidFeatures(result))
  } catch (error) {
    yield put(Actions.orderPaidVersionError(error))
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export function* watchConfigStart() {
  yield takeEvery(Actions.configRequestStart, fetchConfig)
}

export function* watchConfigUpdateStart() {
  yield takeEvery(Actions.updateConfigRequestStart, updateConfig)
}

export function* watchUpdateUserConfigStart() {
  yield takeEvery(Actions.updateUserConfigRequestStart, updateUserConfig)
}

export function* watchRefreshContentDeskPlanUsageAndLimits() {
  yield takeEvery(Actions.refreshContentDeskPlanUsageAndLimits, refreshContentDeskPlanUsageAndLimits)
}

export function* watchFetchCDeskConfigRequestStart() {
  yield takeEvery(Actions.fetchCDeskConfigRequestStart, fetchContentDeskConfig)
}

export function* watchCreateCDeskConfig() {
  yield takeEvery(Actions.createCDeskConfigRequestStart, createContentDeskConfig)
}

export function* watchUpdateCDeskConfig() {
  yield takeEvery(Actions.updateCDeskConfigRequestStart, updateContentDeskConfig)
}

export function* watchVerifyCDeskDomain() {
  yield takeEvery(Actions.verifyCDeskDomainRequestStart, verifyContentDeskDomain)
}

export function* watchColorChanges() {
  yield takeEvery(Actions.setConfig, colorChanges)
  yield takeEvery(Actions.updateConfig, colorChanges)
  yield takeEvery(Actions.resetColors, colorChanges)
}

export function* watchOrderPaidVersion() {
  yield takeEvery(Actions.orderPaidVersionStart, orderPaidVersion)
}

export default function* configSaga() {
  yield all([
    watchConfigStart(),
    watchConfigUpdateStart(),
    watchUpdateUserConfigStart(),
    watchColorChanges(),
    watchRefreshContentDeskPlanUsageAndLimits(),
    watchFetchCDeskConfigRequestStart(),
    watchCreateCDeskConfig(),
    watchUpdateCDeskConfig(),
    watchVerifyCDeskDomain(),
    watchOrderPaidVersion()
  ])
}
