import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { createLoadable } from 'utils/loadable'

import { NewsPageViews, Capabilities } from 'static/constants'

import { Button, IconButton } from 'containers/themed'

import TransitionComponent from 'components/transition_component'
import CapSafe, { ViewConfigCapSafe } from 'containers/CapSafe'
import NewsPageAppBar from 'containers/NewsPageAppBar'
import ErrorBoundary from 'components/error_boundary'
import SearchBar from 'containers/SearchBar'
import QueryTypeDropdown from 'containers/QueryTypeDropdown'
import SavedSearchesMenu from 'containers/news_page/SavedSearchesMenu'
import CustomTagsMenu from 'containers/news_page/CustomTagsMenu'
import masterConfig from 'static/master_config'

import { Icon } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  content: {
    paddingTop: '0 !important',
    transition: 'filter 500ms, opacity 500ms',
    [theme.breakpoints.only('xs')]: {
      paddingTop: 0
    }
  },
  invalid: {
    filter: 'blur(4px) grayscale(100%)',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      opacity: 0.1
    }
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flex: 1
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    }
  },
  charts: {
    marginTop: 10
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14pt',
    padding: '20px 10px 10px',
    color: theme.palette.grey[600]
  },
  timeline: {
    height: 250,
    marginBottom: 40
  },
  iconButton: {
    padding: 0
  }
}))

const ActiveFilterList = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageActiveFilterList" */ 'containers/ActiveFilterList')
))
const AnalysisQueries = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageAnalysisQueries" */ 'containers/news_page/analysis_queries/AnalysisQueries')
))
const CenterMessage = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageCenterMessage" */ 'containers/CenterMessage')
))
const ContentContainer = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageContentContainer" */ 'components/content_container')
))
const Charts = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageCharts" */ './charts')
))
const StaticCharts = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageStaticCharts" */ 'containers/news_page/StaticCharts')
))
const DefaultNewsView = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageDefaultNewsView" */ 'containers/news_page/DefaultNewsView')
))
const DocumentDownloadDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageDocumentDownloadDialog" */ 'containers/DocumentDownloadDialog')
))
const EditDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageEditDialog" */ 'containers/news/edit_dialog/EditDialog')
))
const InfluencerDetailsDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageInfluencerDetailsDialog" */ 'containers/influencer/details_dialog/DetailsDialog')
))
const AddInfluencerToContactsDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageAddInfluencerToContactsDialog" */ 'containers/influencer/AddInfluencerToContactsDialog')
))
const DistributionListCreateDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageDistributionListCreateDialog" */ 'containers/influencer/DistributionListCreateDialog')
))
const Influencers = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageInfluencers" */ 'containers/news_page/influencers/Influencers')
))
const JumpMark = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageJumpMark" */ 'containers/news_page/NewsPageJumpMark')
))
const Navigation = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageNavigation" */ 'containers/news_page/Navigation')
))
const AssignCustomTagsToNewsDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageAssignCustomTagsToNewsDialog" */ 'containers/custom_tags/AssignCustomTagsToNewsDialog')
))
const PageIdentities = createLoadable(() => (
  import(/* webpackChunkName: "NewsPagePageIdentities" */ 'containers/news_page/page_identities/PageIdentities')
))
const PageIdentityStatistics = createLoadable(() => (
  import(/* webpackChunkName: "NewsPagePageIdentityStatistics" */ 'containers/news_page/page_identity_statistics/PageIdentityStatistics')
))
const Publications = createLoadable(() => (
  import(/* webpackChunkName: "NewsPagePublications" */ 'containers/news_page/publications/Publications')
))
const RemoveDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageRemoveNewsDialog" */ 'containers/news/RemoveDialog')
))
const SavedSearchesOptions = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageSavedSearchesOptions" */ 'containers/news_page/SavedSearchesOptions')
))
const ShoppingCartButton = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageShoppingCartButton" */ 'containers/shopping_cart/Button')
))
const ShoppingDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageShoppingDialog" */ 'containers/news/shopping_dialog/ShoppingDialog')
))
const AiChartAnalysisDialog = createLoadable(() => (
  import(/* webpackChunkName: "NewsPageAiChartAnalysisDialog" */ 'containers/charts/AiChartAnalysisDialog')
))

const CustomTagsIcon = CapSafe(IconButton, Capabilities.HAS_CUSTOM_TAGS_MODULE)
const CustomTagsButton = CapSafe(Button, Capabilities.HAS_CUSTOM_TAGS_MODULE)
const CustomTagsMenuComp = CapSafe(CustomTagsMenu, Capabilities.HAS_CUSTOM_TAGS_MODULE)
const SavedSearchesIcon = ViewConfigCapSafe(IconButton, 'savedSearches')
const SavedSearchesButton = ViewConfigCapSafe(Button, 'savedSearches')
const SavedSearchesMenuComp = ViewConfigCapSafe(SavedSearchesMenu, 'savedSearches')
const SavedSearchesOptionsComp = ViewConfigCapSafe(() => (
  <>
    <br />
    <SavedSearchesOptions />
    <br />
  </>
), 'savedSearches', <br />)

const viewComps = {
  [NewsPageViews.CHARTS]: <Charts />,
  [NewsPageViews.STATIC_CHARTS]: <StaticCharts />,
  [NewsPageViews.INFLUENCERS]: <Influencers />,
  [NewsPageViews.PUBLICATIONS]: <Publications />,
  [NewsPageViews.PROFILES]: <PageIdentities />,
  [NewsPageViews.STATISTICS]: <PageIdentityStatistics />,
  [NewsPageViews.BACKWARDS_NEWS]: <DefaultNewsView />,
  [NewsPageViews.NEWS]: <DefaultNewsView />,
  [NewsPageViews.POSTS]: <DefaultNewsView />,
  [NewsPageViews.ANALYSIS_QUERIES]: <AnalysisQueries />
}

export default function NewsPage({
  isValidSearch,
  hasFilters,
  i18n,
  onInvalidSearch,
  view,
  device,
  allowedViews
}) {
  const { classes } = useStyles()
  const renderCenterMessage = () => {
    if (isValidSearch) {
      return null
    }

    setTimeout(() => onInvalidSearch(), 50)

    return (
      <CenterMessage
        icon="help"
        title={i18n.get('invalid_search_title')}
        message={i18n.get('invalid_search_message')}
        actions={[
          <SearchBar
            accent
            autoFocus
            queryTypeDropDown={<QueryTypeDropdown />}
            key="search_bar"
          />
        ]}
      />
    )
  }

  const renderAppBarAction = (actionView, name, Comp, IconComp, icon, MenuComp) => {
    if (allowedViews.indexOf(actionView) === -1) {
      return null
    }

    let handle = (
      <Comp
        color="inherit"
        key={actionView}
        startIcon={<Icon>{icon}</Icon>}
      >
        {i18n.get(name)}
      </Comp>
    )

    if (device.get('lt-lg')) {
      handle = (
        <IconComp
          key={actionView}
          classes={{ root: classes.iconButton }}
          color="inherit"
          title={i18n.get(name)}
        >
          <Icon>
            {icon}
          </Icon>
        </IconComp>
      )
    }

    return (
      <MenuComp
        key={actionView}
        handle={handle}
      />
    )
  }

  const appBarActions = [
    renderAppBarAction(
      NewsPageViews.PIN_BOARDS,
      NewsPageViews.PIN_BOARDS,
      CustomTagsButton,
      CustomTagsIcon,
      masterConfig.getIn(['icons', 'customTags']),
      CustomTagsMenuComp
    ),
    renderAppBarAction(
      NewsPageViews.SAVED_SEARCHES,
      `${NewsPageViews.SAVED_SEARCHES}_short`,
      SavedSearchesButton,
      SavedSearchesIcon,
      masterConfig.getIn(['icons', 'savedSearches']),
      SavedSearchesMenuComp
    )
  ].filter(a => a !== null)

  let containerClass = classes.content

  if (!isValidSearch) {
    containerClass = `${containerClass} ${classes.invalid}`
  }

  return (
    <>
      <ErrorBoundary>
        <AssignCustomTagsToNewsDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <EditDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <ShoppingDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <InfluencerDetailsDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <AddInfluencerToContactsDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <DistributionListCreateDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <RemoveDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <DocumentDownloadDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <AiChartAnalysisDialog />
      </ErrorBoundary>

      <JumpMark />

      <NewsPageAppBar actions={appBarActions} />

      <ContentContainer className={containerClass}>
        <SavedSearchesOptionsComp />

        {hasFilters && (
          <>
            <ActiveFilterList />
            <br />
          </>
        )}

        <Navigation />
        <ErrorBoundary>
          {viewComps[view] ? viewComps[view] : <div />}
        </ErrorBoundary>
        <ShoppingCartButton />
      </ContentContainer>

      <TransitionComponent type="fadeSlow">
        {renderCenterMessage()}
      </TransitionComponent>
    </>
  )
}

NewsPage.propTypes = {
  i18n: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  device: PropTypes.object.isRequired,
  allowedViews: PropTypes.instanceOf(List).isRequired,
  isValidSearch: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool.isRequired,

  onInvalidSearch: PropTypes.func.isRequired
}
