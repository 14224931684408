import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { fromJS, List, Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { Paper, Grid, Box, Typography, Chip, MenuItem } from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EditIcon from '@mui/icons-material/Edit'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PoolIcon from '@mui/icons-material/Pool'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import NotesIcon from '@mui/icons-material/Notes'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { red, green, blue, grey, orange } from '@mui/material/colors'

import { Capabilities } from 'static/constants'
import { listUniqueById } from 'utils/immutable'

import ContentsDelete from 'containers/content_desk/ContentsDelete'
import { IconButton, Menu } from 'containers/themed'
import CapSafe from 'containers/CapSafe'
import ContentsTitle from 'containers/content_desk/ContentsTitle'

const SwitchToSearchPoolButton = CapSafe(IconButton, Capabilities.HAS_NEWS_POOL_MODULE)

const useStyles = makeStyles()(theme => ({
  contentActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  moreActionsButton: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: !theme.isDark && '#404040'
  },
  status: {
    marginLeft: '0.2em'
  },
  deleted: {
    color: red[500]
  },
  plannedForBeforeNow: {
    color: red[400]
  }
}))

const renderStatusIcon = status => {
  switch (status) {
    case 'failed':
      return <CancelIcon style={{ color: red[500] }} />
    case 'released':
      return <CheckCircleIcon style={{ color: green[500] }} />
    case 'scheduled':
      return <AccessTimeIcon style={{ color: blue[500] }} />
    case 'draft':
      return <PendingOutlinedIcon style={{ color: grey[500] }} />
    case 'review_pending':
      return <AlarmOnIcon style={{ color: blue[500] }} />
    case 'revisions_needed':
      return <ChangeCircleIcon style={{ color: orange[500] }} />
    case 'approved':
      return <CheckCircleIcon style={{ color: green[500] }} />
    default:
      return <HelpOutlineOutlinedIcon />
  }
}

function ColumnLabel({ label }) {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ fontStyle: 'italic', color: theme => !theme.isDark && '#757575' }}
      >
        {label}
      </Typography>
    </Box>
  )
}

function ColumnFooter({ text }) {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ color: theme => !theme.isDark && '#757575' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

function GridItem({ size, sx, children }) {
  return (
    <Grid
      item
      xs={12}
      md={size}
      sx={sx}
    >
      {children}
    </Grid>
  )
}

export default function ContentsItem({
  data,
  contentDeskContentTypes,
  onEditClick,
  onCloneClick,
  onViewClick,
  onSwitchToSearchPoolClick,
  onStatusChange,
  saveContentStart
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const plannedForDate = data.get('plannedFor')
  const isPlannedForBeforeNow = moment(plannedForDate).isBefore(moment())
  const scheduledForDate = data.get('scheduledFor')
  const releasedAtDate = data.get('releasedAt')
  const campaignId = data.getIn(['ccdCampaign', 'id'])
  const campaignName = data.getIn(['ccdCampaign', 'name'])
  const plannedFor = plannedForDate ? moment(plannedForDate).format('lll') : '-'
  const scheduledFor = scheduledForDate ? moment(scheduledForDate).format('lll') : '-'
  const lastEditedDate = moment(data.get('updatedAt')).format('lll')
  const status = data.get('status') || data.get('approvalStatus')
  const editor = `${data.getIn(['lastEditor', 'firstName'])} ${data.getIn(['lastEditor', 'lastName'])}`
  const plainText = data.get('plainText') || ''
  const showSearchPoolButton = plainText.length >= 1200
  const [editDisabled, setEditDisabled] = useState(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)

  const [statusChip, setStatusChip] = useState(status)

  useEffect(() => {
    setStatusChip(status)
  }, [status])

  const handleNotes = () => onEditClick({ id: data.get('id'), openContentNotesDialog: true })
  const handleEdit = () => onEditClick({ id: data.get('id'), openEditContentDialog: true })
  const handleViewClick = () => onViewClick(data)

  const currentType = contentDeskContentTypes.find(c => c.get('name').toLowerCase() === data.get('type').toLowerCase())

  const resetLinkedInSpecificFields = () => {
    let linkedInPost = data.get('linkedInPost').delete('createdPostId')

    const mediaFiles = data.get('ccdMediaFiles')

    // this could be an image post or an video post
    // field id needs to be reset to our internal id instead of the linkedIn image ID
    if (mediaFiles.size === 1) {
      linkedInPost = linkedInPost.setIn(['content', 'media', 'id'], String(mediaFiles.first().get('id')))
    }

    // for multi_image
    if (mediaFiles.size > 1) {
      let linkedInImages = mediaFiles.map(selected => (fromJS({
        altText: selected.get('filename'),
        id: String(selected.get('id'))
      })))

      linkedInImages = listUniqueById(linkedInImages)

      const mediaContent = fromJS({
        multiImage: {
          images: linkedInImages
        }
      })

      linkedInPost = linkedInPost.set('content', mediaContent)
    }

    return linkedInPost
  }

  const resetFacebookSpecificFields = () => {
    const facebookPost = data
      .get('facebookPost')
      .delete('createdPostId')
    const mediaAttachments = facebookPost
      .get('mediaAttachments')
      .map(attachment => attachment.delete('fbid'))

    return facebookPost.set('mediaAttachments', mediaAttachments)
  }

  const handleClone = () => {
    let linkedInPost
    let facebookPost

    if (data.get('type').toLowerCase() === 'linkedin') {
      linkedInPost = resetLinkedInSpecificFields()
    }

    if (data.get('type').toLowerCase() === 'facebook') {
      facebookPost = resetFacebookSpecificFields()
    }

    onCloneClick(
      data
        .set('ccdCampaign', fromJS({ value: campaignId, label: campaignName }))
        .set('type', fromJS({ value: currentType.get('id'), label: currentType.get('name') }))
        .set('id', null)
        .set('scheduledFor', null)
        .set('recipients', List())
        .set('linkedInPost', linkedInPost)
        .set('facebookPost', facebookPost)
        .set('title', `${data.get('title')} - duplicate(1)`)
        .set('scheduledJobName', null)
        .set('scheduledEventsJobName', null)
        .set('status', null)
    )

    saveContentStart({ openEditContentDialog: true })
  }

  const handleStatusChange = newStatus => {
    setStatusChip(newStatus)
    onStatusChange({
      id: data.get('id'),
      title: data.get('title'),
      plannedFor: data.get('plannedFor'),
      scheduledFor: data.get('scheduledFor'),
      scheduledForJobName: data.get('scheduledJobName'),
      scheduledEventsJobName: data.get('scheduledEventsJobName'),
      approvalStatus: newStatus,
      ccdCampaignId: campaignId
    })
  }

  const getCampaignName = campaign => (campaign === 'No Campaign' ? i18n.get('no_campaign') : `${i18n.get('campaign')}: ${campaign}`)

  const renderTitleGridItem = () => (
    <GridItem size={3.5}>
      <ColumnLabel label={currentType && currentType.get('name')} />
      <ContentsTitle content={data} />
      <ColumnFooter text={getCampaignName(campaignName)} />
    </GridItem>
  )

  const renderEditorGridItem = () => (
    <GridItem size={2}>
      <ColumnLabel label={i18n.get('last_edited_by')} />
      <div>{editor}</div>
      <ColumnFooter text={lastEditedDate} />
    </GridItem>
  )

  const renderPlannedScheduledForGridItem = () => (
    <GridItem size={2}>
      <ColumnLabel label={i18n.get('planned_for')} />
      <div className={isPlannedForBeforeNow ? classes.plannedForBeforeNow : ''}>{plannedFor}</div>
      <ColumnLabel label={i18n.get('scheduled_for')} />
      <div>{scheduledFor}</div>
    </GridItem>
  )

  const renderStatusViewGridItem = () => (
    <GridItem size={2}>
      <div className={classes.statusWrapper}>
        {renderStatusIcon(status)}
        <div className={classes.status}>{i18n.get(status)}</div>
        {data.get('deletedOnSocial') && (
          <div>&nbsp;|&nbsp;<span className={classes.deleted}>{i18n.get('deleted')}</span></div>
        )}
      </div>

      {status === 'released' && <ColumnFooter text={moment(releasedAtDate).format('lll')} />}
    </GridItem>
  )

  const renderStatusChipGridItem = () => (
    <GridItem size={2}>
      <div className={classes.statusWrapper}>
        <Menu
          handle={(
            <Chip
              icon={renderStatusIcon(statusChip)}
              label={i18n.get(statusChip)}
              variant="outlined"
            />
          )}
        >
          <MenuItem onClick={() => handleStatusChange('draft')}>
            <PendingOutlinedIcon style={{ color: grey[500], marginRight: '0.3em' }} />
            {i18n.get('draft')}
          </MenuItem>
          <MenuItem onClick={() => handleStatusChange('review_pending')}>
            <AlarmOnIcon style={{ color: blue[500], marginRight: '0.3em' }} />
            {i18n.get('review_pending')}
          </MenuItem>
          <MenuItem onClick={() => handleStatusChange('revisions_needed')}>
            <ChangeCircleIcon style={{ color: orange[500], marginRight: '0.3em' }} />
            {i18n.get('revisions_needed')}
          </MenuItem>
          <MenuItem onClick={() => handleStatusChange('approved')}>
            <CheckCircleIcon style={{ color: green[500], marginRight: '0.3em' }} />
            {i18n.get('approved')}
          </MenuItem>
        </Menu>
      </div>
    </GridItem>
  )

  const renderStatusGridItem = () => {
    const releasedStatuses = ['released', 'scheduled', 'failed']

    if (releasedStatuses.includes(status)) {
      return renderStatusViewGridItem()
    }

    return renderStatusChipGridItem()
  }

  const renderActionGridItem = () => (
    <GridItem
      size={2}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'end'
      }}
    >

      <IconButton
        size="small"
        onClick={handleViewClick}
        title={i18n.get('view')}
      >
        <RemoveRedEyeIcon />
      </IconButton>
      <IconButton
        size="small"
        title={i18n.get('edit')}
        disabled={status === 'released' || (status === 'scheduled' && editDisabled)}
        onClick={handleEdit}
      >
        <EditIcon />
      </IconButton>
      <ContentsDelete
        deleteDisabled={editDisabled}
        title={data.get('title')}
        deleteData={{
          id: data.get('id')
        }}
      />
      <Menu
        handle={(
          <IconButton
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        )}
      >
        <div
          onClick={() => { /* Needed to close the menu on action click. */ }}
          className={classes.moreActionsButton}
        >
          {showSearchPoolButton && (
            <SwitchToSearchPoolButton
              size="small"
              title={i18n.get('switch_to_news_pool_module')}
              disabled={status !== 'released'}
              onClick={() => onSwitchToSearchPoolClick({
                field: 'ccdContents',
                value: data
              })}
            >
              <PoolIcon />
            </SwitchToSearchPoolButton>
          )}
          <IconButton
            size="small"
            title={i18n.get('duplicate')}
            onClick={handleClone}
          >
            <ContentCopyIcon />
          </IconButton>
          <IconButton
            size="small"
            title={i18n.get('notes')}
            onClick={handleNotes}
          >
            <NotesIcon />
          </IconButton>
        </div>
      </Menu>
    </GridItem>
  )

  const disableEdit = () => setEditDisabled(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)

  useEffect(() => {
    if (status === 'scheduled' && moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 15 && !editDisabled) {
      const interval = setInterval(() => disableEdit(), 5000)

      return () => clearInterval(interval)
    }

    setEditDisabled(false)

    return () => null
  }, [status])

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {renderTitleGridItem()}
        {renderEditorGridItem()}
        {renderPlannedScheduledForGridItem()}
        {renderStatusGridItem()}
        {renderActionGridItem()}
      </Grid>
    </Paper>
  )
}

ContentsItem.propTypes = {
  data: PropTypes.instanceOf(ImmutableMap).isRequired,
  contentDeskContentTypes: PropTypes.instanceOf(List).isRequired,

  onCloneClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSwitchToSearchPoolClick: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  saveContentStart: PropTypes.func.isRequired
}

GridItem.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number.isRequired,
  sx: PropTypes.object
}

ColumnLabel.propTypes = {
  label: PropTypes.string
}

ColumnFooter.propTypes = {
  text: PropTypes.string
}
