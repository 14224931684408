import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  codes: {
    color: theme.palette.grey[600],
    fontSize: '8pt',
    fontWeight: 300
  }
}))

export default function Codes({ news }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const codes = news.get('codes')

  if (codes.isEmpty()) {
    return null
  }

  return (
    <div className={classes.codes}>
      {i18n.get('topics')}: {codes.sortBy(code => code.get('sortcode')).map(code => code.get('name')).join(' | ')}
    </div>
  )
}

Codes.propTypes = {
  news: PropTypes.object.isRequired
}
