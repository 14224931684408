import { connect } from 'react-redux'

import CampaignsItem from 'components/content_desk/campaigns/campaigns_item'

import { openCampaignForm, setCampaignDialogEditMode, switchToSearchPool } from 'actions/content_desk'

const mapStateToProps = _state => ({
})

export default connect(
  mapStateToProps,
  {
    onClick: openCampaignForm,
    setEditMode: setCampaignDialogEditMode,
    onSwitchToSearchPoolClick: switchToSearchPool
  }
)(CampaignsItem)
