import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/notifications'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  notifications: []
})

export default handleActions({
  [Actions.setNotifications]: (state, { payload }) => state.set('notifications', fromJS(payload)),
  [Actions.fetchNotificationsSuccess]: (state, { payload }) => state
    .update('notifications', notifications => notifications.push(fromJS(...payload))),
  [Actions.updateNotifications]: (state, { payload }) => { // needs refactoring, ugly af
    const [first] = payload

    const newState = state.updateIn(['notifications'], notifications => (
      notifications.map(notification => {
        if (payload.some(p => notification.get('id') === p.groot_notification_id)) {
          return notification.set('seenAt', first.seenAt)
        }

        return notification
      })
    ))

    return newState
  },
  [AppActions.resetState]: () => initialState
}, initialState)
