import { createAction } from 'redux-actions'
import createLoadingAction from 'utils/createLoadingAction'

const defaultPrefix = 'NEWS/SHOPPING_CART/'
let prefix = defaultPrefix

export const addAllNewsToShoppingCartError = createAction(`${prefix}ADD_ALL_NEWS_TO_SHOPPING_CART_ERROR`)
export const addAllNewsToShoppingCartStart = createAction(`${prefix}ADD_ALL_NEWS_TO_SHOPPING_CART_START`)
export const addAllNewsToShoppingCartSuccess = createAction(`${prefix}ADD_ALL_NEWS_TO_SHOPPING_CART_SUCCESS`)

export const addNewsToShoppingCart = createAction(`${prefix}ADD_NEWS_TO_SHOPPING_CART`)
export const collapseAllNewsLists = createAction(`${prefix}COLLAPSE_ALL_NEWS_LISTS`)
export const expandAllNewsLists = createAction(`${prefix}EXPAND_ALL_NEWS_LISTS`)
export const fetchNewsError = createLoadingAction(`${prefix}FETCH_NEWS_ERROR`, false)
export const fetchNewsStart = createLoadingAction(`${prefix}FETCH_NEWS_START`, true)
export const fetchNewsSuccess = createLoadingAction(`${prefix}FETCH_NEWS_SUCCESS`, false)
export const moveCodeInShoppingCart = createAction(`${prefix}MOVE_CODE_IN_SHOPPING_CART`)
export const moveNewsInShoppingCart = createAction(`${prefix}MOVE_NEWS_IN_SHOPPING_CART`)
export const moveNewsInShoppingCartSublist = createAction(`${prefix}MOVE_NEWS_IN_SHOPPING_CART_SUBLIST`)
export const removeNewsFromShoppingCart = createAction(`${prefix}REMOVE_NEWS_FROM_SHOPPING_CART`)
export const resetShoppingCart = createAction(`${prefix}RESET_SHOPPING_CART`)
export const sortBy = createAction(`${prefix}SORT_BY`)
export const sortByUngrouped = createAction(`${prefix}SORT_BY_UNGROUPED`)
export const toggleHighlight = createAction(`${prefix}TOGGLE_HIGHLIGHT`)
export const toggleNewsList = createAction(`${prefix}TOGGLE_NEWS_LIST`)
export const updateTabIndex = createAction(`${prefix}UPDATE_TAB_INDEX`)
export const setCustomTagId = createAction(`${prefix}SET_CUSTOM_TAG_ID`)
export const setRunning = createAction(`${prefix}SET_RUNNING`)
export const pinAllStart = createAction(`${prefix}PIN_ALL_START`)
export const pinAllError = createAction(`${prefix}PIN_ALL_ERROR`)
export const pinAllSuccess = createAction(`${prefix}PIN_ALL_SUCCESS`)
export const unpinAll = createAction(`${prefix}UNPIN_ALL`)
export const showCustomTagDialog = createAction(`${prefix}SHOW_CUSTOM_TAG_DIALOG`)
export const hideCustomTagDialog = createAction(`${prefix}HIDE_CUSTOM_TAG_DIALOG`)
export const showRemoveCustomTagDialog = createAction(`${prefix}SHOW_REMOVE_CUSTOM_TAG_DIALOG`)
export const hideRemoveCustomTagDialog = createAction(`${prefix}HIDE_REMOVE_CUSTOM_TAG_DIALOG`)
export const toggleSnippets = createAction(`${prefix}TOGGLE_SNIPPETS`)
export const checkShoppingCartSize = createAction(`${prefix}CHECK_SHOPPING_CART_SIZE`)
export const setCodes = createAction(`${prefix}SET_CODES`)
export const setNews = createAction(`${prefix}SET_NEWS`)
export const recalculateGroupedSorting = createAction(`${prefix}RECALCULATE_GROUPED_SORTING`)
export const setAutoAddShoppingCartNews = createAction(`${prefix}SET_AUTO_ADD_SHOPPING_CART_NEWS`)

export const fetchClusterForShoppingCartError = createAction(`${prefix}FETCH_CLUSTER_FOR_SHOPPING_CART_ERROR`)
export const fetchClusterForShoppingCartStart = createAction(`${prefix}FETCH_CLUSTER_FOR_SHOPPING_CART_START`)
export const fetchClusterForShoppingCartSuccess = createAction(`${prefix}FETCH_CLUSTER_FOR_SHOPPING_CART_SUCCESS`)

prefix = `${defaultPrefix}AI_ANALYSIS/`
export const aiAnalysisStart = createAction(`${prefix}AI_ANALYSIS_START`)
export const aiAnalysisError = createAction(`${prefix}AI_ANALYSIS_ERROR`)
export const aiAnalysisSuccess = createAction(`${prefix}AI_ANALYSIS_SUCCESS`)
export const showAiAnalysisDialog = createAction(`${prefix}SHOW_AI_ANALYSIS_DIALOG`)
export const hideAiAnalysisDialog = createAction(`${prefix}HIDE_AI_ANALYSIS_DIALOG`)

prefix = `${defaultPrefix}SELECTED/`
export const selectNews = createAction(`${prefix}SELECT_NEWS`)
export const resetNewsSelection = createAction(`${prefix}RESET_NEWS_SELECTION`)
export const invertNewsSelection = createAction(`${prefix}INVERT_NEWS_SELECTION`)
export const setSelectedNews = createAction(`${prefix}SET_SELECTED_NEWS`)

prefix = `${defaultPrefix}SORTING/`
export const selectNewsForSorting = createAction(`${prefix}SELECT_NEWS_FOR_SORTING`)
export const shiftSelectNewsForSorting = createAction(`${prefix}SHIFT_SELECT_NEWS_FOR_SORTING`)
export const resetSortingSelection = createAction(`${prefix}RESET_SORTING_SELECTION`)

prefix = `${defaultPrefix}CLUSTER/`
export const showClusterDialog = createAction(`${prefix}SHOW_CLUSTER_DIALOG`)
export const hideClusterDialog = createAction(`${prefix}HIDE_CLUSTER_DIALOG`)
export const fetchReorderClusteredNewsError = createAction(`${prefix}FETCH_REORDER_CLUSTERED_NEWS_ERROR`)
export const fetchReorderClusteredNewsStart = createAction(`${prefix}FETCH_REORDER_CLUSTERED_NEWS_START`)
export const fetchReorderClusteredNewsSuccess = createAction(`${prefix}FETCH_REORDER_CLUSTERED_NEWS_SUCCESS`)
export const moveNewsInCluster = createAction(`${prefix}MOVE_NEWS_IN_CLUSTER`)
export const replaceCluster = createAction(`${prefix}REPLACE_CLUSTER`)
export const setActiveNewsIdsInCluster = createAction(`${prefix}SET_ACTIVE_NEWS_IDS_IN_CLUSTER`)
export const setHoveredNewsIdsInCluster = createAction(`${prefix}SET_HOVERED_NEWS_IDS_IN_CLUSTER`)
