import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { Alert } from '@mui/material'
import { Link } from 'react-router-dom'

import { Button } from 'containers/themed'

import CampaignsItem from 'containers/content_desk/CampaignsItem'
import DeleteDialog from 'containers/content_desk/DeleteDialog'

const useStyles = makeStyles()({
  showAllButton: {
    marginTop: '1em'
  }
})

const Campaigns = ({ campaigns, hasSearchQueries, loading }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const renderCampaigns = () => {
    if (campaigns.get('items').size) {
      return campaigns
        .get('items')
        .slice(0, 3)
        .map((el, index) => (
          <CampaignsItem
            campaign={el}
            endDate={el.get('endDate')}
            key={index}
            name={el.get('name')}
            startDate={el.get('startDate')}
            summary={el.get('summary')}
          />
        ))
    }

    if (hasSearchQueries && !loading) {
      return (
        <Alert severity="warning">
          {i18n.get('no_hits_title')}
        </Alert>
      )
    }

    return null
  }

  return (
    <>
      {renderCampaigns()}
      {campaigns.get('items').size > 0 && (
        <Link
          to="/app/content_desk/content_management/campaigns"
        >
          <Button
            variant="contained"
            className={classes.showAllButton}
          >
            {`${i18n.get('all')} ${i18n.get('campaigns')}`}
          </Button>
        </Link>
      )}
      <DeleteDialog entity="campaign" />
    </>
  )
}

Campaigns.propTypes = {
  campaigns: PropTypes.instanceOf(Map).isRequired,
  hasSearchQueries: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
}
export default Campaigns
