import { connect } from 'react-redux'

import ContentsShowAll from 'components/content_desk/contents/contents_show_all/ContentsShowAll'

import {
  setPage,
  setSelectedQueryType,
  searchContentsStart,
  refreshContentsStatusStart,
  stopRefreshContentsStatus,
  setSortBy,
  setContentFilters
} from 'actions/content_desk'

import {
  getCDeskContents,
  getCDeskContentPage,
  getCDeskSearchContentsRunning,
  getCDeskContentSearchQueries,
  getCDeskCampaignSearchQueries
} from 'selectors'

const mapStateToProps = state => ({
  ccdContents: getCDeskContents(state),
  page: getCDeskContentPage(state),
  loading: getCDeskSearchContentsRunning(state),
  hasSearchQueries: (getCDeskContentSearchQueries(state).size > 0 || getCDeskCampaignSearchQueries(state).size > 0)
})

export default connect(
  mapStateToProps,
  {
    setPage,
    setSelectedQueryType,
    setSortBy,
    setContentFilters,
    searchContentsStart,
    onLoad: refreshContentsStatusStart,
    onUnload: stopRefreshContentsStatus
  }
)(ContentsShowAll)
