import React, { useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Divider,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Slider
} from '@mui/material'
import { Popover } from 'containers/themed'

import { formatNumber, separate } from 'utils/number'

import Email from 'containers/content_desk/statistics_data/Email'
import LinkedIn from 'containers/content_desk/statistics_data/LinkedIn'
import Facebook from 'containers/content_desk/statistics_data/Facebook'
import News from 'containers/content_desk/statistics_data/News'

const useStyles = makeStyles()(theme => ({
  engagement: {
    fontSize: '30pt',
    fontWeight: '700',
    textAlign: 'center',
    color: !theme.isDark && '#404040'
  },
  engagementLabel: {
    fontSize: '12pt',
    textAlign: 'center',
    color: !theme.isDark && '#757575'
  },
  rootWrapper: {
    marginTop: '0.5em'
  },
  divider: {
    marginTop: '1em'
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '0.5em'
  },
  popover: {
    width: 300,
    maxHeight: 500
  },
  buzz: {
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.9
    }
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
    marginTop: '1em'
  }
}))

function valueLabelFormat(value) {
  return `${value}%`
}

const StatisticsData = ({ data, type, requestRunning, showEarnedStats, onAccuracyChange }) => {
  const { classes, cx } = useStyles()
  const [accuracy, setAccuracy] = useState([30])
  const i18n = useI18n()

  const loadOrShowData = value => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return value
  }

  const renderStatistics = () => {
    if (type === 'email') {
      return <Email data={data} />
    }

    if (type === 'linkedin') {
      return <LinkedIn data={data} />
    }

    if (type === 'facebook') {
      return <Facebook data={data} />
    }

    return null
  }

  const handleAccuracyChange = e => setAccuracy(e.target.value)

  return (
    <div className={classes.rootWrapper}>
      {renderStatistics()}
      {showEarnedStats && (
        <>
          <div className={classes.headlineWrapper}>
            <Typography
              className={classes.headline}
              variant="h6"
            >
              {i18n.get('earned')}
            </Typography>
            <Box sx={{ width: 250 }}>
              <Slider
                value={accuracy}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={handleAccuracyChange}
                onMouseUp={() => onAccuracyChange({ earnedMediaAccuracy: `${accuracy[0]}%` })}
                step={10}
                min={20}
                max={80}
                marks
              />
            </Box>
          </div>
          <Grid
            container
            spacing={2}
            columns={13}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
            >
              <Popover
                classes={{
                  paper: classes.popover
                }}
                handle={(
                  <div
                    className={cx(classes.engagement, classes.buzz)}
                    title={separate(data.getIn(['earned', 'stats', 'total']))}
                  >
                    {loadOrShowData(formatNumber(data.getIn(['earned', 'stats', 'total'])))}
                  </div>
                )}
              >
                <div>
                  <News
                    news={data.getIn(['earned', 'news'])}
                    total={data.getIn(['earned', 'stats', 'total'])}
                  />
                </div>
              </Popover>

              <div className={classes.engagementLabel}>{i18n.get('buzz')}</div>
            </Grid>
            <Divider
              className={classes.divider}
              orientation="vertical"
              flexItem
            />
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
            >
              <div
                className={classes.engagement}
                title={separate(data.getIn(['earned', 'stats', 'reach']))}
              >
                {loadOrShowData(formatNumber(data.getIn(['earned', 'stats', 'reach'])))}
              </div>

              <div className={classes.engagementLabel}>
                {i18n.get('reach')}
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

StatisticsData.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  type: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  showEarnedStats: PropTypes.bool.isRequired,

  onAccuracyChange: PropTypes.func.isRequired
}

export default StatisticsData
