import { connect } from 'react-redux'

import CampaignsDelete from 'components/content_desk/campaigns/campaigns_item/campaigns_delete/CampaignsDelete'

import { openDeleteDialog, setDeleteContentData } from 'actions/content_desk'

const mapStateToProps = (_state, { deleteDisabled, deleteData }) => ({
  deleteDisabled,
  deleteData
})

export default connect(
  mapStateToProps,
  {
    onOpen: () => openDeleteDialog('campaign'),
    setDeleteData: setDeleteContentData
  }
)(CampaignsDelete)
