import {
  put,
  takeEvery,
  all,
  call,
  select,
  take
} from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as Actions from 'actions/contact_management'
import * as AppActions from 'actions/app'
import { fetchCDeskConfigRequestStart } from 'actions/config'
import * as Selectors from 'selectors'
import { downloadBlob } from 'utils/sagas'
import { is } from 'immutable'

const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export function* createDistributionListStart() {
  const i18n = yield select(Selectors.getI18n)
  const data = yield select(Selectors.getCmListData)
  try {
    const body = {
      name: data.get('name'),
      description: data.get('description')
    }
    const result = yield call(Api.createDistributionList, body)

    if (result.message) {
      yield put(Actions.createDistributionListError())
      yield put(AppActions.showAppSnackbarMessage({
        text: i18n.get('distribution_list_name_exists'),
        variant: 'error'
      }))

      return
    }

    yield put(Actions.createDistributionListSuccess(result))
  } catch (error) {
    yield put(Actions.createDistributionListError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* addContactsToDistributionListStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const ccdPlan = yield select(Selectors.getCDeskPlan)
  const data = yield select(Selectors.getCmListData)

  try {
    let contacts = data.get('contacts')

    if (payload) {
      if (payload.source === 'anewstip') {
        contacts = yield select(Selectors.getAnewstipImporContactsForDistributionLists)
      } else if (payload.source === 'influencer') {
        contacts = yield select(Selectors.getInfluencerImportContactsForDistributionLists)
      }
    }

    const body = {
      contact_ids: contacts.map(contact => contact.get('id'))
    }

    if (data.get('id') !== null) {
      const result = yield call(Api.addContactsToDistributionList, data.get('id'), body)
      yield put(Actions.addContactsToDistributionListSuccess(result))
      yield put(AppActions.showAppSnackbarMessage({
        text: i18n.get('added_to_distribution_list'),
        variant: 'success'
      }))
    } else if (contacts.size <= ccdPlan.get('contactsPerDistListLimit')) {
      yield put(Actions.createDistributionListStart())
      yield take(Actions.createDistributionListSuccess)

      const allLists = yield select(Selectors.getCmDistributionLists)

      const result = yield call(
        Api.addContactsToDistributionList,
        allLists
          .find(list => list.get('name') === data.get('name'))
          .get('id'),
        body
      )
      yield put(Actions.addContactsToDistributionListSuccess(result))
      yield put(AppActions.showAppSnackbarMessage({
        text: `${i18n.get('new_distribution_list')} ${i18n.get('created')}`,
        variant: 'success'
      }))
    }

    if (contacts.size > ccdPlan.get('contactsPerDistListLimit')) {
      yield put(Actions.addContactsToDistributionListError())
      yield put(AppActions.showAppSnackbarMessage({
        text: i18n.get('contacts_per_dist_list_limit_reached'),
        variant: 'error'
      }))
    }
  } catch (error) {
    yield put(Actions.addContactsToDistributionListError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* saveContactsStart() {
  const i18n = yield select(Selectors.getI18n)
  const formData = yield select(Selectors.getCmFormData)
  const prevData = yield select(Selectors.getCmSelectedContact)
  const contactId = formData.get('id')
  const contactsNewsradarsId = formData.get('contactsNewsradarsId')

  let tagsToAdd = []
  let tagsToDelete = []

  const uploadedPhoto = yield select(Selectors.getCmUploadedPhoto)

  const tagIds = formData.get('customTags').map(t => t.get('id'))

  if (contactId) {
    const oldTagIds = prevData.get('customTags').map(t => t.get('id'))

    tagsToAdd = tagIds.filter(id => !oldTagIds.includes(id)).toJS()
    tagsToDelete = oldTagIds.filter(id => !tagIds.includes(id)).toJS()
  } else {
    tagsToAdd = tagIds.toJS()
  }

  const languagesToAdd = formData.get('languages').map(t => t.get('id')).toJS()
  const mediaTypesToAdd = formData.get('mediaTypes').map(t => t.get('id')).toJS()
  const mediaTopicsToAdd = formData.get('mediaTopics').map(t => t.get('id')).toJS()

  let picture = formData.get('picture')

  if (uploadedPhoto) {
    const uploadFileForm = [
      {
        key: 'file',
        value: uploadedPhoto
      }
    ]

    const { url } = yield call(Api.uploadFile, uploadFileForm)
    picture = url
  }

  const isMainFieldChanged = (key, value) => (
    key !== 'preferredLanguageId'
    && key !== 'notes'
    && key !== 'customTags'
    && !is(value, prevData.get(key))
  )

  const mainFieldsChanged = formData.some((value, key) => isMainFieldChanged(key, value))
  const switchContact = (formData.get('supplier') !== null) && contactId && mainFieldsChanged

  const requestBody = {
    first_name: formData.get('firstName'),
    last_name: formData.get('lastName'),
    mail: formData.get('mail'),
    title: formData.get('title'),
    job_title: formData.get('jobTitle'),
    dossier: formData.get('dossier'),
    picture,
    address: formData.get('address'),
    phone: formData.get('phone'),
    fax: formData.get('fax'),
    external_publication: formData.get('externalPublication'),
    website: formData.get('website'),
    company: formData.get('company'),
    notes: formData.get('notes'),
    preferred_language_id: formData.get('preferredLanguageId'),
    country_id: formData.getIn(['country', 'id'], null),
    state_id: formData.getIn(['state', 'id'], null),
    linkedin_url: formData.get('linkedinUrl'),
    twitter_url: formData.get('twitterUrl'),
    facebook_url: formData.get('facebookUrl'),
    threads_url: formData.get('threadsUrl'),
    tiktok_url: formData.get('tiktokUrl'),
    instagram_url: formData.get('instagramUrl'),
    youtube_url: formData.get('youtubeUrl'),
    other_url: formData.get('otherUrl'),
    contacts_newsradars_id: contactsNewsradarsId,
    supplier: formData.get('supplier'),
    contacts_languages: languagesToAdd.map(languageId => ({
      language_id: languageId
    })),
    contacts_media_types: mediaTypesToAdd.map(mediaTypeId => ({
      media_type_id: mediaTypeId
    })),
    contacts_media_topics: mediaTopicsToAdd.map(topicId => ({
      media_topic_id: topicId
    })),
    switch_contact: switchContact
  }

  try {
    let result
    let text

    if (contactId) {
      if (tagsToAdd.length) {
        yield call(Api.addTagToContact, contactsNewsradarsId, { custom_tag_ids: tagsToAdd })
      }

      if (tagsToDelete.length) {
        yield all(tagsToDelete.map(customTagId => call(Api.removeTagFromContact, contactsNewsradarsId, customTagId)))
      }

      result = yield call(Api.updateContacts, contactId, requestBody)

      if (result.message) {
        yield put(Actions.saveContactsError())
        yield put(Actions.showEmailExistingDialog())

        return
      }

      text = `${i18n.get('contact')} ${i18n.get('updated')}`
    } else {
      result = yield call(Api.createContacts, requestBody)

      if (result.message) {
        yield put(Actions.saveContactsError())
        yield put(Actions.showEmailExistingDialog())

        return
      }

      if (tagsToAdd.length) {
        yield call(Api.addTagToContact, result.contactsNewsradarsId, { custom_tag_ids: tagsToAdd })
      }

      if (tagsToAdd.length || mediaTopicsToAdd.length) {
        requestBody.contacts_newsradars_id = result.contactsNewsradarsId
        requestBody.contacts_media_topics = mediaTopicsToAdd.map(id => ({
          contact_id: result.id,
          media_topic_id: id
        }))

        result = yield call(Api.updateContacts, result.id, requestBody)
      }

      text = `${i18n.get('contact')} ${i18n.get('created')}`
    }

    yield put(Actions.saveContactsSuccess(result))

    yield put(
      AppActions.showAppMessage({
        text,
        success: true
      })
    )

    if (switchContact) {
      yield put(Actions.fetchContactsStart())
    }
  } catch (error) {
    yield put(Actions.saveContactsError())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* deleteContactsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const selectedContacts = yield select(Selectors.getCmContactsToDelete)
    const contactsToDelete = selectedContacts.map(c => ({
      id: c.get('id'),
      supplier: c.get('supplier')
    }))

    const body = {
      contacts: contactsToDelete
    }

    const result = yield call(Api.deleteContacts, body)

    yield put(Actions.deleteContactsSuccess(result))
    yield put(Actions.fetchContactsStart())
    yield put(
      AppActions.showAppMessage({
        text: `${i18n.get('contacts')} ${i18n.get('deleted')}`,
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.deleteContactsError(error))
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* removeContactFromListStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const listData = yield select(Selectors.getCmListData)
  const id = payload.get('listId') ? payload.get('listId') : listData.get('id')
  const contactId = payload.get('id')

  let result

  try {
    result = yield call(
      Api.removeContactFromList,
      id,
      contactId
    )
    yield put(Actions.removeContactFromListSuccess(result))
  } catch (error) {
    yield put(Actions.removeContactFromListError(error))
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* updateDistributionListStart() {
  const i18n = yield select(Selectors.getI18n)
  const data = yield select(Selectors.getCmListData)

  let result

  try {
    const body = {
      name: data.get('name'),
      description: data.get('description')
    }

    result = yield call(
      Api.updateDistributionList,
      data.get('id'),
      body
    )

    if (result.message) {
      yield put(Actions.updateDistributionListError())
      yield put(AppActions.showAppSnackbarMessage({
        text: i18n.get('distribution_list_name_exists'),
        variant: 'error'
      }))

      return
    }

    yield put(Actions.clearListData())
    yield put(Actions.updateDistributionListSuccess(result))
    yield put(
      AppActions.showAppMessage({
        text: `${i18n.get('distribution_list')} ${i18n.get('updated')}`,
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.updateDistributionListError(error))
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* deleteDistributionListStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const ccdPlan = yield select(Selectors.getCDeskPlan)
  const ccdPlanUsage = yield select(Selectors.getCDeskPlanUsage)

  try {
    const result = yield call(Api.deleteDistributionList, payload)

    if (ccdPlanUsage.get('distributionListsUsage') > ccdPlan.get('distributionListsLimit')) {
      yield put(fetchCDeskConfigRequestStart())
    }

    yield put(Actions.deleteDistributionListSuccess(result))
    yield put(AppActions.showAppSnackbarMessage({
      text: `${i18n.get('distribution_list')} ${i18n.get('deleted')}`,
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.deleteDistributionListError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* uploadContactsStart({ payload: file }) {
  const i18n = yield select(Selectors.getI18n)
  const autoCreateDistributionList = yield select(Selectors.getCmAutoCreateDistributionList)
  const autoAddToExistingList = yield select(Selectors.getCmAutoAddToExistingList)
  const listData = yield select(Selectors.getCmListData)
  const formData = [
    {
      key: 'file',
      value: file
    },
    {
      key: 'auto_create_distribution_list',
      value: autoCreateDistributionList
    },
    {
      key: 'new_list_name',
      value: listData.get('name')
    },
    {
      key: 'auto_add_to_existing_list',
      value: autoAddToExistingList
    },
    {
      key: 'existing_list_id',
      value: listData.get('id')
    }
  ]

  let result
  try {
    result = yield call(Api.uploadContacts, formData)

    if (autoCreateDistributionList || autoAddToExistingList) {
      yield put(Actions.fetchDistributionListsStart())
    }

    yield put(Actions.fetchContactsStart())

    yield put(Actions.uploadContactsSuccess(result))
    yield put(Actions.showUploadResultDialog())
  } catch (error) {
    yield put(Actions.uploadContactsError(error))

    let msg = i18n.get('failed')

    if (error.response && error.response.statusCode === 403) {
      msg = error.response.message
    }

    yield put(
      AppActions.showAppSnackbarMessage({
        text: msg,
        variant: 'error'
      })
    )
  }
}

export function* changeContactTags({ payload }) {
  const existing = payload.newValues.filter(v => !v.isNew).map(t => ({ name: t.label, id: t.value, type: 'custom' }))
  const isNew = payload.newValues.filter(v => v.isNew).map(t => t.label)
  let successData
  let result = []
  try {
    if (isNew.length) {
      result = yield all(isNew.map(tag => call(Api.createContactTag, { name: tag })))
      yield put(Actions.createTag(result))
    }

    successData = [...existing, ...result]
    yield put(Actions.changeTagSuccess(successData))
  } catch (error) {
    yield put(Actions.changeTagError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* renameTag({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const id = payload.tagId
  const { name } = payload

  try {
    const result = yield call(Api.renameContactTag, id, name)
    yield put(Actions.renameTagSuccess(result))
    yield put(Actions.closeTagRenameDialog())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('tag_renamed'),
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.renameTagError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* deleteTag({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const { id } = payload

  try {
    yield call(Api.deleteContactTag, id)
    yield put(Actions.deleteTagSuccess({ id }))
    yield put(Actions.closeTagDeleteDialog())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('tag_deleted'),
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.deleteTagError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* downloadContactsExcelStart({ payload: { contacts, sheetName, isTemplate } }) {
  try {
    const body = {
      contacts,
      sheet_name: sheetName,
      is_template: isTemplate
    }
    const result = yield call(Api.downloadContactsExcel, body)
    yield call(downloadBlob, result, isTemplate ? 'import_contacts_template.xlsx' : 'contacts.xlsx', excelMimeType)
    yield put(Actions.downloadContactsExcelSuccess(result))
  } catch (error) {
    yield put(Actions.downloadContactsExcelError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* mergeDistributionListsStart() {
  const i18n = yield select(Selectors.getI18n)
  const data = yield select(Selectors.getCmSelectedLists)
  const ccdPlan = yield select(Selectors.getCDeskPlan)
  const ccdPlanUsage = yield select(Selectors.getCDeskPlanUsage)
  const lists = data.get('lists')
  const contactIds = lists.map(l => l.get('contacts').map(c => c.get('id'))).flatten().toSet()

  try {
    const body = {
      ids: lists.map(l => l.get('id')),
      contact_ids: contactIds,
      name: data.get('name'),
      delete_merged_lists: data.get('deleteMergedLists')
    }
    const result = yield call(Api.mergeDistributionLists, body)

    if (result.message) {
      yield put(Actions.mergeDistributionListsError())
      yield put(AppActions.showAppSnackbarMessage({
        text: i18n.get('distribution_list_name_exists'),
        variant: 'error'
      }))

      return
    }

    if (ccdPlanUsage.get('distributionListsUsage') > ccdPlan.get('distributionListsLimit')) {
      yield put(fetchCDeskConfigRequestStart())
    }

    yield put(Actions.mergeDistributionListsSuccess(result))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('distribution_lists_merged'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.mergeDistributionListsError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* downloadDistributionListStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  try {
    const body = {
      id: payload.get('id'),
      sheet_name: i18n.get('contacts')
    }
    const result = yield call(Api.downloadDistributionList, body)
    yield call(downloadBlob, result, `${payload.get('name')}.xlsx`, excelMimeType)
    yield put(Actions.downloadDistributionListSuccess(result))
  } catch (error) {
    yield put(Actions.downloadDistributionListError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* watchCreateDistributionListStart() {
  yield takeEvery(
    Actions.createDistributionListStart,
    createDistributionListStart
  )
}

export function* watchUpdateDistributionListStart() {
  yield takeEvery(
    Actions.updateDistributionListStart,
    updateDistributionListStart
  )
}

export function* watchDeleteDistributionListStart() {
  yield takeEvery(
    Actions.deleteDistributionListStart,
    deleteDistributionListStart
  )
}

export function* watchAddContactsToDistributionListStart() {
  yield takeEvery(
    Actions.addContactsToDistributionListStart,
    addContactsToDistributionListStart
  )
}

export function* watchSaveContactStart() {
  yield takeEvery(Actions.saveContactsStart, saveContactsStart)
}

export function* watchRemoveContactFromListStart() {
  yield takeEvery(
    Actions.removeContactFromListStart,
    removeContactFromListStart
  )
}

export function* watchDeleteContactsStart() {
  yield takeEvery(Actions.deleteContactsStart, deleteContactsStart)
}

export function* watchUploadContactsStart() {
  yield takeEvery(Actions.uploadContactsStart, uploadContactsStart)
}

export function* watchChangeContactTagsStart() {
  yield takeEvery(Actions.changeTagStart, changeContactTags)
}

export function* watchRenameTagStart() {
  yield takeEvery(Actions.renameTagStart, renameTag)
}

export function* watchDeleteTagStart() {
  yield takeEvery(Actions.deleteTagStart, deleteTag)
}

export function* watchDownloadContactsExcelStart() {
  yield takeEvery(Actions.downloadContactsExcelStart, downloadContactsExcelStart)
}

export function* watchMergeDistributionListsStart() {
  yield takeEvery(Actions.mergeDistributionListsStart, mergeDistributionListsStart)
}

export function* watchDownloadDistributionListStart() {
  yield takeEvery(Actions.downloadDistributionListStart, downloadDistributionListStart)
}

export default function* mainSaga() {
  yield all([
    watchSaveContactStart(),
    watchDeleteContactsStart(),
    watchCreateDistributionListStart(),
    watchUpdateDistributionListStart(),
    watchDeleteDistributionListStart(),
    watchAddContactsToDistributionListStart(),
    watchRemoveContactFromListStart(),
    watchUploadContactsStart(),
    watchChangeContactTagsStart(),
    watchRenameTagStart(),
    watchDeleteTagStart(),
    watchDownloadContactsExcelStart(),
    watchMergeDistributionListsStart(),
    watchDownloadDistributionListStart()
  ])
}
