import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/ai_settings'
import * as UserActions from 'actions/user'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  locale: 'en'
})

export default handleActions({
  [Actions.setOption]: (state, { payload: { key, value } }) => state.set(key, value),

  [UserActions.setUser]: (state, { payload: { languageShort } }) => state.set('locale', languageShort),

  [AppActions.resetState]: () => initialState
}, initialState)
