import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { MenuItem, Box } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import { Menu, RaisedPrimaryButton } from 'containers/themed'

import AppBar from 'containers/contact_management/AppBar'
import Contacts from 'containers/contact_management/Contacts'
import DistributionLists from 'containers/contact_management/DistributionLists'
import ContactEditDialog from 'containers/contact_management/ContactEditDialog'
import ContactDeleteDialog from 'containers/contact_management/ContactDeleteDialog'
import TagsDialog from 'containers/contact_management/TagsDialog'
import DistributionListCreateDialog from 'containers/contact_management/DistributionListCreateDialog'
import DistributionListEditDialog from 'containers/contact_management/DistributionListEditDialog'
import DistributionListViewDialog from 'containers/contact_management/DistributionListViewDialog'
import DistributionListMergeDialog from 'containers/contact_management/DistributionListMergeDialog'
import ContactDetailsDialog from 'containers/contact_management/ContactDetailsDialog'
import ActiveFilterList from 'containers/contact_management/ActiveFilterList'
import UploadContacts from 'containers/contact_management/UploadContacts'
import EmailExistingDialog from 'containers/contact_management/EmailExistingDialog'
import CalendarDialog from 'containers/content_desk/CalendarDialog'
import UploadResultDialog from 'containers/contact_management/UploadResultDialog'
import SelectedContactsBar from 'containers/contact_management/SelectedContactsBar'
import SelectedDistributionListsBar from 'containers/contact_management/SelectedDistributionListsBar'

import ContentEditDialog from 'containers/content_desk/ContentEditDialog'
import ContentView from 'containers/content_desk/ContentView'
import CenterMessage from 'containers/CenterMessage'

const useStyles = makeStyles()({
  centerMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh'
  },
  content: {
    maxWidth: 800
  },
  link: {
    display: 'flex',
    alignItems: 'center',

    '& > span:first-of-type': {
      marginRight: 5
    }
  }
})

export default function ContactManagement({
  isDomainVerified,
  onEdit,
  onMount,
  onImportClick,
  onCreateListClick
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  if (!isDomainVerified) {
    return (
      <>
        <AppBar />
        <div className={classes.centerMessageContainer}>
          <CenterMessage
            contentClass={classes.content}
            message={i18n.get('account_not_set_up_message')}
          />
        </div>
      </>
    )
  }

  const handleEditClick = () => onEdit(ImmutableMap())
  const handeCreateListClick = () => onCreateListClick()

  useEffect(() => {
    onMount()
  }, [])

  return (
    <>
      <AppBar />
      <Box
        mx={4}
        my={2}
      >
        <ActiveFilterList />
        <Box sx={{ textAlign: 'right' }}>
          <Menu
            handle={(
              <RaisedPrimaryButton endIcon={<AddOutlinedIcon />}>
                {i18n.get('new')}
              </RaisedPrimaryButton>
            )}
          >
            <MenuItem onClick={handleEditClick}>
              {i18n.get('add_contact')}
            </MenuItem>
            <MenuItem onClick={handeCreateListClick}>
              {i18n.get('create_distribution_list')}
            </MenuItem>
            <MenuItem onClick={onImportClick}>
              {i18n.get('import_list')}
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <DistributionLists />
      <Contacts />

      <ContactDetailsDialog />
      <ContactEditDialog />
      <TagsDialog />
      <DistributionListCreateDialog />
      <DistributionListEditDialog />
      <DistributionListViewDialog />
      <DistributionListMergeDialog />
      <UploadContacts />
      <CalendarDialog />
      <EmailExistingDialog />
      <UploadResultDialog />
      <SelectedContactsBar />
      <SelectedDistributionListsBar />
      <ContentEditDialog />
      <ContactDeleteDialog />
      <ContentView />
    </>
  )
}

ContactManagement.propTypes = {
  isDomainVerified: PropTypes.bool.isRequired,

  onEdit: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onImportClick: PropTypes.func.isRequired,
  onCreateListClick: PropTypes.func.isRequired
}
