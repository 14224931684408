import { connect } from 'react-redux'

import NewButton from 'components/content_desk/new_button'
import { openNewContentDialog, openCampaignForm } from 'actions/content_desk'

const mapStateToProps = _state => ({

})

export default connect(
  mapStateToProps,
  {
    openNewContentDialog,
    openCampaignForm
  }
)(NewButton)
