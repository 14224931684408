// DATA
export const getETLibraryData = state => state.emailTemplatesLibrary.get('data')
export const getETLibraryTemplates = state => getETLibraryData(state).get('templates')
export const getETLibraryTemplateFormData = state => getETLibraryData(state).get('templateFormData')

// UI
export const getETLibraryUi = state => state.emailTemplatesLibrary.get('ui')
export const getETLibraryShowEditorDialog = state => getETLibraryUi(state).get('showEditorDialog')
export const getETLibraryShowSaveDialog = state => getETLibraryUi(state).get('showSaveDialog')
export const getETLibraryShowPickerDialog = state => getETLibraryUi(state).get('showPickerDialog')
export const getETLibraryFetchRequestRunning = state => getETLibraryUi(state).get('fetchRequestRunning')
export const getETLibrarySaveRequestRunning = state => getETLibraryUi(state).get('saveRequestRunning')
export const getETLibraryDeleteRequestRunning = state => getETLibraryUi(state).get('deleteRequestRunning')
export const getETLibraryShowPublic = state => getETLibraryUi(state).get('showPublic')
export const getETLibraryReadMode = state => getETLibraryUi(state).get('readMode')

// FILTERS
export const getETLibraryFilter = state => state.emailTemplatesLibrary.get('filter')
