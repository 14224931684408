import { connect } from 'react-redux'

import ContactDetailsDialog from 'components/contact_management/contact_details_dialog'

import {
  openContactEditDialog,
  openDistributionListCreateDialog as onAdd,
  selectContact as onSelect,
  clearListData as onReset,
  closeContactViewDialog as onClose
} from 'actions/contact_management'

import {
  getCmContactDialogOpen,
  getCmSelectedContact,
  getAllStaticCountries,
  getAllStaticMediaTopics,
  getAllStaticMediaTypes,
  getAllStaticLanguages,
  getStaticStates,
  getCDeskIsDistributionListsLocked
} from 'selectors'

const mapStateToProps = state => ({
  open: getCmContactDialogOpen(state),
  contact: getCmSelectedContact(state),
  countries: getAllStaticCountries(state),
  states: getStaticStates(state),
  topics: getAllStaticMediaTopics(state),
  mediaTypes: getAllStaticMediaTypes(state),
  languages: getAllStaticLanguages(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(
  mapStateToProps,
  {
    onClose,
    onEdit: contact => openContactEditDialog({ isEditMode: true, contact }),
    onSelect,
    onAdd,
    onReset
  }
)(ContactDetailsDialog)
