import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FilterListIcon from '@mui/icons-material/FilterList'
import { makeStyles } from 'tss-react/mui'
import SearchBar from 'containers/SearchBar'

import QueryTypeDropdown from 'containers/QueryTypeDropdown'
import DateFilterButton from 'containers/news_page/DateFilterButton'
import AppBar from 'containers/AppBar'
import SearchForm from 'containers/search_form/SearchForm'
import HelpDialog from 'containers/HelpDialog'
import DateForm from 'containers/date_form/DateForm'
import NotificationsButton from 'containers/notifications/NotificationsButton'
import { Button, IconButton } from 'containers/themed'
import { Drawer } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  searchBar: {
    transition: 'width 150ms',
    width: '25%',
    [theme.breakpoints.only('md')]: {
      width: '25%'
    },
    [theme.breakpoints.down('md')]: {
      width: '45%',
      marginLeft: 10
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: 5
    }
  },
  searchActions: {
    marginRight: 10,
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 5,
      marginRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20
    },
    '& > div:first-of-type': {
      marginRight: 10
    }
  },
  backdrop: {
    zIndex: 1
  },
  iconButton: {
    padding: 0
  },
  drawer: {
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 500
    }
  },
  searchFormDrawer: {
    [theme.breakpoints.up('lg')]: {
      width: 750
    }
  }
}))

export default function NewsPageAppBar({
  showDateForm,
  showAdvancedSearch,
  device,
  i18n,
  titleKey,
  actions,
  toggleAdvancedSearch,
  toggleDateForm
}) {
  const { classes, cx } = useStyles()
  const [barStyle, setBarStyle] = useState(null)
  const NotificationButton = <NotificationsButton key="notifications" />

  const handleFocus = () => {
    let width = '100%'

    if (device.get('gt-md')) {
      width = '50%'
    }

    setBarStyle({ width })
  }

  const handleBlur = () => {
    setTimeout(() => setBarStyle(null), 500)
  }

  const renderForms = () => {
    const anchor = device.get('lt-lg') ? 'top' : 'right'

    return (
      <>
        <Drawer
          classes={{
            paper: cx(classes.drawer, classes.searchFormDrawer)
          }}
          anchor={anchor}
          open={showAdvancedSearch}
          onClose={() => toggleAdvancedSearch()}
        >
          <SearchForm />
        </Drawer>
        <Drawer
          classes={{
            paper: classes.drawer
          }}
          anchor={anchor}
          open={showDateForm}
          onClose={() => toggleDateForm()}
        >
          <DateForm />
        </Drawer>
      </>
    )
  }

  const renderFilterButton = () => {
    let comp = (
      <div>
        <Button
          variant="contained"
          color={showAdvancedSearch ? 'secondary' : undefined}
          onClick={() => toggleAdvancedSearch()}
          startIcon={<FilterListIcon />}
        >
          {i18n.get('filter')}
        </Button>
      </div>
    )

    if (device.get('lt-sm')) {
      comp = (
        <IconButton
          classes={{ root: classes.iconButton }}
          color={showAdvancedSearch ? 'secondary' : 'inherit'}
          onClick={() => toggleAdvancedSearch()}
          size="large"
        >
          <FilterListIcon />
        </IconButton>
      )
    }

    return comp
  }

  const renderActions = acts => {
    if (device.get('lt-lg') && barStyle) {
      return null
    }

    return acts
  }

  const renderAppBarActions = () => renderActions([...actions, NotificationButton])

  const renderSearchActions = () => renderActions(
    <div className={classes.searchActions}>
      {renderFilterButton()}
      <DateFilterButton
        onClick={() => toggleDateForm()}
        active={showDateForm}
      />
    </div>
  )

  let title

  if (device.get('gt-sm')) {
    title = i18n.get(titleKey)
  }

  return (
    <>
      <AppBar
        actions={renderAppBarActions()}
        title={title}
      >
        <div
          style={barStyle}
          className={classes.searchBar}
        >
          <SearchBar
            onFocus={handleFocus}
            onBlur={handleBlur}
            queryTypeDropdown={<QueryTypeDropdown />}
          />
        </div>

        {renderSearchActions()}
      </AppBar>

      {renderForms()}

      <HelpDialog />
    </>
  )
}

NewsPageAppBar.propTypes = {
  showDateForm: PropTypes.bool.isRequired,
  showAdvancedSearch: PropTypes.bool.isRequired,
  device: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  titleKey: PropTypes.string,
  actions: PropTypes.array,

  toggleAdvancedSearch: PropTypes.func.isRequired,
  toggleDateForm: PropTypes.func.isRequired
}
