import React, { useState } from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  actions: {
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center'
  }
})

const defaultSortingDirections = {
  title: 'desc',
  last_editor: 'desc',
  date: 'asc',
  updated_at: 'desc',
  campaign: 'desc'
}

function ContentsSortbar({ onSortBy, setPage }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [sortingDirections, setSortingDirections] = useState(defaultSortingDirections)
  const [selectedField, setSelectedField] = useState(null)

  const handleSortingClick = sortBy => {
    const sortOrder = sortingDirections[sortBy] !== 'desc' ? 'desc' : 'asc'

    setSortingDirections({ ...defaultSortingDirections, [sortBy]: sortOrder })
    setSelectedField(sortBy)
    setPage({ key: 'contentPage', value: 1 })
    onSortBy({ type: 'contents', sortBy, sortOrder })
  }

  const sortButtonIcon = () => {
    if (sortingDirections[selectedField] === 'desc') {
      return (
        <i
          className="fa fa-sort-down"
          onClick={() => handleSortingClick(selectedField)}
        />
      )
    }

    if (sortingDirections[selectedField] === 'asc') {
      return (
        <i
          className="fa fa-sort-up"
          onClick={() => handleSortingClick(selectedField)}
        />
      )
    }

    return <i className="fa fa-sort" />
  }

  /* Right now not possible via graph qool
    <Button
      onClick={() => handleSortingClick('last_editor')}
      color={selectedField === 'last_editor' ? 'secondary' : undefined}
    >
      {i18n.get('editor')}
    </Button>

    <Button
      onClick={() => handleSortingClick('campaign')}
      color={selectedField === 'campaign' ? 'secondary' : undefined}
    >
      {i18n.get('campaign')}
    </Button>
  */

  return (
    <div className={classes.actions}>
      <span>
        {sortButtonIcon()}
      </span>
      <Button
        onClick={() => handleSortingClick('title')}
        color={selectedField === 'title' ? 'secondary' : undefined}
      >
        {i18n.get('title')}
      </Button>
      <Button
        onClick={() => handleSortingClick('updated_at')}
        color={selectedField === 'updated_at' ? 'secondary' : undefined}
      >
        {i18n.get('last_edited')}
      </Button>
      <Button
        onClick={() => handleSortingClick('planned_for')}
        color={selectedField === 'planned_for' ? 'secondary' : undefined}
      >
        {i18n.get('planned_for')}
      </Button>
      <Button
        onClick={() => handleSortingClick('scheduled_for')}
        color={selectedField === 'scheduled_for' ? 'secondary' : undefined}
      >
        {i18n.get('scheduled_for')}
      </Button>
      <Button
        onClick={() => handleSortingClick('type')}
        color={selectedField === 'type' ? 'secondary' : undefined}
      >
        {i18n.get('type')}
      </Button>
    </div>
  )
}

ContentsSortbar.propTypes = {
  onSortBy: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
}

export default ContentsSortbar
