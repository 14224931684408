import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Actions from 'actions/contact_management'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* fetchContactsStart() {
  try {
    const { contacts } = yield select(Selectors.getCdSearchBody)
    const page = yield select(Selectors.getCmContactPage)
    const limit = yield select(Selectors.getCmContactLimit)
    const sortBy = yield select(Selectors.getCmSortBy)
    const sortOrder = yield select(Selectors.getCmSortOrder)
    const showUnsubscribed = yield select(Selectors.getCmShowUnsubscribed)

    const body = {
      filter: contacts.includes,
      page,
      limit,
      sort_by: sortBy,
      sort_order: sortOrder,
      ...(showUnsubscribed && { unsubscribed: true })
    }

    const result = yield call(Api.fetchContacts, body)
    yield put(Actions.fetchContactsSuccess(result))
  } catch (error) {
    yield put(Actions.fetchContactsError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* setSortBy() {
  yield put(Actions.fetchContactsStart())
}

export function* setContactsPage() {
  yield put(Actions.fetchContactsStart())
}
export function* setDistributionListsPage() {
  yield put(Actions.fetchDistributionListsStart())
}

export function* fetchDistributionListsStart() {
  const a = yield select(Selectors.getCdSearchBody)
  const { distributionLists } = a
  const page = yield select(Selectors.getCmDistributionListPage)
  const limit = yield select(Selectors.getCmDistributionListLimit)
  const sortBy = yield select(Selectors.getCmListsSortBy)
  const sortOrder = yield select(Selectors.getCmListsSortOrder)

  const body = {
    // filter: distributionLists.includes,
    multi_filter: { operator: 'or', conditions: distributionLists.includes },
    page,
    limit,
    sort_by: sortBy,
    sort_order: sortOrder
  }
  try {
    const result = yield call(Api.fetchDistributionLists, body)
    yield put(Actions.fetchDistributionListsSuccess(result))
  } catch (error) {
    yield put(Actions.fetchDistributionListsError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* searchDistributionListStart({ payload }) {
  const page = yield select(Selectors.getCmDistributionListPage)
  const limit = yield select(Selectors.getCmDistributionListLimit)
  const sortBy = yield select(Selectors.getCmListsSortBy)
  const sortOrder = yield select(Selectors.getCmListsSortOrder)

  const body = {
    filter: payload.filter,
    page,
    limit,
    sort_by: sortBy,
    sort_order: sortOrder
  }
  try {
    const result = yield call(Api.fetchDistributionLists, body)
    yield put(Actions.searchDistributionListSuccess(result))
  } catch (error) {
    yield put(Actions.searchDistributionListError(error))
  }
}

export function* setListSortBy() {
  yield put(Actions.fetchDistributionListsStart())
}

export function* fetchTagsStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const page = yield select(Selectors.getCmTagsPage)
    const limit = yield select(Selectors.getCmTagsLimit)
    const sortBy = yield select(Selectors.getCmTagsSortBy)
    const sortOrder = yield select(Selectors.getCmTagsSortOrder)

    const body = {
      page,
      limit,
      sort_by: sortBy,
      sort_order: sortOrder,
      filter: payload.filter || []
    }

    const result = yield call(Api.searchTags, body)
    yield put(Actions.setContactTags(result))

    yield put(Actions.fetchTagsSuccess())
  } catch (error) {
    yield put(Actions.fetchTagsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* setTagsSortBy() {
  yield put(Actions.fetchTagsStart({ filter: [] }))
}

export function* fetchAll() {
  yield put(Actions.fetchContactsStart())
  yield put(Actions.fetchDistributionListsStart())
}

export function* watchFetchDistributionListsStart() {
  yield takeEvery(
    Actions.fetchDistributionListsStart,
    fetchDistributionListsStart
  )
}

export function* watchSearchDistributionListStart() {
  yield takeEvery(
    Actions.searchDistributionListStart,
    searchDistributionListStart
  )
}

export function* watchfetchContactsStart() {
  yield takeEvery(Actions.fetchContactsStart, fetchContactsStart)
}

export function* watchSetSortBy() {
  yield takeEvery(Actions.sortBy, setSortBy)
}

export function* watchSetListSortBy() {
  yield takeEvery(Actions.listSortBy, setListSortBy)
}

export function* watchSetTagsSortBy() {
  yield takeEvery(Actions.tagsSortBy, setTagsSortBy)
}

export function* watchSetContactsPage() {
  yield takeEvery(Actions.setContactsPage, setContactsPage)
}
export function* watchSetDistributionListsPage() {
  yield takeEvery(Actions.setDistributionListsPage, setDistributionListsPage)
}

export function* watchContactDbRequestStart() {
  yield takeEvery(Actions.fetchAll, fetchAll)
}

export function* watchFetchTagsStart() {
  yield takeEvery(Actions.fetchTagsStart, fetchTagsStart)
}

export default function* searchSaga() {
  yield all([
    watchFetchDistributionListsStart(),
    watchSearchDistributionListStart(),
    watchfetchContactsStart(),
    watchSetSortBy(),
    watchSetListSortBy(),
    watchSetTagsSortBy(),
    watchSetContactsPage(),
    watchSetDistributionListsPage(),
    watchContactDbRequestStart(),
    watchFetchTagsStart()
  ])
}
