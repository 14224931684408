import React from 'react'
import { List as ImmutableList } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import { Alert } from '@mui/material'

import ContentsItem from 'containers/content_desk/ContentsItem'
import DeleteDialog from 'containers/content_desk/DeleteDialog'

import { Button } from 'containers/themed'
import { Link } from 'react-router-dom'

const useStyles = makeStyles()({
  showAllButton: {
    marginTop: '1em'
  }
})

export default function Contents({ contents, count, hasSearchQueries, loading }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const renderWarning = () => {
    if (!(hasSearchQueries && !loading)) {
      return null
    }

    return (
      <Alert severity="warning">
        {i18n.get('no_hits_title')}
      </Alert>
    )
  }
  const renderContents = () => contents.map(element => (
    <ContentsItem
      key={`item__${element.get('id')}`}
      data={element}
    />
  ))

  const renderAllContentButton = () => (
    <Link to="/app/content_desk/content_management/contents">
      <Button
        variant="contained"
        className={classes.showAllButton}
      >
        {i18n.get('all_contents')}
      </Button>
    </Link>
  )

  return (
    <>
      {!count && renderWarning()}
      {count && renderContents()}
      {count && renderAllContentButton()}
      <DeleteDialog entity="content" />
    </>
  )
}

Contents.propTypes = {
  contents: PropTypes.instanceOf(ImmutableList).isRequired,
  count: PropTypes.number.isRequired,
  hasSearchQueries: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
}
