import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Tooltip, Typography } from '@mui/material'
import { red, blue, green, grey, orange } from '@mui/material/colors'

import { Map, fromJS } from 'immutable'
import moment from 'moment-timezone'

import { makeStyles } from 'tss-react/mui'
import CloseIcon from '@mui/icons-material/Close'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import MailIcon from '@mui/icons-material/MailOutline'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import CampaignIcon from '@mui/icons-material/Campaign'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { calendarEvents } from 'utils/calendar_events'
import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'

import useI18n from 'hooks/useI18n'

export const getLocale = () => (moment.locale() || 'de')

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="left"
    ref={ref}
    {...props}
  />
))
Transition.displayName = 'Transition'

const useStyles = makeStyles()({
  fullCalendarEvent: {
    border: 'none !important',
    borderColor: 'transparent !important',
    backgroundColor: 'transparent !important'
  },
  tooltipTitle: {
    whiteSpace: 'pre-line'
  },
  failed: {
    backgroundColor: red[500]
  },
  released: {
    backgroundColor: green[500]
  },
  scheduled: {
    backgroundColor: blue[500]
  },
  draft: {
    backgroundColor: grey[500]
  },
  planned: {
    backgroundColor: orange[500]
  },
  typeIcon: {
    marginRight: 5,
    verticalAlign: 'bottom'
  },
  event: {
    borderRadius: 6,
    border: '1px solid black',
    padding: '2px 5px 2px 5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  eventLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    fontSize: '0.8rem'
  },
  overlay: {
    zIndex: 10,
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%'
  },
  calendarInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  calendarInfoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  calendarInfoItemColor: {
    width: '15px',
    height: '15px'
  },
  calendarInfoItemLabel: {
    fontSize: '1rem',
    fontWeight: 400
  },
  campaignEventProgressLabel: {
    cursor: 'pointer',
    marginLeft: '10px',
    fontSize: '0.8rem'
  },
  campaignEventLabelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

const CalendarDialog = ({
  events,
  open,
  calendarDate,
  onClose,
  onContentClick,
  onCampaignClick,
  onNewContentClick,
  onNewCampaignClick,
  fetchContents,
  setCalendarDate,
  requestRunning
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const wrapperRef = useRef(null)
  const [newEventTop, setNewEventTop] = useState(200)
  const [newEventLeft, setNewEventLeft] = useState(200)
  const [newEventOpen, setNewEventOpen] = useState(false)
  const [newEventStartDate, setNewEventStartDate] = useState(new Date())
  const [newEventEndDate, setNewEventEndDate] = useState(new Date())
  const handleNewEventClose = () => {
    setNewEventOpen(false)
  }

  useEffect(() => {
    if (open && calendarDate) {
      fetchContents(calendarDate)
    }
  }, [open, calendarDate])

  const handleNewContentClick = () => {
    onNewContentClick({
      scheduledFor: moment(newEventEndDate).subtract(12, 'hours').toDate()
    })
    handleNewEventClose()
  }

  const handleNewCampaignClick = () => {
    onNewCampaignClick({
      startDate: moment(newEventStartDate).add(12, 'hours').toDate(),
      endDate: moment(newEventEndDate).subtract(12, 'hours').toDate()
    })
    handleNewEventClose()
  }

  const renderCampaign = eventInfo => {
    const campaignSummary = eventInfo.event.extendedProps.campaign.summary

    const campaign = () => (
      <div
        className={classes.event}
        style={{ backgroundColor: eventInfo.event.extendedProps.color }}
      >

        <CampaignIcon className={classes.typeIcon} />
        <div className={classes.campaignEventLabelContainer}>
          <Typography className={classes.eventLabel}>{eventInfo.event.title}</Typography>
          <Typography className={classes.campaignEventProgressLabel}>{eventInfo.event.extendedProps.campaign.progress}%</Typography>
        </div>
      </div>
    )

    if (!campaignSummary) {
      return campaign()
    }

    const tooltipTitle = (
      <div className={classes.tooltipTitle}>
        {campaignSummary}
      </div>
    )

    return (
      <Tooltip
        title={tooltipTitle}
        arrow
      >
        {campaign()}
      </Tooltip>
    )
  }

  const renderContent = eventInfo => {
    const statusClass = classes[eventInfo.event.extendedProps.status]
    const tooltipTitle = (
      <div className={classes.tooltipTitle}>
        {`Campaign: ${eventInfo.event.extendedProps.campaignName} \n Content: ${eventInfo.event.title}`}
      </div>
    )

    return (
      <Tooltip
        title={tooltipTitle}
        arrow
      >
        <div className={`${classes.event} ${statusClass}`}>
          {eventInfo.event.extendedProps.type === 'email' && <MailIcon className={classes.typeIcon} />}
          {eventInfo.event.extendedProps.type === 'linkedin' && <LinkedInIcon className={classes.typeIcon} />}
          {eventInfo.event.extendedProps.type === 'facebook' && <FacebookIcon className={classes.typeIcon} />}
          <Typography className={classes.eventLabel}>{eventInfo.event.extendedProps.time}: {eventInfo.event.title}</Typography>
        </div>
      </Tooltip>
    )
  }

  const renderEventContent = eventInfo => {
    if (eventInfo.event.extendedProps.eventType === 'campaign') {
      return renderCampaign(eventInfo)
    }

    return renderContent(eventInfo)
  }

  const renderInfo = () => (
    <div className={classes.calendarInfo}>
      <div className={classes.calendarInfoItem}>
        <div
          className={classes.calendarInfoItemColor}
          style={{ backgroundColor: grey[500] }}
        />
        <Typography className={classes.calendarInfoItemLabel}>{i18n.get('draft')}</Typography>
      </div>
      <div className={classes.calendarInfoItem}>
        <div
          className={classes.calendarInfoItemColor}
          style={{ backgroundColor: orange[500] }}
        />
        <Typography className={classes.calendarInfoItemLabel}>{i18n.get('planned')}</Typography>
      </div>
      <div className={classes.calendarInfoItem}>
        <div
          className={classes.calendarInfoItemColor}
          style={{ backgroundColor: blue[500] }}
        />
        <Typography className={classes.calendarInfoItemLabel}>{i18n.get('scheduled')}</Typography>
      </div>
      <div className={classes.calendarInfoItem}>
        <div
          className={classes.calendarInfoItemColor}
          style={{ backgroundColor: green[500] }}
        />
        <Typography className={classes.calendarInfoItemLabel}>{i18n.get('released')}</Typography>
      </div>
      <div className={classes.calendarInfoItem}>
        <div
          className={classes.calendarInfoItemColor}
          style={{ backgroundColor: red[500] }}
        />
        <Typography className={classes.calendarInfoItemLabel}>{i18n.get('failed')}</Typography>
      </div>
    </div>
  )

  const handleEventClick = extendedProps => {
    if (extendedProps.eventType === 'content') {
      return onContentClick(fromJS(extendedProps.content))
    }

    return onCampaignClick(extendedProps.campaign)
  }

  const convertedEvents = calendarEvents(events.get('items'))

  const onCalendarNavClick = calendarApi => {
    setCalendarDate(moment(calendarApi.getDate()).format('YYYY-MM-DD'))
  }

  const onDaySelect = info => {
    setNewEventStartDate(info.start)
    setNewEventEndDate(info.end)

    if (info.jsEvent) {
      setNewEventTop(info.jsEvent.pageY)
      setNewEventLeft(info.jsEvent.pageX)
      setNewEventOpen(true)
    }
  }

  const calendarConfig = {
    customButtons: {
      prevButton: {
        text: 'prev',
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.prev()
          onCalendarNavClick(calendarApi)
        }
      },
      nextButton: {
        text: 'next',
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.next()
          onCalendarNavClick(calendarApi)
        }
      },
      todayButton: {
        text: i18n.get('today'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.today()
          onCalendarNavClick(calendarApi)
        }
      }
    },
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'prevButton,nextButton,todayButton'
    },
    buttonIcons: {
      prevButton: 'chevron-left',
      nextButton: 'chevron-right'
    },
    titleFormat: {
      year: 'numeric',
      month: 'long'
    },
    initialDate: (() => {
      if (convertedEvents.isEmpty()) {
        return moment().format('YYYY-MM-DD')
      }

      return moment(convertedEvents.last().start).format('YYYY-MM-DD')
    })(),
    plugins: [dayGridPlugin, interactionPlugin],
    firstDay: 1,
    dayMaxEventRows: true,
    editable: false,
    selectable: true,
    select: onDaySelect
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
            {renderInfo()}
          </div>

        </DialogTitle>
        <DialogContent>
          {requestRunning && (
            <div className={classes.overlay}>
              <div className={classes.progressBar}>
                <NrxProgressBar />
              </div>
            </div>
          )}
          <FullCalendar
            className={classes.calendar}
            ref={wrapperRef}
            locales={allLocales}
            locale={getLocale()}
            events={convertedEvents.toJS()}
            eventContent={renderEventContent}
            eventClick={e => {
              handleEventClick(e.event.extendedProps)
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: false
            }}
            eventClassNames={() => [classes.fullCalendarEvent]}
            {...calendarConfig}
          />
        </DialogContent>
      </Dialog>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={{ top: newEventTop, left: newEventLeft }}
        open={newEventOpen}
        onClose={handleNewEventClose}
      >
        <MenuItem onClick={handleNewContentClick}>{i18n.get('new_content')}</MenuItem>
        <MenuItem onClick={handleNewCampaignClick}>{i18n.get('new_campaign')}</MenuItem>
      </Menu>
    </div>
  )
}

CalendarDialog.propTypes = {
  events: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,
  calendarDate: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onContentClick: PropTypes.func.isRequired,
  onCampaignClick: PropTypes.func.isRequired,
  onNewContentClick: PropTypes.func.isRequired,
  onNewCampaignClick: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  setCalendarDate: PropTypes.func.isRequired
}

export default CalendarDialog
