/* global FormData */
import { getBffUrl as bffUrlSelector } from 'selectors'
import store from '../store'

import * as Api from '../utils/api'

const getBffUrl = () => bffUrlSelector(store.getState())

export const registerUser = body => Api.post(
  `${getBffUrl()}/users/register`,
  body
)

export const fetchConfig = timezone => Api.post(
  `${getBffUrl()}/config?timezone=${timezone}`
)

export const updateConfig = (body, newsradarId) => Api.patch(
  `${getBffUrl()}/groot_configs/${newsradarId}`,
  body
)

export const updateUserConfig = body => Api.post(
  `${getBffUrl()}/groot_user_configs`,
  body
)

export const fetchContentDeskConfig = () => Api.get(
  `${getBffUrl()}/groot_ccd_configs`
)

export const createContentDeskConfig = body => Api.post(
  `${getBffUrl()}/groot_ccd_configs`,
  body
)

export const updateContentDeskConfig = (id, body) => Api.patch(
  `${getBffUrl()}/groot_ccd_configs/${id}`,
  body
)

export const verifyContentDeskDomain = body => Api.post(
  `${getBffUrl()}/groot_ccd_configs/domain/verify`,
  body
)

export const allAllowedDomains = () => Api.get(
  `${getBffUrl()}/groot_ccd_configs/domain/allowed`
)

export const searchAllowedDomains = body => Api.post(
  `${getBffUrl()}/groot_ccd_configs/domain/allowed/search`,
  body
)

export const createAllowedDomain = body => Api.post(
  `${getBffUrl()}/groot_ccd_configs/domain/allowed`,
  body
)

export const updateAllowedDomain = (id, body) => Api.patch(
  `${getBffUrl()}/groot_ccd_configs/domain/allowed/${id}`,
  body
)

export const deleteAllowedDomain = id => Api.destroy(
  `${getBffUrl()}/groot_ccd_configs/domain/allowed/${id}`
)

export const startExport = body => Api.post(
  `${getBffUrl()}/export`,
  body
)

export const logout = body => Api.post(
  `${getBffUrl()}/logout`,
  body
)

export const login = body => Api.post(
  `${getBffUrl()}/login`,
  body
)

export const search = body => Api.post(
  `${getBffUrl()}/search`,
  body
)

export const searchByIds = body => Api.post(
  `${getBffUrl()}/search/by_ids`,
  body
)

export const validate = body => Api.post(
  `${getBffUrl()}/search/validate`,
  body
)

export const fetchNewsIds = body => Api.post(
  `${getBffUrl()}/search/news_ids`,
  body
)

export const searchInfluencers = body => Api.post(
  `${getBffUrl()}/search/influencers`,
  body
)

export const searchPublications = body => Api.post(
  `${getBffUrl()}/search/publications`,
  body
)

export const searchPageIdentities = body => Api.post(
  `${getBffUrl()}/search/page_identities`,
  body
)

export const searchAllPublications = body => Api.post(
  `${getBffUrl()}/publications/search`,
  body
)

export const searchPageIdentityPosts = body => Api.post(
  `${getBffUrl()}/search/page_identities/posts`,
  body
)

export const getMediaReview = mediaReviewId => Api.get(
  `${getBffUrl()}/media_reviews/${mediaReviewId}`
)

export const getMediaReviewAiAnalysis = (mediaReviewId, locale) => Api.get(
  `${getBffUrl()}/media_reviews/${mediaReviewId}/ai_analysis?locale=${locale}`
)

export const reorderMediaReview = body => Api.post(
  `${getBffUrl()}/media_reviews/reorder`,
  body
)

export const removeNewsFromMediaReview = body => Api.post(
  `${getBffUrl()}/media_reviews/remove_news`,
  body
)

export const loadFullText = id => Api.get(
  `${getBffUrl()}/media_reviews/full_text/${id}`
)

export const filterMediaReviews = body => Api.post(
  `${getBffUrl()}/media_reviews/filter`,
  body
)

export const replaceMediaReviewReaction = (id, body) => Api.post(
  `${getBffUrl()}/media_reviews/${id}/replace_reaction`,
  body
)

export const getMediaReviewReactions = id => Api.get(
  `${getBffUrl()}/media_reviews/${id}/reactions`
)

export const getMediaReviewSnapshot = id => Api.get(
  `${getBffUrl()}/media_reviews/${id}/snapshot`
)

export const addNewsToMediaReview = (id, newsId) => Api.post(
  `${getBffUrl()}/media_reviews/${id}/news/${newsId}`
)

export const getNews = id => Api.get(
  `${getBffUrl()}/news/${id}`
)

export const getNewsAiAnalysis = body => Api.post(
  `${getBffUrl()}/news/ai_analysis`,
  body
)

export const createPublication = body => Api.post(
  `${getBffUrl()}/publications`,
  body
)

export const submitShopping = (id, type) => Api.get(
  `${getBffUrl()}/submit_shopping/${id}/?type=${type}`
)

export const sendMail = body => Api.post(
  `${getBffUrl()}/send_mail`,
  body
)

export const createDispatch = body => Api.post(
  `${getBffUrl()}/dispatches`,
  body
)

export const cancelDispatch = id => Api.post(
  `${getBffUrl()}/dispatches/${id}/cancel`
)

export const fetchDispatchLog = () => Api.get(
  `${getBffUrl()}/dispatches/log`
)

export const saveSubscriptions = body => Api.post(
  `${getBffUrl()}/subscriptions`,
  body
)

export const updateUser = (body, userId) => Api.patch(
  `${getBffUrl()}/users/${userId}`,
  body
)

export const forgotPassword = body => Api.post(
  `${getBffUrl()}/users/forgot_password`,
  body
)

export const resetPassword = body => Api.post(
  `${getBffUrl()}/users/reset_password`,
  body
)

export const activateUser = body => Api.post(
  `${getBffUrl()}/users/activate`,
  body
)

export const createCustomTag = body => Api.post(
  `${getBffUrl()}/custom_tags`,
  body
)

export const updateCustomTag = (id, body) => Api.patch(
  `${getBffUrl()}/custom_tags/${id}`,
  body
)

export const deleteCustomTag = (id, body) => Api.post(
  `${getBffUrl()}/custom_tags/${id}`,
  body
)

export const emptyCustomTag = body => Api.post(
  `${getBffUrl()}/empty_custom_tag`,
  body
)

export const fetchNewsForCustomTag = (id, body) => Api.post(
  `${getBffUrl()}/custom_tags/${id}/news`,
  body
)

export const assignCustomTagToNews = body => Api.post(
  `${getBffUrl()}/assign_custom_tag`,
  body
)

export const createCustomTagGroup = body => Api.post(
  `${getBffUrl()}/custom_tag_groups`,
  body
)

export const updateCustomTagGroup = (id, body) => Api.patch(
  `${getBffUrl()}/custom_tag_groups/${id}`,
  body
)

export const deleteCustomTagGroup = id => Api.destroy(
  `${getBffUrl()}/custom_tag_groups/${id}`
)

export const assignCustomTagToGroup = (groupId, customTagId) => Api.post(
  `${getBffUrl()}/custom_tag_groups/${groupId}/custom_tags/${customTagId}`
)

export const removeCustomTagFromGroup = (groupId, customTagId) => Api.destroy(
  `${getBffUrl()}/custom_tag_groups/${groupId}/custom_tags/${customTagId}`
)

export const uploadFile = data => {
  const formData = new FormData()
  data.forEach(({ key, value, filename }) => {
    if (filename) {
      formData.append(key, value, filename)
    } else {
      formData.append(key, value)
    }
  })

  return Api.form(
    `${getBffUrl()}/upload_file`,
    formData
  )
}

export const updateCharts = body => Api.post(
  `${getBffUrl()}/charts`,
  body
)

export const exportExcel = body => Api.post(
  `${getBffUrl()}/charts/excel`,
  body,
  'blob'
)

export const exportPowerpoint = body => Api.post(
  `${getBffUrl()}/charts/powerpoint`,
  body,
  'blob'
)

export const exportPowerpointBatch = body => Api.post(
  `${getBffUrl()}/charts/powerpoint_batch`,
  body,
  'blob'
)

export const aiChartAnalysis = body => Api.post(
  `${getBffUrl()}/charts/ai_analysis`,
  body
)

export const updateNews = (id, body) => Api.patch(
  `${getBffUrl()}/news/${id}`,
  body
)

export const summarizeNews = body => Api.post(
  `${getBffUrl()}/news/summarize`,
  body
)

export const updateBackwardsNews = (id, body) => Api.patch(
  `${getBffUrl()}/backwards_news/${id}`,
  body
)

export const createNews = body => Api.post(
  `${getBffUrl()}/news`,
  body
)

export const deleteNews = newsIds => Api.destroy(
  `${getBffUrl()}/news/delete?ids=${newsIds}`
)

export const deleteBackwardsNews = newsIds => Api.destroy(
  `${getBffUrl()}/backwards_news/delete?ids=${newsIds}`
)

export const fetchInfluencer = id => Api.get(
  `${getBffUrl()}/influencers/${id}`
)

export const addInfluencerToContacts = (id, body) => Api.post(
  `${getBffUrl()}/influencers/${id}/add_to_contacts`,
  body
)
export const updateMailingList = (id, body) => Api.patch(
  `${getBffUrl()}/mailing_lists/${id}`,
  body
)

export const deleteMailingList = id => Api.destroy(
  `${getBffUrl()}/mailing_lists/${id}`
)

export const createMailingList = body => Api.post(
  `${getBffUrl()}/mailing_lists`,
  body
)

export const createSavedSearch = body => Api.post(
  `${getBffUrl()}/saved_searches`,
  body
)

export const updateSavedSearch = (id, body) => Api.patch(
  `${getBffUrl()}/saved_searches/${id}`,
  body
)

export const deleteSavedSearch = id => Api.destroy(
  `${getBffUrl()}/saved_searches/${id}`
)

export const resolveSavedSearch = (id, body) => Api.post(
  `${getBffUrl()}/saved_searches/${id}/resolve`,
  body
)

export const fetchSavedSearchesTimelines = moduleName => Api.get(
  `${getBffUrl()}/saved_searches/timelines?module_name=${moduleName}`
)

export const fetchSavedSearchNewsFeed = (id, body) => Api.post(
  `${getBffUrl()}/saved_searches/${id}/news_feed`,
  body
)

export const fetchSavedSearchNewsFeedAiAnalysis = (id, body) => Api.post(
  `${getBffUrl()}/saved_searches/${id}/news_feed/ai_analysis`,
  body
)

export const fetchSavedSearchDispatches = (id, limit = 10, page = 1) => Api.get(
  `${getBffUrl()}/saved_searches/${id}/dispatches?limit=${limit}&page=${page}`
)

export const createSavedSearchFeed = body => Api.post(
  `${getBffUrl()}/saved_searches/feeds`,
  body
)

export const updateSavedSearchFeed = (id, body) => Api.patch(
  `${getBffUrl()}/saved_searches/feeds/${id}`,
  body
)

export const deleteSavedSearchFeed = id => Api.destroy(
  `${getBffUrl()}/saved_searches/feeds/${id}`
)

export const fetchDashboardConfig = id => Api.get(
  `${getBffUrl()}/dashboards/${id}/config`
)

export const updateDashboard = (id, body) => Api.patch(
  `${getBffUrl()}/dashboards/${id}`,
  body
)

export const createDashboard = body => Api.post(
  `${getBffUrl()}/dashboards`,
  body
)

export const deleteDashboard = (id, userConfigId) => {
  let destroyRoute = `${getBffUrl()}/dashboards/${id}`

  if (userConfigId) {
    destroyRoute = `${destroyRoute}?user_config_id=${userConfigId}`
  }

  return Api.destroy(destroyRoute)
}

export const fetchSavedDashboard = urlName => Api.get(
  `${getBffUrl()}/saved_dashboards/by_url_name?url_name=${urlName}`
)

export const fetchSavedDashboardInfo = id => Api.get(
  `${getBffUrl()}/saved_dashboards/${id}`
)

export const deleteSavedDashboard = id => Api.destroy(
  `${getBffUrl()}/saved_dashboards/${id}`
)

export const createSavedDashboard = body => Api.post(
  `${getBffUrl()}/saved_dashboards`,
  body
)

export const checkSavedDashboardUrlName = body => Api.post(
  `${getBffUrl()}/saved_dashboards/check_url_name`,
  body
)

export const validateSpf = body => Api.post(
  `${getBffUrl()}/validation/spf`,
  body
)

export const authenticateGoogleAnalytics = body => Api.post(
  `${getBffUrl()}/auth/external/google_analytics`,
  body
)

export const revokeGoogleAnalytics = body => Api.post(
  `${getBffUrl()}/auth/external/google_analytics/revoke`,
  body
)

export const authenticateYoutubeAnalytics = body => Api.post(
  `${getBffUrl()}/auth/external/youtube_analytics`,
  body
)

export const revokeYoutubeAnalytics = body => Api.post(
  `${getBffUrl()}/auth/external/youtube_analytics/revoke`,
  body
)

export const authenticateLinkedIn = body => Api.post(
  `${getBffUrl()}/auth/external/linked_in`,
  body
)

export const revokeLinkedIn = body => Api.post(
  `${getBffUrl()}/auth/external/linked_in/revoke`,
  body
)

export const revokeFacebook = body => Api.post(
  `${getBffUrl()}/auth/external/facebook_analytics/revoke`,
  body
)

export const revokeTwitter = body => Api.post(
  `${getBffUrl()}/auth/external/twitter_analytics/revoke`,
  body
)

export const authenticateFacebook = body => Api.post(
  `${getBffUrl()}/auth/external/facebook_analytics`,
  body
)

export const fetchChartData = body => Api.post(
  `${getBffUrl()}/charts/data`,
  body
)

export const addIdentityToIdentitySet = (id, identityId) => Api.post(
  `${getBffUrl()}/identity_sets/${id}/identities/${identityId}`
)

export const removeIdentityFromIdentitySet = (id, identityId) => Api.destroy(
  `${getBffUrl()}/identity_sets/${id}/identities/${identityId}`
)

export const createIdentitySet = body => Api.post(
  `${getBffUrl()}/identity_sets`,
  body
)

export const updateIdentitySet = (id, body) => Api.patch(
  `${getBffUrl()}/identity_sets/${id}`,
  body
)

export const deleteIdentitySet = id => Api.destroy(
  `${getBffUrl()}/identity_sets/${id}`
)

export const getDarknetSearchResults = () => Api.get(
  `${getBffUrl()}/darknet/search_results/`
)

export const createDarknetScores = body => Api.post(
  `${getBffUrl()}/darknet/scores/request`,
  body
)

export const getDarknetScoresStatus = id => Api.get(
  `${getBffUrl()}/darknet/scores/status/${id}`
)

export const getDarknetScoreResults = (ids, dateFrom, dateTo) => Api.get(
  `${getBffUrl()}/darknet/scores/results?ids=${ids}&dateFrom=${dateFrom}&dateTo=${dateTo}`
)

export const getDarknetDemoScoreResults = () => Api.get(
  `${getBffUrl()}/plotlights/demo/chart`
)

export const searchDarknetEntries = body => Api.post(
  `${getBffUrl()}/darknet/search/`,
  body
)

export const createDarknetSearchQuery = body => Api.post(
  `${getBffUrl()}/darknet/search/create`,
  body
)

export const updateDarknetSearchQuery = (id, body) => Api.patch(
  `${getBffUrl()}/darknet/search/update/${id}`,
  body
)

export const deleteDarknetSearchQuery = id => Api.patch(
  `${getBffUrl()}/darknet/search/delete/${id}`
)

export const createDarknetScoreQuery = body => Api.post(
  `${getBffUrl()}/darknet/scores/create`,
  body
)

export const updateDarknetScoreQuery = (id, body) => Api.patch(
  `${getBffUrl()}/darknet/score_queries/${id}`,
  body
)

export const deleteDarknetScoreQuery = id => Api.destroy(
  `${getBffUrl()}/darknet/score_queries/${id}`
)

export const fetchAllDarknetQueries = () => Api.get(
  `${getBffUrl()}/darknet/queries`
)

export const deactivateDarknetQuery = (id, body) => Api.post(
  `${getBffUrl()}/darknet/queries/${id}/deactivate`,
  body
)

export const activateDarknetQuery = (id, body) => Api.post(
  `${getBffUrl()}/darknet/queries/${id}/activate`,
  body
)

export const testSearchQuery = body => Api.post(
  `${getBffUrl()}/darknet/queries/test_search`,
  body
)

export const translate = body => Api.post(
  `${getBffUrl()}/translate`,
  body
)

export const buildExternalWidgetUrl = content => {
  const queryParams = new URLSearchParams({
    widget_content: content
  })

  return `${getBffUrl()}/charts/render_widget?${queryParams.toString()}`
}

export const sso = body => Api.post(
  `${getBffUrl()}/sso`,
  body
)

export const logSso = body => Api.post(
  `${getBffUrl()}/sso/log`,
  body
)

export const createCampaign = body => Api.post(
  `${getBffUrl()}/content_desk/campaigns`,
  body
)

export const updateCampaign = (id, body) => Api.patch(
  `${getBffUrl()}/content_desk/campaigns/${id}`,
  body
)

export const deleteCampaign = id => Api.destroy(
  `${getBffUrl()}/content_desk/campaigns/${id}`
)

export const fetchCampaigns = body => Api.get(
  `${getBffUrl()}/content_desk/campaigns?page=${body.page}&limit=${body.limit}`
)

export const createContent = body => Api.post(
  `${getBffUrl()}/content_desk/contents`,
  body
)

export const updateContent = (id, body) => Api.patch(
  `${getBffUrl()}/content_desk/contents/${id}`,
  body
)

export const deleteContent = id => Api.post(
  `${getBffUrl()}/content_desk/contents/delete/${id}`
)

export const fetchContents = body => Api.post(
  `${getBffUrl()}/content_desk/contents/ids`,
  body
)

export const fetchContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts/search`,
  body
)

export const searchContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts/search/global`,
  body
)

export const createContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts`,
  body
)

export const updateContacts = (id, body) => Api.patch(
  `${getBffUrl()}/contacts_management/contacts/${id}`,
  body
)

export const deleteContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts/delete`,
  body
)

export const fetchDistributionLists = body => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists/search`,
  body
)

export const createDistributionList = body => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists`,
  body
)

export const updateDistributionList = (id, body) => Api.patch(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}`,
  body
)

export const deleteDistributionList = id => Api.destroy(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}`
)

export const addContactsToDistributionList = (id, body) => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}/add_contacts`, body
)

export const removeContactFromList = (id, contactId) => Api.destroy(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}/remove_contact/${contactId}`
)

export const uploadContacts = data => {
  const formData = new FormData()
  data.forEach(({ key, value }) => formData.append(key, value))

  return Api.form(
    `${getBffUrl()}/contacts_management/contacts/upload_contacts`, formData
  )
}

export const downloadContactsExcel = data => (
  Api.post(
    `${getBffUrl()}/contacts_management/contacts/excel`, data, 'blob'
  )
)

export const downloadDistributionList = data => (
  Api.post(
    `${getBffUrl()}/contacts_management/distribution_lists/excel`, data, 'blob'
  )
)

export const searchCampaigns = body => Api.post(
  `${getBffUrl()}/content_desk/campaigns/search`,
  body
)

export const searchLinkedInCompany = body => Api.post(
  `${getBffUrl()}/content_desk/contents/social_media_statics/linked_in/company_search`,
  body
)

export const searchContents = body => Api.post(
  `${getBffUrl()}/content_desk/contents/search`,
  body
)

export const searchMediaFiles = body => Api.post(
  `${getBffUrl()}/content_desk/media_files/search`,
  body
)

export const deleteMediaFile = id => Api.destroy(
  `${getBffUrl()}/content_desk/media_files/delete/${id}`
)

export const uploadEditorImage = file => {
  const formData = new FormData()
  formData.append('file', file.attachments[0], file.attachments[0].name)

  return Api.form(
    `${getBffUrl()}/content_desk/contents/upload_file`,
    formData
  )
}

export const uploadMediaFile = data => {
  const formData = new FormData()
  data.forEach(({ key, value, filename }) => {
    if (filename) {
      formData.append(key, value, filename)
    } else {
      formData.append(key, value)
    }
  })

  return Api.form(
    `${getBffUrl()}/content_desk/contents/upload_file`,
    formData
  )
}

export const createContactTag = body => Api.post(
  `${getBffUrl()}/contacts_management/tags`,
  body
)

export const renameContactTag = (id, name) => Api.patch(
  `${getBffUrl()}/contacts_management/tags/${id}`,
  { name }
)

export const deleteContactTag = id => Api.destroy(
  `${getBffUrl()}/contacts_management/tags/${id}`
)

export const addTagToContact = (id, body) => Api.post(
  `${getBffUrl()}/contacts_management/contacts/${id}/add_tag`,
  body
)

export const removeTagFromContact = (id, tagId) => Api.destroy(
  `${getBffUrl()}/contacts_management/contacts/${id}/remove_tag/${tagId}`
)

export const fetchStatistics = (id, earnedMediaAccuracy) => Api.get(
  `${getBffUrl()}/content_desk/contents/${id}/fetch_statistics?earned_media_accuracy=${earnedMediaAccuracy}`
)

export const lockContent = id => Api.patch(
  `${getBffUrl()}/content_desk/contents/lock/${id}`
)

export const unlockContent = id => Api.patch(
  `${getBffUrl()}/content_desk/contents/unlock/${id}`
)

export const dispatchContent = (id, body) => Api.post(
  `${getBffUrl()}/content_desk/contents/${id}/dispatch`,
  body
)

export const searchTags = body => Api.post(
  `${getBffUrl()}/contacts_management/tags/search`,
  body
)

export const fetchSavedChart = (id, timezone, locale) => Api.get(
  `${getBffUrl()}/saved_charts/${id}/data?timezone=${encodeURIComponent(timezone)}&locale=${locale}`
)

export const createSavedChart = body => Api.post(
  `${getBffUrl()}/saved_charts`,
  body
)

export const deleteSavedChart = id => Api.destroy(
  `${getBffUrl()}/saved_charts/${id}`
)

export const updateSavedChart = (id, body) => Api.patch(
  `${getBffUrl()}/saved_charts/${id}`,
  body
)

export const submitApiKey = body => Api.post(
  `${getBffUrl()}/api_keys/`, body
)

export const deleteApiKey = id => Api.destroy(
  `${getBffUrl()}/api_keys/${id}`
)

export const extractTextFromPdf = file => {
  const formData = new FormData()
  formData.append('file', file)

  return Api.form(
    `${getBffUrl()}/extract`,
    formData
  )
}

export const markNotificationAsRead = body => Api.post(
  `${getBffUrl()}/notifications/mark_as_read`, body
)

export const fetchNotifications = (page, limit, sortBy, sortOrder) => Api.get(
  `${getBffUrl()}/notifications?page=${page}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`
)

export const submitSurvey = () => Api.post(`${getBffUrl()}/submit_survey`)

export const searchAnewstipJournalists = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/search/journalists`, body
)

export const searchAnewstipOutlets = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/search/outlets`, body
)

export const searchAnewstipArticles = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/search/articles`, body
)

export const fetchAnewstipStatics = () => Api.get(
  `${getBffUrl()}/contacts_management/anewstip/statics`
)

export const searchAnewstipSuggest = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/suggest`, body
)

export const importAnewstipContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/import/anewstip`, body
)

export const replaceContact = body => Api.post(
  `${getBffUrl()}/contacts_management/import/anewstip/replace`, body
)

export const createEmailTemplate = body => Api.post(
  `${getBffUrl()}/content_desk/templates`,
  body
)

export const updateEmailTemplate = (id, body) => Api.patch(
  `${getBffUrl()}/content_desk/templates/${id}`,
  body
)

export const deleteEmailTemplate = id => Api.destroy(
  `${getBffUrl()}/content_desk/templates/${id}`
)

export const fetchEmailTemplates = body => Api.post(
  `${getBffUrl()}/content_desk/templates/search`, body
)

export const editorAiRequest = body => Api.post(
  `${getBffUrl()}/content_desk/ai_actions/exec`,
  body
)

export const searchUnsplashPhotos = body => Api.post(
  `${getBffUrl()}/unsplash/photos/search`, body
)

export const fetchRandomUnsplashPhotos = body => Api.post(
  `${getBffUrl()}/unsplash/photos/random`, body
)

export const downloadUnsplashImage = body => Api.post(
  `${getBffUrl()}/unsplash/photos/download`, body
)

export const fetchOpenGraphMetadata = body => Api.post(
  `${getBffUrl()}/open_graph/metadata`, body
)

export const uploadFileFromUrl = body => Api.post(
  `${getBffUrl()}/upload_from_url`, body
)

export const fetchFacebookLinkMetadata = body => Api.post(
  `${getBffUrl()}/content_desk/fetch_facebook_link_metadata`, body
)

export const mergeDistributionLists = body => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists/merge`, body
)

export const downloadEmailEvents = data => (
  Api.post(
    `${getBffUrl()}/content_desk/contents/email_events/excel`, data, 'blob'
  )
)

export const searchThemaxEditorialPlans = body => Api.post(
  `${getBffUrl()}/themax/search/editorial_plans`, body
)

export const searchThemaxPublications = body => Api.post(
  `${getBffUrl()}/themax/search/publications`, body
)

export const exportThemaxEditorialPlans = body => Api.post(
  `${getBffUrl()}/themax/export/excel/editorial_plans`,
  body,
  'blob'
)

export const searchThemaxSavedSearches = body => Api.post(
  `${getBffUrl()}/themax/saved_searches/search`,
  body
)

export const fetchThemaxSavedSearch = id => Api.get(
  `${getBffUrl()}/themax/saved_searches/${id}`
)

export const createThemaxSavedSearch = body => Api.post(
  `${getBffUrl()}/themax/saved_searches`,
  body
)

export const updateThemaxSavedSearch = (id, body) => Api.patch(
  `${getBffUrl()}/themax/saved_searches/${id}`,
  body
)

export const deleteThemaxSavedSearch = id => Api.destroy(
  `${getBffUrl()}/themax/saved_searches/${id}`
)

export const favoriteThemaxSavedSearch = id => Api.post(
  `${getBffUrl()}/themax/saved_searches/${id}/favorite`
)

export const createOrder = body => Api.post(
  `${getBffUrl()}/orders`,
  body
)

export const createGrootEditorialPlan = body => Api.post(
  `${getBffUrl()}/themax/groot_editorial_plans`,
  body
)

export const deleteGrootEditorialPlan = id => Api.destroy(
  `${getBffUrl()}/themax/groot_editorial_plans/${id}`
)
