import {
  put,
  takeEvery,
  all,
  call,
  select
} from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as Actions from 'actions/email_templates_library'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'

export function* togglePublicTemplates() {
  yield put(Actions.resetTemplatesModule())
  yield put(Actions.resetTemplateFilters())
  yield put(Actions.fetchTemplatesStart())
}

export function* fetchTemplatesStart() {
  const i18n = yield select(Selectors.getI18n)
  const filters = yield select(Selectors.getETLibraryFilter)
  const showPublicTemplates = yield select(Selectors.getETLibraryShowPublic)

  const filter = [
    { field: 'shared', value: showPublicTemplates }
  ]

  const requestBody = {
    filter,
    page: filters.get('page'),
    limit: filters.get('limit'),
    sort_by: filters.get('sortBy'),
    sort_order: filters.get('sortOrder')
  }

  try {
    const result = yield call(Api.fetchEmailTemplates, requestBody)

    yield put(Actions.fetchTemplatesSuccess(result))
  } catch (error) {
    yield put(Actions.fetchTemplatesError())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}
export function* watchTogglePublicTemplates() {
  yield takeEvery(Actions.togglePublicTemplates, togglePublicTemplates)
}

export function* watchFetchTemplateStart() {
  yield takeEvery(Actions.fetchTemplatesStart, fetchTemplatesStart)
}

export default function* searchSaga() {
  yield all([
    watchFetchTemplateStart(),
    watchTogglePublicTemplates()
  ])
}
