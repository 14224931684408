import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { makeStyles } from 'tss-react/mui'

import imageLinks from 'static/image_links'

import WithI18n from 'containers/WithI18n'
import Card from 'components/card'
import DummyCardIcon from 'components/dummy_card_icon'

import { IconButton } from 'containers/themed'

import { CardActions, CardHeader, CardContent, Tooltip } from '@mui/material'

const useStyles = makeStyles()({
  media: {
    height: 200
  },
  spacer: {
    flex: 1
  }
})

const MailingList = ({ mailingList, i18n, onEditClick, onRemoveClick }) => {
  const { classes } = useStyles()

  return (
    <Card>
      <div className={classes.media}>
        <DummyCardIcon image={imageLinks.MAILING_LIST_IMAGE} />
      </div>

      <CardHeader title={mailingList.get('name')} />

      <CardContent>
        {mailingList.get('recipients').size} {i18n.get('recipients')}
      </CardContent>

      <CardActions>
        <span className={classes.spacer} />

        <Tooltip
          placement="top"
          title={i18n.get('edit')}
        >
          <IconButton
            onClick={() => onEditClick(mailingList)}
            size="large"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip
          placement="top"
          title={i18n.get('delete')}
        >
          <IconButton
            onClick={() => onRemoveClick(mailingList)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

MailingList.propTypes = {
  i18n: PropTypes.object.isRequired,
  mailingList: PropTypes.instanceOf(Map).isRequired,

  onEditClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired
}

export default WithI18n()(MailingList)
