import { connect } from 'react-redux'

import UploadContacts from 'components/contact_management/contacts/upload_contacts/UploadContacts'

import {
  closeContactsUploadDialog,
  uploadContactsStart,
  openImportHelpDialog,
  setAutoCreateDistributionList,
  setAutoAddToExistingList,
  changeDistributionListName as onChangeFormData,
  setDistributionList
} from 'actions/contact_management'

import {
  getCmContactsUploadDialogOpen,
  getCmRequestRunning,
  getCmAutoCreateDistributionList,
  getCmAutoAddToExistingList,
  getCmListData,
  getCmDistributionLists,
  getUser
} from 'selectors'

const mapStateToProps = state => ({
  open: getCmContactsUploadDialogOpen(state),
  loading: getCmRequestRunning(state),
  autoCreateDistributionList: getCmAutoCreateDistributionList(state),
  autoAddToExistingList: getCmAutoAddToExistingList(state),
  newListName: getCmListData(state).get('name'),
  lists: getCmDistributionLists(state),
  user: getUser(state)
})

export default connect(mapStateToProps, {
  onClose: closeContactsUploadDialog,
  onFileUpload: uploadContactsStart,
  onHelpClick: openImportHelpDialog,
  onNewListCheckboxClick: setAutoCreateDistributionList,
  onAddToListCheckboxClick: setAutoAddToExistingList,
  onChangeFormData,
  onSelectList: setDistributionList
})(UploadContacts)
