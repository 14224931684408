import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import copyToClipboard from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { Button, IconButton, RaisedPrimaryButton } from 'containers/themed'

const useStyles = makeStyles()({
  dialogContent: {
    minHeight: '200px'
  },
  contentHelperText: {
    whiteSpace: 'break-spaces'
  },
  spacer: {
    flex: 1
  },
  close: {
    color: 'white !important'
  }
})

const variables = [
  { name: 'full_name', value: '%recipient.full_name%', example: 'variables_helper_full_name_example' },
  { name: 'first_name', value: '%recipient.first_name%', example: 'variables_helper_first_name_example' },
  { name: 'last_name', value: '%recipient.last_name%', example: 'variables_helper_last_name_example' },
  { name: 'unsubscribe', value: '%tag_unsubscribe_url%', example: 'variables_helper_unsubscribe_example' }
]

const TemplatesEditorVariables = ({ raisedBtn }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleCopyVariable = variable => {
    copyToClipboard(variable, { format: 'text/plain' })

    enqueueSnackbar(
      `${i18n.get('copied')}`,
      { variant: 'info', preventDuplicate: true }
    )
  }

  return (
    <>
      {raisedBtn ? (
        <RaisedPrimaryButton
          variant="contained"
          onClick={handleOpen}
          size="small"
        >{i18n.get('variables')}
        </RaisedPrimaryButton>
      )
        : (
          <Button
            variant="contained"
            onClick={handleOpen}
          >
            {i18n.get('variables')}
          </Button>
        )}
      <Dialog
        maxWidth="md"
        fullWidth
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        open={open}
      >
        <AppBar
          enableColorOnDark
          position="static"
        >
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
            >
              {`${i18n.get('variables')}`}
            </Typography>
            <div className={classes.spacer} />
            <IconButton
              className={classes.close}
              icon="close"
              onClick={handleClose}
              size="normal"
            />
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <div
            className={classes.contentHelperText}
            dangerouslySetInnerHTML={{
              __html: i18n.get('variables_helper_description')
            }}
          />
          <br />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{i18n.get('type')}</TableCell>
                <TableCell>{i18n.get('variable')}</TableCell>
                <TableCell>{i18n.get('copy')}</TableCell>
                <TableCell>{i18n.get('example')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variables.map((variable, index) => (
                <TableRow key={index}>
                  <TableCell>{i18n.get(variable.name)}</TableCell>
                  <TableCell>{i18n.get(variable.value)}</TableCell>
                  <TableCell><IconButton
                    icon={<ContentCopyIcon fontSize="25px" />}
                    onClick={() => handleCopyVariable(variable.value)}
                    size="small"
                  />
                  </TableCell>
                  <TableCell>{i18n.get(variable.example)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ mr: 2, mb: 2 }}
            variant="contained"
            onClick={handleClose}
          >
            {i18n.get('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

TemplatesEditorVariables.defaultProps = {
  raisedBtn: false
}

TemplatesEditorVariables.propTypes = {
  raisedBtn: PropTypes.bool
}

export default TemplatesEditorVariables
