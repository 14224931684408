import { connect } from 'react-redux'

import CampaignsNoData from 'components/content_desk/campaigns/campaigns_no_data/CampaignsNoData'

import { openCampaignForm } from 'actions/content_desk'

const mapStateToProps = _state => ({

})

export default connect(
  mapStateToProps,
  {
    onClick: openCampaignForm
  }
)(CampaignsNoData)
