import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { makeStyles } from 'tss-react/mui'

import {
  CircularProgress,
  InputAdornment,
  FormControl,
  FormHelperText,
  Tooltip
} from '@mui/material'

import {
  Search as SearchIcon,
  ManageSearch as SettingsIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'
import { Input, IconButton, Button, Autocomplete } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  searchForm: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginTop: '1em',
      height: '90%'
    }
  },
  fullScreenSearchForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resultsScreenSearchForm: {
    paddingTop: '10px'
  },
  fullScreenSimpleSearchContainer: {
    display: 'inherit',
    flexDirection: 'column',
    gap: '8px'
  },
  fullScreenSimpleSearchInput: {
    display: 'flex',
    width: '800px',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  resultsScreenSimpleSearchInput: {
    display: 'flex',
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '200px'
    }
  },
  simpleSearchIconButton: {
    marginLeft: '10px'
  },
  fullScreenAdvancedSearchContainer: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  fullScreenSwitchTypeButton: {
    alignSelf: 'flex-end'
  },
  resultsScreenSwitchTypeButton: {
    alignSelf: 'flex-end',
    marginTop: '10px'
  },
  staggeredListItem: {
    display: 'inherit',
    alignItems: 'inherit',
    width: 'inherit'
  },
  fullScreenAdvancedSearchInputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      width: '360px'
    }
  },
  resultsScreenAdvancedSearchInputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    gap: '12px',
    [theme.breakpoints.down('md')]: {
      width: '260px'
    }
  },
  fullScreenFormSectionHeader: {
    display: 'flex',
    justifyContent: 'center'
  },
  searchButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em'
  },
  searchTooltip: {
    marginLeft: '5px'
  },
  searchQueryWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
}))

const inputProps = { sx: { borderRadius: 5 } }

const mapToOptions = list => list.map(el => ({
  value: el,
  label: el
}))

const uniq = a => a.reduce((result, element) => {
  const normalize = x => (typeof x === 'string' ? x.toLowerCase() : x)
  const normalizedElement = normalize(element)

  if (result.every(otherElement => normalize(otherElement) !== normalizedElement)) {
    result.push(element)
  }

  return result
}, [])

const Search = ({
  statics,
  searchFormData,
  filtersFormData,
  isSimpleSearch,
  requestRunning,
  suggest,
  fullScreen,
  onSearchChange,
  onFilterChange,
  resetFilters,
  switchSearchType,
  onSearch,
  onSuggest
}) => {
  const i18n = useI18n()
  const device = useDevice()
  const isSmallScreen = !device.get('gt-md')
  const compSize = isSmallScreen ? 'small' : 'medium'
  const { classes } = useStyles()

  const [formError, setFormError] = useState(false)

  const topic = filtersFormData.get('topic')
  const mediaType = filtersFormData.get('mediaType')
  const state = filtersFormData.get('state')
  const city = filtersFormData.get('city')
  const country = filtersFormData.get('country')
  const states = statics.get('states')

  const query = searchFormData.get('query')
  const name = searchFormData.get('name')
  const jobTitle = searchFormData.get('jobTitle')
  const outletName = searchFormData.get('outletName')

  const filteredStatesByCountry = states && states.find(s => s.get('name') === country)
  const formattedStatesList = filteredStatesByCountry ? uniq(filteredStatesByCountry
    .get('states', []).map(el => el.get('name'))) : []

  const outletSuggestionsList = (suggest && suggest.get('name')) ? suggest.get('name').map(s => s.get('text')) : List([])
  const titleSuggestionsList = (suggest && suggest.get('title')) ? suggest.get('title').map(s => s.get('text')) : List([])

  const isAdvancedSearchFormValid = !(!query && !name && !jobTitle && !outletName)

  const handleSearchChange = (key, v) => {
    const { value } = v || { value: null }
    onSearchChange({ key, value })
  }

  const handleFilterChange = (key, v) => {
    const { value } = v || { value: null }
    onFilterChange({ key, value })
  }

  const handleOnSearch = () => {
    if (isSimpleSearch && query === '') {
      setFormError(true)
    } else if (!isSimpleSearch && !isAdvancedSearchFormValid) {
      setFormError(true)
    } else {
      setFormError(false)
      resetFilters()
      onSearch()
    }
  }

  const handleSuggestions = (key, source, value, { action }) => {
    if (action !== 'menu-close' && action !== 'input-blur') {
      handleSearchChange(key, { value })

      if (value.length >= 3) {
        onSuggest({ keywords: value, source })
      }
    }
  }

  const handleOnEnter = e => {
    if (e.code === 'Enter') {
      handleOnSearch()
    }
  }

  const renderSwitchTypeButton = () => (
    <div className={fullScreen ? classes.fullScreenSwitchTypeButton : classes.resultsScreenSwitchTypeButton}>
      <Button
        startIcon={<SettingsIcon />}
        variant="text"
        color="primary"
        size={!isSimpleSearch && compSize === 'small' ? 'small' : 'medium'}
        onClick={() => switchSearchType()}
        disabled={requestRunning}
      >
        {`${i18n.get(isSimpleSearch ? 'advanced_search' : 'simple_search')}`}
      </Button>
    </div>
  )

  const renderSimpleSearchButton = () => (
    <div className={classes.simpleSearchIconButton}>
      <StaggeredListItem
        delay={100}
        className={classes.staggeredListItem}
        type="rightToLeft"
      >
        <IconButton
          title={i18n.get('search')}
          onClick={handleOnSearch}
          disabled={requestRunning}
        >
          {requestRunning && (
            <CircularProgress
              style={{ position: 'absolute' }}
              size={34}
              variant="indeterminate"
            />
          )}
          <SearchIcon />
        </IconButton>
      </StaggeredListItem>
    </div>
  )

  const renderSimpleSearch = () => (
    <div className={classes.fullScreenSimpleSearchContainer}>
      <StaggeredList>
        <FormControl
          className={fullScreen ? classes.fullScreenSimpleSearchInput : classes.resultsScreenSimpleSearchInput}
          sx={{ gap: '8px' }}
          error={formError}
        >
          <StaggeredListItem
            delay={50}
            className={classes.staggeredListItem}
            type="fade"
          >
            <div className={classes.searchQueryWrapper}>
              <Input
                type="text"
                onKeyDown={handleOnEnter}
                label={i18n.get('free_text_search')}
                InputProps={{
                  ...inputProps,
                  endAdornment: (
                    <InputAdornment position="end">{renderSimpleSearchButton()}</InputAdornment>
                  )
                }}
                onChange={({ target: { value } }) => handleSearchChange('query', { value })}
                value={query}
                error={formError && query === ''}
                disabled={requestRunning}
                autoComplete="off"
              />
              <Tooltip
                className={classes.searchTooltip}
                placement="bottom-start"
                arrow
                title={<span style={{ whiteSpace: 'pre-line' }}>{i18n.get('anewstip_search_query_info')}</span>}
              >
                <InfoIcon color="primary" />
              </Tooltip>
            </div>
          </StaggeredListItem>
          {formError && !query
            ? (
              <FormHelperText>
                {i18n.get('anewstip_empty_search_error')}
              </FormHelperText>
            ) : null}
        </FormControl>
        <StaggeredListItem
          delay={150}
          className={classes.staggeredListItem}
        >
          {renderSwitchTypeButton()}
        </StaggeredListItem>
      </StaggeredList>
    </div>
  )

  const renderAdvancedSearch = () => (
    <StaggeredList>
      <div className={classes.fullScreenAdvancedSearchContainer}>
        <div className={fullScreen ? classes.fullScreenAdvancedSearchInputs : classes.resultsScreenAdvancedSearchInputs}>
          <FormControl
            sx={{ gap: '12px' }}
            error={formError}
          >
            <StaggeredListItem type="fade">
              <div className={classes.searchQueryWrapper}>
                <Input
                  type="text"
                  onKeyDown={handleOnEnter}
                  label={i18n.get('free_text_search')}
                  InputProps={inputProps}
                  size={compSize}
                  onChange={({ target: { value } }) => handleSearchChange('query', { value })}
                  value={query}
                  disabled={requestRunning}
                  autoComplete="off"
                />
                <Tooltip
                  className={classes.searchTooltip}
                  placement="right"
                  arrow
                  title={<span style={{ whiteSpace: 'pre-line' }}>{i18n.get('anewstip_search_query_info')}</span>}
                >
                  <InfoIcon color="primary" />
                </Tooltip>
              </div>
            </StaggeredListItem>
            <StaggeredListItem delay={50}>
              <Input
                onKeyDown={handleOnEnter}
                label={i18n.get('name')}
                InputProps={inputProps}
                size={compSize}
                style={{ marginRight: '5px' }}
                onChange={({ target: { value } }) => handleSearchChange('name', { value })}
                value={name}
                disabled={requestRunning}
                autoComplete="off"
              />
            </StaggeredListItem>
            <div style={{ zIndex: 6 }}>
              <StaggeredListItem delay={50}>
                <Autocomplete
                  sx={{
                    borderRadius: 5,
                    height: compSize === 'small' && 40
                  }}
                  isClearable
                  onKeyDown={handleOnEnter}
                  options={mapToOptions(titleSuggestionsList)}
                  placeholder=""
                  label={i18n.get('job_title')}
                  textFieldProps={{
                    size: compSize
                  }}
                  onChange={value => handleSearchChange('jobTitle', value)}
                  onInputChange={(v, e) => handleSuggestions('jobTitle', 'all', v, e)}
                  value={jobTitle ? { value: jobTitle, label: jobTitle } : jobTitle}
                  isDisabled={requestRunning}
                  autoComplete="off"
                />
              </StaggeredListItem>
            </div>
            <div style={{ zIndex: 5 }}>
              <StaggeredListItem delay={50}>
                <Autocomplete
                  sx={{
                    borderRadius: 5,
                    height: compSize === 'small' && 40
                  }}
                  isClearable
                  onKeyDown={handleOnEnter}
                  options={mapToOptions(outletSuggestionsList)}
                  placeholder=""
                  label={i18n.get('outlet_name')}
                  textFieldProps={{
                    size: compSize
                  }}
                  onChange={value => handleSearchChange('outletName', value)}
                  onInputChange={(v, e) => handleSuggestions('outletName', 'all', v, e)}
                  value={outletName ? { value: outletName, label: outletName } : outletName}
                  isDisabled={requestRunning}
                  autoComplete="off"
                />
              </StaggeredListItem>
            </div>

            {formError && !isAdvancedSearchFormValid
              ? (
                <FormHelperText>
                  {i18n.get('anewstip_missing_required_field_search_error')}
                </FormHelperText>
              ) : null}
          </FormControl>

          <div style={{ zIndex: 4 }}>
            <StaggeredListItem
              delay={150}
              className={classes.staggeredListItem}
            >
              <Autocomplete
                sx={{
                  borderRadius: 5,
                  height: compSize === 'small' && 40
                }}
                isClearable
                onKeyDown={handleOnEnter}
                options={mapToOptions(statics.get('topics'))}
                placeholder=""
                label={i18n.get('topic')}
                textFieldProps={{
                  size: compSize
                }}
                onChange={value => handleFilterChange('topic', value)}
                value={topic ? { value: topic, label: topic } : topic}
                clearOnBlur
                isDisabled={requestRunning}
                autoComplete="off"
              />
            </StaggeredListItem>
          </div>
          <div style={{ zIndex: 3 }}>
            <StaggeredListItem
              delay={200}
              className={classes.staggeredListItem}
            >
              <Autocomplete
                sx={{
                  borderRadius: 5,
                  height: compSize === 'small' && 40
                }}
                isClearable
                onKeyDown={handleOnEnter}
                options={mapToOptions(statics.get('mediaTypes'))}
                placeholder=""
                label={i18n.get('media_type')}
                textFieldProps={{
                  size: compSize
                }}
                onChange={value => handleFilterChange('mediaType', value)}
                value={mediaType ? { value: mediaType, label: mediaType } : mediaType}
                clearOnBlur
                isDisabled={requestRunning}
                autoComplete="off"
              />
            </StaggeredListItem>
          </div>
          <div style={{ zIndex: 2 }}>
            <StaggeredListItem
              delay={250}
              className={classes.staggeredListItem}
            >
              <Autocomplete
                sx={{
                  borderRadius: 5,
                  height: compSize === 'small' && 40
                }}
                isClearable
                onKeyDown={handleOnEnter}
                options={mapToOptions(statics.get('countries'))}
                placeholder=""
                label={i18n.get('country')}
                textFieldProps={{
                  size: compSize
                }}
                onChange={value => handleFilterChange('country', value)}
                value={country ? { value: country, label: country } : country}
                clearOnBlur
                isDisabled={requestRunning}
                autoComplete="off"
              />
            </StaggeredListItem>
          </div>
          <div style={{ zIndex: 1 }}>
            <StaggeredListItem
              delay={300}
              className={classes.staggeredListItem}
            >
              <Autocomplete
                sx={{
                  borderRadius: 5,
                  height: compSize === 'small' && 40
                }}
                isClearable
                onKeyDown={handleOnEnter}
                options={mapToOptions(formattedStatesList)}
                placeholder=""
                label={i18n.get('state')}
                textFieldProps={{
                  size: compSize
                }}
                onChange={value => handleFilterChange('state', value)}
                value={state ? { value: state, label: state } : state}
                clearOnBlur
                isDisabled={requestRunning}
                autoComplete="off"
              />
            </StaggeredListItem>
          </div>
          <StaggeredListItem
            delay={350}
            className={classes.staggeredListItem}
          >
            <Input
              type="text"
              onKeyDown={handleOnEnter}
              label={i18n.get('city')}
              InputProps={inputProps}
              size={compSize}
              onChange={({ target: { value } }) => handleFilterChange('city', { value })}
              value={city}
              disabled={requestRunning}
              autoComplete="off"
            />
          </StaggeredListItem>
          <StaggeredListItem
            delay={400}
            className={classes.staggeredListItem}
          >
            {renderSwitchTypeButton()}
          </StaggeredListItem>
          <div className={classes.searchButtonWrapper}>
            <StaggeredListItem
              delay={400}
              className={classes.staggeredListItem}
            >
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                style={{ width: '200px' }}
                size={compSize}
                onClick={handleOnSearch}
                disabled={requestRunning}
                loading={requestRunning}
              >
                {i18n.get('search')}
              </Button>
            </StaggeredListItem>
          </div>
        </div>
      </div>
    </StaggeredList>
  )

  return (
    <div className={`${classes.searchForm} ${fullScreen ? classes.fullScreenSearchForm : classes.resultsScreenSearchForm}`}>
      {isSimpleSearch ? renderSimpleSearch() : renderAdvancedSearch()}
    </div>
  )
}

Search.propTypes = {
  statics: PropTypes.instanceOf(Map),
  searchFormData: PropTypes.instanceOf(Map).isRequired,
  filtersFormData: PropTypes.instanceOf(Map).isRequired,
  isSimpleSearch: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  suggest: PropTypes.instanceOf(Map),

  onSearchChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  switchSearchType: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSuggest: PropTypes.func.isRequired
}

export default Search
