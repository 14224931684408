import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Grid,
  CircularProgress,
  FormGroup,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Box,
  Tooltip,
  InputAdornment,
  Autocomplete,
  TextField
} from '@mui/material'

import { orange, green, red } from '@mui/material/colors'

import SaveIcon from '@mui/icons-material/Save'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ReplayIcon from '@mui/icons-material/Replay'
import RefreshIcon from '@mui/icons-material/Refresh'
import InfoIcon from '@mui/icons-material/Info'

import ContentContainer from 'components/content_container'
import { Capabilities, uploadedMediaFilesSizeLimit } from 'static/constants'

import AppBar from 'containers/AppBar'
import AllowedDomains from 'containers/administration/content_desk_settings/AllowedDomains'
import { Button, Input, Select, IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'
import { validEmail, validDomain } from 'utils/regex'
import { getDomainRegionOptions, getDomainByRegion } from 'utils/mailgun'
import { formatBytes } from 'utils/number'

const useStyles = makeStyles()(theme => ({
  content: {
    paddingTop: 10
  },
  panelDetails: {
    flexDirection: 'column'
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
      margin: '0 auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  verifiedIcon: {
    marginLeft: 10,
    color: green[500],
    fontSize: 29
  },
  notVerifiedIcon: {
    color: orange[500],
    fontSize: 29
  },
  statusIconContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  domainStatusContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  verifyDomainButton: {
    padding: 0,
    marginLeft: 10
  },
  checkStatusIcon: {
    fontSize: 29
  }
}))

const domainRegionOptions = getDomainRegionOptions()

const mapDomainsToOptions = domains => domains.map(d => ({
  value: d.get('name'),
  label: '@'.concat(d.get('name'))
})).toJS()

export default function ContentDeskSettings({
  capabilities,
  config,
  defaultDomains,
  defaultDomainByTld,
  requestRunning,
  uploadedMediaFilesTotalSize,
  onSave,
  onChange,
  onUpdate,
  onVerify,
  onConfigRefresh
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [replayIcon, toggleReplayIcon] = useState(false)

  const { isDomainVerified, id } = config.toJS()

  const [senderEmailLocal, setSenderEmailLocal] = useState((config.get('senderEmail') || '').split('@')[0])
  const [senderEmailDomain, setSenderEmailDomain] = useState((config.get('senderEmail') || '')
    .split('@')[1] || defaultDomainByTld.get('domain'))
  const [senderEmail, setSenderEmail] = useState(config.get('senderEmail') || '')
  const [senderName, setSenderName] = useState(config.get('senderName') || '')
  const [domain, setDomain] = useState(config.get('domain', defaultDomainByTld.get('domain')))
  const [domainRegion, setDomainRegion] = useState(config.get('domainRegion', defaultDomainByTld.get('domainRegion')))
  const [customDomain, setCustomDomain] = useState(config.get('domain', ''))
  const [customRegion, setCustomRegion] = useState(config.get('domainRegion', defaultDomainByTld.get('domainRegion')))
  const [useCustomDomain, setUseCustomDomain] = useState(config.get('domain') !== defaultDomainByTld.get('domain'))

  const domainsOptions = defaultDomains.toJS()
    .map(o => ({ value: o.domain, label: '@'.concat(o.domain) }))
    .concat(mapDomainsToOptions(config.get('ccdAllowedDomains') || ImmutableList()))

  const configExists = id !== null
  const emailIsValid = validEmail(senderEmail)
  const domainIsValid = validDomain(domain)
  const customDomainIsValid = validDomain(customDomain)
  const loaderPercentage = (usage, limit) => {
    const percentage = Math.round((100 * usage) / limit)

    return { percentage: percentage > 100 ? 100 : percentage, isMax: percentage >= 100 }
  }

  const ccdPlanName = config.getIn(['ccdPlan', 'name'])
  const emailsSendLimit = config.getIn(['ccdPlan', 'emailsSendLimit'], 0)
  const emailsSendUsage = config.getIn(['ccdPlanUsage', 'emailsSendUsage'], 0)
  const distributionListsLimit = config.getIn(['ccdPlan', 'distributionListsLimit'], 0)
  const distributionListsUsage = config.getIn(['ccdPlanUsage', 'distributionListsUsage'], 0)
  const contactsPerDistListLimit = config.getIn(['ccdPlan', 'contactsPerDistListLimit'], 0)
  const journalistsImportLimit = config.getIn(['ccdPlan', 'journalistsImportLimit'], 0)
  const journalistsImportUsage = config.getIn(['ccdPlanUsage', 'journalistsImportUsage'], 0)
  const journalistsViewLimit = config.getIn(['ccdPlan', 'journalistsViewLimit'], 0)
  const journalistsViewUsage = config.getIn(['ccdPlanUsage', 'journalistsViewUsage'], 0)

  const accordionDetails = [
    {
      title: 'uploads_storage_space',
      usage: uploadedMediaFilesTotalSize,
      limit: uploadedMediaFilesSizeLimit
    },
    {
      title: 'sent_emails',
      usage: emailsSendUsage,
      limit: emailsSendLimit
    },
    {
      title: 'distribution_lists',
      usage: distributionListsUsage,
      limit: distributionListsLimit
    },
    {
      title: 'contacts_per_dist_list',
      limit: contactsPerDistListLimit
    },
    {
      title: 'imported_journalists',
      usage: journalistsImportUsage,
      limit: journalistsImportLimit
    },
    {
      title: 'viewed_journalists',
      usage: journalistsViewUsage,
      limit: journalistsViewLimit
    }
  ]

  const handleMailChange = value => {
    setSenderEmailLocal(value)
    setSenderEmail(value.concat('@').concat(senderEmailDomain))
  }

  const handleSenderEmailDomainChange = value => {
    setSenderEmailDomain(value)
    setSenderEmail(senderEmailLocal.concat('@').concat(value))
  }

  const handleNameChange = value => setSenderName(value)
  const handleUseCustomDomainChange = e => {
    const { checked } = e.target

    if (!checked) {
      setDomain(defaultDomainByTld.get('domain'))
      setDomainRegion(defaultDomainByTld.get('domainRegion'))
    } else {
      setDomain(customDomain)
      setDomainRegion(customRegion)
    }

    setUseCustomDomain(e.target.checked)
  }
  const handleDomainChange = value => {
    setDomain(value)
    setUseCustomDomain(false)
  }
  const handleRegionChange = value => {
    setDomainRegion(value)
    setDomain(getDomainByRegion(value))
    setUseCustomDomain(false)
  }
  const handleCustomDomainChange = value => {
    setCustomDomain(value)
    setDomain(value)
    setUseCustomDomain(true)
  }
  const handleCustomRegionChange = value => {
    setCustomRegion(value)
    setDomainRegion(value)
    setUseCustomDomain(true)
  }

  useEffect(() => {
    onChange({ senderEmail, senderName, domain, domainRegion, useCustomDomain })
  }, [senderEmail, senderName, domain, domainRegion, customDomain, customRegion, useCustomDomain])

  const handleSave = () => onSave()

  const allValid = (emailIsValid && domainIsValid && domainRegion !== '')
  const saveDisabled = requestRunning || !allValid

  const handleCDConfigRefresh = () => onConfigRefresh()

  const renderSaveButton = () => (
    <Button
      startIcon={<SaveIcon />}
      variant="contained"
      color="primary"
      loading={requestRunning}
      disabled={saveDisabled}
      onClick={handleSave}
    >
      {i18n.get('save')}
    </Button>
  )

  const renderUpdateButton = () => (
    <Button
      startIcon={<SaveIcon />}
      variant="contained"
      color="primary"
      loading={requestRunning}
      disabled={saveDisabled}
      onClick={() => onUpdate()}
    >
      {i18n.get('update')}
    </Button>
  )

  const renderDomainActions = () => (
    <AccordionActions>
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={useCustomDomain}
              onChange={handleUseCustomDomainChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          )}
          label={i18n.get('use_custom_domain')}
        />
      </FormGroup>
      {configExists && renderUpdateButton()}
      {!configExists && renderSaveButton()}
    </AccordionActions>
  )

  const renderSenderActions = () => (
    <AccordionActions>
      {configExists && renderUpdateButton()}
      {!configExists && renderSaveButton()}
    </AccordionActions>
  )

  const renderDomainStatus = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <div className={classes.domainStatusContainer}>
        <div className={classes.statusIconContainer}>
          {`${i18n.get('domain')} ${i18n.get('status')}: `}
          {isDomainVerified
            ? (
              <CheckCircleOutlineIcon
                className={classes.verifiedIcon}
                fontSize="medium"
              />
            )
            : (
              <div>
                <IconButton
                  onClick={onVerify}
                  title={i18n.get('check_status')}
                  className={classes.verifyDomainButton}
                  onMouseEnter={() => toggleReplayIcon(true)}
                  onMouseLeave={() => toggleReplayIcon(false)}
                  disabled={requestRunning}
                >
                  {requestRunning && (
                    <CircularProgress
                      style={{ position: 'absolute' }}
                      size={34}
                      variant="indeterminate"
                    />
                  )}
                  {replayIcon || requestRunning
                    ? <ReplayIcon className={classes.checkStatusIcon} />
                    : <HelpOutlineIcon className={classes.notVerifiedIcon} />}
                </IconButton>
              </div>

            )}
        </div>
      </div>
    </Grid>
  )
  const renderCustomRegionGridItem = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Select
        variant="outlined"
        onChange={value => handleCustomRegionChange(value.value)}
        options={domainRegionOptions}
        value={customRegion}
        label={i18n.get('region')}
        disabled={requestRunning}
      />
    </Grid>
  )
  const renderCustomDomainGridItem = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Input
        value={customDomain}
        error={!customDomainIsValid}
        label={i18n.get('custom_domain')}
        onChange={event => handleCustomDomainChange(event.target.value)}
        disabled={requestRunning}
      />
    </Grid>
  )
  const renderRegionGridItem = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Select
        variant="outlined"
        onChange={value => handleRegionChange(value.value)}
        options={domainRegionOptions}
        value={domainRegion}
        label={i18n.get('region')}
        disabled={requestRunning}
      />
    </Grid>
  )
  const renderDomainGridItem = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Input
        value={domain}
        label={i18n.get('domain')}
        onChange={event => handleDomainChange(event.target.value)}
        disabled
      />
    </Grid>
  )
  const renderSenderMailGridItem = () => (
    <Grid
      item
      xs={12}
      md={12}
    >
      <Input
        fullWidth
        type="text"
        value={senderEmailLocal}
        error={!emailIsValid && senderEmailLocal !== ''}
        label={i18n.get('sender_email')}
        onChange={event => handleMailChange(event.target.value)}
        disabled={requestRunning}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ width: '100%' }}
            >
              <Autocomplete
                disablePortal
                disableClearable
                size="small"
                options={domainsOptions}
                value={domainsOptions.find(option => option.value === senderEmailDomain) || domainsOptions[0]}
                onChange={(_event, option) => handleSenderEmailDomainChange(option.value)}
                disabled={requestRunning}
                sx={{ width: '100%' }}
                renderInput={params => (
                  <TextField {...params} />
                )}
              />
              <Tooltip
                title={i18n.get('ccd_config_sender_email_tooltip')}
                placement="top-end"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-20, 10]
                      }
                    }
                  ]

                }}
              >
                <InfoIcon sx={{ marginLeft: '10px' }} />
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
    </Grid>
  )
  const renderSenderNameGridItem = () => (
    <Grid
      item
      xs={12}
      md={12}
    >
      <Input
        fullWidth
        type="text"
        value={senderName}
        label={i18n.get('sender_name')}
        onChange={event => handleNameChange(event.target.value)}
        disabled={requestRunning}
      />
    </Grid>
  )

  const renderDetail = (detail, loader, index) => {
    let usageLimitDisplay

    if (detail.usage !== undefined) {
      if (detail.title === 'uploads_storage_space') {
        usageLimitDisplay = `${formatBytes(detail.usage)} / ${formatBytes(detail.limit)}`
      } else {
        usageLimitDisplay = `${detail.usage} / ${detail.limit}`
      }
    } else {
      usageLimitDisplay = detail.limit
    }

    return (
      <AccordionDetails
        key={index}
        classes={{ root: classes.panelDetails }}
      >
        <Typography>{i18n.get(detail.title)}</Typography>
        <Typography>{usageLimitDisplay}</Typography>
        {loader && (
          <LinearProgress
            variant="determinate"
            value={loader.percentage}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: loader.isMax && red[500]
              }
            }}
          />
        )}
      </AccordionDetails>
    )
  }

  return (
    <>
      <AppBar title={i18n.get('content_desk_settings')} />

      <ContentContainer className={classes.content}>
        <div className={classes.container}>
          <Accordion
            defaultExpanded
            disabled={!ccdPlanName}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
            >
              <Typography sx={{ flexGrow: 1 }}>{`${i18n.get('usage_and_limits')} / ${i18n.get('current_plan')} - ${ccdPlanName || 'none'}`}
              </Typography>
              <Box onClick={e => e.stopPropagation()}>
                <FormControlLabel
                  control={
                    (
                      <IconButton
                        color="primary"
                        onClick={handleCDConfigRefresh}
                        title={i18n.get('refresh')}
                        disabled={requestRunning}
                      >
                        <RefreshIcon />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </AccordionSummary>
            {ccdPlanName && (
              accordionDetails.map((detail, index) => {
                const loader = detail.usage && detail.limit ? loaderPercentage(detail.usage, detail.limit) : null

                return renderDetail(detail, loader, index)
              })
            )}
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('general')}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <Grid
                container
                spacing={2}
              >
                {renderSenderMailGridItem()}
                {renderSenderNameGridItem()}
              </Grid>
            </AccordionDetails>
            {renderSenderActions()}
          </Accordion>

          {capabilities.get(Capabilities.HAS_MAILGUN_DOMAIN_SETTINGS) && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{i18n.get('mailgun_domain_settings')}</Typography>
              </AccordionSummary>

              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <Grid
                  container
                  spacing={2}
                >
                  {!useCustomDomain && renderRegionGridItem()}
                  {!useCustomDomain && renderDomainGridItem()}
                  {useCustomDomain && renderCustomRegionGridItem()}
                  {useCustomDomain && renderCustomDomainGridItem()}
                  {configExists && renderDomainStatus()}
                </Grid>
              </AccordionDetails>
              {renderDomainActions()}
            </Accordion>
          )}
          {capabilities.get(Capabilities.HAS_CONTENT_DESK_ALLOWED_DOMAINS_SETTINGS) && (
            <AllowedDomains />
          )}
        </div>
      </ContentContainer>
    </>
  )
}

ContentDeskSettings.propTypes = {
  capabilities: PropTypes.instanceOf(ImmutableMap).isRequired,
  config: PropTypes.object.isRequired,
  defaultDomains: PropTypes.instanceOf(ImmutableList).isRequired,
  defaultDomainByTld: PropTypes.instanceOf(ImmutableMap).isRequired,
  requestRunning: PropTypes.bool.isRequired,
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired,

  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  onConfigRefresh: PropTypes.func.isRequired
}
