export const filterNameMapping = {
  news: {
    customTagGroups: 'custom_tag_group_id',
    customTags: 'custom_tag_id',
    emojis: 'emoji',
    entities: 'entity_name',
    entitiesPerson: 'entity_person_name',
    entitiesOrganization: 'entity_organization_name',
    entitiesTopic: 'entity_topic_name',
    entitiesProduct: 'entity_product_name',
    entitiesLocation: 'entity_location_name',
    groupedStatementCodes: 'grouped_statement_code_id',
    hashtags: 'hashtag',
    identities: 'identity_id',
    languages: 'language_id',
    mediaReviewCodes: 'mediareview_code_id',
    mediaReviewTypes: 'media_review_type_id',
    mediaTopics: 'media_topic_id',
    mentions: 'mention',
    newsStatementTonalities: 'news_statement_tonality',
    pageIdentities: 'page_identity_id',
    publications: 'publication_id',
    sentiments: 'sentiment',
    statementCodes: 'statement_code_id',
    tonalities: 'tonality',
    outlinks: 'outlink_id',
    domains: 'domain',
    interfaces: 'interface_id',
    suppliers: 'supplier_id',
    analysis: {
      selectedCodes: 'selected_code_ids',
      restrictedCodes: 'restricted_code_ids',
      excludedCodes: 'excluded_code_ids',
      groupedCodes: 'grouped_code_ids',
      statementTonalities: 'statement_tonalities'
    },
    similarity: {
      ccdContents: 'ccd_content_ids',
      ccdCampaigns: 'ccd_campaign_ids'
    }
  },
  publication: {
    channels: 'channel_id',
    countries: 'country_id',
    distributionAreas: 'distribution_area_ids',
    filterGroups: 'filter_group_ids',
    mediaTypes: 'media_type_id',
    newsguardRanks: 'newsguard_rank',
    newsguardOrientations: 'newsguard_orientation_id',
    newsguardScoreRanges: 'newsguard_score_range',
    states: 'state_id'
  },
  pageIdentity: {
    identitySets: 'identity_set_ids'
  },
  outlink: {
    outlinkDomains: 'domain',
    outlinkUrls: 'url'
  }
}

export const NewsPageViews = {
  NEWS: 'news',
  BACKWARDS_NEWS: 'backwards_news',
  INFLUENCERS: 'influencers',
  PUBLICATIONS: 'publications',
  CHARTS: 'charts',
  PIN_BOARDS: 'pin_boards',
  STATISTICS: 'statistics',
  PROFILES: 'profiles',
  POSTS: 'posts',
  SAVED_SEARCHES: 'saved_searches',
  STATIC_CHARTS: 'static_charts',
  ANALYSIS_QUERIES: 'analysis_queries'
}

export const NewsPageModules = {
  NEWS: 'news',
  ANALYSIS: 'analysis',
  PROFILE_MONITORING: 'profile_monitoring',
  NEWS_POOL: 'news_pool'
}

export const CustomTagsNewsTypes = {
  [NewsPageModules.NEWS_POOL]: 'backwards_news',
  [NewsPageModules.NEWS]: 'default',
  [NewsPageModules.ANALYSIS]: 'default',
  [NewsPageModules.PROFILE_MONITORING]: 'default'
}

export const NewsPageViewTypes = {
  LISTVIEW: 'list_view',
  CARDVIEW: 'card_view'
}

export const DefaultExportFormats = ['pdf', 'xlsx', 'html', 'txt', 'xml', 'xml_pure', 'json', 'json_pure']

export const InitialRoutes = {
  DASHBOARD: '/app/dashboard',
  NEWSBOARD: '/app/news',
  PROFILE_MONITORING: '/app/profile_monitoring',
  SEARCH_POOL: '/app/search_pool',
  MEDIA_MONITORING: '/app/media_monitoring',
  MEDIA_REVIEWS: '/app/media_reviews',
  CONTENT_DESK: '/app/content_desk/content_management',
  CONTACT_DATABASE_MODULE: '/app/contact_management/my_contacts',
  ANEWSTIP: '/app/contact_management/journalist_search',
  THEMAX: '/app/themax'
}

export const DateTypes = {
  ARTICLE: 'article',
  MEDIA_REVIEW: 'mediareview'
}

export const KeyFigures = {
  BUZZ: 'buzz',
  INTERACTIONS: 'interactions',
  REACH: 'reach',
  CHANNEL_KEY_FIGURES: 'channelKeyFigures',
  TOP_NEGATIVE_SENTIMENT: 'top_negative_sentiment',
  TOP_POSITIVE_SENTIMENT: 'top_positive_sentiment'
}

export const AllowedKeyFigures = {
  DEFAULT: [
    KeyFigures.BUZZ,
    KeyFigures.REACH,
    KeyFigures.INTERACTIONS,
    KeyFigures.CHANNEL_KEY_FIGURES
  ],
  BACKWARDS_NEWS: [
    KeyFigures.BUZZ,
    KeyFigures.REACH,
    KeyFigures.INTERACTIONS,
    KeyFigures.CHANNEL_KEY_FIGURES,
    KeyFigures.TOP_NEGATIVE_SENTIMENT,
    KeyFigures.TOP_POSITIVE_SENTIMENT
  ]

}

export const SearchFields = {
  AUTHOR_QUERIES: 'authorQueries',
  BOOLEANS: 'booleans',
  CHANNELS: 'channels',
  COUNTRIES: 'countries',
  CUSTOM_TAGS: 'customTags',
  CUSTOM_TAG_GROUPS: 'customTagGroups',
  DISTRIBUTION_AREAS: 'distributionAreas',
  DOMAINS: 'domains',
  OUTLINKS: 'outlinks',
  OUTLINK_DOMAINS: 'outlinkDomains',
  OUTLINK_URLS: 'outlinkUrls',
  EMOJIS: 'emojis',
  ENTITIES: 'entities',
  ENTITIES_PERSON: 'entitiesPerson',
  ENTITIES_ORGANIZATION: 'entitiesOrganization',
  ENTITIES_TOPIC: 'entitiesTopic',
  ENTITIES_PRODUCT: 'entitiesProduct',
  ENTITIES_LOCATION: 'entitiesLocation',
  EXCLUDE_RETWEETS: 'excludeRetweets',
  EXCLUDE_VISITOR_POSTS: 'excludeVisitorPosts',
  EXCLUDE_COMMENTS: 'excludeComments',
  FILTER_GROUPS: 'filterGroups',
  GEO_BOUNDING_BOX: 'geoBoundingBox',
  GLOBAL_CLUSTERS: 'globalClusters',
  HASHTAGS: 'hashtags',
  MENTIONS: 'mentions',
  IDENTITIES: 'identities',
  IDENTITY_SETS: 'identitySets',
  INFLUENCERS: 'influencers',
  INTERFACES: 'interfaces',
  SUPPLIERS: 'suppliers',
  LANGUAGES: 'languages',
  MEDIA_REVIEW_CODES: 'mediaReviewCodes',
  MEDIA_REVIEW_TYPES: 'mediaReviewTypes',
  MEDIA_TOPICS: 'mediaTopics',
  MEDIA_TYPES: 'mediaTypes',
  NEWS_QUERIES: 'newsQueries',
  PAGE_IDENTITIES: 'pageIdentities',
  PUBLICATION_QUERIES: 'publicationQueries',
  PUBLICATIONS: 'publications',
  SENTIMENTS: 'sentiments',
  SIMILARITY_CCD_CONTENTS: 'similarityCcdContents',
  SIMILARITY_CCD_CAMPAIGNS: 'similarityCcdCampaigns',
  SUMMARIZED_ONLY: 'summarizedOnly',
  TONALITIES: 'tonalities',
  ANALYSIS: 'analysis',
  STATEMENT_CODES: 'statementCodes',
  GROUPED_STATEMENT_CODES: 'groupedStatementCodes',
  NEWS_STATEMENT_TONALITIES: 'newsStatementTonalities',
  NEWSGUARD_RANKS: 'newsguardRanks',
  NEWSGUARD_ORIENTATIONS: 'newsguardOrientations',
  WITH_OUTLINKS_ONLY: 'withOutlinksOnly',
  WITHOUT_OUTLINKS_ONLY: 'withoutOutlinksOnly',
  STATES: 'states'
}

export const DarknetSearchFields = {
  DARKNET_SEARCH_QUERIES: 'darknetSearchQueries',
  MIN_HACKISHNESS: 'minHackishness',
  NETWORKS: 'networks'
}

export const SearchFieldGroups = {
  NEWS: 'news',
  INFLUENCERS: 'influencers',
  PUBLICATIONS: 'publications'
}

export const IndexTypes = {
  DEFAULT: 'default',
  BACKWARDS_NEWS: 'backwards_news'
}

export const GroupingTypes = {
  CHANNEL: 'channel',
  MEDIA_REVIEW_CODE: 'media_review_code',
  CUSTOM_TAG: 'custom_tag',
  PAGE_IDENTITY: 'page_identity',
  UNGROUPED: 'ungrouped',
  UNGROUPED_PAGE_IDENTITY: 'ungrouped_page_identity',
  NEWSGUARD_RANK: 'newsguard_rank'
}

export const Capabilities = {
  HAS_28_DAYS_EXPIRY: 'HAS_28_DAYS_EXPIRY',
  HAS_ADMINISTRATION_MODULE: 'HAS_ADMINISTRATION_MODULE',
  HAS_ADVERTISING_BANNER_FEATURE: 'HAS_ADVERTISING_BANNER_FEATURE',
  HAS_ANALYSIS_INFLUENCER_DETAILS_FEATURE: 'HAS_ANALYSIS_INFLUENCER_DETAILS_FEATURE',
  HAS_ANALYSIS_INFLUENCERS_MODULE: 'HAS_ANALYSIS_INFLUENCERS_MODULE',
  HAS_ANALYSIS_MODULE: 'HAS_ANALYSIS_MODULE',
  HAS_ANALYSIS_PUBLICATIONS_MODULE: 'HAS_ANALYSIS_PUBLICATIONS_MODULE',
  HAS_ANALYSIS_SAVED_SEARCHES_EDITING_FEATURE: 'HAS_ANALYSIS_SAVED_SEARCHES_EDITING_FEATURE',
  HAS_ANALYSIS_SAVED_SEARCHES: 'HAS_ANALYSIS_SAVED_SEARCHES',
  HAS_ANALYSIS_SAVED_SEARCHES_FEEDS: 'HAS_ANALYSIS_SAVED_SEARCHES_FEEDS',
  HAS_CONTACTS_MANAGEMENT_ANEWSTIP_API_KEY_EDITING_FEATURE: 'HAS_CONTACTS_MANAGEMENT_ANEWSTIP_API_KEY_EDITING_FEATURE',
  HAS_CONTACTS_MANAGEMENT_ANEWSTIP: 'HAS_CONTACTS_MANAGEMENT_ANEWSTIP',
  HAS_AUTOMATIC_SEARCH_RESULT_TRANSLATIONS: 'HAS_AUTOMATIC_SEARCH_RESULT_TRANSLATIONS',
  HAS_CHARTS_MODULE: 'HAS_CHARTS_MODULE',
  HAS_CUSTOM_TAG_ASSISTANT: 'HAS_CUSTOM_TAG_ASSISTANT',
  HAS_CUSTOM_TAG_RSS_EXPORT: 'HAS_CUSTOM_TAG_RSS_EXPORT',
  HAS_CUSTOM_TAGS: 'HAS_CUSTOM_TAGS',
  HAS_CUSTOM_TAGS_MODULE: 'HAS_CUSTOM_TAGS_MODULE',
  HAS_CUSTOM_TAG_XML_EXPORT: 'HAS_CUSTOM_TAG_XML_EXPORT',
  HAS_CUSTOM_TAG_JSON_EXPORT: 'HAS_CUSTOM_TAG_JSON_EXPORT',
  HAS_DASHBOARD_EDITING_FEATURE: 'HAS_DASHBOARD_EDITING_FEATURE',
  HAS_DASHBOARD_MODULE: 'HAS_DASHBOARD_MODULE',
  HAS_DASHBOARD_PUBLISHING_FEATURE: 'HAS_DASHBOARD_PUBLISHING_FEATURE',
  HAS_DELETE_NEWS_FEATURE: 'HAS_DELETE_NEWS_FEATURE',
  HAS_EDITING_DUMMY_MODULE: 'HAS_EDITING_DUMMY_MODULE',
  HAS_EDITING_FEATURE: 'HAS_EDITING_FEATURE',
  HAS_EDITING_FULLTEXT_FEATURE: 'HAS_EDITING_FULLTEXT_FEATURE',
  HAS_EXTERNAL_ACCOUNT_SETTINGS: 'HAS_EXTERNAL_ACCOUNT_SETTINGS',
  HAS_EXTERNAL_ANALYTICS: 'HAS_EXTERNAL_ANALYTICS',
  HAS_GLOBAL_SETTINGS: 'HAS_GLOBAL_SETTINGS',
  HAS_INFLUENCER_DETAILS_FEATURE: 'HAS_INFLUENCER_DETAILS_FEATURE',
  HAS_INFLUENCERS_MODULE: 'HAS_INFLUENCERS_MODULE',
  HAS_MAILGUN_DOMAIN_SETTINGS: 'HAS_MAILGUN_DOMAIN_SETTINGS',
  HAS_MAILING_LISTS: 'HAS_MAILING_LISTS',
  HAS_MEDIA_REVIEW_DISPATCH_FEATURE: 'HAS_MEDIA_REVIEW_DISPATCH_FEATURE',
  HAS_MEDIA_REVIEW_EDITING_FEATURE: 'HAS_MEDIA_REVIEW_EDITING_FEATURE',
  HAS_MEDIA_REVIEW_MODULE: 'HAS_MEDIA_REVIEW_MODULE',
  HAS_NEWS_MODULE: 'HAS_NEWS_MODULE',
  HAS_NEWS_POOL_DELETE_NEWS_FEATURE: 'HAS_NEWS_POOL_DELETE_NEWS_FEATURE',
  HAS_NEWS_POOL_EDITING_FEATURE: 'HAS_NEWS_POOL_EDITING_FEATURE',
  HAS_NEWS_POOL_EXPORT: 'HAS_NEWS_POOL_EXPORT',
  HAS_NEWS_POOL_INFLUENCER_DETAILS_FEATURE: 'HAS_NEWS_POOL_INFLUENCER_DETAILS_FEATURE',
  HAS_NEWS_POOL_INFLUENCERS_MODULE: 'HAS_NEWS_POOL_INFLUENCERS_MODULE',
  HAS_NEWS_POOL_MODULE: 'HAS_NEWS_POOL_MODULE',
  HAS_NEWS_POOL_PUBLICATIONS_MODULE: 'HAS_NEWS_POOL_PUBLICATIONS_MODULE',
  HAS_NEWS_POOL_SAVED_SEARCHES_EDITING_FEATURE: 'HAS_NEWS_POOL_SAVED_SEARCHES_EDITING_FEATURE',
  HAS_NEWS_POOL_SAVED_SEARCHES: 'HAS_NEWS_POOL_SAVED_SEARCHES',
  HAS_NEWS_POOL_SAVED_SEARCHES_SMS_ALERTING_FEATURE: 'HAS_NEWS_POOL_SAVED_SEARCHES_SMS_ALERTING_FEATURE',
  HAS_NEWS_POOL_SAVED_SEARCHES_FEEDS: 'HAS_NEWS_POOL_SAVED_SEARCHES_FEEDS',
  HAS_NEWS_SAVED_SEARCHES_EDITING_FEATURE: 'HAS_NEWS_SAVED_SEARCHES_EDITING_FEATURE',
  HAS_NEWS_SAVED_SEARCHES: 'HAS_NEWS_SAVED_SEARCHES',
  HAS_NEWS_SAVED_SEARCHES_FEEDS: 'HAS_NEWS_SAVED_SEARCHES_FEEDS',
  HAS_MOZ: 'HAS_MOZ',
  HAS_PROFILE_MONITORING_MODULE: 'HAS_PROFILE_MONITORING_MODULE',
  HAS_PROFILE_MONITORING_SAVED_SEARCHES_EDITING_FEATURE: 'HAS_PROFILE_MONITORING_SAVED_SEARCHES_EDITING_FEATURE',
  HAS_PROFILE_MONITORING_SAVED_SEARCHES: 'HAS_PROFILE_MONITORING_SAVED_SEARCHES',
  HAS_PROFILE_MONITORING_SAVED_SEARCHES_FEEDS: 'HAS_PROFILE_MONITORING_SAVED_SEARCHES_FEEDS',
  HAS_PUBLICATIONS_MODULE: 'HAS_PUBLICATIONS_MODULE',
  HAS_RTV_ORDER_FEATURE: 'HAS_RTV_ORDER_FEATURE',
  HAS_SHOPPING_CART: 'HAS_SHOPPING_CART',
  HAS_SUBSCRIPTIONS_MODULE: 'HAS_SUBSCRIPTIONS_MODULE',
  HAS_US_TUTORIALS: 'HAS_US_TUTORIALS',
  HAS_XML_EXPORT: 'HAS_XML_EXPORT',
  HAS_JSON_EXPORT: 'HAS_JSON_EXPORT',
  HAS_NEWSGUARD: 'HAS_NEWSGUARD',
  HAS_DARKNET_MODULE: 'HAS_DARKNET_MODULE',
  HAS_DARKNET_DASHBOARD: 'HAS_DARKNET_DASHBOARD',
  HAS_DARKNET_QUERY_MANAGER: 'HAS_DARKNET_QUERY_MANAGER',
  HAS_EXTERNAL_CONVENTO_LINK: 'HAS_EXTERNAL_CONVENTO_LINK',
  HAS_DISABLED_USER_SETTINGS: 'HAS_DISABLED_USER_SETTINGS',
  HAS_CONTENT_DESK_MODULE: 'HAS_CONTENT_DESK_MODULE',
  HAS_CONTENT_DESK_LINKED_IN: 'HAS_CONTENT_DESK_LINKED_IN',
  HAS_CONTENT_DESK_FACEBOOK: 'HAS_CONTENT_DESK_FACEBOOK',
  HAS_CONTACTS_MANAGEMENT_MODULE: 'HAS_CONTACTS_MANAGEMENT_MODULE',
  HAS_SAVED_CHARTS: 'HAS_SAVED_CHARTS',
  HAS_TRANSLATION_FEATURE: 'HAS_TRANSLATION_FEATURE',
  HAS_DISPATCH_LOG: 'HAS_DISPATCH_LOG',
  HAS_CONTENT_DESK_PUBLIC_TEMPLATES_READ_RIGHTS: 'HAS_CONTENT_DESK_PUBLIC_TEMPLATES_READ_RIGHTS',
  HAS_CONTENT_DESK_PUBLIC_TEMPLATES_WRITE_RIGHTS: 'HAS_CONTENT_DESK_PUBLIC_TEMPLATES_WRITE_RIGHTS',
  HAS_CONTENT_DESK_ALLOWED_DOMAINS_SETTINGS: 'HAS_CONTENT_DESK_ALLOWED_DOMAINS_SETTINGS',
  HAS_AI_SUMMARY_FEATURE: 'HAS_AI_SUMMARY_FEATURE',
  HAS_THE_MAX_MODULE: 'HAS_THE_MAX_MODULE',
  HAS_THE_MAX_PAID_VERSION: 'HAS_THE_MAX_PAID_VERSION'
}

export const AlertTypes = {
  NONE: 'none',
  INSTANTLY: 'instantly',
  HOURLY: 'hourly',
  DAILY: 'daily',
  DAILY_THRESHOLD: 'daily_threshold'
}

export const CustomTagAfterExportActions = {
  NONE: 'none',
  PURGE: 'purge',
  SUBSTITUTE: 'substitute'
}

export const NewsPageSize = 10
export const NewsPageMoreNewsSize = 50
export const ChartGridRowHeight = 95
export const ChartLimits = [3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]

export const StepTypes = {
  KPI: 'kpi',
  CHARTS: 'charts',
  TIMELINE: 'timeline',
  SAVED_SEARCHES_TIMELINE: 'saved_searches_timeline',
  NEWS_FEED: 'news_feed',
  TOP_INFLUENCERS: 'top_influencers',
  TOP_PUBLICATIONS: 'top_publications',
  PAGE_IDENTITIES_TIMELINE: 'page_identities_timeline',
  PAGE_IDENTITIES: 'page_identities',
  IMAGE_FEED: 'image_feed'
}

export const ChartDataSources = {
  PRESSRELATIONS_NEWS: 'pressrelations_news',
  GOOGLE_ANALYTICS: 'google_analytics',
  MOZ: 'moz',
  YOUTUBE_ANALYTICS: 'youtube_analytics',
  LINKED_IN_ANALYTICS: 'linked_in_analytics',
  FACEBOOK_ANALYTICS: 'facebook_analytics',
  TWITTER_ANALYTICS: 'twitter_analytics',
  IMAGE: 'image',
  TEXT: 'text',
  EXTERNAL_WIDGET: 'external_widget'
}

export const ChartTypes = {
  AREA: 'area',
  BAR: 'bar',
  BUBBLE: 'bubble',
  EXTERNAL_WIDGET: 'externalWidget',
  FUNNEL: 'funnel',
  GAUGE: 'gauge',
  HEATMAP: 'heatmap',
  HORIZONTAL_BAR: 'horizontalBar',
  IMAGE: 'image',
  IMAGE_FEED: 'imageFeed',
  INFLUENCER_FEED: 'influencerFeed',
  LINE: 'line',
  NEWS_FEED: 'newsFeed',
  NEWS_TICKER: 'newsTicker',
  PAGE_IDENTITY_FEED: 'pageIdentityFeed',
  PIE: 'pie',
  PUBLICATION_FEED: 'publicationFeed',
  RADAR: 'radar',
  RADIAL_BAR: 'radialBar',
  RECTANGLE: 'rectangle',
  SOCIAL_MEDIA_ANALYTICS_FEED: 'socialMediaAnalyticsFeed',
  SOCIAL_MEDIA_ANALYTICS_STORIES_FEED: 'socialMediaAnalyticsStoriesFeed',
  STATE_MAP: 'stateMap',
  TAG_CLOUD: 'tagCloud',
  TEXT: 'text',
  TREEMAP: 'treemap',
  WORLD_MAP: 'worldMap'
}

export const CookieNames = {
  NEWSRADAR: 'groot_newsradar',
  INITIAL_ROUTE: 'groot_initial_route',
  SESSION: 'groot_session',
  ACCESS_TOKEN: 'groot_access_token',
  COOKIES_ACCEPTED: 'groot_cookies_accepted'
}

export const DateModes = {
  DATE_RANGE: 'date_range',
  DATE: 'date',
  RELATIVE: 'relative'
}

export const Networks = [
  { name: 'Onion', id: 'onion', forDarkowlSearchQuery: true },
  { name: 'Telegram', id: 'telegram', forDarkowlSearchQuery: true },
  { name: 'I2P', id: 'i2p', forDarkowlSearchQuery: true },
  { name: 'Discord', id: 'discord', forDarkowlSearchQuery: true },
  { name: 'Zeronet', id: 'zeronet', forDarkowlSearchQuery: true },
  { name: 'FTP', id: 'ftp', forDarkowlSearchQuery: true },
  { name: 'IRC', id: 'irc', forDarkowlSearchQuery: true },
  { name: 'OpenNIC', id: 'openNIC', forDarkowlSearchQuery: true },
  { name: 'Unclassified', id: 'unclassified', forDarkowlSearchQuery: false }
]

export const Intervals = {
  hourly: 60,
  twice_a_day: 720,
  daily: 1440,
  twice_a_week: 5040,
  weekly: 10080,
  monthly: 40320
}

export const DateRanges = [
  'today',
  'yesterday',
  'this_week',
  'last_week',
  'this_month',
  'last_month',
  'this_quarter',
  'last_quarter',
  'this_half_year',
  'last_half_year',
  'this_year',
  'last_year',
  'last_6_months',
  'last_12_months',
  'last_7_days',
  'last_30_days',
  'last_60_days',
  'last_90_days',
  'last_12_hours',
  'last_24_hours'
]

export const SortingOptions = [
  '',
  'reach',
  'interactions'
]

export const StructuredExportFormatFlags = ['rssEnabled', 'xmlEnabled', 'jsonEnabled']

export const ContentDeskSearchFields = {
  CONTENT_PLAIN_TEXT: 'content_plain_text',
  CONTENT_TITLE: 'content_title',
  CAMPAIGN_NAME: 'campaign_name'
}

export const ContactManagementSearchFields = {
  CONTACT_NAME: 'contact_name',
  CONTACT_TAG: 'contact_tag',
  DISTRIBUTION_LIST_NAME: 'distribution_list_name',
  DISTRIBUTION_LIST_CONTACTS: 'distribution_list_contacts'
}

export const ThemaxSearchFields = {
  TOPIC_PLAN: 'topic_plan',
  PUBLICATION_NAME: 'publication_name',
  PUBLICATION_INFO: 'publication_info',
  REACH_MIN: 'reachMin',
  REACH_MAX: 'reachMax',
  PAGE_AVE_MIN: 'pageAveMin',
  PAGE_AVE_MAX: 'pageAveMax',
  BOOKMARKED_ONLY: 'bookmarkedOnly',
  PUBLICATION: 'publication'
}

export const LicensersWithDisabledArticleDateEditing = [20]
export const LicensersWithDisabledMediaReviewDateEditing = [9]

export const Countries = {
  AUT: 40,
  CHE: 756,
  DEU: 276,
  IRL: 372,
  USA: 840
}

export const CdEditorMoods = [
  { id: 1, label: 'optimistic but not exaggerated' },
  { id: 2, label: 'pessimistic but not exaggerated' },
  { id: 3, label: 'humorous' },
  { id: 4, label: 'inspiring' },
  { id: 5, label: 'casual' },
  { id: 6, label: 'dramatic' },
  { id: 7, label: 'romantic' },
  { id: 8, label: 'urgent' },
  { id: 9, label: 'nostalgic' },
  { id: 10, label: 'cautious' },
  { id: 11, label: 'apologetic' },
  { id: 12, label: 'reassuring' },
  { id: 13, label: 'provocative' },
  { id: 14, label: 'passionate' }
]

export const currentTypeformSurveyId = 'LasNKD9q'

export const MediaReviewFilterSizes = [5, 10, 30, 50, 100]

export const uploadedMediaFilesSizeLimit = 524288000 // 500 MB

export const languageOptions = [
  {
    languageId: 1,
    languageShort: 'de',
    countryCode: 'de',
    localeMapping: {}
  },
  {
    languageId: 2,
    languageShort: 'en',
    countryCode: 'us',
    localeMapping: {
      'en-us': 'us',
      'en-ca': 'ca',
      'en-gb': 'gb',
      'en-ie': 'ie'
    }
  },
  {
    languageId: 4,
    languageShort: 'fr',
    countryCode: 'fr',
    localeMapping: {}
  },
  {
    languageId: 5,
    languageShort: 'ru',
    countryCode: 'ru',
    localeMapping: {}
  },
  {
    languageId: 6,
    languageShort: 'zh',
    countryCode: 'cn',
    localeMapping: {}
  },
  {
    languageId: 7,
    languageShort: 'ja',
    countryCode: 'jp',
    localeMapping: {}
  }
]

export const themaxReachFilterOptions = {
  min: 0,
  max: 30000000,
  marks: [
    {
      value: 0,
      scaledValue: 0,
      label: '0'
    },
    {
      value: 25,
      scaledValue: 100000,
      label: '100k'
    },
    {
      value: 50,
      scaledValue: 1000000,
      label: '1M'
    },
    {
      value: 75,
      scaledValue: 10000000,
      label: '10M'
    },
    {
      value: 100,
      scaledValue: 30000000,
      label: '30M'
    }
  ]
}

export const themaxPageAveFilterOptions = {
  min: 0,
  max: 1000000,
  marks: [
    {
      value: 0,
      scaledValue: 0,
      label: '0'
    },
    {
      value: 25,
      scaledValue: 10000,
      label: '10k'
    },
    {
      value: 50,
      scaledValue: 50000,
      label: '50k'
    },
    {
      value: 75,
      scaledValue: 500000,
      label: '500k'
    },
    {
      value: 100,
      scaledValue: 1000000,
      label: '1M'
    }
  ]
}
