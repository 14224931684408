import { connect } from 'react-redux'

import Campaigns from 'components/content_desk/campaigns'

import {
  getCDeskCampaigns,
  getCDeskContentSearchQueries,
  getCDeskCampaignSearchQueries,
  getCDeskSearchCampaignsRunning
} from 'selectors'

const mapStateToProps = state => ({
  campaigns: getCDeskCampaigns(state).slice(0, 3),
  hasSearchQueries: (getCDeskCampaignSearchQueries(state).size > 0 || getCDeskContentSearchQueries(state).size > 0),
  loading: getCDeskSearchCampaignsRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(Campaigns)
