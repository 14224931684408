import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import ThemedAppBar from 'containers/AppBar'
import SearchBar from 'containers/content_desk/SearchBar'
import QueryTypeDropdown from 'containers/content_desk/QueryTypeDropdown'

import Actions from 'containers/content_desk/Actions'
import TransitionComponent from 'components/transition_component'

const useStyles = makeStyles()(theme => ({
  searchBar: {
    transition: 'width 150ms',
    width: '25%',
    [theme.breakpoints.only('md')]: {
      marginLeft: '1em',
      width: '25%'
    },
    [theme.breakpoints.down('md')]: {
      width: '45%',
      marginLeft: '1em'
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1em'
    }
  }
}))

const AppBar = ({ isDomainVerified }) => {
  const { classes } = useStyles()
  const [barStyle, setBarStyle] = useState(null)
  const i18n = useI18n()
  const device = useDevice()

  const handleFocus = () => {
    let width = '100%'

    if (device.get('gt-md')) {
      width = '50%'
    }

    if (device.get('md')) {
      width = '40%'
    }

    setBarStyle({ width })
  }

  const handleBlur = () => {
    setTimeout(() => setBarStyle(null), 500)
  }

  const title = device.get('gt-md') ? <span>{i18n.get('content_management')}<sup>beta</sup></span> : undefined

  return (
    <div>
      <TransitionComponent type="slideFadeDown">
        <ThemedAppBar
          title={title}
          actions={isDomainVerified ? <Actions /> : null}
        >
          {isDomainVerified && (
            <div
              style={barStyle}
              className={classes.searchBar}
            >
              <SearchBar
                onFocus={handleFocus}
                onBlur={handleBlur}
                queryTypeDropdown={<QueryTypeDropdown />}
              />
            </div>
          )}
        </ThemedAppBar>
      </TransitionComponent>
    </div>
  )
}

AppBar.propTypes = {
  isDomainVerified: PropTypes.bool.isRequired
}

export default AppBar
