/* global document */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { v4 } from 'uuid'
import { makeStyles } from 'tss-react/mui'
import { red } from '@mui/material/colors'

const useStyles = makeStyles()({
  values: {
    marginTop: 20,
    display: 'flex'
  },
  nav: {
    width: 50
  },
  navElement: {
    cursor: 'pointer',
    opacity: 0.5,
    '&:hover': {
      opacity: 1
    }
  },
  navGroups: {
    maxHeight: 400,
    width: '100%',
    overflowY: 'auto'
  },
  navGroup: {
    marginBottom: 10
  },
  navSubElement: {
    display: 'flex',
    '& > span:first-of-type': {
      minWidth: 300
    },
    '& > span:last-of-type': {
      cursor: 'pointer',
      color: red[500],
      opacity: 0.7,

      '&:hover': {
        opacity: 1
      }
    }
  }
})

const areEqual = (prevProps, nextProps) => {
  if (!nextProps.groups.equals(prevProps.groups)) {
    return false
  }

  if (!nextProps.alpha.equals(prevProps.alpha)) {
    return false
  }

  return true
}

const AlphaNavigation = ({ alpha, groups, onDelete }) => {
  const { classes } = useStyles()
  const id = v4()
  const anchor = a => `${a}_${id}`

  return (
    <div className={classes.values}>
      <div className={classes.nav}>
        {alpha.map(a => (
          <div
            key={a}
            className={classes.navElement}
            onClick={() => document.getElementById(anchor(a)).scrollIntoView()}
          >
            {a}
          </div>
        ))}
      </div>

      <div className={classes.navGroups}>
        {groups.keySeq().sortBy(key => key.toLowerCase()).map(key => (
          <div
            className={classes.navGroup}
            key={key}
            id={anchor(key)}
          >
            <b>{key}</b>
            <br />
            {groups.get(key).map(a => (
              <div
                className={classes.navSubElement}
                key={a}
              >
                <span>{a}</span>
                <span onClick={() => onDelete(a)}>X</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

AlphaNavigation.propTypes = {
  groups: PropTypes.instanceOf(Map).isRequired,
  alpha: PropTypes.instanceOf(List).isRequired,

  onDelete: PropTypes.func.isRequired
}

export default memo(AlphaNavigation, areEqual)
