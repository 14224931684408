import React, { useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'
import {
  Grid,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Breadcrumbs,
  Link,
  Slide,
  CircularProgress
} from '@mui/material'
import { green, grey, red, blue, orange } from '@mui/material/colors'

import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import EditIcon from '@mui/icons-material/Edit'
import PeopleIcon from '@mui/icons-material/People'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

import StatisticsData from 'containers/content_desk/StatisticsData'
import ContentPreview from 'containers/content_desk/ContentPreview'
import RecipientsTable from 'containers/content_desk/RecipientsTable'

const useStyles = makeStyles()(theme => ({
  approveSwitch: {
    float: 'right',
    display: 'flex',
    marginRight: '2em',
    color: !theme.isDark && '#404040'
  },
  dialog: {
    margin: '1.5em 1.5em 1.5em 1.5em'
  },
  typeLabel: {
    fontStyle: 'italic',
    fontSize: '10pt',
    color: !theme.isDark && '#757575'
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '1em'
  },
  title: {
    fontSize: '14pt',
    color: !theme.isDark && '#404040',
    overflowWrap: 'break-word'
  },
  closeIcon: {
    position: 'absolute',
    right: 15,
    top: 15
  },
  editButton: {
    float: 'right',
    display: 'flex'
  },
  columnLabel: {
    fontStyle: 'italic',
    fontSize: '10pt',
    color: !theme.isDark && '#757575'
  },
  chip: {
    width: '160px',
    padding: '0px 3px 0px 3px'
  },
  statusIconWrapper: {
    display: 'flex',
    marginBottom: '5px'
  },
  statusIcon: {
    marginRight: '0.3em'
  },
  status: {
    marginLeft: '0.2em'
  },
  divider: {
    margin: '1.5em 0em 1em 0em'
  },
  approvalStatusIcon: {
    display: 'flex'
  },
  dialogTitle: {
    marginTop: 5,
    paddingLeft: 45
  },
  breadcrumbLink: {
    fontSize: 23,
    color: !theme.isDark ? '#404040' : '#ffffff'
  },
  deleted: {
    color: red[500]
  },
  recipientsChipParagraph: {
    display: 'flex',
    alignItems: 'center',
    gap: 5
  }
}))

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
))
Transition.displayName = 'Transition'

const renderStatusIcon = status => {
  switch (status) {
    case 'failed':
      return <CancelIcon style={{ color: red[500] }} />
    case 'released':
      return <CheckCircleIcon style={{ color: green[500] }} />
    case 'scheduled':
      return <AccessTimeIcon style={{ color: blue[500] }} />
    case 'draft':
      return <PendingOutlinedIcon style={{ color: grey[500] }} />
    case 'review_pending':
      return <AlarmOnIcon style={{ color: blue[500] }} />
    case 'revisions_needed':
      return <ChangeCircleIcon style={{ color: orange[500] }} />
    case 'approved':
      return <CheckCircleIcon style={{ color: green[500] }} />
    default:
      return <HelpOutlineOutlinedIcon />
  }
}

const ContentView = ({ open, content, requestRunning, onEditClick, statisticsData, resetEvents, onClose }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [recipientsView, setRecipientsView] = useState(false)

  if (!content || content.isEmpty() || !content.get('id')) {
    return null
  }

  const lastEditedDate = moment(content.get('updatedAt')).format('lll')
  const status = content.get('status') || content.get('approvalStatus')

  const handleEdit = () => {
    onEditClick({ id: content.get('id'), openEditContentDialog: true })
    onClose()
  }

  const combinedRecipientData = content
    .get('recipients')
    .map(recipient => {
      const match = statisticsData.get('events').find(event => event.get('email').toLowerCase()
        .includes(recipient.get('mail').toLowerCase()))

      let events

      if (match) {
        events = match.get('events').toJS()
      } else {
        events = []
      }

      return ({
        firstName: recipient.get('firstName'),
        lastName: recipient.get('lastName'),
        email: recipient.get('mail'),
        events
      })
    })

  const handleClose = () => {
    setTimeout(() => {
      resetEvents()
      setRecipientsView(false)
    }, 400)

    onClose()
  }

  const renderCampaignName = campaign => {
    switch (campaign) {
      case 'No Campaign':
        return i18n.get('no_campaign')
      default:
        return `${i18n.get('campaign')}: ${campaign}`
    }
  }

  const renderRecipients = () => {
    if (content.get('type') !== 'email') {
      return null
    }

    return (
      <>
        <Chip
          icon={<PeopleIcon />}
          label={(
            <p className={classes.recipientsChipParagraph}>
              {i18n.get('recipients')}
              {requestRunning ? <CircularProgress size={15} /> : `(${content.get('recipients').size})`}
            </p>
          )}
          variant="outlined"
          className={classes.chip}
          onClick={() => (content.get('recipients').size !== 0 ? setRecipientsView(true) : null)}
          disabled={requestRunning}
        />
      </>
    )
  }

  const renderMainView = () => (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          md={4}
        >
          <div className={classes.typeLabel}>
            {i18n.get('title')}
          </div>
          <div className={classes.title}>{content.get('title')}</div>
          <div className={classes.columnLabel}>
            {renderCampaignName(content.getIn(['ccdCampaign', 'name']))}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.title}>
            {`${content.getIn(['lastEditor', 'firstName'])} ${content.getIn(['lastEditor', 'lastName'])}`}
          </div>
          <div className={classes.columnLabel}>
            {i18n.get('last_edited_by')}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.title}>{lastEditedDate}</div>
          <div className={classes.columnLabel}>
            {i18n.get('last_edited')}
          </div>
        </Grid>
        <Grid item>
          {renderRecipients()}
        </Grid>
        <Grid item>
          <div className={classes.statusIconWrapper}>
            {renderStatusIcon(status)}
            <div className={classes.status}>{i18n.get(status)}</div>
            {content.get('deletedOnSocial') && (
              <div>&nbsp;|&nbsp;<span className={classes.deleted}>{i18n.get('deleted')}</span></div>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {status === 'released' && (
        <StatisticsData
          data={statisticsData}
          type={content.get('type')}
          requestRunning={requestRunning}
        />
      )}
      {status !== 'released' && (
        <>
          <Button
            variant="contained"
            color="inherit"
            className={classes.editButton}
            endIcon={<EditIcon />}
            onClick={handleEdit}
          >
            {i18n.get('edit')}
          </Button>
        </>
      )}
      <Typography
        className={classes.headline}
        variant="h6"
      >
        {i18n.get('content')}
      </Typography>
      <ContentPreview />
    </>
  )

  const renderBreadcrumbs = () => (
    <Breadcrumbs>
      <Link
        underline={recipientsView ? 'hover' : 'none'}
        className={classes.breadcrumbLink}
        style={{ cursor: recipientsView && 'pointer' }}
        onClick={() => setRecipientsView(false)}
      >
        {i18n.get('details')}
      </Link>
      {recipientsView && (
        <Link
          underline="none"
          className={classes.breadcrumbLink}
        >
          {i18n.get('recipients')}
        </Link>
      )}
    </Breadcrumbs>
  )

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '95%',
            maxHeight: '95%'
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {renderBreadcrumbs()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {!recipientsView
            ? renderMainView()
            : <RecipientsTable data={combinedRecipientData} />}
        </DialogContent>
      </Dialog>
    </div>
  )
}

ContentView.propTypes = {
  content: PropTypes.object.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  statisticsData: PropTypes.instanceOf(Map).isRequired,
  resetEvents: PropTypes.func.isRequired
}

export default ContentView
