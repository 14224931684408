import { put, call, select, takeEvery, all, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import TagManager from 'react-gtm-module'

import * as Actions from 'actions/user'
import {
  showAppMessage,
  genericErrorMessage,
  genericSuccessMessage,
  setLoginMessage,
  loginRequestSuccess,
  exception
} from 'actions/app'
import { navigate, backToLogin } from 'actions/navigation'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
// import { setOption } from 'actions/ga'
import { saveCookie } from 'utils/cookieHelper'
import { determineMomentLocale } from 'utils/locale'
import * as ConfigActions from 'actions/config'
import { setUser as setSentryUser } from 'utils/sentry'
import { CookieNames } from 'static/constants'

export function* registerUser({ payload }) {
  try {
    const baseUrl = yield select(Selectors.getBaseUrl)
    const data = { ...payload, base_url: baseUrl }

    const result = yield call(Api.registerUser, data)
    yield put(Actions.registerUserRequestSuccess(result))
  } catch (error) {
    yield put(Actions.registerUserRequestError(error))

    yield put(exception(error))
  }
}

export function* updateUser({ payload }) {
  const { oldPassword,
    newPassword,
    languageId,
    firstName,
    lastName,
    uploadedPhoto,
    darkMode,
    preferSummaries,
    globalClusterDefault,
    disableNotificationAlerts,
    preferMedia } = payload

  const i18n = yield select(Selectors.getI18n)

  try {
    const user = yield select(Selectors.getUser)

    let photo = null

    if (uploadedPhoto) {
      const formData = [
        {
          key: 'file',
          value: uploadedPhoto
        }
      ]

      const { url } = yield call(Api.uploadFile, formData)
      photo = url
    }

    const body = {
      old_password: oldPassword,
      new_password: newPassword,
      language_id: languageId,
      first_name: firstName,
      last_name: lastName,
      dark_mode: darkMode,
      prefer_summaries: preferSummaries,
      global_cluster_default: globalClusterDefault,
      disable_notification_alerts: disableNotificationAlerts,
      prefer_media: preferMedia,
      photo
    }
    yield call(Api.updateUser, body, user.get('id'))

    yield put(Actions.updateUserRequestSuccess())

    if (languageId || darkMode !== undefined) {
      yield put(genericSuccessMessage())
    } else {
      yield put(showAppMessage({ text: i18n.get('password_change_success') }))
    }

    if (languageId) {
      yield put(ConfigActions.configRequestStart())
    }
  } catch (error) {
    yield put(Actions.updateUserRequestError(error))

    if (error.response.statusCode === 401) {
      yield put(showAppMessage({ text: i18n.get('password_old_wrong') }))
    } else {
      yield put(genericErrorMessage())
    }

    yield put(exception(error))
  }
}

export function* forgotPassword({ payload: { email } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const search = yield select(Selectors.getSearchFromUrl)
    const baseUrl = yield select(Selectors.getBaseUrl)
    const from = yield select(Selectors.getWhitelabelEmailAddress)
    const logo = yield select(Selectors.getEmailLogo)
    const colors = yield select(Selectors.getWhitelabelColors)
    const body = {
      email,
      search,
      base_url: baseUrl,
      from,
      logo,
      color: colors.get('primary')
    }
    yield call(Api.forgotPassword, body)

    yield put(Actions.forgotPasswordRequestSuccess())
    yield put(setLoginMessage({
      loginMsg: i18n.get('success_forgot_password'),
      loginError: false
    }))
  } catch (error) {
    yield put(Actions.forgotPasswordRequestError(error))

    if (error.response && error.response.statusCode === 404) {
      yield put(setLoginMessage({
        loginMsg: i18n.get('error_forgot_password_user_not_found'),
        loginError: true
      }))
    } else {
      yield put(setLoginMessage({
        loginMsg: i18n.get('error_forgot_password'),
        loginError: true
      }))
      yield put(exception(error))
    }
  }

  yield put(backToLogin())
}

export function* resetPassword({ payload: { accessToken, newPassword } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const search = yield select(Selectors.getSearchFromUrl)
    const baseUrl = yield select(Selectors.getBaseUrl)
    const from = yield select(Selectors.getWhitelabelEmailAddress)
    const logo = yield select(Selectors.getEmailLogo)
    const colors = yield select(Selectors.getWhitelabelColors)
    const body = {
      access_token: accessToken,
      new_password: newPassword,
      search,
      base_url: baseUrl,
      from,
      logo,
      color: colors.get('primary')
    }
    const { user } = yield call(Api.resetPassword, body)

    yield put(Actions.resetPasswordRequestSuccess())
    yield put(loginRequestSuccess(user))

    const queryParams = yield select(Selectors.getQueryParamsFromUrl)

    if (queryParams.redirect) {
      yield put(navigate(queryParams.redirect))
    }

    yield delay(5000)
    yield put(showAppMessage({ text: i18n.get('success_reset_password') }))
  } catch (error) {
    yield put(Actions.resetPasswordRequestError(error))
    yield put(setLoginMessage({
      loginMsg: i18n.get('error_reset_password'),
      loginError: true
    }))

    yield put(exception(error))
  }

  yield put(backToLogin())
}

export function* setUser({ payload: user }) {
  yield call(saveCookie, CookieNames.SESSION, user.sid)
  yield call(saveCookie, CookieNames.ACCESS_TOKEN, user.accessToken)
  // yield put(setOption({ key: 'userId', value: user.id }))
  // yield put(setOption({ key: 'dimension1', value: user.id }))
  // yield put(setOption({ key: 'dimension2', value: user.company }))
  yield call(setSentryUser, {
    email: user.email,
    id: user.id
  })

  const locale = yield select(Selectors.getLocale)
  const timezone = yield select(Selectors.getTimezone)
  const momentLocale = determineMomentLocale(user.languageShort, locale, timezone)

  yield call(moment.locale, momentLocale)
}

export function* activateUser({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  try {
    yield call(Api.activateUser, { aid: payload.aid })
    yield put(setLoginMessage({
      loginMsg: i18n.get('success_user_activation'),
      loginError: false
    }))
  } catch (error) {
    if (error.response.statusCode === 404) {
      yield put(setLoginMessage({
        loginMsg: '',
        loginError: false
      }))
    } else {
      yield put(Actions.activateUserError(error))
      yield put(setLoginMessage({
        loginMsg: i18n.get('error_user_activation'),
        loginError: true
      }))
    }
  }

  if (payload.module === 'subscriptions') {
    yield put(navigate('/app/subscriptions'))
  } else {
    yield put(backToLogin())
  }
}

export function* setCookieOption({ payload: { cookieOption } }) {
  yield call(saveCookie, CookieNames.COOKIES_ACCEPTED, cookieOption, moment().add(1, 'years').toDate(), true)

  const sid = yield select(Selectors.getSid)
  const accessToken = yield select(Selectors.getAccessToken)
  const newsradarId = yield select(Selectors.getNewsradarId)

  if (sid) {
    yield call(saveCookie, CookieNames.SESSION, sid)
  }

  if (accessToken) {
    yield call(saveCookie, CookieNames.ACCESS_TOKEN, accessToken)
  }

  if (newsradarId) {
    yield call(saveCookie, CookieNames.NEWSRADAR, newsradarId)
  }

  if (process.env.NODE_ENV === 'production') {
    const isInfoboard = yield select(Selectors.isInfoboard)

    if (isInfoboard && (cookieOption === 'accept_all' || cookieOption === 'true')) {
      TagManager.initialize({ gtmId: 'GTM-KB3QG9R' })
    }
  }
}

export function* watchRegisterUser() {
  yield takeEvery(Actions.registerUserRequestStart, registerUser)
}

export function* watchUpdateUser() {
  yield takeEvery(Actions.updateUserRequestStart, updateUser)
}

export function* watchForgotPassword() {
  yield takeEvery(Actions.forgotPasswordRequestStart, forgotPassword)
}

export function* watchResetPassword() {
  yield takeEvery(Actions.resetPasswordRequestStart, resetPassword)
}

export function* watchSetUser() {
  yield takeEvery(Actions.setUser, setUser)
}

export function* watchActivateUser() {
  yield takeEvery(Actions.activateUserStart, activateUser)
}

export function* watchSetCookieOption() {
  yield takeEvery(Actions.setCookieOption, setCookieOption)
}

export default function* userSaga() {
  yield all([
    watchRegisterUser(),
    watchUpdateUser(),
    watchForgotPassword(),
    watchResetPassword(),
    watchSetUser(),
    watchActivateUser(),
    watchSetCookieOption()
  ])
}
