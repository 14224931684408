import { connect } from 'react-redux'
import moment from 'moment-timezone'

import ContentsEditBlockDialog from 'components/content_desk/contents/contents_edit_block_dialog'

import { getCDeskShowBlockedContentEditDialog, getCDeskSelectedContent } from 'selectors'

import { closeBlockedContentEditDialog as onClose } from 'actions/content_desk'

const getLocker = state => getCDeskSelectedContent(state).get('lastLocker')
const getLockDate = state => {
  const lockedAt = getCDeskSelectedContent(state).get('lockedAt')

  if (!lockedAt) {
    return null
  }

  return moment(lockedAt).format('LLL')
}

const mapStateToProps = state => ({
  lockedBy: getLocker(state),
  lockDate: getLockDate(state),
  open: getCDeskShowBlockedContentEditDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onClose
  }
)(ContentsEditBlockDialog)
