import { createImmutableSelector } from 'utils/reselect'
import { List } from 'immutable'

// CAMPAIGN_FORM

// DATA
export const getCDeskData = state => state.contentDesk.get('data')
export const getCDeskCampaigns = state => getCDeskData(state).get('ccdCampaigns')
export const getCDeskContents = state => getCDeskData(state).get('ccdContents')
export const getCDeskAssignees = state => getCDeskData(state).get('ccdAssignees')
export const getCDeskContentFormData = state => getCDeskData(state).get('contentFormData')
export const getCDeskContentFormDataRecipients = state => getCDeskData(state).get('contentFormData').get('recipients')
export const getCDeskCampaignFormData = state => getCDeskData(state).get('campaignFormData')
export const getCDeskCampaignDropdownOptions = state => getCDeskData(state).get('campaignDropdownOptions')
export const getCDeskContactDropdownOptions = state => getCDeskData(state).get('contactDropdownOptions')
export const getCDeskDistributionListDropdownOptions = state => getCDeskData(state).get('distributionListDropdownOptions')
export const getCDeskStatistics = state => getCDeskData(state).get('ccdStatistics')
export const getCDeskSelectedContent = state => getCDeskData(state).get('selectedContent')
export const getCDeskHasContents = state => !getCDeskContents(state).get('items').isEmpty()
export const getCDeskHasCampaigns = state => !getCDeskCampaigns(state).get('items').isEmpty()
export const getCDeskCalendarEvents = state => getCDeskData(state).get('calendarEvents')
export const getCDeskCalendarDate = state => getCDeskData(state).get('calendarDate')
export const getCDeskUploadedMediaFiles = state => getCDeskData(state).get('uploadedMediaFiles')
export const getCDeskUploadedMediaFilesTotal = state => getCDeskData(state).get('uploadedMediaFilesTotal')
export const getCDeskAiSelectedText = state => getCDeskData(state).get('aiSelectedText')
export const getCDeskAiRequestResults = state => getCDeskData(state).get('aiRequestResults')
export const getCDeskUploadedMediaFilesTotalSize = state => getCDeskData(state).get('uploadedMediaFilesTotalSize')

// UI
export const getCDeskUi = state => state.contentDesk.get('ui')

export const getCDeskRequestRunning = state => getCDeskUi(state).get('requestRunning')
export const getCDeskShowNewContentDialog = state => getCDeskUi(state).get('showNewContentDialog')
export const getCDeskShowEditContentDialog = state => getCDeskUi(state).get('showEditContentDialog')
export const getCDeskShowViewContentDialog = state => getCDeskUi(state).get('showViewContentDialog')
export const getCDeskShowDeleteDialog = state => getCDeskUi(state).get('showDeleteDialog')
export const getCDeskShowBlockedContentEditDialog = state => getCDeskUi(state).get('showBlockedContentEditDialog')
export const getCDeskDeleteData = state => getCDeskUi(state).get('deleteData')
export const getCDeskShowNewCampaignDialog = state => getCDeskUi(state).get('showNewCampaignDialog')
export const getCDeskCampaignDialogEditMode = state => getCDeskUi(state).get('campaignDialogEditMode')
export const getCDeskShowCalendarDialog = state => getCDeskUi(state).get('showCalendarDialog')
export const getCDeskNewDialogView = state => getCDeskUi(state).get('newDialogView')
export const getCDeskEmailEditorLoadingStatus = state => getCDeskUi(state).get('emailEditorLoading')
export const getCDeskContactDropdownOptionsLoading = state => getCDeskUi(state).get('contactDropdownOptionsLoading')
export const getCDeskCampaignDropdownOptionsLoading = state => getCDeskUi(state).get('campaignDropdownOptionsLoading')
export const getCDeskDistributionListDropdownOptionsLoading = state => getCDeskUi(state).get('distributionListDropdownOptionsLoading')
export const getCDeskContentIsLoading = state => getCDeskUi(state).get('isContentLoading')
export const getCDeskContentDialogEditMode = state => getCDeskUi(state).get('contentDialogEditMode')
export const getCDeskContentDialogCloneMode = state => getCDeskUi(state).get('contentDialogCloneMode')
export const getCDeskSelectedQueryType = state => getCDeskUi(state).get('selectedQueryType')
export const getCDesksearchBarLabel = state => getCDeskUi(state).get('searchBarLabel')
export const getCDeskSearchContentsRunning = state => getCDeskUi(state).get('searchContentsRunning')
export const getCDeskSearchCampaignsRunning = state => getCDeskUi(state).get('searchCampaignsRunning')
export const getCDeskContentUploadedImageUrl = state => getCDeskUi(state).get('uploadedImageUrl')
export const getActiveCDeskFiltersCollapsed = state => getCDeskUi(state).get('activeFiltersCollapsed')
export const getCDeskStatisticsRequestRunning = state => getCDeskUi(state).get('statisticsLoading')
export const getCDeskDuplicateError = state => getCDeskUi(state).get('duplicateError')
export const getCDeskShowScheduleDialog = state => getCDeskUi(state).get('showScheduleDialog')
export const getCDeskShowPlanDialog = state => getCDeskUi(state).get('showPlanDialog')
export const getCDeskMediaFileUploading = state => getCDeskUi(state).get('mediaFileUploading')
export const getCDeskMediaFilesInitialFetch = state => getCDeskUi(state).get('mediaFilesInitialFetch')
export const getCDeskMediaFilesLoading = state => getCDeskUi(state).get('mediaFilesLoading')
export const getCDeskEditorAiMode = state => getCDeskUi(state).get('editorAiMode')
export const getCDeskEditorAiRequestRunning = state => getCDeskUi(state).get('editorAiRequestRunning')
export const getCDeskShowSaveTemplateDialog = state => getCDeskUi(state).get('showSaveTemplateDialog')
export const getSocialMediaLinkLoading = state => getCDeskUi(state).get('socialMediaLinkLoading')
export const getSocialMediaLinkBuildFailed = state => getCDeskUi(state).get('socialMediaLinkBuildFailed')
export const getCDeskShowTestMailDialog = state => getCDeskUi(state).get('showTestMailDialog')
export const getCDeskDownloadEmailEventsLoading = state => getCDeskUi(state).get('downloadEmailEventsLoading')
export const getLinkedInCompanySearchResults = state => getCDeskUi(state).get('linkedInCompanySearchResults')
export const getCDeskShowContentNotesDialog = state => getCDeskUi(state).get('showContentNotesDialog')

// FILTERS
export const getCDeskFilters = state => state.contentDesk.get('filter')
export const getActiveCDeskFilters = createImmutableSelector(
  getCDeskFilters,
  filters => filters.filter((value, key) => List.isList(value) && key !== 'contentFilters')
)
export const getCDeskContentSearchQueries = state => getCDeskFilters(state).get('contentSearchQueries')
export const getCDeskCampaignSearchQueries = state => getCDeskFilters(state).get('campaignSearchQueries')
export const getCDeskCampaignPage = state => getCDeskFilters(state).get('campaignPage')
export const getCDeskCampaignLimit = state => getCDeskFilters(state).get('campaignLimit')
export const getCDeskCampaignSortBy = state => getCDeskFilters(state).get('campaignSortBy')
export const getCDeskCampaignSortOrder = state => getCDeskFilters(state).get('campaignSortOrder')
export const getCDeskContentPage = state => getCDeskFilters(state).get('contentPage')
export const getCDeskContentLimit = state => getCDeskFilters(state).get('contentLimit')
export const getCDeskContentSortBy = state => getCDeskFilters(state).get('contentSortBy')
export const getCDeskContentSortOrder = state => getCDeskFilters(state).get('contentSortOrder')
export const getCDeskMediaFilesPage = state => getCDeskFilters(state).get('mediaFilesPage')
export const getCDeskMediaFilesLimit = state => getCDeskFilters(state).get('mediaFilesLimit')
export const getCDeskContentFilters = state => getCDeskFilters(state).get('contentFilters')
