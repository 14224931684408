/* global window */
import React, { useEffect } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { Alert, Typography } from '@mui/material'

import ContentsItem from 'containers/content_desk/ContentsItem'
import AppBar from 'containers/content_desk/AppBar'
import NewButton from 'containers/content_desk/NewButton'
import ActiveFilterList from 'containers/content_desk/ActiveFilterList'
import { Button } from 'containers/themed'

import ContentNewDialog from 'containers/content_desk/ContentNewDialog'
import ContentEditDialog from 'containers/content_desk/ContentEditDialog'
import ContentNotesDialog from 'containers/content_desk/ContentNotesDialog'
import CalendarDialog from 'containers/content_desk/CalendarDialog'
import CampaignForm from 'containers/content_desk/CampaignForm'
import ContentsFilter from 'containers/content_desk/ContentsFilter'
import ContentsSortBar from 'containers/content_desk/ContentsSortBar'
import DeleteDialog from 'containers/content_desk/DeleteDialog'
import ContentsEditBlockDialog from 'containers/content_desk/ContentsEditBlockDialog'
import ContentView from 'containers/content_desk/ContentView'

import { StaggeredListItem } from 'components/staggered_list'

import { ContentDeskSearchFields } from 'static/constants'

const useStyles = makeStyles()(theme => ({
  title: {
    position: 'relative',
    color: !theme.isDark && '#404040'
  },
  total: {
    marginBottom: '1em'
  },
  noHits: {
    marginBottom: '1em'
  },
  wrapper: {
    padding: '2em'
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loadMoreButton: {
    float: 'right',
    marginBottom: '1em'
  }
}))

function ContentsShowAll({
  ccdContents,
  page,
  loading,
  hasSearchQueries,
  setPage,
  setSelectedQueryType,
  setSortBy,
  setContentFilters,
  searchContentsStart,
  onLoad,
  onUnload
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const contents = ccdContents.get('items')

  let remaining = ccdContents.get('total') - contents.size
  remaining = remaining > 0 ? remaining : 0

  useEffect(() => {
    window.scrollTo(0, 0)
    setSelectedQueryType(ContentDeskSearchFields.CONTENT_TITLE)
    searchContentsStart()
  }, [searchContentsStart])

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [contents])

  useEffect(() => () => {
    setContentFilters({ operator: 'and', conditions: [] })
    setSortBy({ type: 'contents', sortBy: '', sortOrder: '' })
    searchContentsStart()
  }, [])

  const onLoadMoreClick = () => {
    setPage({ key: 'contentPage', value: page + 1 })
    searchContentsStart()
  }

  const renderContentItems = () => {
    if (ccdContents.get('items').size) {
      return ccdContents.get('items').map((el, index) => (
        <StaggeredListItem
          delay={index * 50}
          key={index}
        >
          <ContentsItem
            data={el}
          />
        </StaggeredListItem>
      ))
    }

    if (hasSearchQueries && !loading) {
      return (
        <Alert
          severity="warning"
          className={classes.noHits}
        >
          {i18n.get('no_hits_title')}
        </Alert>
      )
    }

    return null
  }

  return (
    <div className={classes.wrapper}>
      <AppBar />
      <ActiveFilterList />
      <div className={classes.menuWrapper}>
        <NewButton />
      </div>
      <Typography
        variant="h5"
        className={classes.title}
      >
        {i18n.get('contents')}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.total}
      >
        {i18n.get('total')}: {ccdContents.get('total')}
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ContentsSortBar />
        <ContentsFilter />
      </div>
      {renderContentItems()}
      <Button
        className={classes.loadMoreButton}
        variant="contained"
        color="primary"
        onClick={onLoadMoreClick}
        loading={loading}
        disabled={remaining === 0}
      >
        {i18n.get('load_more_remaining', { remaining })}
      </Button>
      <ContentNewDialog />
      <ContentEditDialog />
      <ContentNotesDialog />
      <CalendarDialog />
      <CampaignForm />
      <DeleteDialog entity="content" />
      <ContentsEditBlockDialog />
      <ContentView />
    </div>
  )
}

ContentsShowAll.propTypes = {
  ccdContents: PropTypes.instanceOf(Map).isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  hasSearchQueries: PropTypes.bool.isRequired,

  setPage: PropTypes.func.isRequired,
  setSelectedQueryType: PropTypes.func.isRequired,
  setSortBy: PropTypes.func.isRequired,
  setContentFilters: PropTypes.func.isRequired,
  searchContentsStart: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired
}

export default ContentsShowAll
