import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/email_templates_library'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  filter: {},
  page: 1,
  limit: 20,
  sortBy: 'created_at',
  sortOrder: 'desc'
})

export default handleActions({
  [Actions.increaseTemplatesPage]: state => state.set('page', state.get('page') + 1),
  [combineActions(Actions.resetTemplateFilters, Actions.resetTemplatesModule)]: _state => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
