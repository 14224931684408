import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/unsplash'
import * as CDActions from 'actions/content_desk'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  page: 1,
  perPage: 20,
  orderBy: 'relevant'
})

export default handleActions({
  [Actions.resetUnsplashPhotos]: state => state.set('page', initialState.get('page')),
  [Actions.incrementPage]: state => state.set('page', state.get('page') + 1),
  [combineActions(CDActions.closeNewContentDialog, CDActions.closeEditContentDialog)]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
