import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

import { makeStyles } from 'tss-react/mui'
import { Grid, LinearProgress, Box } from '@mui/material'

import EditorialPlansItem from 'containers/themax/editorial_plans/editorial_plans_item/EditorialPlansItem'

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: '35px',
    padding: '0px 20px 20px 20px',
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    borderRadius: '5px'
  },
  editorialPlansItemWrapper: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 1.5em'
    }
  }
}))

const renderEditorialPlan = (editorialPlan, index) => (
  <Grid
    key={index}
    item
    xs={12}
    sm={6}
    md={4}
    lg={3}
    xl={3}
  >
    <EditorialPlansItem editorialPlan={editorialPlan} />
  </Grid>
)

const EditorialPlans = ({ editorialPlans,
  editorialPlansTotal,
  fetchMoreRequestRunning,
  onScrollBottom }) => {
  const { classes } = useStyles()

  const { size } = editorialPlans

  if (size === 0) {
    return null
  }

  const moreEditorialPlans = size > 1 && size < editorialPlansTotal

  const onEnterWaypoint = () => {
    if (moreEditorialPlans) {
      onScrollBottom('editorial_plans')
    }
  }

  return (
    <Box
      className={classes.container}
      style={moreEditorialPlans ? { paddingBottom: '400px' } : {}}
    >
      <Grid
        container
        spacing={2}
        className={classes.editorialPlansItemWrapper}
      >
        {editorialPlans.slice(0, -1).map((editorialPlan, index) => (
          renderEditorialPlan(editorialPlan, index)
        ))}
        <Waypoint
          key="waypoint"
          onEnter={onEnterWaypoint}
        />
        {editorialPlans.slice(-1).map((editorialPlan, index) => (
          renderEditorialPlan(editorialPlan, index)
        ))}

        {fetchMoreRequestRunning && (
          <Grid
            key="loading"
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
          >
            <LinearProgress
              variant="indeterminate"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

EditorialPlans.propTypes = {
  editorialPlans: PropTypes.object.isRequired,
  editorialPlansTotal: PropTypes.number.isRequired,
  fetchMoreRequestRunning: PropTypes.bool.isRequired,
  onScrollBottom: PropTypes.func.isRequired
}

export default EditorialPlans
