import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/email_templates_library'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  templates: {
    hasMore: false,
    items: []
  },
  templateFormData: {
    id: null,
    name: null,
    shared: false,
    released: false,
    json: null,
    html: null,
    isDirty: false
  }
})

export default handleActions({
  [Actions.setTemplateFormData]: (state, { payload: { key, value } }) => (
    state.setIn(['templateFormData', key], value).setIn(['templateFormData', 'isDirty'], true)
  ),
  [Actions.loadSelectedTemplate]: (state, { payload }) => (
    state.set('templateFormData', payload)
  ),
  [Actions.closeEditorDialog]: state => state.set('templateFormData', initialState.get('templateFormData')),
  [Actions.saveTemplateSuccess]: (state, { payload }) => {
    if (!payload) {
      return state
    }

    const { id } = payload
    const currentTemplates = state.getIn(['templates', 'items'])

    if (currentTemplates.some(template => template.get('id') === id)) {
      const index = currentTemplates.findIndex(template => template.get('id') === id)

      return state.setIn(['templates', 'items', index], fromJS(payload))
    }

    return state.updateIn(['templates', 'items'], templatesList => templatesList.unshift(fromJS(payload)))
  },
  [Actions.fetchTemplatesSuccess]: (state, { payload: { templates, hasMore } }) => state
    .updateIn(['templates', 'items'], templatesList => templatesList.push(...fromJS(templates)))
    .setIn(['templates', 'hasMore'], hasMore),
  [Actions.deleteTemplateSuccess]: (state, { payload }) => state
    .setIn(['templates', 'items'], state.getIn(['templates', 'items']).filter(template => template.get('id') !== payload)),
  [Actions.resetTemplatesModule]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
