import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Popover, IconButton } from '@mui/material'

const MuiPopover = ({
  children,
  innerRef,
  handle,
  hover,
  anchorOrigin: aO,
  transformOrigin: tO,
  sx: sxx,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  let eventHandlers = {
    onClick: event => setAnchorEl(event.currentTarget)
  }

  let anchorOrigin
  let transformOrigin
  let sx

  if (hover) {
    eventHandlers = {
      onMouseEnter: event => setAnchorEl(event.currentTarget),
      onMouseLeave: () => setAnchorEl(null)
    }
    anchorOrigin = { ...({
      vertical: 'bottom',
      horizontal: 'left'
    }),
    ...(aO || {}) }
    transformOrigin = { ...({
      vertical: 'top',
      horizontal: 'left'
    }),
    ...(tO || {}) }
    sx = { ...({
      pointerEvents: 'none'
    }),
    ...(sxx || {}) }
  }

  const newHandle = React.cloneElement(handle, eventHandlers)

  const newChildren = React.Children.map(
    children,
    child => {
      let clickHandler = () => setAnchorEl(null)

      if (child && child.props && child.props.onClick) {
        clickHandler = (...args) => {
          child.props.onClick(...args)
          setAnchorEl(null)
        }

        return React.cloneElement(child, {
          onClick: clickHandler
        })
      }

      return child
    }
  )

  return (
    <>
      {newHandle}

      <Popover
        ref={innerRef}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin || aO}
        transformOrigin={transformOrigin || tO}
        sx={sx}
        {...props}
      >
        {newChildren}
      </Popover>
    </>
  )
}

function PopoverWrapper(props, ref) {
  return (
    <MuiPopover
      {...props}
      innerRef={ref}
    />
  )
}

export default forwardRef(PopoverWrapper)

MuiPopover.defaultProps = {
  handle: (
    <IconButton size="large">
      <MoreVertIcon />
    </IconButton>
  )
}

MuiPopover.propTypes = {
  children: PropTypes.any,
  handle: PropTypes.any,
  innerRef: PropTypes.any,
  hover: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  sx: PropTypes.object
}
