import { connect } from 'react-redux'

import ContentScheduleDialog from 'components/content_desk/content_edit_dialog/content_schedule_dialog'

import {
  setContentFormData,
  closeScheduleDialog,
  saveContentStart as onSave
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskShowScheduleDialog,
  getUserLocale
} from 'selectors'

const mapStateToProps = state => ({
  data: getCDeskContentFormData(state),
  locale: getUserLocale(state),
  isOpen: getCDeskShowScheduleDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    closeScheduleDialog,
    onSave
  }
)(ContentScheduleDialog)
