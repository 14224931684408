import { stripHtml } from 'string-strip-html'

export const stripDossier = dossier => {
  const match = dossier.match(/(\s+)$/s)
  const endingWhitespaceOrNewline = match ? match[0] : ''

  let stripped = stripHtml(dossier, {
    skipHtmlDecoding: true,
    dumpLinkHrefsNearby: {
      enabled: true,
      wrapHeads: '(',
      wrapTails: ')'
    }
  }).result

  stripped += endingWhitespaceOrNewline

  return stripped
}
