import { put, takeEvery, all, call, select, take, race, delay } from 'redux-saga/effects'
import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* search() {
  // reset page number
  yield put(Actions.setPage({ key: 'campaignPage', value: 1 }))
  yield put(Actions.setPage({ key: 'contentPage', value: 1 }))
  yield put(Actions.searchCampaignsStart())
  yield put(Actions.searchContentsStart())
}

export function* searchCampaignsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const { campaigns, contents } = yield select(Selectors.getCDeskSearchBody)
    const page = yield select(Selectors.getCDeskCampaignPage)
    const limit = yield select(Selectors.getCDeskCampaignLimit)

    // reset data if we are on the first page
    if (page === 1) {
      yield put(Actions.resetCampaigns())
    }

    const body = {
      filter: campaigns.includes,
      content_filter: contents.includes,
      page,
      limit
    }

    const result = yield call(Api.searchCampaigns, body)
    yield put(Actions.setCampaigns(result))

    yield put(Actions.searchCampaignsSuccess())
  } catch (error) {
    yield put(Actions.searchCampaignsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* searchContentsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const { contents, campaigns } = yield select(Selectors.getCDeskSearchBody)
    const page = yield select(Selectors.getCDeskContentPage)
    const limit = yield select(Selectors.getCDeskContentLimit)
    const sortBy = yield select(Selectors.getCDeskContentSortBy)
    const sortOrder = yield select(Selectors.getCDeskContentSortOrder)
    const multiFilter = yield select(Selectors.getCDeskContentFilters)

    // reset data if we are on the first page
    if (page === 1) {
      yield put(Actions.resetContents())
    }

    const body = {
      filter: contents.includes,
      multi_filter: multiFilter,
      campaign_filter: campaigns.includes,
      page,
      limit,
      sort_by: sortBy,
      sort_order: sortOrder
    }

    const result = yield call(Api.searchContents, body)
    yield put(Actions.setContents(result))

    yield put(Actions.searchContentsSuccess())
  } catch (error) {
    yield put(Actions.searchContentsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* refreshContentsStatusStart() {
  const i18n = yield select(Selectors.getI18n)
  const contentsData = yield select(Selectors.getCDeskContents)
  const { items } = contentsData.toJS()
  const scheduledContents = items.filter(c => c.status === 'scheduled')

  const contentIds = scheduledContents.map(c => c.id)

  try {
    if (contentIds.length !== 0) {
      while (true) {
        const { stop } = yield race({
          stop: take(Actions.stopRefreshContentsStatus),
          refresh: delay(30000)
        })

        if (stop) {
          break
        }

        const body = {
          ids: contentIds
        }

        const { contents } = yield call(Api.fetchContents, body)
        yield put(Actions.setUpdatedContents(contents))
        yield put(Actions.refreshContentsStatusSuccess())
      }
    }
  } catch (error) {
    yield put(Actions.refreshContentsStatusError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* linkedInCompanySearchStart({ payload }) {
  try {
    const results = yield call(Api.searchLinkedInCompany, { query: payload })
    yield put(Actions.linkedInCompanySearchSuccess(results))
  } catch (error) {
    yield put(Actions.linkedInCompanySearchError(error))

    const i18n = yield select(Selectors.getI18n)

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* watchLinkedInCompnaySearchStart() {
  yield takeEvery(Actions.linkedInCompanySearchStart, linkedInCompanySearchStart)
}

export function* watchSearch() {
  yield takeEvery(Actions.search, search)
}

export function* watchSearchCampaignsStart() {
  yield takeEvery(Actions.searchCampaignsStart, searchCampaignsStart)
}

export function* watchSearchContentsStart() {
  yield takeEvery(Actions.searchContentsStart, searchContentsStart)
}

export function* watchRefreshContentsStatusStart() {
  yield takeEvery(Actions.refreshContentsStatusStart, refreshContentsStatusStart)
}

export default function* searchSaga() {
  yield all([
    watchSearch(),
    watchLinkedInCompnaySearchStart(),
    watchSearchCampaignsStart(),
    watchSearchContentsStart(),
    watchRefreshContentsStatusStart()
  ])
}
