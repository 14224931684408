import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import PoolIcon from '@mui/icons-material/Pool'

import { Capabilities } from 'static/constants'

import ContentsItem from 'containers/content_desk/ContentsItem'
import CapSafe from 'containers/CapSafe'

import { IconButton } from 'containers/themed'

import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material'

import CampaignsDelete from 'containers/content_desk/CampaignsDelete'

const SwitchToSearchPoolButton = CapSafe(IconButton, Capabilities.HAS_NEWS_POOL_MODULE)

const useStyles = makeStyles()(theme => ({
  campaignsItem: {
    marginBottom: '1em',
    padding: '1em',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent'
    }
  },
  columnLabel: {
    fontSize: '10pt',
    fontStyle: 'italic',
    color: !theme.isDark && '#757575'
  },
  contentActions: {
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'auto'
  },
  expandButton: {
    position: 'absolute',
    right: 0,
    justifyContent: 'center'
  },
  description: {
    marginBottom: '3px'
  },
  summary: {
    marginBottom: '1.5em'
  },
  spacer: {
    marginBottom: '1.5em'
  }
}))

const CampaignsItem = ({
  campaign,
  onClick,
  setEditMode,
  onSwitchToSearchPoolClick
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleEdit = e => {
    e.stopPropagation()

    setEditMode()
    onClick(campaign)
  }

  const sortContents = (a, b) => {
    const statusOrder = {
      scheduled: 1,
      released: 2,
      failed: 3,
      draft: 4
    }

    const statusA = statusOrder[a.get('status')]
    const statusB = statusOrder[b.get('status')]

    if (statusA !== statusB) {
      return statusA - statusB
    }

    switch (a.get('status')) {
      case 'scheduled':
        return new Date(b.get('scheduledFor')) - new Date(a.get('scheduledFor'))
      case 'released':
        return new Date(b.get('releasedAt')) - new Date(a.get('releasedAt'))
      case 'failed':
        return new Date(b.get('updatedAt')) - new Date(a.get('updatedAt'))
      case 'draft':
        return new Date(b.get('updatedAt')) - new Date(a.get('updatedAt'))
      default:
        return 0
    }
  }

  const sortedContents = campaign.get('ccdContents').sort(sortContents)

  return (
    <Accordion
      className={classes.campaignsItem}
    >
      <AccordionSummary
        sx={{
          pointerEvents: 'none'
        }}
        expandIcon={(
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto'
            }}
          />
        )}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid
          container
          spacing={5}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={3}
            xl={3}
          >
            {!campaign.get('defaultAssigned') && (
              <div className={classes.columnLabel}>
                {i18n.get('campaign')}
              </div>
            )}
            {campaign.get('name')}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={1}
            lg={1}
            xl={1}
          >
            <div className={classes.columnLabel}>
              {i18n.get('status')}
            </div>
            {campaign.get('defaultAssigned') ? '-' : <div>{campaign.get('active') ? i18n.get('active') : i18n.get('closed')}</div>}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
          >
            <div className={classes.columnLabel}>
              {i18n.get('start_date')}
            </div>
            {campaign.get('startDate') ? moment(campaign.get('startDate')).format('L') : '-'}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
          >
            <div className={classes.columnLabel}>
              {i18n.get('end_date')}
            </div>
            {campaign.get('endDate') ? moment(campaign.get('endDate')).format('L') : '-'}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={1}
            xl={1}
          >
            <div className={classes.columnLabel}>
              {i18n.get('contents')}
            </div>
            {campaign.get('ccdContentsCount')}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={1}
            lg={1}
            xl={1}
          >
            <div className={classes.columnLabel}>
              {i18n.get('progress')}
            </div>
            {campaign.get('defaultAssigned') ? '-' : <div>{`${campaign.get('progress')}%`}</div>}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
          >
            <div className={classes.contentActions}>
              <IconButton
                size="small"
                title={i18n.get('edit')}
                onClick={handleEdit}
                disabled={campaign.get('defaultAssigned')}
              >
                <EditIcon />
              </IconButton>
              <SwitchToSearchPoolButton
                size="small"
                title={i18n.get('switch_to_news_pool_module')}
                disabled={!campaign.get('hasReleasedContents') || campaign.get('defaultAssigned')}
                onClick={() => onSwitchToSearchPoolClick({
                  field: 'ccdCampaigns',
                  value: campaign
                })}
              >
                <PoolIcon />
              </SwitchToSearchPoolButton>
              <CampaignsDelete
                title={campaign.get('title')}
                deleteDisabled={campaign.get('defaultAssigned') || false}
                deleteData={{
                  id: campaign.get('id')
                }}
              />
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {campaign.get('summary') ? (
          <>
            <Typography className={classes.description}>
              {`${i18n.get('briefing')}:`}
            </Typography>
            <Typography className={classes.summary}>
              {campaign.get('summary')}
            </Typography>
          </>
        ) : <div className={classes.spacer} />}
        {sortedContents
          .map((el, index) => (
            <ContentsItem
              key={index}
              data={el.set('ccdCampaign', campaign)}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  )
}

CampaignsItem.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,

  onClick: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  onSwitchToSearchPoolClick: PropTypes.func.isRequired
}

export default CampaignsItem
