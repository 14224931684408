import { connect } from 'react-redux'

import ContentsItem from 'components/content_desk/contents/contents_item'

import { getStaticCdContentTypes } from 'selectors'

import {
  lockContentStart as onEditClick,
  cloneContentsFormData as onCloneClick,
  openViewContentDialog as onViewClick,
  switchToSearchPool as onSwitchToSearchPoolClick,
  saveContentFormlessStart as onStatusChange,
  saveContentStart
} from 'actions/content_desk'

const mapStateToProps = (state, { data }) => ({
  data,
  contentDeskContentTypes: getStaticCdContentTypes(state)
})

export default connect(
  mapStateToProps,
  {
    onEditClick,
    onCloneClick,
    onViewClick,
    onSwitchToSearchPoolClick,
    onStatusChange,
    saveContentStart
  }
)(ContentsItem)
