import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import InfoIcon from '@mui/icons-material/Info'
import { makeStyles } from 'tss-react/mui'
import { Popover, IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { Typography } from '@mui/material'

const useStyles = makeStyles()({
  handle: {
    opacity: 0.5,
    cursor: 'help',
    transition: 'opacity 225ms',

    '&:hover': {
      opacity: 1
    }
  },
  descriptionPopover: {
    padding: 10,
    maxWidth: 400
  }
})

export default function Description({ chart }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <Popover
      classes={{ paper: classes.descriptionPopover }}
      handle={(
        <IconButton
          classes={{ root: classes.handle }}
          title={i18n.get('description')}
          size="large"
        >
          <InfoIcon />
        </IconButton>
      )}
    >
      <Typography
        dangerouslySetInnerHTML={{
          __html: chart.get('description', '').replace(/\r?\n\r?/g, '<br />')
        }}
      />
    </Popover>
  )
}

Description.propTypes = {
  chart: PropTypes.instanceOf(Map).isRequired
}
