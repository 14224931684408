import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from '@mui/material'

import { Button, IconButton } from 'containers/themed'
import TemplatesWrapper from 'containers/email_templates_library/TemplatesWrapper'

const useStyles = makeStyles()({
  dialogTitleAndActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 0
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '25px'
  },
  dialogContent: {
    paddingBottom: 0
  }
})

const TemplatePickerDialog = ({ loadTemplate, isOpen, closePickerDialog, setTemplatesReadModeOnly, saveCcdContent }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleClose = () => {
    closePickerDialog()
    setTemplatesReadModeOnly(false)
  }

  return (
    <Dialog
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
      open={isOpen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitleAndActions}>
        {i18n.get('templates_library')}
        <div className={classes.dialogActions}>
          <Button
            sx={{ height: '30px' }}
            variant="contained"
            color="primary"
            onClick={() => saveCcdContent({ switchToEmailTemplatesLibrary: true })}
            size="small"
          >{`${i18n.get('manage_templates')}`}
          </Button>
          <IconButton
            icon="close"
            onClick={handleClose}
            size="normal"
          />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TemplatesWrapper loadTemplate={loadTemplate} />
      </DialogContent>
    </Dialog>
  )
}

TemplatePickerDialog.propTypes = {
  loadTemplate: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  closePickerDialog: PropTypes.func.isRequired,
  setTemplatesReadModeOnly: PropTypes.func.isRequired,

  saveCcdContent: PropTypes.func.isRequired
}

export default TemplatePickerDialog
