import { connect } from 'react-redux'

import ContentDesk from 'components/content_desk'
import {
  openNewContentDialog,
  openCampaignForm,
  refreshContentsStatusStart,
  stopRefreshContentsStatus
} from 'actions/content_desk'
import {
  getCDeskConfig,
  getCDeskSearchCampaignsRunning,
  getCDeskSearchContentsRunning,
  getCDeskHasCampaigns,
  getCDeskHasContents,
  getCDeskContents
} from 'selectors'

const mapStateToProps = state => ({
  isDomainVerified: getCDeskConfig(state).get('isDomainVerified') || false,
  loading: getCDeskSearchCampaignsRunning(state) || getCDeskSearchContentsRunning(state),
  hasCampaigns: getCDeskHasCampaigns(state),
  hasContents: getCDeskHasContents(state),
  contents: getCDeskContents(state).get('items')
})

export default connect(
  mapStateToProps,
  {
    openNewContentDialog,
    openCampaignForm,
    onLoad: refreshContentsStatusStart,
    onUnload: stopRefreshContentsStatus
  }
)(ContentDesk)
