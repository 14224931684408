import { createAction } from 'redux-actions'
import createLoadingAction from 'utils/createLoadingAction'

const prefix = 'NEWS/CHARTS/'

export const setChartStart = createAction(`${prefix}SET_CHART_START`)
export const setChartSuccess = createAction(`${prefix}SET_CHART_SUCCESS`)
export const setChartError = createAction(`${prefix}SET_CHART_ERROR`)
export const setChartData = createAction(`${prefix}SET_CHART_DATA`)
export const setStaticChartData = createAction(`${prefix}SET_STATIC_CHART_DATA`)
export const setChartLoading = createAction(`${prefix}SET_CHART_LOADING`)
export const calculateNewChart = createAction(`${prefix}CALCULATE_NEW_CHART`)
export const removeChart = createAction(`${prefix}REMOVE_CHART`)
export const setCharts = createAction(`${prefix}SET_CHARTS`)
export const setLayouts = createAction(`${prefix}SET_LAYOUTS`)
export const updateChartsStart = createAction(`${prefix}UPDATE_CHARTS_START`)
export const updateChartsSuccess = createAction(`${prefix}UPDATE_CHARTS_SUCCESS`)
export const updateChartsError = createAction(`${prefix}UPDATE_CHARTS_ERROR`)
export const toggleEditing = createAction(`${prefix}TOGGLE_EDITING`)
export const setNormalMode = createAction(`${prefix}SET_NORMAL_MODE`)
export const setPrevious = createAction(`${prefix}SET_PREVIOUS`)
export const setPreviousToData = createAction(`${prefix}SET_PREVIOUS_TO_DATA`)
export const downloadImage = createAction(`${prefix}DOWNLOAD_IMAGE`)

export const showChartDialog = createAction(`${prefix}SHOW_CHART DIALOG`)
export const hideChartDialog = createAction(`${prefix}HIDE_CHART DIALOG`)
export const setSelectedChart = createAction(`${prefix}SET_SELECTED_CHART`)
export const setSelectedChartOpt = createAction(`${prefix}SET_SELECTED_CHART_OPT`)
export const setSelectedChartStepType = createAction(`${prefix}SET_SELECTED_CHART_STEP_TYPE`)
export const setSelectedChartData = createAction(`${prefix}SET_SELECTED_CHART_DATA`)
export const setSelectedChartLoading = createAction(`${prefix}SET_SELECTED_CHART_LOADING`)
export const checkChartChange = createAction(`${prefix}CHECK_CHART_CHANGE`)

export const exportExcelStart = createLoadingAction(`${prefix}EXPORT_EXCEL_START`, true)
export const exportExcelSuccess = createLoadingAction(`${prefix}EXPORT_EXCEL_SUCCESS`, false)
export const exportExcelError = createLoadingAction(`${prefix}EXPORT_EXCEL_ERROR`, false)

export const exportPowerpointStart = createLoadingAction(`${prefix}EXPORT_POWERPOINT_START`, true)
export const exportPowerpointSuccess = createLoadingAction(`${prefix}EXPORT_POWERPOINT_SUCCESS`, false)
export const exportPowerpointError = createLoadingAction(`${prefix}EXPORT_POWERPOINT_ERROR`, false)

export const exportPowerpointBatchStart = createLoadingAction(`${prefix}EXPORT_POWERPOINT_BATCH_START`, true)
export const exportPowerpointBatchSuccess = createLoadingAction(`${prefix}EXPORT_POWERPOINT_BATCH_SUCCESS`, false)
export const exportPowerpointBatchError = createLoadingAction(`${prefix}EXPORT_POWERPOINT_BATCH_ERROR`, false)

export const drilldown = createAction(`${prefix}DRILLDOWN`)

export const copyFromDisplaySize = createAction(`${prefix}COPY_FROM_DISPLAY_SIZE`)

export const aggregationsRequestError = createAction(`${prefix}AGGREGATIONS_REQUEST_ERROR`)
export const aggregationsRequestStart = createAction(`${prefix}AGGREGATIONS_REQUEST_START`)
export const aggregationsRequestSuccess = createAction(`${prefix}AGGREGATIONS_REQUEST_SUCCESS`)

export const setAiChartAnalysisChart = createAction(`${prefix}AI_CHART_ANALYSIS_CHART`)
export const showAiChartAnalysisDialog = createAction(`${prefix}SHOW_AI_CHART_ANALYSIS_DIALOG`)
export const hideAiChartAnalysisDialog = createAction(`${prefix}HIDE_AI_CHART_ANALYSIS_DIALOG`)
export const aiChartAnalysisStart = createAction(`${prefix}AI_CHART_ANALYSIS_START`)
export const aiChartAnalysisSuccess = createAction(`${prefix}AI_CHART_ANALYSIS_SUCCESS`)
export const aiChartAnalysisError = createAction(`${prefix}AI_CHART_ANALYSIS_ERROR`)

export const setAggregationValue = createAction(`${prefix}SET_AGGREGATION_VALUE`)
