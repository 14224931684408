import { connect } from 'react-redux'

import { getCDeskContents } from 'selectors'
import { openCalendarDialog } from 'actions/content_desk'

import CalendarWidget from 'components/content_desk/calendar_widget'

const mapStateToProps = state => ({
  contents: getCDeskContents(state)
})

export default connect(
  mapStateToProps,
  {
    openCalendarDialog
  }
)(CalendarWidget)
