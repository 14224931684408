import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { InlineDateTimePicker, Dialog, RaisedPrimaryButton, Button } from 'containers/themed'

import { DialogActions, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import { red } from '@mui/material/colors'

const useStyles = makeStyles()({
  picker: {
    margin: '1em 0em 1em 0em',
    '& > div': {
      width: '100%'
    }
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: red[500]
  },
  warning: {
    marginLeft: '0.3em'
  }
})

const ContentScheduleDialog = ({
  data,
  locale,
  isOpen,
  onChange,
  closeScheduleDialog,
  onSave
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const notApproved = ['draft', 'review_pending', 'revisions_needed'].includes(data.get('status'))

  const [scheduledFor, setScheduledFor] = useState(data.get('scheduledFor'))

  const handleSchedule = () => {
    onChange({ key: 'scheduledFor', value: scheduledFor })
    onSave({ closeEditContentDialog: true })
    closeScheduleDialog()
  }

  const handleClose = () => {
    setScheduledFor(data.get('scheduledFor'))
    closeScheduleDialog()
  }

  const renderWarning = () => (
    <div className={classes.warningWrapper}>
      <WarningIcon
        size="small"
      />
      <Typography className={classes.warning}>{i18n.get('content_not_approved')}</Typography>
    </div>
  )

  return (
    <Dialog
      maxWidth="sm"
      title={i18n.get('select_date_and_time')}
      onClose={handleClose}
      open={isOpen}
    >
      <Typography>{i18n.get('schedule_content_info')}</Typography>
      <div className={classes.picker}>
        <InlineDateTimePicker
          ampm={locale === 'en-us'}
          label={i18n.get('scheduled_for')}
          value={scheduledFor}
          onChange={v => setScheduledFor(v)}
        />
      </div>
      {notApproved && renderWarning()}
      <DialogActions>
        <Button onClick={handleClose}>
          {i18n.get('close')}
        </Button>
        <RaisedPrimaryButton onClick={handleSchedule}>
          {i18n.get('schedule')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

ContentScheduleDialog.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  locale: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  closeScheduleDialog: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default ContentScheduleDialog
