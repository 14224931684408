import { put, select, call, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { fromJS, List, Set } from 'immutable'
import moment from 'moment-timezone'
import { CustomTagsNewsTypes } from 'static/constants'

import * as Actions from 'actions/shopping_cart'
import * as NewsActions from 'actions/news'
import * as CustomTagActions from 'actions/custom_tags'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'
import * as LocalStorage from 'utils/localStorage'
import * as AppActions from 'actions/app'
import { back } from 'actions/navigation'
import { listChunk, listUniqueById } from 'utils/immutable'
import { getCluster } from 'utils/sagas'
import { sortGroupedNews } from 'utils/sorting'
import { multiSelect } from 'utils/multiselect'

const sortLast = 'zzzzzzzzzzzzz'

export function* buildLocalStorageKey(suffix = '') {
  const newsradarId = yield select(Selectors.getNewsradarId)
  const moduleName = yield select(Selectors.getViewConfigModuleName)

  if (newsradarId && moduleName) {
    return [`shopping_cart:${moduleName}:${newsradarId}`, suffix].filter(s => s).join(':')
  }

  return null
}

export function* fetchNews({ payload: recalculate }) {
  try {
    const newsIdsList = yield select(Selectors.getShoppingCartNewsIds)
    const indexType = yield select(Selectors.getViewConfigIndexType)
    const key = yield call(buildLocalStorageKey)
    const minDate = yield call(LocalStorage.getItem, `${key}:min_date`)
    const maxDate = yield call(LocalStorage.getItem, `${key}:max_date`)

    const body = {
      news_ids: newsIdsList.toJS(),
      index_type: indexType,
      article_date_from: minDate,
      article_date_to: maxDate
    }

    const result = yield call(Api.searchByIds, body)
    const news = fromJS(result)

    yield put(NewsActions.addNews(news))
    yield put(Actions.fetchNewsSuccess(news))

    if (recalculate !== false) {
      yield put(Actions.recalculateGroupedSorting())
    }
  } catch (error) {
    yield put(Actions.fetchNewsError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  } finally {
    yield put(AppActions.setAppReady())
  }
}

export function* addAllNewsToShoppingCart() {
  try {
    let body = yield select(Selectors.getSearchBody)
    body = {
      ...body,
      from: 0,
      size: 1000,
      use_global_size: true,
      news: {
        ...body.news,
        booleans: {
          ...body.news.booleans,
          with_inner_hits: true
        }
      }
    }
    const result = yield call(Api.search, body)
    const news = fromJS(result.groups).reduce((acc, group) => acc.concat(group.get('news')), fromJS([]))
    yield put(NewsActions.addNews(news))
    const newsIds = Set(news.map(n => n.get('id'))).toJS()
    yield put(Actions.fetchClusterForShoppingCartStart(newsIds))
    yield put(Actions.recalculateGroupedSorting())
  } catch (error) {
    yield put(Actions.addAllNewsToShoppingCartError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* fetchClusterForShoppingCart({ payload: newsIds }) {
  try {
    const ids = fromJS(newsIds)
    let fetchedNews = fromJS([])
    for (let i = 0; i < ids.size; i += 1) {
      const newsId = ids.get(i)
      let news = yield select(Selectors.getNewsById, newsId)

      if (news.get('clusterSize') > 1 && news.get('clusteredNews').size === 0) {
        const clusteredNews = yield call(getCluster, news)

        news = news.set('clusteredNews', clusteredNews)
      }

      fetchedNews = fetchedNews.push(news)
    }

    yield put(NewsActions.addNews(fetchedNews))
    yield put(Actions.fetchClusterForShoppingCartSuccess(fetchedNews))
    yield put(Actions.recalculateGroupedSorting(fetchedNews))
  } catch (error) {
    yield put(Actions.fetchClusterForShoppingCartError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* pinAll({ payload: { customTag, action } }) {
  try {
    const i18n = yield select(Selectors.getI18n)
    const moduleName = yield select(Selectors.getViewConfigModuleName)
    let news = yield select(Selectors.getShoppingCartSelectedNewsObjects)

    if (news.isEmpty()) {
      news = yield select(Selectors.getShoppingCartNews)
    }

    news = news.toList()

    const newsIncludingClusters = news.reduce((acc, n) => {
      const cn = n.get('clusteredNews') || fromJS([])

      return acc.concat(cn.push(n))
    }, List())

    const chunkCount = Math.ceil(newsIncludingClusters.size / 100)
    const chunks = listChunk(newsIncludingClusters, chunkCount)

    yield put(AppActions.setAppBarMessage(i18n.get('pinning_progress', { progress: '0%' })))

    let changedCount = 0
    for (let i = 0; i < chunks.size; i += 1) {
      const chunkNews = chunks.get(i)

      let progress = `${Math.ceil(((i + 1) / chunks.size) * 100)}%`
      yield put(AppActions.setAppBarMessage(i18n.get('pinning_progress', { progress })))

      const data = chunkNews.map(n => (fromJS({
        news_id: n.get('id'),
        article_date: n.get('articleDate'),
        [`${action === 'unpin' ? '-' : '+'}custom_tag_ids`]: [customTag.get('id')]
      })))

      yield call(Api.assignCustomTagToNews, { data, news_type: CustomTagsNewsTypes[moduleName] })

      changedCount += data.size

      progress = `${Math.ceil(((i + 1) / chunks.size) * 100)}%`
      yield put(AppActions.setAppBarMessage(i18n.get('pinning_progress', { progress })))
    }

    const newTag = customTag.update('newsCount', count => count + (action === 'unpin' ? -changedCount : changedCount))

    news = news.map(n => n.update('customTags', customTags => {
      if (action === 'unpin') {
        return customTags.filter(c => c.get('id') !== newTag.get('id'))
      }

      return customTags.push(newTag)
    }))

    yield put(NewsActions.updateNews(news))
    yield put(AppActions.setAppBarMessage(null))
    yield put(Actions.pinAllSuccess({ customTag, pinnedCount: news.size }))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(AppActions.setAppBarMessage(null))
    yield put(Actions.pinAllError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* unpinAll({ payload: { customTag } }) {
  yield call(pinAll, { payload: { customTag, action: 'unpin' } })
}

export function* resetShoppingCart({ payload }) {
  const { navigateBack } = (payload || {})

  const mainNewsIds = yield select(Selectors.getShoppingCartMainNewsIds)
  yield put(Actions.removeNewsFromShoppingCart(mainNewsIds))

  if (navigateBack) {
    yield put(back())
  }
}

export function* addNewsToShoppingCart({ payload }) {
  yield put(Actions.fetchClusterForShoppingCartStart(payload))
}

export function* sortBy({ payload: { field, order } }) {
  let newCodes

  if (field === 'topic') {
    const codes = yield select(Selectors.getShoppingCartCodesRaw)
    newCodes = codes.sortBy(code => code.get('sortcode') || sortLast)

    if (order === 'desc') {
      newCodes = newCodes.reverse()
    }
  } else {
    const codes = yield select(Selectors.getShoppingCartCodes)

    const isPublicationField = ['publicationName', 'channelId'].indexOf(field) !== -1
    const isCountryField = field === 'country'
    const isKeyFiguresField = ['reach', 'interactions'].indexOf(field) !== -1

    let pathToSortingField = [field]
    let type = 'string'

    if (isPublicationField) {
      pathToSortingField = ['publication', field === 'publicationName' ? 'name' : field]
    }

    if (isCountryField) {
      pathToSortingField = ['publication', 'country', 'name']
    }

    if (isKeyFiguresField) {
      pathToSortingField = ['keyFigures', field]
      type = 'number'
    }

    newCodes = sortGroupedNews(codes, pathToSortingField, order, type)
  }

  yield put(Actions.setCodes(newCodes))
}

export function* sortByUngrouped({ payload: { field, order } }) {
  const news = yield select(Selectors.getShoppingCartNews)
  const newsClusters = yield select(Selectors.getShoppingCartNewsIds)

  const newNews = news.sortBy(n => {
    if (['publicationName', 'channelId'].indexOf(field) !== -1) {
      return (n.getIn(['publication', field === 'publicationName' ? 'name' : field]) || sortLast).toString().toLowerCase()
    }

    if (field === 'country') {
      return (n.getIn(['publication', 'country', 'name']) || sortLast).toString().toLowerCase()
    }

    if (['reach', 'interactions'].indexOf(field) !== -1) {
      return n.getIn(['keyFigures', field]) || 0
    }

    return (n.get(field) || sortLast).toLowerCase()
  }).map(n => n.get('id'))

  let result = newsClusters.sortBy(cluster => newNews.indexOf(cluster.first()))

  if (order === 'desc') {
    result = result.reverse()
  }

  yield put(Actions.setNews(result))
}

export function* recalculateGroupedSorting(action) {
  if (action && action.payload) {
    const codes = yield select(Selectors.getShoppingCartCodesRaw)
    let newCodes = codes

    action.payload.forEach(updatedNews => {
      const updatedNewsHasCode = code => updatedNews.get('codes').some(c => c.get('id') === code.get('id'))
      const codeHasUpdatedNews = code => code.get('news').includes(updatedNews.get('id'))

      let newNewCodes = newCodes.map(code => {
        if (codeHasUpdatedNews(code) && !updatedNewsHasCode(code)) {
          return code.update('news', news => news.filter(id => id !== updatedNews.get('id')))
        }

        if (!codeHasUpdatedNews(code) && updatedNewsHasCode(code)) {
          return code.update('news', news => news.push(updatedNews.get('id')))
        }

        return code
      })

      const codesToAdd = updatedNews
        .get('codes')
        .filter(code => !newNewCodes.some(c => c.get('id') === code.get('id')))
        .map(code => code.merge({
          showNews: true,
          news: fromJS([updatedNews.get('id')])
        }))

      let lastCode = newCodes.last()

      if (lastCode && lastCode.get('id') === null) {
        newNewCodes = newNewCodes
          .filter(code => code.get('id') !== null)
          .concat(codesToAdd)

        if (updatedNews.get('codes').isEmpty()) {
          lastCode = lastCode.update('news', news => news.push(updatedNews.get('id')))
        } else {
          lastCode = lastCode.update('news', news => news.filter(n => n !== updatedNews.get('id')))
        }

        newNewCodes = newNewCodes.push(lastCode)
      } else {
        newNewCodes = newNewCodes.concat(codesToAdd)

        if (updatedNews.get('codes').isEmpty()) {
          newNewCodes = newNewCodes.push(fromJS({
            id: null,
            showNews: true,
            news: [updatedNews.get('id')]
          }))
        }
      }

      newCodes = newNewCodes.filter(code => !code.get('news').isEmpty())
    })

    const isShoppingCart = yield select(Selectors.isShoppingCart)

    if (!isShoppingCart) {
      newCodes = newCodes.map(code => code.set('showNews', false))
      yield put(Actions.checkShoppingCartSize(newCodes))
    } else {
      yield put(Actions.setCodes(newCodes))
    }
  } else {
    const news = yield select(Selectors.getShoppingCartNews)

    const addNewsToCode = (n, code) => code.set('news', (
      n.filter(nn => nn.get('codes').find(c => c.get('id') === code.get('id'))).map(nn => nn.get('id'))
    ))
    const extractCodesFromNews = n => n.flatMap(nn => nn.get('codes')).sortBy(c => c.get('sortcode'))

    let codes = extractCodesFromNews(news)
    codes = listUniqueById(codes)
      .map(code => code.set('showNews', (!(news.size > 50))))
      .map(code => addNewsToCode(news, code))

    const ungroupedNews = news.filter(n => n.get('codes').isEmpty())

    if (!ungroupedNews.isEmpty()) {
      codes = codes.push(fromJS({
        id: null,
        showNews: (!(news.size > 50)),
        news: ungroupedNews.map(n => n.get('id'))
      }))
    }

    yield put(Actions.setCodes(codes))
  }
}

export function* removeNews({ payload }) {
  const newPayload = fromJS(payload)
  const newsIds = List.isList(newPayload) ? newPayload : newPayload.get('newsIds')
  const shoppingCartNewsIds = yield select(Selectors.getShoppingCartFlatNewsIds)

  yield put(NewsActions.decrementReferences(newsIds.filter(id => !shoppingCartNewsIds.includes(id))))
}

export function* saveToLocalStorage() {
  let data = yield select(Selectors.getShoppingCartData)
  const customTag = yield select(Selectors.getShoppingCartCustomTag)

  if (!customTag.get('id')) {
    data = JSON.stringify(data)
    const news = yield select(Selectors.getAllShoppingCartNews)
    const key = yield call(buildLocalStorageKey)

    const sorted = news.map(n => n.get('articleDate')).sort()
    const minDate = sorted.first()
    const maxDate = sorted.last()

    if (minDate) {
      yield call(LocalStorage.setItem, `${key}:min_date`, moment(minDate).startOf('day').format())
    }

    if (maxDate) {
      yield call(LocalStorage.setItem, `${key}:max_date`, moment(maxDate).endOf('day').format())
    }

    yield call(LocalStorage.setItem, key, data)
  }
}

export function* removeFromLocalStorage() {
  const key = yield call(buildLocalStorageKey)

  yield call(LocalStorage.removeItem, key)
}

export function* loadFromLocalStorage({ payload: setBusy }) {
  try {
    const key = yield call(buildLocalStorageKey)
    let data = yield call(LocalStorage.getItem, key)

    if (data) {
      data = fromJS(JSON.parse(data))

      if (data.get('news').size) {
        if (setBusy !== false) {
          yield put(AppActions.setAppBusy())
        }

        yield put(Actions.setCodes(data.get('codes')))
        yield put(Actions.setNews(data.get('news')))
        yield put(Actions.fetchNewsStart(false))
      } else {
        yield put(Actions.resetShoppingCart({ navigateBack: false }))
      }
    } else if (key) {
      yield put(Actions.resetShoppingCart({ navigateBack: false }))
    }
  } catch (error) {
    yield put(AppActions.exception(error))
    yield put(AppActions.setAppReady())
  }
}

export function* shiftSelectNewsForSorting({ payload: { newsIds, isReplace, keyPress } }) {
  const codeId = newsIds.first().get('codeId')
  let newsByCodeId

  if (codeId === undefined) {
    newsByCodeId = yield select(Selectors.getShoppingCartNews)
  } else {
    newsByCodeId = yield select(Selectors.getShoppingCartNewsByCodeId, codeId)
  }

  const selectedNews = yield select(Selectors.getShoppingCartSelectedNewsForSorting)

  if (selectedNews.isEmpty()) {
    yield put(Actions.selectNewsForSorting({ news: newsIds, isReplace, keyPress }))
  } else {
    const shiftSelection = yield call(multiSelect, selectedNews, newsByCodeId, newsIds)
    yield put(Actions.selectNewsForSorting({ news: shiftSelection, isReplace, keyPress }))
  }
}

export function* checkShoppingCartSize({ payload }) {
  const newsCount = payload.map(code => code.get('news')).flatten().size
  const newPayload = payload.map(code => code.set('showNews', (!(newsCount > 100)) || payload.size === 1))
  yield put(Actions.setCodes(newPayload))
}

export function* showClusterDialog() {
  yield put(Actions.fetchReorderClusteredNewsStart())
}

export function* fetchReorderClusteredNews() {
  try {
    const newsIds = yield select(Selectors.getShoppingCartClusterNewsIds)
    const indexType = yield select(Selectors.getViewConfigIndexType)
    const key = yield call(buildLocalStorageKey)
    const minDate = yield call(LocalStorage.getItem, `${key}:min_date`)
    const maxDate = yield call(LocalStorage.getItem, `${key}:max_date`)

    const body = {
      news_ids: newsIds.map(id => [id]).toJS(),
      index_type: indexType,
      article_date_from: minDate,
      article_date_to: maxDate
    }

    const result = yield call(Api.searchByIds, body)

    yield put(Actions.fetchReorderClusteredNewsSuccess(result))
  } catch (error) {
    yield put(Actions.fetchReorderClusteredNewsError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* replaceCluster({ payload: { news } }) {
  yield put(NewsActions.addNews(news))
}

const mapCodeNewsPairs = (codeId, newsIds) => newsIds.map(n => (codeId === undefined ? String(n) : `${codeId}_${n}`))

export function* selectNews({ payload: { codeId, newsIds, omitIntersection } }) {
  let codeNewsIdPairs = mapCodeNewsPairs(codeId, newsIds)

  let selectedNews = yield select(Selectors.getShoppingCartSelectedNews)
  const tabIndex = yield select(Selectors.getShoppingCartTabIndex)

  let allCodeNewsIdPairs

  if (tabIndex === 0) {
    const newsClusterIds = yield select(Selectors.getNewsClusterIdsOfAllCodesInShoppingCart)

    allCodeNewsIdPairs = newsClusterIds
      .map(pair => mapCodeNewsPairs(pair.get('codeId'), pair.get('newsIds')))
      .flatten(true)
  } else {
    const newsClusterIds = yield select(Selectors.getShoppingCartFlatNewsIds)

    allCodeNewsIdPairs = mapCodeNewsPairs(undefined, newsClusterIds)
  }

  if (selectedNews.isEmpty()) {
    codeNewsIdPairs = allCodeNewsIdPairs.filterNot(c => codeNewsIdPairs.includes(c))
  }

  yield put(Actions.setSelectedNews({ codeNewsIdPairs, omitIntersection }))

  selectedNews = yield select(Selectors.getShoppingCartSelectedNews)

  if (selectedNews.size === allCodeNewsIdPairs.size) {
    yield put(Actions.resetNewsSelection())
  }
}

export function* invertNewsSelection() {
  const selectedNews = yield select(Selectors.getShoppingCartSelectedNews)
  const tabIndex = yield select(Selectors.getShoppingCartTabIndex)

  let allCodeNewsIdPairs

  if (tabIndex === 0) {
    const newsClusterIds = yield select(Selectors.getNewsClusterIdsOfAllCodesInShoppingCart)

    allCodeNewsIdPairs = newsClusterIds
      .map(pair => mapCodeNewsPairs(pair.get('codeId'), pair.get('newsIds')))
      .flatten(true)
  } else {
    const newsClusterIds = yield select(Selectors.getShoppingCartFlatNewsIds)

    allCodeNewsIdPairs = mapCodeNewsPairs(undefined, newsClusterIds)
  }

  const codeNewsIdPairs = allCodeNewsIdPairs.filterNot(c => selectedNews.includes(c))

  yield put(Actions.resetNewsSelection())
  yield put(Actions.setSelectedNews({ codeNewsIdPairs }))
}

export function* setCustomTagId({ payload: customTagId }) {
  try {
    if (customTagId) {
      yield put(Actions.setRunning(true))
      const customTag = yield select(Selectors.getShoppingCartCustomTag)
      const indexType = yield select(Selectors.getViewConfigIndexType)
      const body = {
        news: {
          booleans: {
            global_clusters: true,
            with_inner_hits: true
          }
        },
        size: 1000,
        index_type: indexType
      }

      const result = yield call(Api.fetchNewsForCustomTag, customTag.get('id'), body)

      yield put(NewsActions.addNews(result.news))

      const newsIds = result.news.map(n => n.id)

      yield put(Actions.resetShoppingCart())
      yield put(Actions.addNewsToShoppingCart(newsIds))
      yield put(CustomTagActions.fetchNewsForCustomTagSuccess(result))
    }
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  } finally {
    yield put(Actions.setRunning(false))
  }
}

export function* aiAnalysis() {
  try {
    const locale = yield select(Selectors.getAiSettingsLocale)
    let news = yield select(Selectors.getShoppingCartNews)

    news = news.map(n => ({
      id: n.get('id'),
      snippet: n.get('snippet'),
      headline: n.get('headline'),
      publication: {
        channel: {
          name: n.getIn(['publication', 'channel', 'name'])
        },
        name: n.getIn(['publication', 'name'])
      },
      key_figures: {
        reach: n.getIn(['keyFigures', 'reach']),
        interactions: n.getIn(['keyFigures', 'interactions'])
      }
    })).toJS()

    const body = {
      locale,
      news
    }

    const result = yield call(Api.getNewsAiAnalysis, body)

    yield put(Actions.aiAnalysisSuccess(result))
  } catch (error) {
    yield put(Actions.aiAnalysisError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchFetchNews() {
  yield takeEvery(Actions.fetchNewsStart, fetchNews)
}

export function* watchPinAll() {
  yield takeEvery(Actions.pinAllStart, pinAll)
}

export function* watchUnpinAll() {
  yield takeEvery(Actions.unpinAll, unpinAll)
}

export function* watchAddAllNewsToShoppingCart() {
  yield takeEvery(Actions.addAllNewsToShoppingCartStart, addAllNewsToShoppingCart)
}

export function* watchResetShoppingCart() {
  yield takeEvery(Actions.resetShoppingCart, resetShoppingCart)
}

export function* watchAddNewsToShoppingCart() {
  yield takeEvery(Actions.addNewsToShoppingCart, addNewsToShoppingCart)
}

export function* watchSortBy() {
  yield takeEvery(Actions.sortBy, sortBy)
}

export function* watchSortByUngrouped() {
  yield takeEvery(Actions.sortByUngrouped, sortByUngrouped)
}

export function* watchRecalculateGroupedSorting() {
  yield takeEvery(Actions.recalculateGroupedSorting, recalculateGroupedSorting)
}

export function* watchRemoveNews() {
  yield takeEvery(Actions.removeNewsFromShoppingCart, removeNews)
}

export function* watchFetchClusterForShoppingCart() {
  yield takeEvery(Actions.fetchClusterForShoppingCartStart, fetchClusterForShoppingCart)
}

export function* watchSaveToLocalStorage() {
  yield takeEvery(Actions.removeNewsFromShoppingCart, saveToLocalStorage)
  yield takeEvery(Actions.moveNewsInShoppingCart, saveToLocalStorage)
  yield takeEvery(Actions.moveNewsInShoppingCartSublist, saveToLocalStorage)
  yield takeEvery(Actions.moveCodeInShoppingCart, saveToLocalStorage)
  yield takeEvery(Actions.setCodes, saveToLocalStorage)
  yield takeEvery(Actions.setNews, saveToLocalStorage)
  yield takeEvery(Actions.toggleNewsList, saveToLocalStorage)
  yield takeEvery(Actions.expandAllNewsLists, saveToLocalStorage)
  yield takeEvery(Actions.collapseAllNewsLists, saveToLocalStorage)
}

export function* watchLoadFromLocalStorage() {
  yield takeEvery(AppActions.loadFromLocalStorage, loadFromLocalStorage)
}

export function* watchShiftSelectNewsForSorting() {
  yield takeEvery(Actions.shiftSelectNewsForSorting, shiftSelectNewsForSorting)
}

export function* watchCheckShoppingCartSize() {
  yield takeEvery(Actions.checkShoppingCartSize, checkShoppingCartSize)
}

export function* watchShowClusterDialog() {
  yield takeEvery(Actions.showClusterDialog, showClusterDialog)
}

export function* watchFetchReorderClusteredNews() {
  yield takeEvery(Actions.fetchReorderClusteredNewsStart, fetchReorderClusteredNews)
}

export function* watchReplaceCluster() {
  yield takeEvery(Actions.replaceCluster, replaceCluster)
}

export function* watchSelectNews() {
  yield takeEvery(Actions.selectNews, selectNews)
}

export function* watchInvertNewsSelection() {
  yield takeEvery(Actions.invertNewsSelection, invertNewsSelection)
}

export function* watchSetCustomTagId() {
  yield takeEvery(Actions.setCustomTagId, setCustomTagId)
}

export function* watchAiAnalysis() {
  yield takeLatest(Actions.aiAnalysisStart, aiAnalysis)
}

export default function* shoppingCartSaga() {
  yield all([
    watchFetchNews(),
    watchFetchClusterForShoppingCart(),
    watchPinAll(),
    watchUnpinAll(),
    watchAddAllNewsToShoppingCart(),
    watchResetShoppingCart(),
    watchAddNewsToShoppingCart(),
    watchSortBy(),
    watchSortByUngrouped(),
    watchRecalculateGroupedSorting(),
    watchRemoveNews(),
    watchSaveToLocalStorage(),
    watchLoadFromLocalStorage(),
    watchShiftSelectNewsForSorting(),
    watchCheckShoppingCartSize(),
    watchShowClusterDialog(),
    watchFetchReorderClusteredNews(),
    watchReplaceCluster(),
    watchSelectNews(),
    watchInvertNewsSelection(),
    watchSetCustomTagId(),
    watchAiAnalysis()
  ])
}
