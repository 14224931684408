import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { InlineDateTimePicker, Dialog, RaisedPrimaryButton, Button } from 'containers/themed'

import { DialogActions, Typography } from '@mui/material'

const useStyles = makeStyles()({
  picker: {
    margin: '1em 0em 1em 0em',
    '& > div': {
      width: '100%'
    }
  }
})

const ContentPlanDialog = ({
  data,
  locale,
  isOpen,
  onChange,
  onSave,
  closePlanDialog
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [plannedFor, setPlannedFor] = useState(data.get('plannedFor'))

  const handlePlan = () => {
    onChange({ key: 'plannedFor', value: plannedFor })
    onSave({ closeEditContentDialog: true })
    closePlanDialog()
  }

  const handleClose = () => {
    setPlannedFor(data.get('plannedFor'))
    closePlanDialog()
  }

  return (
    <Dialog
      maxWidth="sm"
      title={i18n.get('select_date_and_time')}
      onClose={handleClose}
      open={isOpen}
    >
      <Typography>{i18n.get('plan_content_info')}</Typography>
      <div className={classes.picker}>
        <InlineDateTimePicker
          ampm={locale === 'en-us'}
          label={i18n.get('planned_for')}
          value={plannedFor}
          onChange={v => setPlannedFor(v)}
        />
      </div>
      <DialogActions>
        <Button onClick={handleClose}>
          {i18n.get('close')}
        </Button>
        <RaisedPrimaryButton onClick={handlePlan}>
          {i18n.get('plan')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

ContentPlanDialog.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  locale: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  closePlanDialog: PropTypes.func.isRequired
}

export default ContentPlanDialog
