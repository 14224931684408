import { connect } from 'react-redux'

import CampaignsShowAll from 'components/content_desk/campaigns/campaigns_show_all/CampaignsShowAll'
import {
  setPage,
  setSelectedQueryType,
  searchCampaignsStart
} from 'actions/content_desk'
import {
  getCDeskCampaigns,
  getCDeskCampaignPage,
  getCDeskSearchCampaignsRunning,
  getCDeskCampaignSearchQueries,
  getCDeskContentSearchQueries
} from 'selectors'

const mapStateToProps = state => ({
  ccdCampaigns: getCDeskCampaigns(state),
  page: getCDeskCampaignPage(state),
  loading: getCDeskSearchCampaignsRunning(state),
  hasSearchQueries: (getCDeskCampaignSearchQueries(state).size > 0 || getCDeskContentSearchQueries(state).size > 0)
})

export default connect(
  mapStateToProps,
  {
    setPage,
    setSelectedQueryType,
    searchCampaignsStart
  }
)(CampaignsShowAll)
