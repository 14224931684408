import { put, select, call, takeEvery, all, takeLatest, race, take, delay, debounce } from 'redux-saga/effects'
import { fromJS, is, List } from 'immutable'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import { CustomTagsNewsTypes } from 'static/constants'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/media_reviews'
import * as NewsActions from 'actions/news'
import { listUniqueById, listChunk } from 'utils/immutable'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { multiSelect } from 'utils/multiselect'
import { sortGroupedNews } from 'utils/sorting'
import { reload } from 'utils/browser'

export function* loadFullTextStart({ payload: news }) {
  const newsId = news.get('id')

  try {
    const response = yield call(Api.loadFullText, newsId)

    const payload = fromJS({
      newsId,
      text: response.news.text
    })
    yield put(Actions.loadFullTextSuccess(payload))
  } catch (error) {
    yield put(Actions.loadFullTextError(error))
  }
}

export function* checkDiff() {
  try {
    const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)
    let savedSnapshot = yield call(Api.getMediaReviewSnapshot, mediaReviewId)
    savedSnapshot = fromJS(savedSnapshot)
    const loadedSnapshot = yield select(Selectors.getMediaReviewDetailSnapshot)
    const sorting = yield select(Selectors.getMediaReviewSorting)
    const futureSnapshot = fromJS(camelcaseKeysDeep(sorting.toJS()))

    let callReorder = true

    if (!is(savedSnapshot, loadedSnapshot)) {
      if (is(savedSnapshot, futureSnapshot)) {
        callReorder = false
      } else {
        yield put(Actions.showDiffWarning())

        const decision = yield race({
          accept: take(Actions.acceptDiff),
          reject: take(Actions.rejectDiff),
          timeout: delay(120000)
        })

        if (decision.reject || decision.timeout) {
          callReorder = false
          yield call(reload)
        } else {
          yield put(Actions.hideDiffWarning())
        }
      }
    }

    if (callReorder) {
      yield put(Actions.reorderStart())
    }

    yield put(Actions.setSnapshot(sorting))
  } catch (error) {
    yield put(Actions.checkDiffError(error))
    yield put(AppActions.exception(error))
  }
}

export function* reorder() {
  try {
    const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)
    const sorting = yield select(Selectors.getMediaReviewSorting)
    const body = {
      id: mediaReviewId,
      sorting
    }

    yield call(Api.reorderMediaReview, body)

    yield put(Actions.reorderSuccess())
  } catch (error) {
    yield put(Actions.reorderError(error))
    yield put(AppActions.exception(error))
  }
}

export function* fetchNewsForMediaReview({ payload: mediaReviewId }) {
  try {
    yield put(Actions.selectMediaReview(mediaReviewId))

    const result = yield call(Api.getMediaReview, mediaReviewId)

    const news = listUniqueById(fromJS(result.data).flatMap(d => d.get('news')))

    yield put(NewsActions.addNews(news.toJS()))
    yield put(Actions.fetchNewsForMediaReviewSuccess(result))
    yield put(Actions.checkMediaReviewReactionsStart())
  } catch (error) {
    yield put(Actions.fetchNewsForMediaReviewError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* moveCodeInMediaReview() {
  yield put(Actions.checkDiffStart())
  yield put(Actions.moveCodeInMediaReviewSuccess())
}

export function* moveNewsInMediaReview() {
  yield put(Actions.checkDiffStart())
  yield put(Actions.moveNewsInMediaReviewSuccess())
}

export function* removeNews({ payload: newsId }) {
  try {
    const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)

    const body = {
      id: newsId,
      media_review_id: mediaReviewId
    }

    yield call(Api.removeNewsFromMediaReview, body)
    yield put(Actions.removeNewsFromMediaReviewSuccess({ newsId }))
    yield put(Actions.updateMediaReviewStats())
  } catch (error) {
    yield put(Actions.removeNewsFromMediaReviewError(error))
  }
}

export function* regroupNews() {
  const isMediaReviewDetail = yield select(Selectors.isMediaReviewDetail)

  if (isMediaReviewDetail) {
    yield put(Actions.updateMediaReviewStats())
  }
}

export function* showAllSnippets() {
  const groupedNews = yield select(Selectors.getMediaReviewGroupedNews)
  const newsIds = groupedNews.flatMap(index => index.get('news').map(newsItem => fromJS({
    newsId: newsItem,
    codeId: index.getIn(['code', 'id'])
  })))
  yield put(Actions.showSnippet(newsIds))
}

export function* hideAllSnippets() {
  const groupedNews = yield select(Selectors.getMediaReviewGroupedNews)
  const newsIds = groupedNews.flatMap(index => index.get('news').map(newsItem => fromJS({
    newsId: newsItem,
    codeId: index.getIn(['code', 'id'])
  })))
  yield put(Actions.hideSnippet(newsIds))
}

export function* showAllSummaries() {
  const groupedNews = yield select(Selectors.getMediaReviewGroupedNews)
  const newsIds = groupedNews.flatMap(index => index.get('news').map(newsItem => fromJS({
    newsId: newsItem,
    codeId: index.getIn(['code', 'id'])
  })))
  yield put(Actions.showSummary(newsIds))
}

export function* hideAllSummaries() {
  const groupedNews = yield select(Selectors.getMediaReviewGroupedNews)
  const newsIds = groupedNews.flatMap(index => index.get('news').map(newsItem => fromJS({
    newsId: newsItem,
    codeId: index.getIn(['code', 'id'])
  })))
  yield put(Actions.hideSummary(newsIds))
}

export function* updateMediaReviewStats() {
  const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)
  const news = yield select(Selectors.getMediaReviewNewsUngrouped)

  yield put(Actions.recalculateMediaReviewStats({
    mediaReviewId,
    news
  }))
}

export function* shiftSelectNewsForSorting({ payload: { newsIds, isReplace, keyPress } }) {
  const codeId = newsIds.first().get('codeId')
  const newsByCodeId = yield select(Selectors.getMediaReviewNewsByCodeId, codeId)
  const selectedNewsForSorting = yield select(Selectors.getMediaReviewSelectedNewsForSorting)

  if (selectedNewsForSorting.isEmpty()) {
    yield put(Actions.selectNewsForSorting({ newsIds, isReplace, keyPress }))
  } else {
    const shiftSelection = yield call(multiSelect, selectedNewsForSorting, newsByCodeId, newsIds)
    yield put(Actions.selectNewsForSorting({ newsIds: shiftSelection, isReplace, keyPress }))
  }
}

export function* collapseAllTopics() {
  const codeIds = yield select(Selectors.getMediaReviewCodeIds)

  yield put(Actions.collapseTopics(codeIds))
}

export function* expandAllTopics() {
  const codeIds = yield select(Selectors.getMediaReviewCodeIds)

  yield put(Actions.expandTopics(codeIds))
}

export function* sortBy({ payload: { field, order } }) {
  const sortLast = 'zzzzzzzzzzzzz'
  let newGroupedNews

  if (field === 'topic') {
    const groupedNews = yield select(Selectors.getMediaReviewGroupedNews)
    newGroupedNews = groupedNews.sortBy(groupIndex => groupIndex.getIn(['code', 'sortcode']) || sortLast)

    if (order === 'desc') {
      newGroupedNews = newGroupedNews.reverse()
    }
  } else {
    const groupedNews = yield select(Selectors.getMediaReviewNews)

    const isPublicationField = ['publicationName', 'channelId'].indexOf(field) !== -1
    let pathToSortingField = [field]

    if (isPublicationField) {
      pathToSortingField = ['publication', field === 'publicationName' ? 'name' : field]
    }

    newGroupedNews = sortGroupedNews(groupedNews, pathToSortingField, order)
  }

  yield put(Actions.setGroupedNews(newGroupedNews))
}

const mapCodeNewsPairs = (codeId, newsIds) => newsIds.map(n => (codeId === undefined ? String(n) : `${codeId}_${n}`))

export function* selectNews({ payload: { codeId, newsIds, omitIntersection } }) {
  let codeNewsIdPairs = mapCodeNewsPairs(codeId, newsIds)
  let selectedNews = yield select(Selectors.getMediaReviewSelectedNews)
  const newsClusterIds = yield select(Selectors.getNewsClusterIdsOfAllCodesInMediaReview)

  const allCodeNewsIdPairs = newsClusterIds
    .map(pair => mapCodeNewsPairs(pair.get('codeId'), pair.get('newsIds')))
    .flatten(true)

  if (selectedNews.isEmpty()) {
    codeNewsIdPairs = allCodeNewsIdPairs
      .filterNot(c => codeNewsIdPairs.includes(c))
  }

  yield put(Actions.setSelectedNews({ codeNewsIdPairs, omitIntersection }))

  selectedNews = yield select(Selectors.getMediaReviewSelectedNews)

  if (selectedNews.size === allCodeNewsIdPairs.size) {
    yield put(Actions.resetNewsSelection())
  }
}

export function* invertNewsSelection() {
  const selectedNews = yield select(Selectors.getMediaReviewSelectedNews)
  const newsClusterIds = yield select(Selectors.getNewsClusterIdsOfAllCodesInMediaReview)

  const allCodeNewsIdPairs = newsClusterIds
    .map(pair => mapCodeNewsPairs(pair.get('codeId'), pair.get('newsIds')))
    .flatten(true)

  const codeNewsIdPairs = allCodeNewsIdPairs.filterNot(c => selectedNews.includes(c))

  yield put(Actions.resetNewsSelection())
  yield put(Actions.setSelectedNews({ codeNewsIdPairs }))
}

export function* filterMediaReviewsStart() {
  const filterData = yield select(Selectors.getMediaReviewsFilter)

  try {
    const body = {
      media_review_type_ids: filterData.get('filter'),
      size: filterData.get('size') + 1
    }

    const result = yield call(Api.filterMediaReviews, body)

    yield put(Actions.filterMediaReviewsSuccess(result))
  } catch (error) {
    yield put(Actions.filterMediaReviewsError(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* pinAll({ payload: { customTag, action } }) {
  try {
    const i18n = yield select(Selectors.getI18n)
    const moduleName = yield select(Selectors.getViewConfigModuleName)
    let news = yield select(Selectors.getMediaReviewsSelectedNewsObjects)

    if (news.isEmpty()) {
      news = yield select(Selectors.getMediaReviewNewsUngrouped)
    }

    news = news.toList()

    const newsIncludingClusters = news.reduce((acc, n) => {
      const cn = n.get('clusteredNews') || fromJS([])

      return acc.concat(cn.push(n))
    }, List())

    const chunkCount = Math.ceil(newsIncludingClusters.size / 100)
    const chunks = listChunk(newsIncludingClusters, chunkCount)

    yield put(AppActions.setAppBarMessage(i18n.get('pinning_progress', { progress: '0%' })))

    let changedCount = 0
    for (let i = 0; i < chunks.size; i += 1) {
      const chunkNews = chunks.get(i)

      let progress = `${Math.ceil(((i + 1) / chunks.size) * 100)}%`
      yield put(AppActions.setAppBarMessage(i18n.get('pinning_progress', { progress })))

      const data = chunkNews.map(n => (fromJS({
        news_id: n.get('id'),
        article_date: n.get('articleDate'),
        [`${action === 'unpin' ? '-' : '+'}custom_tag_ids`]: [customTag.get('id')]
      })))

      yield call(Api.assignCustomTagToNews, { data, news_type: CustomTagsNewsTypes[moduleName] })

      changedCount += data.size

      progress = `${Math.ceil(((i + 1) / chunks.size) * 100)}%`
      yield put(AppActions.setAppBarMessage(i18n.get('pinning_progress', { progress })))
    }

    const newTag = customTag.update('newsCount', count => count + (action === 'unpin' ? -changedCount : changedCount))

    news = news.map(n => n.update('customTags', customTags => {
      if (action === 'unpin') {
        return customTags.filter(c => c.get('id') !== newTag.get('id'))
      }

      return customTags.push(newTag)
    }))

    yield put(NewsActions.updateNews(news))
    yield put(AppActions.setAppBarMessage(null))
    yield put(Actions.pinAllSuccess({ customTag, pinnedCount: news.size }))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(AppActions.setAppBarMessage(null))
    yield put(Actions.pinAllError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* unpinAll({ payload: { customTag } }) {
  yield call(pinAll, { payload: { customTag, action: 'unpin' } })
}

export function* replaceMediaReviewReaction({ payload: { newsId, codeId, reactionTypeId } }) {
  try {
    const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)

    const body = {
      news_id: newsId,
      code_id: codeId,
      reaction_type_id: reactionTypeId
    }

    const result = yield call(Api.replaceMediaReviewReaction, mediaReviewId, body)

    yield put(Actions.replaceMediaReviewReactionSuccess(result))
  } catch (error) {
    yield put(Actions.replaceMediaReviewReactionError(error))
  }
}

export function* fetchMediaReviewReactions() {
  try {
    const blocked = yield select(Selectors.getMediaReviewCheckMediaReviewReactionsBlocked)

    if (!blocked) {
      const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)

      const result = yield call(Api.getMediaReviewReactions, mediaReviewId)

      yield put(Actions.fetchMediaReviewReactionsSuccess(result))
    }
  } catch (error) {
    yield put(Actions.fetchMediaReviewReactionsError(error))
  }
}

export function* checkMediaReviewReactions() {
  for (; ;) {
    const { stop } = yield race({
      stop: take(Actions.checkMediaReviewReactionsStop),
      refresh: delay(10000)
    })

    if (stop) {
      break
    }

    yield put(Actions.fetchMediaReviewReactionsStart())
  }
}

export function* aiAnalysis() {
  try {
    const locale = yield select(Selectors.getAiSettingsLocale)
    const mediaReviewId = yield select(Selectors.getSelectedMediaReviewId)

    const result = yield call(Api.getMediaReviewAiAnalysis, mediaReviewId, locale)

    yield put(Actions.aiAnalysisSuccess(result))
  } catch (error) {
    yield put(Actions.aiAnalysisError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchFetchNewsForMediaReview() {
  yield takeEvery(Actions.fetchNewsForMediaReviewStart, fetchNewsForMediaReview)
}

export function* watchMoveCode() {
  yield takeEvery(Actions.moveCodeInMediaReviewStart, moveCodeInMediaReview)
}

export function* watchMoveNews() {
  yield takeEvery(Actions.moveNewsInMediaReviewStart, moveNewsInMediaReview)
}

export function* watchRemoveNews() {
  yield takeEvery(Actions.removeNewsFromMediaReviewStart, removeNews)
}

export function* watchRegroupNews() {
  yield takeEvery(Actions.regroupNews, regroupNews)
}

export function* watchUpdateMediaReviewStats() {
  yield takeEvery(Actions.updateMediaReviewStats, updateMediaReviewStats)
}

export function* watchCollapseAllTopics() {
  yield takeEvery(Actions.collapseAllTopics, collapseAllTopics)
}

export function* watchExpandAllTopics() {
  yield takeEvery(Actions.expandAllTopics, expandAllTopics)
}

export function* watchShowAllSnippets() {
  yield takeEvery(Actions.showAllSnippets, showAllSnippets)
}

export function* watchHideAllSnippets() {
  yield takeEvery(Actions.hideAllSnippets, hideAllSnippets)
}

export function* watchShowAllSummaries() {
  yield takeEvery(Actions.showAllSummaries, showAllSummaries)
}

export function* watchHideAllSummaries() {
  yield takeEvery(Actions.hideAllSummaries, hideAllSummaries)
}

export function* watchShiftSelectNewsForSorting() {
  yield takeEvery(Actions.shiftSelectNewsForSorting, shiftSelectNewsForSorting)
}

export function* watchLoadFullTextStart() {
  yield takeEvery(Actions.loadFullTextStart, loadFullTextStart)
}

export function* watchSortBy() {
  yield takeEvery(Actions.sortBy, sortBy)
}

export function* watchSelectNews() {
  yield takeEvery(Actions.selectNews, selectNews)
}

export function* watchInvertNewsSelection() {
  yield takeEvery(Actions.invertNewsSelection, invertNewsSelection)
}

export function* watchFilterMediaReviewsStart() {
  yield takeEvery(Actions.filterMediaReviewsStart, filterMediaReviewsStart)
}

export function* watchPinAll() {
  yield takeEvery(Actions.pinAllStart, pinAll)
}

export function* watchUnpinAll() {
  yield takeEvery(Actions.unpinAll, unpinAll)
}

export function* watchReplaceMediaReviewReaction() {
  yield takeEvery(Actions.replaceMediaReviewReactionStart, replaceMediaReviewReaction)
}

export function* watchFetchMediaReviewReations() {
  yield takeLatest(Actions.fetchMediaReviewReactionsStart, fetchMediaReviewReactions)
}

export function* watchCheckMediaReviewReactions() {
  yield takeEvery(Actions.checkMediaReviewReactionsStart, checkMediaReviewReactions)
}

export function* watchReorder() {
  yield takeLatest(Actions.reorderStart, reorder)
}

export function* watchCheckDiff() {
  yield debounce(1000, Actions.checkDiffStart, checkDiff)
}

export function* watchAiAnalysis() {
  yield takeLatest(Actions.aiAnalysisStart, aiAnalysis)
}

export default function* mediaReviewSaga() {
  yield all([
    watchFetchNewsForMediaReview(),
    watchMoveCode(),
    watchMoveNews(),
    watchRemoveNews(),
    watchRegroupNews(),
    watchUpdateMediaReviewStats(),
    watchCollapseAllTopics(),
    watchExpandAllTopics(),
    watchShowAllSnippets(),
    watchHideAllSnippets(),
    watchShowAllSummaries(),
    watchHideAllSummaries(),
    watchShiftSelectNewsForSorting(),
    watchLoadFullTextStart(),
    watchSortBy(),
    watchSelectNews(),
    watchInvertNewsSelection(),
    watchFilterMediaReviewsStart(),
    watchPinAll(),
    watchUnpinAll(),
    watchReplaceMediaReviewReaction(),
    watchFetchMediaReviewReations(),
    watchCheckMediaReviewReactions(),
    watchReorder(),
    watchCheckDiff(),
    watchAiAnalysis()
  ])
}
