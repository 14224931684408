import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { Paper, Divider, Grid, Box, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  date: {
    fontWeight: 400,
    color: !theme.isDark && '#404040'
  },
  monthAndYear: {
    color: !theme.isDark && '#404040',
    fontWeight: 400,
    fontSize: '11pt'
  },
  day: {
    color: !theme.isDark && '#404040',
    fontWeight: 400,
    fontSize: '20pt'
  },
  calendarIcon: {
    fontSize: '28px',
    color: !theme.isDark && '#404040'
  }
}))

function VerticalDivider() {
  return (
    <Divider
      orientation="vertical"
      variant="fullWidth"
      flexItem
      role="presentation"
      sx={{
        mr: 1,
        border: '2px solid',
        borderRadius: '4px',
        borderColor: theme => !theme.isDark && '#757575'
      }}
    />
  )
}

export default function Calendar({ contents, openCalendarDialog }) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const today = moment()
  const day = today.format('dddd')
  const date = today.format('D')
  const monthAndYear = today.format('MMMM YYYY')
  const todaysReleases = contents.get('items')
    .filter(content => today.isSame(moment.utc(content.get('scheduledFor')), 'day'))
    .filter(content => !(content.get('releasedAt') || content.get('releaseError')))

  const renderRelease = content => {
    const time = moment.utc(content.get('scheduledFor')).local().format('LT')
    const id = content.get('id')
    const title = content.get('title')
    const campaign = `${i18n.get('campaign')}: ${content.getIn(['ccdCampaign', 'name'])}`

    return (
      <Box
        my={3}
        key={`content__${id}`}
      >
        <Divider variant="fullWidth" />
        <Box sx={{ display: 'flex', pt: 1 }}>
          <VerticalDivider />

          <Box
            sx={{
              display: 'flex',
              color: theme => !theme.isDark && '#757575'
            }}
          >
            <Typography variant="body2">{time}</Typography>

            <Box ml={2}>
              <Typography variant="body2">{title}</Typography>
              <Typography variant="body2">{campaign}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const renderNextReleases = () => {
    if (!todaysReleases.size) {
      return (
        <Box pt={3}>
          <Divider
            variant="fullWidth"
          />
          <Typography
            pt={2}
            color="text.secondary"
            variant="subtitle1"
          >
            {i18n.get('no_scheduled_releases')}
          </Typography>
        </Box>
      )
    }

    return todaysReleases.take(5).map(renderRelease)
  }

  return (
    <Paper sx={{ minHeight: 200, p: 2 }}>
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          sm={9}
          sx={{
            display: 'flex'
          }}
        >
          <Typography
            variant="h2"
            p={0}
            pr={2}
            m={0}
            className={classes.date}
          >
            {date}
          </Typography>
          <Box>
            <Typography className={classes.day}>{day}</Typography>
            <Typography className={classes.monthAndYear}>
              {monthAndYear}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            minWidth: '60px'
          }}
        >
          <IconButton
            size="normal"
            onClick={() => openCalendarDialog()}
            title={i18n.get('calendar')}
          >
            <CalendarMonthIcon className={classes.calendarIcon} />
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
      {renderNextReleases()}
    </Paper>
  )
}

Calendar.propTypes = {
  contents: PropTypes.instanceOf(Map).isRequired,
  openCalendarDialog: PropTypes.func.isRequired
}
