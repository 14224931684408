import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { MenuItem, Divider } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import { Menu, Button } from 'containers/themed'

const useStyles = makeStyles()({
  menuButton: {
    width: 120,
    marginBottom: '2em'
  },
  buttonIcon: {
    marginLeft: 8
  }
})

const NewButton = ({
  openNewContentDialog,
  openCampaignForm
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Menu
      handle={(
        <Button
          className={classes.menuButton}
          variant="contained"
          color="primary"
        >
          {i18n.get('new')}
          <AddOutlinedIcon className={classes.buttonIcon} />
        </Button>
      )}
    >
      <MenuItem onClick={() => openNewContentDialog()}>
        {i18n.get('content')}
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => openCampaignForm()}>
        {i18n.get('campaign')}
      </MenuItem>
    </Menu>
  )
}

NewButton.propTypes = {
  openNewContentDialog: PropTypes.func.isRequired,
  openCampaignForm: PropTypes.func.isRequired
}

export default NewButton
