import { createAction } from 'redux-actions'

const defaultPrefix = 'EMAIL_TEMPLATES_LIBRARY/'
let prefix = 'EMAIL_TEMPLATES_LIBRARY/'

// DATA
prefix = `${defaultPrefix}DATA/`
export const setTemplateFormData = createAction(`${prefix}SET_TEMPLATE_FORM_DATA`)
export const saveTemplateStart = createAction(`${prefix}SAVE_TEMPLATE_START`)
export const saveTemplateSuccess = createAction(`${prefix}SAVE_TEMPLATE_SUCCESS`)
export const saveTemplateError = createAction(`${prefix}SAVE_TEMPLATE_ERROR`)
export const deleteTemplateStart = createAction(`${prefix}DELETE_TEMPLATE_START`)
export const deleteTemplateSuccess = createAction(`${prefix}DELETE_TEMPLATE_SUCCESS`)
export const deleteTemplateError = createAction(`${prefix}DELETE_TEMPLATE_ERROR`)
export const fetchTemplatesStart = createAction(`${prefix}FETCH_TEMPLATES_START`)
export const fetchTemplatesSuccess = createAction(`${prefix}FETCH_TEMPLATES_SUCCESS`)
export const fetchTemplatesError = createAction(`${prefix}FETCH_TEMPLATES_ERROR`)
export const resetTemplatesModule = createAction(`${prefix}RESET_TEMPLATES_MODULE`)
export const loadSelectedTemplate = createAction(`${prefix}LOAD_SELECTED_TEMPLATE`)
export const fetchMoreTemplatesStart = createAction(`${prefix}FETCH_MORE_TEMPLATES_START`)
export const cloneTemplateStart = createAction(`${prefix}CLONE_TEMPLATE_START`)
export const cloneTemplateSuccess = createAction(`${prefix}CLONE_TEMPLATE_SUCCESS`)
export const cloneTemplateError = createAction(`${prefix}CLONE_TEMPLATE_ERROR`)

// UI
prefix = `${defaultPrefix}UI/`
export const openEditorDialog = createAction(`${prefix}OPEN_EDITOR_DIALOG`)
export const closeEditorDialog = createAction(`${prefix}CLOSE_EDITOR_DIALOG`)
export const openSaveDialog = createAction(`${prefix}OPEN_SAVE_DIALOG`)
export const closeSaveDialog = createAction(`${prefix}CLOSE_SAVE_DIALOG`)
export const openPickerDialog = createAction(`${prefix}OPEN_PICKER_DIALOG`)
export const closePickerDialog = createAction(`${prefix}CLOSE_PICKER_DIALOG`)
export const togglePublicTemplates = createAction(`${prefix}SHOW_PUBLIC_TEMPLATES`)
export const setReadMode = createAction(`${prefix}SET_READ_MODE`)

// FILTER
prefix = `${defaultPrefix}FILTER/`
export const increaseTemplatesPage = createAction(`${prefix}INCREASE_TEMPLATES_PAGE`)
export const resetTemplateFilters = createAction(`${prefix}RESET_TEMPLATE_FILTERS`)
