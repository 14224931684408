import { connect } from 'react-redux'
import { List } from 'immutable'

import ActiveFilterList from 'components/active_filter_list'
import {
  getActiveCDeskFilters, getActiveCDeskFiltersCollapsed
} from 'selectors'
import { toggleActiveFilters } from 'actions/content_desk'

const mapStateToProps = state => ({
  activeFilters: getActiveCDeskFilters(state),
  activeBooleanFilters: List(),
  filterCount: getActiveCDeskFilters(state).size,
  collapsed: getActiveCDeskFiltersCollapsed(state),
  activeFilterType: 'contentDesk'
})

export default connect(
  mapStateToProps,
  {
    onCollapseClick: toggleActiveFilters
  }
)(ActiveFilterList)
