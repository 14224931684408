import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap } from 'immutable'
import { green, red } from '@mui/material/colors'
import { CircularProgress, InputAdornment } from '@mui/material'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'

import { IconButton, Input } from 'containers/themed'

const useStyles = makeStyles()({
  cancelTitleIcon: {
    color: red[500],
    padding: '0px !important'
  },
  contentTitle: {
    '&:hover': {
      cursor: 'text'
    }
  },
  editIcon: {
    fontSize: 'small',
    marginLeft: '3px'
  },
  saveTitleIcon: {
    color: green[500],
    padding: '0px !important'
  },
  titleActions: {
    marginLeft: '-5px'
  }
})

export default function ContentsTitle({
  content,
  requestRunning,
  onSave
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [nameEditable, setNameEditable] = useState(false)
  const [title, setTitle] = useState(content.get('title'))

  useEffect(() => {
    setTitle(content.get('title'))
  }, [content])

  const handleTitleClick = () => {
    setNameEditable(!nameEditable)
  }

  const handleOnEnter = e => {
    if (e.code === 'Enter') {
      setNameEditable(!nameEditable)
      onSave({
        id: content.get('id'),
        title,
        plannedFor: content.get('plannedFor'),
        scheduledFor: content.get('scheduledFor'),
        scheduledForJobName: content.get('scheduledForJobName'),
        scheduledEventsJobName: content.get('scheduledEventsJobName'),
        approvalStatus: content.get('status'),
        ccdCampaignId: content.getIn(['ccdCampaign', 'id'])
      })
    }

    if (e.code === 'Escape') {
      setTitle(content.get('title'))
      setNameEditable(!nameEditable)
    }
  }

  const handleSaveClick = () => {
    setNameEditable(!nameEditable)
    onSave({
      id: content.get('id'),
      title,
      plannedFor: content.get('plannedFor')
    })
  }

  const handleCancelClick = () => {
    setTitle(content.get('title'))
    setNameEditable(!nameEditable)
  }

  const renderTitleActions = () => (
    <div className={classes.titleActions}>
      <IconButton
        title={i18n.get('check')}
        onClick={() => handleSaveClick()}
        disabled={requestRunning}
      >
        {requestRunning && (
          <CircularProgress
            style={{ position: 'absolute' }}
            size={34}
            variant="indeterminate"
          />
        )}
        <CheckCircleIcon className={classes.saveTitleIcon} />
      </IconButton>
      <IconButton
        title={i18n.get('check')}
        onClick={() => handleCancelClick()}
        disabled={requestRunning}
      >
        <CancelIcon className={classes.cancelTitleIcon} />
      </IconButton>
    </div>
  )

  if (nameEditable) {
    return (
      <Input
        type="text"
        onKeyDown={handleOnEnter}
        label={i18n.get('content_title')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{renderTitleActions()}</InputAdornment>
          )
        }}
        onChange={({ target: { value } }) => setTitle(value)}
        value={title}
        error={title === ''}
        disabled={requestRunning}
        helperText={title === '' ? i18n.get('content_needs_a_name') : null}
      />
    )
  }

  return (
    <div
      className={classes.contentTitle}
      onClick={handleTitleClick}
    >
      {title}
      {content.get('status') === 'released' && (
        <EditIcon className={classes.editIcon} />
      )}
    </div>
  )
}

ContentsTitle.defaultProps = {
  requestRunning: false
}

ContentsTitle.propTypes = {
  content: PropTypes.instanceOf(ImmutableMap).isRequired,
  requestRunning: PropTypes.bool,
  onSave: PropTypes.func.isRequired
}
