import { connect } from 'react-redux'

import ContentsDelete from 'components/content_desk/contents/contents_item/contents_delete/ContentsDelete'

import { openDeleteDialog, setDeleteContentData } from 'actions/content_desk'

const mapStateToProps = (_state, { deleteDisabled, deleteData }) => ({
  deleteDisabled,
  deleteData
})

export default connect(
  mapStateToProps,
  {
    onOpen: () => openDeleteDialog('content'),
    setDeleteData: setDeleteContentData
  }
)(ContentsDelete)
