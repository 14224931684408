import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { Dialog, RaisedPrimaryButton, Button } from 'containers/themed'

import { DialogActions, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import { red } from '@mui/material/colors'

const useStyles = makeStyles()({
  warningWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: red[500],
    marginTop: '1em'
  },
  warning: {
    marginLeft: '0.3em'
  }
})

const PublishConfirmDialog = ({
  open,
  status,
  onChange,
  onCloseDialog,
  onSave
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const notApproved = ['draft', 'review_pending', 'revisions_needed'].includes(status)

  const handlePublish = () => {
    const currentDateAndTime = moment.utc().local()

    onChange({ key: 'scheduledFor', value: currentDateAndTime })
    onSave({ closeEditContentDialog: true })
    onCloseDialog()
  }

  const renderWarning = () => (
    <div className={classes.warningWrapper}>
      <WarningIcon
        size="small"
      />
      <Typography className={classes.warning}>{i18n.get('content_not_approved')}</Typography>
    </div>
  )

  return (
    <Dialog
      maxWidth="sm"
      title={i18n.get('please_confirm')}
      onClose={onCloseDialog}
      open={open}
    >
      {i18n.get('publish_confirmation')}
      {notApproved && renderWarning()}
      <DialogActions>
        <Button onClick={onCloseDialog}>
          {i18n.get('no')}
        </Button>
        <RaisedPrimaryButton onClick={handlePublish}>
          {i18n.get('yes')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

PublishConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default PublishConfirmDialog
