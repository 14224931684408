import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Box, Grid, Chip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { PrimaryButton } from 'containers/themed'
import ContactImage from 'components/contact_management/contact_image'

const useStyles = makeStyles()(() => ({
  chipBorderRadius: {
    borderRadius: '4px',
    fontSize: '75%'
  },
  itemsAlignCenter: {
    alignItems: 'center'
  }
}))

export default function ContactProfile({ contact, countries, states, isDistListsLocked, onAdd }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const country = (countries.find(c => c.get('id') === contact.getIn(['country', 'id'])) || ImmutableMap()).get('name')
  const state = (states.find(c => c.get('id') === contact.getIn(['state', 'id'])) || ImmutableMap()).get('name')
  const publication = contact.get('identity') ? contact.getIn(['identity', 'publication', 'name']) : contact.get('externalPublication')
  const unsubscribed = contact.get('unsubscribed')

  return (
    <Grid
      container
      classes={{ root: classes.itemsAlignCenter }}
    >
      <Grid
        item
        xs={4}
      >
        <Box mb={2}>
          <Chip
            color={contact.get('supplier') ? 'primary' : 'default'}
            label={contact.get('supplier') ? 'pressrelations' : i18n.get('my_contact')}
            size="small"
            variant="outlined"
            classes={{ root: classes.chipBorderRadius }}
          />
        </Box>
        {contact.get('title') && (
          <Typography
            variant="body2"
            sx={{ color: theme => theme.palette.grey[700] }}
          >
            {contact.get('title')}
          </Typography>
        )}
        <Typography variant="h6">
          {contact.get('firstName')} {contact.get('lastName')}
        </Typography>

        <Typography
          variant="body2"
          py={1}
        >
          {contact.get('jobTitle')}
        </Typography>

        <Typography
          variant="body2"
          py={1}
        >
          {publication}
        </Typography>

        {contact.get('stateId') && (
          <Typography
            variant="body2"
            sx={{ color: theme => theme.palette.grey[700] }}
          >
            {state}
          </Typography>
        )}

        <Typography
          variant="body2"
          sx={{ color: theme => theme.palette.grey[700] }}
        >
          {country}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <ContactImage url={contact.get('picture')} />
      </Grid>
      <Grid
        item
        xs={4}
        className={classes.itemsAlignCenter}
      >
        <PrimaryButton
          disabled={unsubscribed || isDistListsLocked}
          onClick={onAdd}
          startIcon={<AddIcon />}
        >
          {i18n.get('add_to_list')}
        </PrimaryButton>
      </Grid>
    </Grid>
  )
}

ContactProfile.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  states: PropTypes.instanceOf(ImmutableList).isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  onAdd: PropTypes.func.isRequired
}
