import { connect } from 'react-redux'

import ContentsSortbar from 'components/content_desk/contents/contents_show_all/contents_sortbar/ContentsSortbar'
import { setSortBy, setPage } from 'actions/content_desk'

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps,
  {
    onSortBy: setSortBy,
    setPage
  }
)(ContentsSortbar)
