import { connect } from 'react-redux'

import AppBar from 'components/content_desk/app_bar/AppBar'

import { getCDeskConfig } from 'selectors'

const mapStateToProps = state => ({
  isDomainVerified: getCDeskConfig(state).get('isDomainVerified') || false
})

export default connect(
  mapStateToProps,
  {
  }
)(AppBar)
