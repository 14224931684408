import { connect } from 'react-redux'

import ContentsTitle from 'components/content_desk/contents/contents_item/contents_title'

import { getCDeskRequestRunning } from 'selectors'

import { saveContentFormlessStart } from 'actions/content_desk'

const mapStateToProps = (state, { content }) => ({
  content,
  requestRunning: getCDeskRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onSave: saveContentFormlessStart
  }
)(ContentsTitle)
