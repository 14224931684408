import { connect } from 'react-redux'

import ContentNotesDialog from 'components/content_desk/content_notes_dialog'

import {
  setContentFormData,
  saveContentStart,
  unlockContentStart
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskShowContentNotesDialog,
  getUserId,
  getUserFirstName,
  getUserLastName,
  getCDeskContentIsLoading
} from 'selectors'

const mapStateToProps = state => ({
  data: getCDeskContentFormData(state),
  isOpen: getCDeskShowContentNotesDialog(state),
  userId: getUserId(state),
  userFirstName: getUserFirstName(state),
  userLastName: getUserLastName(state),
  saving: getCDeskContentIsLoading(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    onClose: unlockContentStart,
    onSave: saveContentStart
  }
)(ContentNotesDialog)
