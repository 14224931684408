import { connect } from 'react-redux'

import ContentsFilter from 'components/content_desk/contents/contents_show_all/contents_filter/ContentsFilter'

import {
  searchContentsStart,
  setContentFilters,
  setPage
} from 'actions/content_desk'

import {
  getCDeskSearchContentsRunning,
  getUserLocale
} from 'selectors'

const mapStateToProps = state => ({
  loading: getCDeskSearchContentsRunning(state),
  locale: getUserLocale(state)
})

export default connect(
  mapStateToProps,
  {
    searchContents: searchContentsStart,
    setContentFilters,
    setPage
  }
)(ContentsFilter)
