import { createAction } from 'redux-actions'
import createLoadingAction from 'utils/createLoadingAction'

const prefix = 'DASHBOARD/'

export const toggleEditing = createAction(`${prefix}TOGGLE_EDITING`)
export const setDashboards = createAction(`${prefix}SET_DASHBOARDS`)
export const setSelectedDashboard = createAction(`${prefix}SET_SELECTED_DASHBOARD`)
export const cloneDashboard = createAction(`${prefix}CLONE_DASHBOARD`)

export const setEditDashboard = createAction(`${prefix}SET_EDIT_DASHBOARD`)
export const setEditDashboardUploadedBackgroundImage = createAction(`${prefix}SET_EDIT_DASHBOARD_UPLOADED_BACKGROUND_IMAGE`)
export const setEditDashboardBackgroundImageUrl = createAction(`${prefix}SET_EDIT_DASHBOARD_BACKGROUND_IMAGE_URL`)
export const setEditDashboardColor = createAction(`${prefix}SET_EDIT_DASHBOARD_COLOR`)
export const resetEditDashboardBackgroundImage = createAction(`${prefix}RESET_EDIT_DASHBOARD_BACKGROUND_IMAGE`)

export const fetchDashboard = createAction(`${prefix}FETCH_DASHBOARD`)

export const saveDashboardStart = createAction(`${prefix}SAVE_DASHBOARD_START`)
export const saveDashboardSuccess = createAction(`${prefix}SAVE_DASHBOARD_SUCCESS`)
export const saveDashboardError = createAction(`${prefix}SAVE_DASHBOARD_ERROR`)

export const fetchSavedSearchAggregationsStart = createAction(`${prefix}FETCH_SAVED_SEARCH_AGGREGATIONS_START`)
export const fetchSavedSearchAggregationsSuccess = createAction(`${prefix}FETCH_SAVED_SEARCH_AGGREGATIONS_SUCCESS`)
export const fetchSavedSearchAggregationsError = createAction(`${prefix}FETCH_SAVED_SEARCH_AGGREGATIONS_ERROR`)

export const deleteDashboardStart = createAction(`${prefix}DELETE_DASHBOARD_START`)
export const deleteDashboardSuccess = createAction(`${prefix}DELETE_DASHBOARD_SUCCESS`)
export const deleteDashboardError = createAction(`${prefix}DELETE_DASHBOARD_ERROR`)

export const showChartDialog = createAction(`${prefix}SHOW_CHART_DIALOG`)
export const hideChartDialog = createAction(`${prefix}HIDE_CHART_DIALOG`)
export const showMultiChartsDialog = createAction(`${prefix}SHOW_MULTI_CHARTS_DIALOG`)
export const hideMultiChartsDialog = createAction(`${prefix}HIDE_MULTI_CHARTS_DIALOG`)

export const showEditDialog = createAction(`${prefix}SHOW_EDIT_DASHBOARD_DIALOG`)
export const hideEditDialog = createAction(`${prefix}HIDE_EDIT_DASHBOARD_DIALOG`)

export const showDeleteDialog = createAction(`${prefix}SHOW_DELETE_DASHBOARD_DIALOG`)
export const hideDeleteDialog = createAction(`${prefix}HIDE_DELETE_DASHBOARD_DIALOG`)

export const showDrilldownDialog = createAction(`${prefix}SHOW_DRILLDOWN_DIALOG`)
export const hideDrilldownDialog = createAction(`${prefix}HIDE_DRILLDOWN_DIALOG`)

export const setSelectedChart = createAction(`${prefix}SET_SELECTED_CHART`)
export const setSelectedChartOpt = createAction(`${prefix}SET_SELECTED_CHART_OPT`)
export const setSelectedChartStepType = createAction(`${prefix}SET_SELECTED_CHART_STEP_TYPE`)
export const setChart = createAction(`${prefix}SET_CHART`)
export const setChartOpt = createAction(`${prefix}SET_CHART_OPT`)
export const setChartData = createAction(`${prefix}SET_CHART_DATA`)
export const setChartLoading = createAction(`${prefix}SET_CHART_LOADING`)
export const checkChartChange = createAction(`${prefix}CHECK_CHART_CHANGE`)
export const removeChart = createAction(`${prefix}REMOVE_CHART`)
export const setLayouts = createAction(`${prefix}SET_LAYOUTS`)
export const setNormalMode = createAction(`${prefix}SET_NORMAL_MODE`)
export const setPrevious = createAction(`${prefix}SET_PREVIOUS`)
export const setPreviousToData = createAction(`${prefix}SET_PREVIOUS_TO_DATA`)
export const cancelEditing = createAction(`${prefix}CANCEL_EDITING`)
export const setChartLimitReached = createAction(`${prefix}SET_CHART_LIMIT_REACHED`)

export const selectSavedSearch = createAction(`${prefix}SELECT_SAVED_SEARCH`)
export const selectAdditionalSavedSearches = createAction(`${prefix}SELECT_ADDITIONAL_SAVED_SEARCHES`)
export const selectChartDialogGroupingType = createAction(`${prefix}SELECT_CHART_DIALOG_GROUPING_TYPE`)
export const selectChartDialogSortBy = createAction(`${prefix}SELECT_CHART_DIALOG_SORT_BY`)
export const selectDrilldownGroupingType = createAction(`${prefix}SELECT_DRILLDOWN_GROUPING_TYPE`)
export const selectDrilldownSortBy = createAction(`${prefix}SELECT_DRILLDOWN_SORT_BY`)
export const selectDateRange = createAction(`${prefix}SELECT_DATE_RANGE`)
export const selectDate = createAction(`${prefix}SELECT_DATE`)
export const selectDateMode = createAction(`${prefix}SELECT_DATE_MODE`)
export const selectDateType = createAction(`${prefix}SELECT_DATE_TYPE`)
export const selectDateInterval = createAction(`${prefix}SELECT_DATE_INTERVAL`)
export const selectThirdLevel = createAction(`${prefix}SELECT_THIRD_LEVEL`)
export const selectFirstLevel = createAction(`${prefix}SELECT_FIRST_LEVEL`)
export const updateDateRangeOfSelectedDashboard = createAction(`${prefix}UPDATE_DATE_RANGE_OF_SELECTED_DASHBOARD`)
export const updateKpisOfSelectedDashboard = createAction(`${prefix}UPDATE_KPI_OF_SELECTED_DASHBOARD`)
export const updateDatesOfSelectedDashboard = createAction(`${prefix}UPDATE_DATES_OF_SELECTED_DASHBOARD`)

export const toggleFavorite = createAction(`${prefix}TOGGLE_FAVORITE`)
export const loadDashboard = createAction(`${prefix}LOAD_DASHBOARD`)

export const loadMoreFeedChartItems = createAction(`${prefix}LOAD_MORE_FEED_CHART_ITEMS`)

export const loadMoreNewsFeedNewsStart = createAction(`${prefix}LOAD_MORE_NEWS_FEED_NEWS_START`)
export const loadMoreNewsFeedNewsSuccess = createAction(`${prefix}LOAD_MORE_NEWS_FEED_NEWS_SUCCESS`)
export const loadMoreNewsFeedNewsError = createAction(`${prefix}LOAD_MORE_NEWS_FEED_NEWS_ERROR`)

export const fetchNewsFeedClusterStatsError = createAction(`${prefix}FETCH_NEWS_FEED_CLUSTER_STATS_ERROR`)
export const fetchNewsFeedClusterStatsStart = createAction(`${prefix}FETCH_NEWS_FEED_CLUSTER_STATS_START`)
export const fetchNewsFeedClusterStatsSuccess = createAction(`${prefix}FETCH_NEWS_FEED_CLUSTER_STATS_SUCCESS`)

export const loadDrilldownNewsFeedNewsStart = createAction(`${prefix}LOAD_DRILLDOWN_NEWS_FEED_NEWS_START`)
export const loadDrilldownNewsFeedNewsSuccess = createAction(`${prefix}LOAD_DRILLDOWN_NEWS_FEED_NEWS_SUCCESS`)
export const loadDrilldownNewsFeedNewsError = createAction(`${prefix}LOAD_DRILLDOWN_NEWS_FEED_NEWS_ERROR`)

export const loadDrilldownNewsFeedAiAnalysisStart = createAction(`${prefix}LOAD_DRILLDOWN_NEWS_FEED_AI_ANALYSIS_START`)
export const loadDrilldownNewsFeedAiAnalysisSuccess = createAction(`${prefix}LOAD_DRILLDOWN_NEWS_FEED_AI_ANALYSIS_SUCCESS`)
export const loadDrilldownNewsFeedAiAnalysisError = createAction(`${prefix}LOAD_DRILLDOWN_NEWS_FEED_AI_ANALYSIS_ERROR`)

export const loadMoreDrilldownNewsFeedNewsStart = createAction(`${prefix}LOAD_MORE_DRILLDOWN_NEWS_FEED_NEWS_START`)
export const loadMoreDrilldownNewsFeedNewsSuccess = createAction(`${prefix}LOAD_MORE_DRILLDOWN_NEWS_FEED_NEWS_SUCCESS`)
export const loadMoreDrilldownNewsFeedNewsError = createAction(`${prefix}LOAD_MORE_DRILLDOWN_NEWS_FEED_NEWS_ERROR`)

export const fetchDrilldownNewsFeedClusterStatsError = createAction(`${prefix}FETCH_DRILLDOWN_NEWS_FEED_CLUSTER_STATS_ERROR`)
export const fetchDrilldownNewsFeedClusterStatsStart = createAction(`${prefix}FETCH_DRILLDOWN_NEWS_FEED_CLUSTER_STATS_START`)
export const fetchDrilldownNewsFeedClusterStatsSuccess = createAction(`${prefix}FETCH_DRILLDOWN_NEWS_FEED_CLUSTER_STATS_SUCCESS`)

export const loadMoreSocialMediaAnalyticsFeedPostsStart = createAction(`${prefix}LOAD_MORE_SOCIAL_MEDIA_ANALYTICS_FEED_POSTS_START`)
export const loadMoreSocialMediaAnalyticsFeedPostsSuccess = createAction(`${prefix}LOAD_MORE_SOCIAL_MEDIA_ANALYTICS_FEED_POSTS_SUCCESS`)
export const loadMoreSocialMediaAnalyticsFeedPostsError = createAction(`${prefix}LOAD_MORE_SOCIAL_MEDIA_ANALYTICS_FEED_POSTS_ERROR`)

export const executeSavedSearch = createAction(`${prefix}EXECUTE_SAVED_SEARCH`)
export const executedSavedSearch = createAction(`${prefix}EXECUTED_SAVED_SEARCH`)

export const exportExcelStart = createLoadingAction(`${prefix}EXPORT_EXCEL_START`, true)
export const exportExcelSuccess = createLoadingAction(`${prefix}EXPORT_EXCEL_SUCCESS`, false)
export const exportExcelError = createLoadingAction(`${prefix}EXPORT_EXCEL_ERROR`, false)

export const exportPowerpointStart = createLoadingAction(`${prefix}EXPORT_POWERPOINT_START`, true)
export const exportPowerpointSuccess = createLoadingAction(`${prefix}EXPORT_POWERPOINT_SUCCESS`, false)
export const exportPowerpointError = createLoadingAction(`${prefix}EXPORT_POWERPOINT_ERROR`, false)

export const exportDashboardAsPowerpointStart = createAction(`${prefix}EXPORT_DASHBOARD_AS_POWERPOINT_START`)
export const exportDashboardAsPowerpointSuccess = createAction(`${prefix}EXPORT_DASHBOARD_AS_POWERPOINT_SUCCESS`)
export const exportDashboardAsPowerpointError = createAction(`${prefix}EXPORT_DASHBOARD_AS_POWERPOINT_ERROR`)

export const downloadImageStart = createAction(`${prefix}DOWNLOAD_IMAGE_START`)
export const downloadImageError = createAction(`${prefix}DOWNLOAD_IMAGE_ERROR`)
export const downloadImageSuccess = createAction(`${prefix}DOWNLOAD_IMAGE_SUCCESS`)

export const downloadPdfStart = createAction(`${prefix}DOWNLOAD_PDF_START`)
export const downloadPdfError = createAction(`${prefix}DOWNLOAD_PDF_ERROR`)
export const downloadPdfSuccess = createAction(`${prefix}DOWNLOAD_PDF_SUCCESS`)

export const copyFromDisplaySize = createAction(`${prefix}COPY_FROM_DISPLAY_SIZE`)

export const toggleAutoRefresh = createAction(`${prefix}TOGGLE_AUTO_REFRESH`)
export const startAutoRefresh = createAction(`${prefix}START_AUTO_REFRESH`)
export const stopAutoRefresh = createAction(`${prefix}STOP_AUTO_REFRESH`)

export const addNewChartShortcut = createAction(`${prefix}ADD_NEW_CHART_SHORTCUT`)

export const selectGoogleAnalyticsChart = createAction(`${prefix}SELECT_GOOGLE_ANALYTICS_CHART`)
export const selectGoogleAnalyticsView = createAction(`${prefix}SELECT_GOOGLE_ANALYTICS_VIEW`)

export const selectMozChart = createAction(`${prefix}SELECT_MOZ_CHART`)
export const selectMozTargets = createAction(`${prefix}SELECT_MOZ_TARGETS`)

export const selectLinkedInAnalyticsChart = createAction(`${prefix}SELECT_LINKED_IN_ANALYTICS_CHART`)
export const selectLinkedInAnalyticsOrganization = createAction(`${prefix}SELECT_LINKED_IN_ANALYTICS_ORGANIZATION`)

export const selectFacebookAnalyticsChart = createAction(`${prefix}SELECT_FACEBOOK_ANALYTICS_CHART`)
export const setFacebookAnalyticsChart = createAction(`${prefix}SET_FACEBOOK_ANALYTICS_CHART`)
export const selectFacebookAnalyticsPage = createAction(`${prefix}SELECT_FACEBOOK_ANALYTICS_PAGE`)
export const changeFacebookAnalyticsPage = createAction(`${prefix}CHANGE_FACEBOOK_ANALYTICS_PAGE`)

export const selectTwitterAnalyticsChart = createAction(`${prefix}SELECT_TWITTER_ANALYTICS_CHART`)

export const setSelectedChartUploadedPhoto = createAction(`${prefix}SET_SELECTED_CHART_UPLOADED_PHOTO`)

export const setSelectedChartMultipleSavedSearchesMode = createAction(`${prefix}SET_SELECTED_CHART_MULTIPLE_SAVED_SEARCHES_MODE`)
