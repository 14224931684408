import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { red } from '@mui/material/colors'

import { IconButton } from 'containers/themed'

const CampaignsDelete = ({
  deleteDisabled,
  deleteData,
  onOpen,
  setDeleteData
}) => {
  const i18n = useI18n()

  const handleOpen = e => {
    e.stopPropagation()
    setDeleteData(deleteData)
    onOpen()
  }

  return (
    <IconButton
      size="small"
      sx={{ color: red[400] }}
      onClick={handleOpen}
      title={i18n.get('delete')}
      disabled={deleteDisabled}
    >
      <DeleteOutlineIcon />
    </IconButton>
  )
}

CampaignsDelete.propTypes = {
  deleteDisabled: PropTypes.bool.isRequired,
  deleteData: PropTypes.object.isRequired,

  onOpen: PropTypes.func.isRequired,
  setDeleteData: PropTypes.func.isRequired
}

export default CampaignsDelete
