import * as GA from 'utils/ga'
import { toggleLoading } from 'actions/app'
import { logException } from 'utils/sentry'

export const trackExceptions = _store => next => action => {
  try {
    return next(action)
  } catch (error) {
    if (!error.response || (error.response && error.response.statusCode !== 401)) {
      logException(error)
    }

    throw error
  }
}

export const trackActions = _store => next => action => {
  try {
    let category = 'AUTO_TRACKING'
    let act = action.type
    const parts = action.type.split('/')

    if (parts[0] !== 'GA') {
      if (parts.length > 1) {
        category = `${category}/${parts.slice(0, -1).join('/')}`
        act = parts[parts.length - 1]
      }

      GA.event({
        category,
        action: act,
        label: (typeof action.payload === 'string' ? action.payload : undefined)
      })
    }
  } catch (error) { // eslint-disable-line no-empty
  }

  return next(action)
}

export const loadingIndicator = store => next => action => {
  if (action.meta && action.meta.indicator !== undefined) {
    store.dispatch(toggleLoading(action.meta.indicator))
  }

  return next(action)
}
