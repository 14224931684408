import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import { Button } from 'containers/themed'

export default function ContentsEditBlockDialog({ lockDate, lockedBy, open, onClose }) {
  const i18n = useI18n()
  const handleClose = () => onClose()

  if (!lockDate || !lockedBy) {
    return null
  }

  const name = `${lockedBy.get('firstName')} ${lockedBy.get('lastName')}`.trim()

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        {i18n.get('editing_forbidden')}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {i18n.get('content_being_edited_by', { name })}
          <br />
          <small>{i18n.get('is_locked_since', { date: lockDate })}</small>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          {i18n.get('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContentsEditBlockDialog.propTypes = {
  lockDate: PropTypes.string,
  lockedBy: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
