import {
  put,
  takeEvery,
  all,
  call,
  select
} from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as Actions from 'actions/email_templates_library'
import * as CdActions from 'actions/content_desk'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'

export function* saveTemplateStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const formData = yield select(Selectors.getETLibraryTemplateFormData)
  const emailEditorFormData = yield select(Selectors.getCDeskContentFormData)
  const templateId = formData.get('id')

  let requestBody

  if (payload) {
    requestBody = {
      name: formData.get('name'),
      json: emailEditorFormData.get('editorDesign'),
      html: emailEditorFormData.get('body'),
      shared: formData.get('shared'),
      released: formData.get('released')
    }
  } else {
    requestBody = {
      id: templateId,
      name: formData.get('name'),
      json: formData.get('json'),
      html: formData.get('html'),
      shared: formData.get('shared'),
      released: formData.get('released')
    }
  }

  try {
    let result
    let text

    if (templateId) {
      result = yield call(Api.updateEmailTemplate, templateId, requestBody)
      text = `${i18n.get('template')} ${i18n.get('updated')}`
    } else {
      result = yield call(Api.createEmailTemplate, requestBody)
      text = `${i18n.get('template')} ${i18n.get('created')}`
    }

    if (payload) {
      yield put(Actions.saveTemplateSuccess())
      yield put(CdActions.closeSaveTemplateDialog())
    } else {
      yield put(Actions.saveTemplateSuccess(result))
      yield put(Actions.closeEditorDialog())
      yield put(Actions.closeSaveDialog())
    }

    yield put(
      AppActions.showAppMessage({
        text,
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.saveTemplateError())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* deleteTemplateStart({ payload: { id } }) {
  const i18n = yield select(Selectors.getI18n)
  try {
    yield call(Api.deleteEmailTemplate, id)
    yield put(Actions.deleteTemplateSuccess(id))
    yield put(
      AppActions.showAppMessage({
        text: `${i18n.get('template')} ${i18n.get('deleted')}`,
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.deleteTemplateError())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* cloneTemplateStart({ payload }) {
  const { id } = payload
  const i18n = yield select(Selectors.getI18n)
  const templates = yield select(Selectors.getETLibraryTemplates)
  const template = templates.get('items').find(temp => temp.get('id') === id)

  const requestBody = {
    name: `${template.get('name')} ${i18n.get('duplicate')}`,
    json: template.get('json'),
    html: template.get('html'),
    shared: false,
    released: false
  }

  try {
    const result = yield call(Api.createEmailTemplate, requestBody)

    if (template.get('shared')) {
      yield put(Actions.togglePublicTemplates())
    } else {
      yield put(Actions.saveTemplateSuccess(result))
    }

    yield put(
      AppActions.showAppMessage({
        text: `${i18n.get('template')} ${i18n.get('duplicated')}`,
        success: true
      })
    )
  } catch (error) {
    yield put(Actions.saveTemplateError())
    yield put(
      AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      })
    )
  }
}

export function* watchSaveTemplateStart() {
  yield takeEvery(Actions.saveTemplateStart, saveTemplateStart)
}

export function* watchDeleteTemplateStart() {
  yield takeEvery(Actions.deleteTemplateStart, deleteTemplateStart)
}

export function* watchCloneTemplateStart() {
  yield takeEvery(Actions.cloneTemplateStart, cloneTemplateStart)
}

export default function* mainSaga() {
  yield all([
    watchSaveTemplateStart(),
    watchDeleteTemplateStart(),
    watchCloneTemplateStart()
  ])
}
