import { connect } from 'react-redux'

import { getCDeskCalendarEvents, getCDeskShowCalendarDialog, getCDeskRequestRunning, getCDeskCalendarDate } from 'selectors'
import {
  closeCalendarDialog,
  openNewContentDialog,
  openViewContentDialog,
  fetchCalendarEventsStart,
  openCampaignForm,
  setCalendarDate
} from 'actions/content_desk'

import CalendarDialog from 'components/content_desk/calendar_dialog'

const mapStateToProps = state => ({
  events: getCDeskCalendarEvents(state),
  open: getCDeskShowCalendarDialog(state),
  calendarDate: getCDeskCalendarDate(state),
  requestRunning: getCDeskRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeCalendarDialog,
    onContentClick: openViewContentDialog,
    onCampaignClick: openCampaignForm,
    onNewContentClick: openNewContentDialog,
    onNewCampaignClick: openCampaignForm,
    fetchContents: fetchCalendarEventsStart,
    setCalendarDate
  }
)(CalendarDialog)
