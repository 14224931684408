import {
  put,
  takeEvery,
  all
} from 'redux-saga/effects'

import * as Actions from 'actions/email_templates_library'

export function* fetchMoreTemplatesStart() {
  yield put(Actions.increaseTemplatesPage())
  yield put(Actions.fetchTemplatesStart())
}

export function* watchFetchMoreTemplatesStart() {
  yield takeEvery(Actions.fetchMoreTemplatesStart, fetchMoreTemplatesStart)
}

export default function* filterSaga() {
  yield all([
    watchFetchMoreTemplatesStart()
  ])
}
