import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Box, Grid, Typography, Paper, CircularProgress } from '@mui/material'

import AppBar from 'containers/content_desk/AppBar'
import NewButton from 'containers/content_desk/NewButton'
import ActiveFilterList from 'containers/content_desk/ActiveFilterList'
import Contents from 'containers/content_desk/Contents'
import CalendarWidget from 'containers/content_desk/CalendarWidget'
import Campaigns from 'containers/content_desk/Campaigns'
import CampaignForm from 'containers/content_desk/CampaignForm'
import CalendarDialog from 'containers/content_desk/CalendarDialog'
import ContentNewDialog from 'containers/content_desk/ContentNewDialog'
import ContentEditDialog from 'containers/content_desk/ContentEditDialog'
import ContentNotesDialog from 'containers/content_desk/ContentNotesDialog'
import ContentsEditBlockDialog from 'containers/content_desk/ContentsEditBlockDialog'
import ContentView from 'containers/content_desk/ContentView'
import CenterMessage from 'containers/CenterMessage'
import ContentsNoData from 'containers/content_desk/ContentsNoData'
import CampaignsNoData from 'containers/content_desk/CampaignsNoData'

const useStyles = makeStyles()({
  centerMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh'
  },
  content: {
    maxWidth: 800
  },
  link: {
    display: 'flex',
    alignItems: 'center',

    '& > span:first-of-type': {
      marginRight: 5
    }
  },
  circularProgress: {
    marginLeft: '1em',
    display: 'flex',
    alignItems: 'center'
  }
})

export default function ContentDesk({
  isDomainVerified,
  loading,
  hasCampaigns,
  hasContents,
  contents,
  onLoad,
  onUnload
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  if (!isDomainVerified) {
    return (
      <>
        <AppBar />
        <div className={classes.centerMessageContainer}>
          <CenterMessage
            contentClass={classes.content}
            message={i18n.get('account_not_set_up_message')}
          />
        </div>
      </>
    )
  }

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [contents])

  const renderLoader = () => (
    <div className={classes.circularProgress}>
      <CircularProgress
        size={40}
      />
    </div>
  )

  const renderContentSection = () => {
    if (loading) {
      return renderLoader()
    }

    if (!hasContents) {
      return <ContentsNoData />
    }

    return <Contents />
  }

  const renderCampaignSection = () => {
    if (loading) {
      return renderLoader()
    }

    if (!hasCampaigns) {
      return <CampaignsNoData />
    }

    return <Campaigns />
  }

  return (
    <Box
      px={8}
      py={4}
    >
      <AppBar />
      <ActiveFilterList />

      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
      >
        <NewButton />
      </Box>

      <CampaignForm />

      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme => (theme.isDark ? '#1E1E1E' : '#F0F0F0'),
          p: 4,
          mb: 4
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: theme => !theme.isDark && '#404040'
          }}
        >
          {i18n.get('contents')}
        </Typography>

        <Grid
          container
          spacing={4}
          mt={0}
        >
          <Grid
            item
            xs={12}
            lg={8}
            xl={9}
          >
            {renderContentSection()}
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
            xl={3}
          >
            {loading ? null : (<CalendarWidget />)}
          </Grid>
        </Grid>

      </Paper>

      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme => (theme.isDark ? '#1E1E1E' : '#F0F0F0'),
          p: 4,
          mb: 4
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: theme => !theme.isDark && '#404040'
          }}
        >
          {i18n.get('campaigns')}
        </Typography>

        <Grid
          container
          spacing={4}
          mt={0}
        >
          <Grid
            item
            xs={12}
          >
            {renderCampaignSection()}
          </Grid>
        </Grid>
      </Paper>

      <ContentNewDialog />
      <ContentEditDialog />
      <ContentNotesDialog />
      <CalendarDialog />
      <ContentsEditBlockDialog />
      <ContentView />
    </Box>
  )
}

ContentDesk.propTypes = {
  isDomainVerified: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hasContents: PropTypes.bool.isRequired,
  hasCampaigns: PropTypes.bool.isRequired,
  contents: PropTypes.instanceOf(List),

  onLoad: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired
}
