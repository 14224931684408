import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import PersonIcon from '@mui/icons-material/Person'
import SaveIcon from '@mui/icons-material/Save'
import { makeStyles } from 'tss-react/mui'

import { Button, Input } from 'containers/themed'
import { guessLocale } from 'utils/locale'
import PhotoUpload from 'components/photo_upload'

import { CardContent, Typography, Avatar } from '@mui/material'
import { languageOptions } from 'static/constants'

const useStyles = makeStyles()({
  flags: {
    display: 'flex'
  },
  flag: {
    cursor: 'pointer',
    fontSize: 50,
    marginRight: 10,
    opacity: 0.5
  },
  active: {
    opacity: 1
  },
  photo: {
    display: 'flex',
    marginBottom: 10
  }
})

const locale = guessLocale()

export default function BasicSettings({
  i18n,
  user,
  previewUrl,
  uploadedPhoto,
  onSave,
  onChange,
  onPreviewUrlChange,
  onImageUpload
}) {
  const { classes } = useStyles()

  const handlePreviewUrlChange = url => onPreviewUrlChange(url)
  const handleUploadedPhotoChange = photo => onImageUpload(photo)
  const handleChange = (name, value) => onChange(user.set(name, value).toJS())
  const handleSaveClick = () => onSave({ ...user.toJS(), uploadedPhoto })

  const handleLanguageChange = language => {
    onChange(user.merge(language).toJS())

    onSave({ ...user.merge(language).toJS(), uploadedPhoto })
  }

  const renderFlag = language => {
    let className = ''

    if (language.languageId === user.get('languageId')) {
      className = classes.active
    }

    const countryCode = language.localeMapping[locale] || language.countryCode

    return (
      <div
        key={language.languageId}
        className={`fi fi-${countryCode} ${classes.flag} ${className}`}
        onClick={() => handleLanguageChange(language)}
      />
    )
  }

  return (
    <CardContent>
      <Typography
        variant="h5"
        gutterBottom
      >
        {i18n.get('basic_information')}
      </Typography>

      <div className={classes.photo}>
        <Avatar src={previewUrl || user.get('photo')}>
          <PersonIcon />
        </Avatar>

        <PhotoUpload
          buttonProps={{
            variant: 'text',
            color: 'default'
          }}
          photoUrl={previewUrl}
          onFileChange={handleUploadedPhotoChange}
          onFileUrlChange={handlePreviewUrlChange}
        />
      </div>

      <Input
        label={i18n.get('first_name')}
        value={user.get('firstName') || ''}
        onChange={({ target: { value } }) => handleChange('firstName', value)}
      />

      <br />
      <br />

      <Input
        label={i18n.get('last_name')}
        value={user.get('lastName') || ''}
        onChange={({ target: { value } }) => handleChange('lastName', value)}
      />

      <br />
      <br />

      <Typography
        variant="h5"
        gutterBottom
      >
        {i18n.get('language')}
      </Typography>

      <div className={classes.flags}>
        {languageOptions.map(l => renderFlag(l))}
      </div>

      <br />

      <Button
        color="primary"
        variant="contained"
        onClick={() => handleSaveClick()}
        saving={user.get('updating')}
        startIcon={<SaveIcon />}
      >
        {i18n.get('save')}
      </Button>
    </CardContent>
  )
}

BasicSettings.propTypes = {
  i18n: PropTypes.object.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  previewUrl: PropTypes.string.isRequired,
  uploadedPhoto: PropTypes.object,

  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPreviewUrlChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired
}
