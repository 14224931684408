import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Map, List } from 'immutable'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'

import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
  FormControl
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import CloseConfirmDialog from 'components/content_desk/close_confirm_dialog'

import { InlineDatePicker, Autocomplete, Button, Select } from 'containers/themed'

import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()(theme => ({
  briefing: {
    marginTop: '2em',
    marginBottom: '2em'
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 15
  },
  form: {
    padding: '30px',
    minWidth: '850px'
  },
  textInput: {
    marginBottom: '2em'
  },
  title: {
    fontSize: '1.5rem',
    marginTop: '0.5em',
    marginLeft: '0.5em',
    color: !theme.isDark && '#404040'
  },
  assignSelect: {
    marginBottom: '2em'
  },
  datePicker: {
    marginBottom: '2em',
    marginTop: '2em'
  },
  button: {
    width: '120px'
  },
  select: {
    width: '265px'
  }
}))

const CampaignForm = ({ data, onUpdate, open, isInEditMode, assignees, onClose, onSave, resetForm }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [formHasChanged, setFormHasChanged] = useState(false)

  const statusOptions = [
    { value: true, label: i18n.get('active') },
    { value: false, label: i18n.get('closed') }
  ]

  const progressOptions = [
    { value: 0, label: '0%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' }
  ]

  const handleChange = e => {
    setFormHasChanged(true)
    const { name, value } = e.target
    onUpdate({ [name]: value })
  }

  const handleAutocompleteChange = (name, el) => {
    setFormHasChanged(true)

    if (!el) {
      onUpdate({ [name]: null })
    } else {
      const { value } = el
      onUpdate({ [name]: value })
    }
  }

  const handleDateChange = (name, value) => {
    setFormHasChanged(true)
    let newValue = null

    if (value) {
      // not proud of this, but otherwise campaigns someone created in Sofia will be shifted one day for Berlin
      newValue = value.add(12, 'hours')
    }

    onUpdate({ [name]: newValue })
  }

  const handleStatusChange = option => {
    setFormHasChanged(true)
    onUpdate({ active: option.value })
  }

  const handleProgressChange = option => {
    setFormHasChanged(true)
    onUpdate({ progress: option.value })
  }

  const [confDialogOpen, setConfDialogOpen] = useState(false)

  const onExit = () => {
    onClose()
    closeSnackbar()
    setFormHasChanged(false)
    resetForm()
  }

  const handleClose = () => {
    if (formHasChanged) {
      setConfDialogOpen(true)
    } else {
      closeSnackbar()
      onClose()
      setFormHasChanged(false)
      resetForm()
    }
  }

  const handleSave = () => {
    if (!data.get('name')) {
      return enqueueSnackbar(
        `${i18n.get('name')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    onSave()
    onClose()
    closeSnackbar()
    setFormHasChanged(false)

    return resetForm()
  }

  const handleKeyUp = event => {
    if (event.keyCode === 13) {
      handleSave()
    }
  }

  const userOptions = mapToOptions(assignees)

  return (
    <div>
      <CloseConfirmDialog
        open={confDialogOpen}
        onBack={() => setConfDialogOpen(false)}
        onClose={onExit}
      />
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography className={classes.title}>
            {`${isInEditMode ? i18n.get('edit') : i18n.get('new')} ${i18n.get('campaign')}`}
          </Typography>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent />
        <FormControl
          className={classes.form}
          onKeyUp={handleKeyUp}
        >
          <TextField
            required
            label={i18n.get('campaign_name')}
            className={classes.textInput}
            value={data.get('name')}
            name="name"
            onChange={handleChange}
          />
          <Autocomplete
            key="assignedUserId"
            variant="outlined"
            isClearable
            onChange={el => handleAutocompleteChange('assignedUserId', el)}
            options={mapToOptions(assignees)}
            value={userOptions.find(u => u.value === data.get('assignedUserId'))}
            placeholder=""
            label={i18n.get('assigned_user')}
          />
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <InlineDatePicker
                label={i18n.get('start_date')}
                value={data.get('startDate') ? moment(data.get('startDate')) : null}
                className={classes.datePicker}
                onChange={value => handleDateChange('startDate', value)}
                inputFormat="DD/MM/YYYY"
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item>
              <InlineDatePicker
                label={i18n.get('end_date')}
                value={data.get('endDate') ? moment(data.get('endDate')) : null}
                className={classes.datePicker}
                onChange={value => handleDateChange('endDate', value)}
                inputFormat="DD/MM/YYYY"
                minDate={moment(data.get('startDate'))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <div className={classes.select}>
                <Select
                  variant="outlined"
                  onChange={handleStatusChange}
                  options={statusOptions}
                  value={(statusOptions.find(o => o.value === data.get('active')) || {}).value}
                  label={i18n.get('status')}
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.select}>
                <Select
                  variant="outlined"
                  onChange={handleProgressChange}
                  options={progressOptions}
                  value={(progressOptions.find(o => o.value === data.get('progress')) || {}).value}
                  label={i18n.get('progress')}
                />
              </div>
            </Grid>
          </Grid>
          <TextField
            className={classes.briefing}
            label={i18n.get('briefing')}
            multiline
            rows={10}
            value={data.get('summary')}
            name="summary"
            onChange={handleChange}
          />
        </FormControl>
        <DialogActions>
          <Button
            key="cancel"
            className={classes.button}
            onClick={handleClose}
          >
            {i18n.get('cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={handleSave}
          >
            {i18n.get('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

CampaignForm.defaultProps = {
  assignees: List()
}

CampaignForm.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,
  isInEditMode: PropTypes.bool.isRequired,
  assignees: PropTypes.instanceOf(List),

  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired
}

export default CampaignForm
