import { connect } from 'react-redux'

import EmailTemplatesLibrary from 'components/email_templates_library'

import {
  fetchTemplatesStart as onMount,
  resetTemplatesModule as onUnmount
} from 'actions/email_templates_library'

import { getCDeskConfig } from 'selectors'

const mapStateToProps = state => ({
  isDomainVerified: getCDeskConfig(state).get('isDomainVerified') || false
})

export default connect(
  mapStateToProps,
  {
    onMount,
    onUnmount
  }
)(EmailTemplatesLibrary)
