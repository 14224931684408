import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Typography,
  Badge,
  Avatar,
  CircularProgress,
  Tooltip
} from '@mui/material'

import {
  Place as PlaceIcon,
  Insights as InsightsIcon,
  Add as AddIcon,
  Check as CheckIcon,
  ReadMore as ReadMoreIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import { IconButton, Button } from 'containers/themed'
import BlurredSocialHandles from 'components/anewstip/results/blurred_social_handles'

const useStyles = makeStyles()(theme => ({
  journalistContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  journalistInfoWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15px'
  },
  journalistAddress: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
  },
  journalistActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  statsLabel: {
    background: '#e0e0e0',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '0px 5px 0px 5px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  twitter: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  journalistNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  drillDownButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  avatarLoaderWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  breakPoint: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  influenceScoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  }
}))

const Journalist = ({
  journalist,
  importResult,
  requestRunning,
  onAddClick,
  onAddToDistributionListClick,
  drillDownEntity,
  drillDown,
  isLimitedFunctionality,
  isDistListsLocked,
  openDrillDownDialog,
  setDrillDownEntity,
  onDrillDownClick,
  setDrillDownFiltersForm
}) => {
  const entity = 'journalist'
  const { classes } = useStyles()
  const i18n = useI18n()

  const avatar = journalist.get('avatar') || ''
  const [avatarLoaded, setAvatarLoaded] = useState(avatar === '')

  const id = journalist.get('id')
  const name = journalist.get('name')
  const title = journalist.get('title')
  const city = journalist.get('city')
  const state = journalist.get('state')
  const country = journalist.get('country')
  const topics = journalist.get('topic')
  const influenceScore = journalist.get('influenceScore')
  const outlet = journalist.get('outlet')
  const languages = journalist.get('lang')
  const identityId = journalist.get('identityId')
  const personId = journalist.get('personId')
  const topicIds = journalist.get('topicIds')
  const contactId = journalist.get('contactId')

  const isImported = journalist.get('alreadyImported')
    || (importResult || List()).map(el => el.getIn(['contactImported', 'externalId'])).includes(journalist.get('id'))

  const handleOnAvatarLoad = () => {
    setAvatarLoaded(true)
  }

  const handleOnAvatarError = () => {
    setAvatarLoaded(true)
  }

  const handleDrillDown = () => {
    setDrillDownEntity(entity)
    setDrillDownFiltersForm({ key: 'drillDownEntityId', value: id })
    onDrillDownClick({ entity, topics, name, personId, identityId, topicIds, contactId })
    openDrillDownDialog({ entity })
  }

  const stringAvatar = () => {
    const splitName = name.split(' ')

    return {
      children: `${splitName[0][0]}${splitName[1] !== undefined ? splitName[1][0] : ''}`
    }
  }

  const addToDistListButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={isImported || isDistListsLocked}
      loading={requestRunning}
      onClick={() => onAddToDistributionListClick({
        id: journalist.get('id')
      })}
    >
      {i18n.get('add_to_distribution_list')}
    </Button>
  )

  return (
    <>
      <div className={classes.journalistContainer}>
        <div className={classes.avatarContainer}>
          {!avatarLoaded && avatar
            ? (
              <div className={classes.avatarLoaderWrapper}>
                <CircularProgress
                  size={25}
                  variant="indeterminate"
                />
              </div>

            ) : null}
          <Badge
            sx={{ display: avatarLoaded ? 'flex' : 'none' }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={(
              <Avatar
                sx={{ width: 30, height: 30, fontSize: 15 }}
                {...stringAvatar()}
              />
            )}
          >
            <Avatar
              sx={{ width: 100, height: 100 }}
              alt={name}
              src={avatar}
              onLoad={handleOnAvatarLoad}
              onError={handleOnAvatarError}
            />
          </Badge>
        </div>
        <div className={classes.journalistInfoWrapper}>
          <div className={classes.journalistNameWrapper}>
            <Typography variant="h6">{name}</Typography>
            {(!drillDown || (drillDown && drillDownEntity === 'journalist')) && (
              <Tooltip
                placement="right-start"
                arrow
                title={i18n.get('related_information')}
              >
                <IconButton
                  disabled={isLimitedFunctionality}
                  size="small"
                  className={classes.drillDownButton}
                  onClick={handleDrillDown}
                >
                  <ReadMoreIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Typography>{title}</Typography>
          <BlurredSocialHandles />
          {city !== '' || state !== '' || country !== ''
            ? (
              <Typography
                className={classes.journalistAddress}
                mt={2}
              >
                <PlaceIcon fontSize="small" />
                {city !== '' ? `${city}, ` : null}
                {state !== '' ? `${state}, ` : null}
                {country}
              </Typography>
            ) : null}
          {outlet && <Typography mt={1}>{i18n.get('outlet')}: {outlet}</Typography>}
          <Typography>
            {topics.size > 0
              ? `${topics.size > 1 ? i18n.get('topics')
                : i18n.get('topic')}: ${topics.map(t => t).join(', ')}` : null}
          </Typography>
          <Typography>
            {languages.size > 0
              ? `${languages.size > 1 ? i18n.get('languages')
                : i18n.get('language')}: ${languages.map(l => l).join(', ')}` : null}
          </Typography>
        </div>
        <div className={classes.journalistActions}>
          <Button
            variant="contained"
            color="primary"
            disabled={isImported}
            loading={requestRunning}
            onClick={() => onAddClick({ id: journalist.get('id') })}
          >
            {isImported ? <CheckIcon fontSize="small" /> : <AddIcon fontSize="small" />}
            {isImported ? i18n.get('contact_added') : i18n.get('add_to_contacts')}
          </Button>
          {isDistListsLocked ? (
            <Tooltip
              title={i18n.get('distribution_lists_limit_reached')}
              placement="left-start"
              arrow
            >
              <span>{addToDistListButton}</span>
            </Tooltip>
          ) : addToDistListButton}
          <div className={classes.influenceScoreWrapper}>
            <Typography className={classes.statsLabel}>
              <InsightsIcon fontSize="medium" /> {i18n.get('influence_score').toUpperCase()} - {influenceScore || 0}
            </Typography>
            <Tooltip
              title={i18n.get('anewstip_influence_score_info')}
              placement="bottom-end"
              arrow
            >
              <InfoIcon color="primary" />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  )
}

Journalist.defaultProps = {
  drillDown: false,
  isLimitedFunctionality: true
}

Journalist.propTypes = {
  journalist: PropTypes.instanceOf(Map).isRequired,
  importResult: PropTypes.instanceOf(List).isRequired,
  requestRunning: PropTypes.bool.isRequired,
  isLimitedFunctionality: PropTypes.bool,
  isDistListsLocked: PropTypes.bool.isRequired,
  drillDownEntity: PropTypes.string,
  drillDown: PropTypes.bool,

  onAddClick: PropTypes.func.isRequired,
  onAddToDistributionListClick: PropTypes.func.isRequired,
  openDrillDownDialog: PropTypes.func.isRequired,
  setDrillDownEntity: PropTypes.func.isRequired,
  onDrillDownClick: PropTypes.func.isRequired,
  setDrillDownFiltersForm: PropTypes.func.isRequired
}

export default Journalist
