import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import selectedNews from './selected_news'
import sorting from './sorting'
import cluster from './cluster'
import aiAnalysis from './ai_analysis'

export default combineReducers({
  aiAnalysis,
  ui,
  data,
  selectedNews,
  sorting,
  cluster
})
