import { connect } from 'react-redux'

import Contents from 'components/content_desk/contents'

import {
  getCDeskContents,
  getCDeskContentSearchQueries,
  getCDeskCampaignSearchQueries,
  getCDeskSearchContentsRunning
} from 'selectors'

const mapStateToProps = state => ({
  contents: getCDeskContents(state).get('items').take(3),
  count: getCDeskContents(state).get('items').size,
  hasSearchQueries: (getCDeskContentSearchQueries(state).size > 0 || getCDeskCampaignSearchQueries(state).size > 0),
  loading: getCDeskSearchContentsRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(Contents)
