import React from 'react'
import { List } from 'immutable'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'

import TemplatesListItem from 'containers/email_templates_library/TemplatesListItem'

import { StaggeredListItem, StaggeredList } from 'components/staggered_list'

const useStyles = makeStyles()({
  listItemWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 240px)',
    gridAutoRows: '300px',
    justifyContent: 'center',
    gap: '30px',
    margin: 'auto'
  },
  listItem: {
    height: '300px',
    width: '240px'
  }
})

const TemplatesList = ({ loadTemplate, templates, readMode }) => {
  const { classes } = useStyles({ readMode })

  const filteredTemplates = readMode ? templates.filter(el => el.get('released') !== false) : templates

  return (
    <StaggeredList>
      <div
        className={classes.listItemWrapper}
      >
        {filteredTemplates.map((el, index) => (
          <StaggeredListItem
            className={classes.listItem}
            delay={index * 50}
            key={index}
          >
            <TemplatesListItem
              loadTemplate={loadTemplate}
              template={el}
              key={index}
            />
          </StaggeredListItem>
        ))}
      </div>
    </StaggeredList>
  )
}

TemplatesList.propTypes = {
  loadTemplate: PropTypes.func,
  templates: PropTypes.instanceOf(List).isRequired,
  readMode: PropTypes.bool.isRequired
}

export default TemplatesList
