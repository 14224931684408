import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { Dialog, Button } from 'containers/themed'
import { WithWhitelabelMuiTheme } from 'containers/WithMuiTheme'

import useI18n from 'hooks/useI18n'

import { Typography, Radio, RadioGroup, FormControlLabel, Switch, Link } from '@mui/material'

const useStyles = makeStyles()({
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

const AcceptCookies = ({ onAccept }) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const [showDetailledInfo, setShowDetailledInfo] = useState(false)
  const [cookieOption, setCookieOption] = useState('accept_all')
  const [allowGoogleCookies, setAllowGoogleCookies] = useState(true)
  const [allowSentryCookies, setAllowSentryCookies] = useState(true)

  const handleSave = () => {
    let currentOption

    if (allowGoogleCookies && allowSentryCookies) {
      currentOption = 'accept_all'
    }

    if (allowGoogleCookies && !allowSentryCookies) {
      currentOption = 'only_google'
    }

    if (!allowGoogleCookies && allowSentryCookies) {
      currentOption = 'only_sentry'
    }

    if (!allowGoogleCookies && !allowSentryCookies) {
      currentOption = 'only_necessary'
    }

    onAccept({ cookieOption: currentOption })
  }

  const handleSetCookieOption = option => {
    if (option === 'accept_all') {
      setAllowGoogleCookies(true)
      setAllowSentryCookies(true)
    }

    if (option === 'only_necessary') {
      setAllowGoogleCookies(false)
      setAllowSentryCookies(false)
    }

    setCookieOption(option)
  }

  const renderMainInfo = () => {
    if (showDetailledInfo) {
      return (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDetailledInfo(false)}
          >
            {i18n.get('back_title')}
          </Button>
          <Typography variant="h5">
            {i18n.get('details_and_settings')}
          </Typography>

          <br />

          <Typography
            variant="h6"
            className={classes.switchContainer}
          >
            {i18n.get('technically_necessary_cookies')}
            <FormControlLabel
              control={(
                <Switch
                  color="secondary"
                  disabled
                  checked
                />
              )}
            />
          </Typography>

          <Typography
            dangerouslySetInnerHTML={{
              __html: i18n.get('dsgvo_3')
            }}
          />

          <br />

          <Typography
            variant="h6"
            className={classes.switchContainer}
          >
            {i18n.get('google_cookies')}
            <FormControlLabel
              control={(
                <Switch
                  color="secondary"
                  checked={allowGoogleCookies}
                  onChange={e => setAllowGoogleCookies(e.target.checked)}
                />
              )}
            />
          </Typography>

          <Typography
            dangerouslySetInnerHTML={{
              __html: i18n.get('google_cookies_description')
            }}
          />

          <br />

          <Typography
            variant="h6"
            className={classes.switchContainer}
          >
            {i18n.get('sentry_cookies')}
            <FormControlLabel
              control={(
                <Switch
                  color="secondary"
                  checked={allowSentryCookies}
                  onChange={e => setAllowSentryCookies(e.target.checked)}
                />
              )}
            />
          </Typography>

          <Typography
            dangerouslySetInnerHTML={{
              __html: i18n.get('sentry_cookies_description')
            }}
          />

          <Link
            href="https://sentry.io/privacy/"
            target="_blank"
          >
            https://sentry.io/privacy/
          </Link>
        </>
      )
    }

    return (
      <>
        <Typography>
          {i18n.get('dsgvo_2')}
        </Typography>

        <RadioGroup
          name="cookie_options"
          value={cookieOption}
          onChange={({ target }) => handleSetCookieOption(target.value)}
        >
          <FormControlLabel
            label={i18n.get('accept_all')}
            value="accept_all"
            control={<Radio />}
          />
          <FormControlLabel
            label={i18n.get('only_necessary_cookies')}
            value="only_necessary"
            control={<Radio />}
          />
        </RadioGroup>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowDetailledInfo(true)}
        >
          {i18n.get('details_and_settings')}
        </Button>
      </>
    )
  }

  return (
    <Dialog
      open
      maxWidth="md"
      title={i18n.get('dsgvo_1')}
      actions={[(
        <Button
          key="accept"
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
        >
          {i18n.get('save')}
        </Button>
      )]}
    >
      {renderMainInfo()}
    </Dialog>
  )
}

AcceptCookies.propTypes = {
  onAccept: PropTypes.func.isRequired
}

export default WithWhitelabelMuiTheme(AcceptCookies)
