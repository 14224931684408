import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress
} from '@mui/material'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  deleteButton: {
    width: 100
  },
  progress: {
    color: 'white'
  }
})

const DeleteDialog = ({
  entity,
  requestRunning,
  open,
  deleteData,
  deleteContent,
  deleteCampaign,
  onClose
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleDelete = () => {
    if (entity === 'content') {
      deleteContent(deleteData)
    }

    if (entity === 'campaign') {
      deleteCampaign(deleteData)
    }
  }

  return (
    <Dialog
      open={open === entity}
      onClose={onClose}
    >
      <DialogTitle>
        {entity === 'content' && i18n.get('delete_content')}
        {entity === 'campaign' && i18n.get('delete_campaign')}
      </DialogTitle>
      <DialogActions>
        <Button
          key="cancel"
          onClick={onClose}
          disabled={requestRunning}
        >
          {i18n.get('cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleDelete}
          disabled={requestRunning}
          className={classes.deleteButton}
        >
          {requestRunning ? (
            <CircularProgress
              className={classes.progress}
              size={25}
            />
          ) : i18n.get('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteDialog.propTypes = {
  entity: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  open: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  deleteData: PropTypes.object.isRequired,

  deleteContent: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default DeleteDialog
