import { connect } from 'react-redux'

import ContentsNoData from 'components/content_desk/contents/contents_no_data/ContentsNoData'

import { openNewContentDialog } from 'actions/content_desk'

const mapStateToProps = _state => ({

})

export default connect(
  mapStateToProps,
  {
    onClick: openNewContentDialog
  }
)(ContentsNoData)
