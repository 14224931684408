import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Dialog, RaisedPrimaryButton, Button, Input } from 'containers/themed'

import { DialogActions, Typography } from '@mui/material'
import { validEmail } from 'utils/regex'

const useStyles = makeStyles()({
  input: {
    marginTop: 15
  }
})

const ContentTestMailDialog = ({
  sender,
  isOpen,
  requestRunning,
  onClose,
  onDispatch
}) => {
  const [recipient, setRecipient] = useState('')

  useEffect(() => {
    if (sender) {
      setRecipient(sender)
    }
  }, [sender])

  const { classes } = useStyles()
  const i18n = useI18n()

  const validateEmail = () => (
    recipient.split(/,\s*|;\s*/).reduce((acc, e) => acc && validEmail(e), true) && recipient.split(/,|;/).length < 6
  )

  const handleDispatch = () => {
    onDispatch({ test: true, recipient })
    onClose()
  }

  const handleChange = value => {
    setRecipient(value)
  }

  return (
    <Dialog
      maxWidth="sm"
      title={i18n.get('test_email')}
      onClose={() => onClose()}
      open={isOpen}
    >
      <Typography>{i18n.get('choose_recipient_test_mail')}</Typography>
      <Input
        type="email"
        label={i18n.get('email_address')}
        error={!validateEmail(recipient)}
        value={recipient}
        onChange={event => handleChange(event.target.value)}
        disabled={requestRunning}
        className={classes.input}
      />
      <DialogActions>
        <Button onClick={() => onClose()}>
          {i18n.get('close')}
        </Button>
        <RaisedPrimaryButton
          onClick={handleDispatch}
          disabled={requestRunning || !validateEmail(recipient)}
        >
          {i18n.get('dispatch')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

ContentTestMailDialog.propTypes = {
  sender: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDispatch: PropTypes.func.isRequired
}

export default ContentTestMailDialog
