import { call, put, select, takeEvery, takeLatest, take, all } from 'redux-saga/effects'
import { fromJS } from 'immutable'

import { SearchFields } from 'static/constants'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { exception } from 'actions/app'
import { uiAddFilters } from 'actions/ui'
import { setNewsView, newsRequestSuccess } from 'actions/news'
import { retriable } from 'utils/sagas'

export function* fetchInfluencersTry() {
  const body = yield select(Selectors.getInfluencersSearchBody)

  const result = yield call(Api.searchInfluencers, body)

  yield put(Actions.influencersRequestSuccess(result))
}

export function* fetchInfluencersFail(error) {
  yield put(Actions.influencersRequestError(error))
  yield put(exception(error))
}

export function* fetchInfluencers() {
  yield call(retriable, fetchInfluencersTry, fetchInfluencersFail)
}

export function* fetchMoreInfluencersTry() {
  const from = yield select(Selectors.getInfluencersFrom)

  if (from) {
    const searchBody = yield select(Selectors.getInfluencersSearchBody)
    const body = { ...searchBody, from }
    const result = yield call(Api.searchInfluencers, body)
    yield put(Actions.moreInfluencersRequestSuccess(result))
  }
}

export function* fetchMoreInfluencersFail(error) {
  yield put(Actions.moreInfluencersRequestError(error))
  yield put(exception(error))
}

export function* fetchMoreInfluencers() {
  yield call(retriable, fetchMoreInfluencersTry, fetchMoreInfluencersFail)
}

export function* fetchInfluencerTry({ payload: id }) {
  const result = yield call(Api.fetchInfluencer, id)

  yield put(Actions.fetchInfluencerRequestSuccess(result))
}

export function* fetchInfluencerFail(error) {
  yield put(Actions.fetchInfluencerRequestError(error))
  yield put(exception(error))
}

export function* fetchInfluencer(action) {
  yield call(retriable, fetchInfluencerTry, fetchInfluencerFail, action)
}

export function* searchForInfluencer({ payload: { influencer, targetView, groupType = null } }) {
  const searchOptions = [{
    filter: influencer,
    field: SearchFields.INFLUENCERS
  }]

  if (groupType) {
    const groups = yield select(Selectors.getNewsGroups)
    searchOptions.push({
      filter: groups.find(gr => gr.get('name').toLowerCase() === groupType.toLowerCase()),
      field: SearchFields.CHANNELS
    })
  }

  yield put(uiAddFilters(searchOptions))
  yield take(newsRequestSuccess)
  yield put(setNewsView(targetView))
}

export function* searchForSelectedInfluencers({ payload: { targetView } }) {
  const selectedInfluencerIds = yield select(Selectors.getSelectedInfluencerIds)
  const allInfluencer = yield select(Selectors.getInfluencers)
  const selectedInfluencer = selectedInfluencerIds
    .map(id => allInfluencer.find(influencer => influencer.get('id') === id))
    .toJS()
    .map(filter => ({ filter: fromJS(filter), field: SearchFields.INFLUENCERS }))

  yield put(uiAddFilters(selectedInfluencer))
  yield take(newsRequestSuccess)
  yield put(setNewsView(targetView))
}

export function* setSortBy() {
  yield put(Actions.influencersRequestStart())
}

export function* showInfluencerDetails({ payload: influencer }) {
  yield put(Actions.fetchInfluencerRequestStart(influencer.get('id')))
}

export function* selectChannel() {
  const channelId = yield select(Selectors.getInfluencersDetailsSelectedChannelId)
  yield put(Actions.highlightReachChart(channelId))
}

export function* addInfluencerToContacts({ payload: identityId }) {
  const i18n = yield select(Selectors.getI18n)
  const contactData = yield select(Selectors.getAddInfluencerToContactsDialogContactData)
  const selectedEmail = contactData.get('selectedEmail')
  const influencer = yield select(Selectors.getAddInfluencerToContactsDialogInfluencer)
  const mail = influencer.getIn(['flags', 'contactManagementAddable']) ? influencer.getIn(['contacts', 'emails', selectedEmail]) : null
  const isNewsBoard = yield select(Selectors.isNewsBoard)

  const body = {
    identity_id: identityId,
    contact_data: {
      first_name: influencer.get('firstName'),
      last_name: influencer.get('lastName'),
      mail,
      source: isNewsBoard ? 'news_board' : 'search_pool'
    }
  }

  try {
    const result = yield call(Api.addInfluencerToContacts, influencer.get('id'), body)
    yield put(Actions.setImportContactsForDistributionLists(result))
    yield put(Actions.addInfluencerToContactsSuccess({ influencer, mail }))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('contact_added'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.addInfluencerToContactsError(error))
    yield put(exception(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* searchContacts({ payload: email }) {
  try {
    const body = {
      exact_filter: [
        { field: 'mail', value: email },
        { field: 'supplier', value: 'anewstip' },
        { field: 'supplier', value: 'procm' },
        { field: 'supplier', value: 'search_pool' },
        { field: 'supplier', value: 'news_board' }
      ]
    }
    const result = yield call(Api.searchContacts, body)
    yield put(Actions.searchContactsSuccess(result))
  } catch (error) {
    yield put(Actions.searchContactsError(error))
    yield put(exception(error))
  }
}

export function* watchFetchInfluencers() {
  yield takeLatest(Actions.influencersRequestStart, fetchInfluencers)
}

export function* watchFetchMoreInfluencers() {
  yield takeEvery(Actions.moreInfluencersRequestStart, fetchMoreInfluencers)
}

export function* watchFetchInfluencer() {
  yield takeLatest(Actions.fetchInfluencerRequestStart, fetchInfluencer)
}

export function* watchSetSortBy() {
  yield takeEvery(Actions.setSortBy, setSortBy)
}

export function* watchSearchForInfluencer() {
  yield takeLatest(Actions.searchForInfluencer, searchForInfluencer)
}

export function* watchSearchForSelectedInfluencers() {
  yield takeLatest(Actions.searchForSelectedInfluencers, searchForSelectedInfluencers)
}

export function* watchShowDetails() {
  yield takeEvery(Actions.showInfluencerDetails, showInfluencerDetails)
}

export function* watchSelectChannel() {
  yield takeEvery(Actions.selectChannel, selectChannel)
}

export function* watchAddInfluencerToContacts() {
  yield takeLatest(Actions.addInfluencerToContactsStart, addInfluencerToContacts)
}

export function* watchSearchContactsStart() {
  yield takeEvery(Actions.searchContactsStart, searchContacts)
}

export default function* saga() {
  yield all([
    watchFetchInfluencers(),
    watchFetchMoreInfluencers(),
    watchFetchInfluencer(),
    watchSetSortBy(),
    watchSearchForInfluencer(),
    watchSearchForSelectedInfluencers(),
    watchShowDetails(),
    watchAddInfluencerToContacts(),
    watchSelectChannel(),
    watchSearchContactsStart()
  ])
}
