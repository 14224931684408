import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Paper, Typography, Menu, MenuItem, Tooltip } from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'

import { orange } from '@mui/material/colors'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  templateItemPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  templateItemPaperClickable: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer'
    },
    alignItems: 'center'
  },
  moreOptionsIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%'
  },
  templateName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '65px'
  },
  htmlContainer: {
    pointerEvents: 'auto',
    marginTop: '10px',
    height: '200px',
    width: '200px',
    position: 'relative'
  },
  htmlPreview: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: 'none',
    transformOrigin: '0 0',
    pointerEvents: 'none'
  }
})

const TemplatesListItem = ({
  loadTemplate,
  template,
  activeNewsradarId,
  readMode,
  onDelete,
  openDialog,
  loadSelectedTemplate,
  closePickerDialog,
  setTemplatesReadModeOnly,
  onTemplateClone
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { classes } = useStyles()
  const i18n = useI18n()
  const isReleased = template.get('released')

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    setAnchorEl(null)
    openDialog()
    loadSelectedTemplate(template)
  }

  const handleClone = () => {
    setAnchorEl(null)
    onTemplateClone({ id: template.get('id') })
  }

  const handleDelete = () => {
    setAnchorEl(null)
    onDelete({ id: template.get('id') })
  }

  const handleLoadTemplate = () => {
    if (!readMode) {
      return
    }

    closePickerDialog()
    loadTemplate(template.get('json'))
    setTimeout(() => setTemplatesReadModeOnly(false), 500)
  }

  const htmlContent = `
    <style>
      html { 
        overflow: hidden; 
        user-select: none;
      }
    </style>
    <div style='width: 660px; transform: scale(0.3); transform-origin: 0 0;'>
      ${template.get('html')}
    </div>
  `

  return (
    <Paper
      className={readMode ? classes.templateItemPaperClickable : classes.templateItemPaper}
      elevation={1}
      onClick={handleLoadTemplate}
    >
      {!readMode && (
        <>
          <div className={classes.moreOptionsIcon}>
            {!isReleased
              && (
                <Tooltip
                  title={i18n.get('template_is_not_released')}
                  placement="top-end"
                  arrow
                >
                  <ReportGmailerrorredIcon sx={{ color: orange[500] }} />
                </Tooltip>
              )}
            <IconButton
              size="small"
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleEdit}
              disabled={activeNewsradarId !== template.get('newsradarId')}
            >
              {i18n.get('edit')}
            </MenuItem>
            <MenuItem
              onClick={handleClone}
            >
              {i18n.get('duplicate')}
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              disabled={activeNewsradarId !== template.get('newsradarId')}
            >
              {i18n.get('delete')}
            </MenuItem>
          </Menu>
        </>
      )}
      <div className={classes.htmlContainer}>
        <iframe
          title="template-preview"
          className={classes.htmlPreview}
          srcDoc={htmlContent}
        />
      </div>
      <Typography className={classes.templateName}>
        {template.get('name')}
      </Typography>
    </Paper>
  )
}

TemplatesListItem.propTypes = {
  template: PropTypes.object,
  loadTemplate: PropTypes.func,

  activeNewsradarId: PropTypes.number,
  readMode: PropTypes.bool.isRequired,

  onDelete: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  loadSelectedTemplate: PropTypes.func.isRequired,
  closePickerDialog: PropTypes.func.isRequired,
  setTemplatesReadModeOnly: PropTypes.func.isRequired,
  onTemplateClone: PropTypes.func.isRequired
}

export default TemplatesListItem
