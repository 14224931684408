import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'
import { Dialog, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'

import ContactTags from 'components/contact_management/contact_details_dialog/contact_tags'
import ContactChannels from 'components/contact_management/contact_details_dialog/contact_channels'
import ContactProfile from 'components/contact_management/contact_details_dialog/contact_profile'
import ContactInfo from 'components/contact_management/contact_details_dialog/contact_info'
// import ContactActions from 'components/contact_management/contact_details_dialog/contact_actions'
import ContactTopics from 'components/contact_management/contact_details_dialog/contact_topics'
import ContactLanguages from 'components/contact_management/contact_details_dialog/contact_languages'
import ContactMediaTypes from 'components/contact_management/contact_details_dialog//contact_media_types'

import { IconButton, PrimaryButton } from 'containers/themed'

export default function ContactDetailsDialog({
  open,
  contact,
  countries,
  states,
  topics,
  mediaTypes,
  languages,
  isDistListsLocked,
  onClose,
  onEdit,
  onSelect,
  onAdd
}) {
  const theme = useTheme()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const unsubscribed = contact.get('unsubscribed')

  const handleAdd = () => {
    onAdd()
    onSelect(contact)
  }

  const handleEdit = () => onEdit(contact)
  const handleClose = () => onClose()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        mx={1}
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        <PrimaryButton
          disabled={unsubscribed}
          onClick={handleEdit}
          startIcon={<EditIcon />}
          sx={{
            position: 'absolute',
            right: 60,
            top: 15
          }}
        >
          {i18n.get('edit_profile')}
        </PrimaryButton>
        {i18n.get('details')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', paddingLeft: t => t.spacing(3) }}

      >
        <ContactProfile
          contact={contact}
          countries={countries}
          states={states}
          isDistListsLocked={isDistListsLocked}
          onAdd={handleAdd}
        />
        <ContactChannels contact={contact} />
        <ContactInfo contact={contact} />
        <ContactTags contact={contact} />
        <ContactTopics
          contact={contact}
          mediaTopics={topics}
        />
        <ContactMediaTypes
          contact={contact}
          mediaTypes={mediaTypes}
        />
        <ContactLanguages
          contact={contact}
          languages={languages}
        />
        <Typography
          variant="body2"
          sx={{ float: 'right', color: t => t.palette.grey[700], marginTop: '2em' }}
        >
          {contact.get('updatedAt')
            ? `${i18n.get('last_updated_at')}: ${moment(contact.get('updatedAt')).format('lll')}`
            : `${i18n.get('created_at')}: ${moment(contact.get('createdAt')).format('lll')}`}
        </Typography>
      </DialogContent>

      {/* <DialogActions
        sx={{ paddingLeft: t => t.spacing(2) }}
        disableSpacing
      >
        <ContactActions />
      </DialogActions> */}
    </Dialog>
  )
}

ContactDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  states: PropTypes.instanceOf(ImmutableList).isRequired,
  topics: PropTypes.instanceOf(ImmutableList).isRequired,
  mediaTypes: PropTypes.instanceOf(ImmutableList).isRequired,
  languages: PropTypes.instanceOf(ImmutableList).isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
}
