/* global window */
import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { mergeDeepKeepLists } from 'utils/immutable'
import { parse } from 'query-string-es5'

import * as Actions from 'actions/whitelabel'

import masterConfig from 'static/master_config'
import imageLinks from 'static/image_links'

import bffConfig from '../../config/bff.json'

const { theme } = parse(window.location.hash.split('?')[1] || '')

export const initialState = fromJS({
  emailAddress: 'customerservice@pressrelations.de',
  logo: imageLinks.NRX_LOGO_TRANSPARENT,
  emailLogo: imageLinks.NRX_LOGO_COLOR,
  title: 'NRX - driven by data',
  favicon: '',
  hasMyNews: false,
  emotionals: null,
  theme: theme || 'nrx',
  progressLetters: ['N', 'R', 'X'],
  loginTypes: [
    'default',
    'azure'
  ],
  colors: {
    primary: masterConfig.getIn(['theme', 'nrx', 'colors', 'primary']),
    accent: masterConfig.getIn(['theme', 'nrx', 'colors', 'accent'])
  },
  sso: {
    azure: {
      clientId: '13b6988c-70ec-44a2-b981-810e4ba68a31',
      authority: 'https://login.microsoftonline.com/common'
    }
  },
  loginLinks: {
    termsAndConditions: null,
    legalNotice: null,
    privacy: null,
    homepage: {
      name: null,
      link: null
    }
  },
  bffUrl: bffConfig
})

export default handleActions({
  [Actions.whitelabelRequestSuccess]: (state, { payload }) => mergeDeepKeepLists(state, fromJS(payload))
}, initialState)
