import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Grid, Divider, Chip, ImageListItem, ImageList, ImageListItemBar } from '@mui/material'

import ContentPreview from 'containers/content_desk/ContentPreview'

import { formatBytes } from 'utils/number'
import { getMediaFileSrcAndFit } from 'utils/content_desk'
import { Document, Page } from 'utils/pdf'

const useStyles = makeStyles()(theme => ({
  label: {
    color: !theme.isDark && '#757575',
    fontSize: '11pt',
    fontWeight: 500
  },
  previewContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  divider: {
    margin: '0px 10px 0px 0px',
    height: '40px',
    borderRightWidth: '5px',
    borderColor: theme.isDark ? '#ffffff' : '#757575',
    borderRadius: '10px'
  },
  bottomActionsContainer: {
    marginTop: '2em',
    marginBottom: '2em',
    padding: '0em 2em 0em 2em',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  releaseButton: {
    marginLeft: '1em'
  },
  recipients: {
    marginTop: 5,
    marginRight: 10
  },
  previewContainer: {
    display: 'block',
    borderRadius: '10px',
    padding: '1em',
    width: '100%'
  },
  previewInnerContainer: {
    margin: 'auto'
  },
  previewLabel: {
    fontStyle: 'italic',
    color: !theme.isDark && '#757575',
    fontSize: '11pt',
    fontWeight: 500
  },
  recipientsContainer: {
    display: 'flex',
    maxWidth: 600,
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '20px'
  },
  mediaListBox: {
    height: 580
  }
}))

const VerticalDivider = () => {
  const { classes } = useStyles()

  return (
    <Divider
      orientation="vertical"
      flexItem
      className={classes.divider}
    />
  )
}

const DistributeForm = ({ data }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const preHeader = data.get('preHeader')
  const body = data.get('body') || ''

  const checkIfHasUnsubscribeLink = () => {
    const plainText = data.get('plainText') || ''

    return plainText.includes('%tag_unsubscribe_url%')
  }

  const renderFileImage = mf => {
    const { src, style } = getMediaFileSrcAndFit(mf)

    if (mf.get('mimeType') === 'application/pdf') {
      return (
        <Document
          file={mf.get('url')}
        >
          <Page
            pageNumber={1}
            scale={0.3}
            width={500}
          />
        </Document>
      )
    }

    return (
      <img
        src={src}
        srcSet={src}
        alt={mf.get('id')}
        loading="lazy"
        style={{
          ...style,
          maxWidth: '150px',
          maxHeight: '200px'
        }}
      />
    )
  }

  const renderMediaFiles = () => {
    if (data.getIn(['type', 'value']) !== 1) {
      return null
    }

    return (
      <>
        <div className={classes.label}>
          {`${i18n.get('attachments')}:`}
        </div>
        <div className={classes.previewContent}>
          {data.get('ccdMediaFiles').size ? (
            <ImageList
              cols={3}
            >
              {data.get('ccdMediaFiles').map(mf => (
                <ImageListItem
                  key={mf.get('id')}
                >
                  <ImageListItemBar
                    style={{ zIndex: 9999 }}
                    position="bottom"
                    title={mf.get('filename')}
                    subtitle={formatBytes(mf.get('size'))}
                  />
                  {renderFileImage(mf)}
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <>
              <VerticalDivider />
              -
            </>
          )}
        </div>
      </>
    )
  }

  const renderRecipients = () => {
    if (data.getIn(['type', 'value']) !== 1) {
      return null
    }

    const recipients = data.get('recipients').map(
      currentRecipient => (
        <div
          className={classes.recipients}
          key={`${currentRecipient.get('id')}_${currentRecipient.get('contactId')}`}
        >
          <Chip
            color="primary"
            variant="outlined"
            label={currentRecipient.get('mail')}
          />
        </div>
      )
    )

    return (
      <>
        <div className={classes.label}>
          {`${i18n.get('recipients')}:`}
        </div>
        <div className={classes.recipientsContainer}>
          {recipients.size ? recipients : (
            <>
              <VerticalDivider />
              -
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <Grid item>
            <div className={classes.label}>
              {`${i18n.get('title')}:`}
            </div>
            <div className={classes.previewContent}>
              <VerticalDivider />
              {data.get('title') || '-'}
            </div>
          </Grid>
          {data.getIn(['type', 'value']) === 1 && (
            <>
              <Grid item>
                <div className={classes.label}>
                  {`${i18n.get('email_subject')}:`}
                </div>
                <div className={classes.previewContent}>
                  <VerticalDivider />
                  {data.get('subject') || '-'}
                </div>
              </Grid>
              <Grid item>
                <div className={classes.label}>
                  {`${i18n.get('email_pre_header')}:`}
                </div>
                <div className={classes.previewContent}>
                  <VerticalDivider />
                  {preHeader || '-'}
                </div>
              </Grid>
            </>
          )}
          <Grid item>
            <div className={classes.label}>
              {`${i18n.get('campaign')}:`}
            </div>
            <div className={classes.previewContent}>
              <VerticalDivider />
              {data.getIn(['ccdCampaign', 'label']) || '-'}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.label}>
              {`${i18n.get('content_type')}:`}
            </div>
            <div className={classes.previewContent}>
              <VerticalDivider />
              {data.getIn(['type', 'label']) || '-'}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.label}>
              {`${i18n.get('planned_for')}:`}
            </div>
            <div className={classes.previewContent}>
              <VerticalDivider />
              {data.get('plannedFor') ? moment(data.get('plannedFor')).format('lll') : '-'}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.label}>
              {`${i18n.get('scheduled_for')}:`}
            </div>
            <div className={classes.previewContent}>
              <VerticalDivider />
              {data.get('scheduledFor') ? moment(data.get('scheduledFor')).format('lll') : '-'}
            </div>
          </Grid>
          {data.getIn(['type', 'value']) === 1 && (
            <Grid item>
              <div className={classes.label}>
                {`${i18n.get('has_unsubscribe_link')}`}
              </div>
              <div className={classes.previewContent}>
                <VerticalDivider />
                {checkIfHasUnsubscribeLink() ? i18n.get('yes') : i18n.get('no')}
              </div>
            </Grid>
          )}
          <Grid item>
            {renderMediaFiles()}
          </Grid>
          <Grid item>
            {renderRecipients()}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          alignContent={!(body.length > 5000) && 'center'}
        >
          <div className={classes.previewContainer}>
            <div className={classes.previewInnerContainer}>
              <div className={classes.previewLabel}>
                {`${i18n.get('preview')}:`}
              </div>
              <ContentPreview content={data} />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

DistributeForm.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired
}

export default DistributeForm
