/* global document, window */
import { call, put, select, takeEvery, all, take, race, delay, takeLatest, debounce } from 'redux-saga/effects'
import decamelizeKeysDeep from 'decamelize-keys-deep'
import decamelize from 'decamelize'
import { fromJS } from 'immutable'
import { startFullscreen, stopFullscreen, isFullscreen } from 'utils/browser'
import {
  isPressrelationsNewsChart,
  isImageChart,
  isFacebookAnalyticsChart,
  isLinkedInAnalyticsChart,
  isTwitterAnalyticsChart,
  isSocialMediaAnalyticsFeed
} from 'utils/chart'
import { replace } from 'redux-first-history'

import { DateTypes, NewsPageModules, NewsPageViews, Capabilities } from 'static/constants'
import { findFirstChart as findFirstFacebookChart } from 'static/facebook_analytics_charts'

import * as Actions from 'actions/dashboard'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'
import * as SavedSearchesActions from 'actions/saved_searches'
import * as NavigationActions from 'actions/navigation'
import * as NewsActions from 'actions/news'
import * as ChartsActions from 'actions/charts'
import * as FilterActions from 'actions/filter'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { drilldown, fetchChartData, excelExport, powerpointExport, powerpointBatchExport } from 'sagas/charts'
import { uploadPhoto } from 'utils/sagas'
import { mergeDeepKeepLists } from 'utils/immutable'
import { resolveSavedSearch } from './saved_searches'
import { newsradarSwitch } from './app'

const allowedBreakpoints = ['xs', 'sm', 'md', 'lg']

const filterFieldMapping = {
  analysisCodes: 'analysis'
}

const drilldownAutoViews = {
  [NewsPageModules.PROFILE_MONITORING]: NewsPageViews.POSTS
}

export function* buildDrilldownNewsFeedBody() {
  const searchParams = yield select(Selectors.getDashboardDrilldownSearchParams)
  const dateFrom = yield select(Selectors.getDashboardDrilldownDateFrom)
  const dateTo = yield select(Selectors.getDashboardDrilldownDateTo)
  const dateRange = yield select(Selectors.getDashboardDrilldownDateRange)
  const dateType = yield select(Selectors.getDashboardDrilldownDateType)
  const groupingType = yield select(Selectors.getDashboardDrilldownGroupingType)
  const sortBy = yield select(Selectors.getDashboardDrilldownSortBy)

  return {
    date_from: dateFrom,
    date_to: dateTo,
    date_range: dateRange,
    date_type: dateType,
    grouping_type: groupingType,
    sort_by: sortBy,
    search_params: searchParams
  }
}

export function* fetchChartAggregation(chart, index, breakpoint, showLoadingIndicator) {
  try {
    if (showLoadingIndicator) {
      yield put(Actions.setChartLoading({
        index,
        breakpoint
      }))
    }

    const result = yield call(fetchChartData, chart)

    let chartError

    if (result && result.error) {
      const isAnalyticsChart = !isPressrelationsNewsChart(chart)

      const payload = {
        message: result.error
      }

      if (isAnalyticsChart) {
        payload.icon = 'warning'
        const hasExternalAccounts = yield select(Selectors.hasCapability, Capabilities.HAS_EXTERNAL_ACCOUNT_SETTINGS)

        if (hasExternalAccounts) {
          const i18n = yield select(Selectors.getI18n)

          payload.moduleLink = '/app/administration/external_accounts'
          payload.message = i18n.get(payload.message)
          payload.info = i18n.get('click_here_to_reconnect_account')
        }
      }

      chartError = fromJS(payload)
    }

    yield put(Actions.setChartData({
      index,
      breakpoint,
      data: result ? result[chart.get('firstLevel')] : null,
      chartError
    }))
  } catch (error) {
    if (!(error.response && error.response.statusCode === 404)) {
      yield put(AppActions.exception(error))
    }

    yield put(Actions.setChartData({
      index,
      breakpoint,
      data: null
    }))
  }
}

export function* fetchSelectedDashboardData(showLoadingIndicator = true) {
  const selectedDashboard = yield select(Selectors.getSelectedDashboard)

  if (selectedDashboard.get('id')) {
    let currentBreakpoint = yield select(Selectors.getBreakpoint)
    const device = yield select(Selectors.getDevice)

    let breakpointsToLoad = allowedBreakpoints

    if (device.get('phone') || device.get('tablet')) {
      breakpointsToLoad = [currentBreakpoint]

      if (device.get('landscape')) {
        breakpointsToLoad.push(allowedBreakpoints[allowedBreakpoints.indexOf(currentBreakpoint) - 1])
      } else {
        breakpointsToLoad.push(allowedBreakpoints[allowedBreakpoints.indexOf(currentBreakpoint) + 1])
      }

      if (breakpointsToLoad.every(breakpoint => selectedDashboard.getIn(['charts', breakpoint], fromJS([])).isEmpty())) {
        breakpointsToLoad = allowedBreakpoints
          .filter(breakpoint => breakpointsToLoad.indexOf(breakpoint) === -1)
          .filter(breakpoint => !selectedDashboard.getIn(['charts', breakpoint], fromJS([])).isEmpty())

        const [newBreakpoint] = breakpointsToLoad
        currentBreakpoint = newBreakpoint
      }
    }

    const preferredEffects = []
    const effects = []
    fromJS(selectedDashboard.get('charts'))
      .forEach((charts, breakpoint) => {
        charts.forEach((chart, index) => {
          if (breakpointsToLoad.indexOf(breakpoint) !== -1) {
            const effect = call(fetchChartAggregation, chart, index, breakpoint, showLoadingIndicator)

            // Prefer current breakpoint to fake faster loading
            if (breakpoint === currentBreakpoint) {
              preferredEffects.push(effect)
            } else {
              effects.push(effect)
            }
          }
        })
      })

    yield all(preferredEffects)
    yield delay(1000)
    yield all(effects)
  }
}

export function* fetchDashboard({ payload: id }) {
  const dashboards = yield select(Selectors.getDashboards)
  const dashboard = dashboards.find(d => d.get('id') === id)

  if (dashboard) {
    yield put(Actions.setSelectedDashboard(dashboard))
    yield call(fetchSelectedDashboardData)
  } else {
    try {
      const result = yield call(Api.fetchDashboardConfig, id)

      yield call(newsradarSwitch, result.newsradarId)
    } catch (error) {
      const currentPath = yield select(Selectors.getCurrentPath)

      if (currentPath !== '/app/dashboard') {
        yield put(NavigationActions.navigate('/app/dashboard'))
      }
    }
  }
}

export function* fetchSavedSearchAggregations(action) {
  try {
    if (action && action.payload && action.payload.withDelay) {
      yield (delay(1000))
    }

    const chartDialogVisible = yield select(Selectors.getDashboardShowChartDialog)

    if (!chartDialogVisible) {
      yield put(Actions.showChartDialog())
    }

    const chart = yield select(Selectors.getDashboardSelectedChart)
    const groupingType = yield select(Selectors.getDashboardSelectedChartGroupingType)
    const sortBy = yield select(Selectors.getDashboardSelectedChartSortBy)
    const chartAfter = chart.merge({
      groupingType,
      sortBy
    })

    const isLoadable = yield select(Selectors.getDashboardPreviewLoadable)

    if (isLoadable) {
      const result = yield call(fetchChartData, chartAfter)

      if (result) {
        yield put(Actions.fetchSavedSearchAggregationsSuccess(result))
      }
    }
  } catch (error) {
    yield put(Actions.fetchSavedSearchAggregationsError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* cancelEditing() {
  const previous = yield select(Selectors.getPreviousDashboard)
  yield put(Actions.setSelectedDashboard(previous))
  yield put(Actions.toggleEditing())
}

// CAUTION: This saga is also used in sagas/analysis
export function* saveDashboard(action) {
  try {
    let dashboard = action.payload ? action.payload.dashboard : null

    if (!dashboard) {
      dashboard = yield select(Selectors.getSelectedDashboard)
    }

    let result
    let body

    if (dashboard.get('id')) {
      const uploadedBackgroundImage = dashboard.get('uploadedBackgroundImage')
      const backgroundImageUrl = yield call(uploadPhoto, uploadedBackgroundImage)

      dashboard = dashboard.update(
        'config',
        config => (config || fromJS({})).update('backgroundImageUrl', url => backgroundImageUrl || url || null)
      )

      const sanitizedData = dashboard.update(
        'charts',
        charts => charts.reduce(
          (acc, values, key) => acc.set(key, values.map(chart => chart.deleteAll(['data', 'savedSearch']))),
          fromJS({})
        )
      ).toJS()

      body = {
        name_de: sanitizedData.nameDe,
        name_en: sanitizedData.nameEn,
        name_fr: sanitizedData.nameFr,
        name_ru: sanitizedData.nameRu,
        name_zh: sanitizedData.nameZh,
        name_ja: sanitizedData.nameJa,
        charts: decamelizeKeysDeep(sanitizedData.charts),
        layouts: decamelizeKeysDeep(sanitizedData.layouts),
        config: decamelizeKeysDeep(sanitizedData.config)
      }

      result = yield call(Api.updateDashboard, sanitizedData.id, body)
    } else {
      body = {
        name_de: dashboard.get('nameDe'),
        name_en: dashboard.get('nameEn'),
        name_fr: dashboard.get('nameFr'),
        name_ru: dashboard.get('nameRu'),
        name_zh: dashboard.get('nameZh'),
        name_ja: dashboard.get('nameJa')
      }

      result = yield call(Api.createDashboard, body)
    }

    yield put(Actions.saveDashboardSuccess(result))

    if (!action.payload || (action.payload && Boolean(action.payload.toggleEditing))) {
      yield put(Actions.toggleEditing())
    } else {
      yield put(NavigationActions.navigate(`/app/dashboard/${result.id}`))
      yield put(Actions.hideEditDialog())
    }

    yield put(Actions.setPrevious(dashboard))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.saveDashboardError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* deleteDashboard() {
  try {
    const dashboard = yield select(Selectors.getSelectedDashboard)
    const userConfig = yield select(Selectors.getUserConfig)

    yield call(Api.deleteDashboard, dashboard.get('id'), userConfig.get('id'))

    yield put(Actions.hideDeleteDialog())
    yield put(Actions.deleteDashboardSuccess(dashboard.get('id')))
    yield put(ConfigActions.setDashboardId(null))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.deleteDashboardError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* loadAggregations(payload) {
  const isLoadable = yield select(Selectors.getDashboardPreviewLoadable)

  if (isLoadable) {
    const chart = yield select(Selectors.getDashboardSelectedChart)
    const index = yield select(Selectors.getDashboardSelectedChartIndex)
    const multipleSavedSearchesMode = yield select(Selectors.getDashboardSelectedChartMultipleSavedSearchesMode)

    yield put(Actions.fetchSavedSearchAggregationsStart({
      chart,
      index,
      withDelay: payload && (payload.withDelay === true),
      isMulti: multipleSavedSearchesMode
    }))
  }
}

export function* toggleFavorite({ payload: dashboard }) {
  const id = dashboard.get('id')

  yield put(ConfigActions.setDashboardId(id))

  yield put(ConfigActions.updateUserConfigRequestStart())
}

export function* loadDashboard({ payload: id }) {
  const favoritedDashboardId = yield select(Selectors.getFavoritedDashboardId)
  const selectedDashboard = yield select(Selectors.getSelectedDashboard)
  const dashboards = yield select(Selectors.getDashboards)
  const dashboardFavoriteExists = dashboards.some(d => d.get('id') === favoritedDashboardId)

  let newId

  // ID from URL
  if (id) {
    newId = id

  // Already loaded, reloading selected dashboard
  } else if (selectedDashboard.get('id')) {
    newId = selectedDashboard.get('id')

  // Favorite as fallback
  } else if (dashboardFavoriteExists) {
    newId = favoritedDashboardId

  // No dashboards at all
  } else if (!dashboards.isEmpty()) {
    newId = dashboards.first().get('id')
  }

  const currentPath = yield select(Selectors.getCurrentPath)

  if (newId) {
    const newPath = `/app/dashboard/${newId}`

    if (currentPath !== newPath) {
      yield put(replace(`/app/dashboard/${newId}`))
    } else {
      yield put(Actions.fetchDashboard(newId))
    }
  } else if (currentPath !== '/app/dashboard') {
    yield put(NavigationActions.navigate('/app/dashboard'))
  }
}

export function* loadMoreFeedChartItems({ payload }) {
  const { index, breakpoint } = payload
  const chart = yield select(Selectors.getSelectedDashboardChartByIndexAndBreakpoint, index, breakpoint)

  if (chart) {
    if (isSocialMediaAnalyticsFeed(chart)) {
      yield put(Actions.loadMoreSocialMediaAnalyticsFeedPostsStart(payload))
    } else {
      yield put(Actions.loadMoreNewsFeedNewsStart(payload))
    }
  }
}

export function* loadMoreNewsFeedNews({ payload: { index, group, breakpoint } }) {
  try {
    const chart = yield select(Selectors.getSelectedDashboardChartByIndexAndBreakpoint, index, breakpoint)
    const from = group ? group.get('from', 10) : (chart.getIn(['data', 'from']) || 10)

    const body = {
      date_from: chart.get('dateFrom'),
      date_to: chart.get('dateTo'),
      date_range: chart.get('dateRange'),
      date_type: chart.get('dateType'),
      grouping_type: chart.get('groupingType'),
      sort_by: chart.get('sortBy'),
      group_id: group ? group.get('id') : undefined,
      from
    }

    const result = yield call(Api.fetchSavedSearchNewsFeed, chart.get('savedSearchId'), body)

    yield put(Actions.loadMoreNewsFeedNewsSuccess({ index, result, breakpoint, group }))
  } catch (error) {
    yield put(Actions.loadMoreNewsFeedNewsError({ error, index, breakpoint, group }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* fetchNewsFeedClusterStats({ payload: { index, group, breakpoint, newsId } }) {
  try {
    const chart = yield select(Selectors.getSelectedDashboardChartByIndexAndBreakpoint, index, breakpoint)

    let news

    if (group) {
      news = chart.getIn(['data', 'groups'])
        .find(g => g.get('id') === group.get('id'))
        .get('news')
        .find(n => n.get('id') === newsId)
    } else {
      news = chart.getIn(['data', 'news'])
        .find(n => n.get('id') === newsId)
    }

    const body = {
      date_from: chart.get('dateFrom'),
      date_to: chart.get('dateTo'),
      date_range: chart.get('dateRange'),
      date_type: chart.get('dateType'),
      grouping_type: chart.get('groupingType'),
      sort_by: chart.get('sortBy'),
      group_id: group ? group.get('id') : undefined
    }

    if (news.get('clusterSize') > 1) {
      const groupId = group ? group.get('id') : undefined
      const overwrites = fromJS({
        from: 0,
        size: 10000,
        sort_by: 'reach',
        search_params: {
          news: {
            cluster_id: [news.get('clusterId')],
            exclude_ids: [news.get('id')],
            booleans: {
              global_clusters: false
            }
          }
        }
      })

      const searchBody = yield call(mergeDeepKeepLists, fromJS(body), overwrites)
      let result = yield call(Api.fetchSavedSearchNewsFeed, chart.get('savedSearchId'), searchBody)
      let newNews

      if (result.groups !== undefined) {
        result = fromJS((result.groups.find(g => g.id === groupId) || { news: [] }).news)
        newNews = news.set('clusteredNews', result)
      } else {
        newNews = news.set('clusteredNews', fromJS(result.news))
      }

      yield put(Actions.fetchNewsFeedClusterStatsSuccess({ index, breakpoint, group, newNews }))
    }
  } catch (error) {
    yield put(Actions.fetchNewsFeedClusterStatsError({ error, group, newsId, breakpoint, index }))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* loadMoreSocialMediaAnalyticsFeedPosts({ payload: { index, breakpoint } }) {
  try {
    let chart = yield select(Selectors.getSelectedDashboardChartByIndexAndBreakpoint, index, breakpoint)

    if (isFacebookAnalyticsChart(chart)) {
      chart = chart.setIn(['opts', 'facebookAnalytics', 'paging'], chart.getIn(['data', 'paging']))
    }

    if (isLinkedInAnalyticsChart(chart)) {
      chart = chart.setIn(['opts', 'linkedInAnalytics', 'paging'], chart.getIn(['data', 'paging']))
    }

    if (isTwitterAnalyticsChart(chart)) {
      chart = chart.setIn(['opts', 'twitterAnalytics', 'paging'], chart.getIn(['data', 'paging']))
    }

    const result = yield call(fetchChartData, chart)

    yield put(Actions.loadMoreSocialMediaAnalyticsFeedPostsSuccess({ index, result: result[chart.get('firstLevel')], breakpoint }))
  } catch (error) {
    yield put(Actions.loadMoreSocialMediaAnalyticsFeedPostsError({ index, error, breakpoint }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* loadDrilldownNewsFeedNews() {
  try {
    const savedSearch = yield select(Selectors.getDashboardSelectedDrilldownChartSavedSearch)
    const body = yield call(buildDrilldownNewsFeedBody)

    const result = yield call(Api.fetchSavedSearchNewsFeed, savedSearch.get('id'), body)

    yield put(Actions.loadDrilldownNewsFeedNewsSuccess(result))
  } catch (error) {
    yield put(Actions.loadDrilldownNewsFeedNewsError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* loadDrilldownNewsFeedAiAnalysis() {
  try {
    const i18n = yield select(Selectors.getI18n)
    const chart = yield select(Selectors.getDashboardSelectedDrilldownChart)
    const filterData = yield select(Selectors.getDashboardSelectedDrilldownFilterData)
    const secondFilter = yield select(Selectors.getDashboardSelectedDrilldownSecondFilter)

    const savedSearch = yield select(Selectors.getDashboardSelectedDrilldownChartSavedSearch)
    let body = yield call(buildDrilldownNewsFeedBody)
    const locale = yield select(Selectors.getAiSettingsLocale)

    const metadata = [
      {
        name: filterData.get('name'),
        type: i18n.get(decamelize(chart.get('firstLevel')), {}, true)
      }
    ]

    if (secondFilter) {
      metadata.push({
        id: secondFilter.get('id'),
        name: secondFilter.get('name'),
        type: i18n.get(decamelize(chart.get('secondLevel')), {}, true)
      })
    }

    body = { ...body, locale, metadata }

    const result = yield call(Api.fetchSavedSearchNewsFeedAiAnalysis, savedSearch.get('id'), body)

    yield put(Actions.loadDrilldownNewsFeedAiAnalysisSuccess(result))
  } catch (error) {
    yield put(Actions.loadDrilldownNewsFeedAiAnalysisError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* loadMoreDrilldownNewsFeedNews({ payload: { group } }) {
  try {
    const savedSearch = yield select(Selectors.getDashboardSelectedDrilldownChartSavedSearch)
    const body = yield call(buildDrilldownNewsFeedBody)

    body.from = group.get('from', 10)

    const result = yield call(Api.fetchSavedSearchNewsFeed, savedSearch.get('id'), body)

    yield put(Actions.loadMoreDrilldownNewsFeedNewsSuccess({ result, group }))
  } catch (error) {
    yield put(Actions.loadMoreDrilldownNewsFeedNewsError({ error, group }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* fetchDrilldownNewsFeedClusterStats({ payload: { group, newsId } }) {
  try {
    const news = yield select(Selectors.getDashboardDrilldownDataNewsById, newsId)

    if (news.get('clusterSize') > 1) {
      const savedSearch = yield select(Selectors.getDashboardSelectedDrilldownChartSavedSearch)
      const groupId = group ? group.get('id') : undefined
      const body = yield call(buildDrilldownNewsFeedBody)
      const overwrites = fromJS({
        from: 0,
        size: 10000,
        sort_by: 'reach',
        group_id: groupId,
        search_params: {
          news: {
            cluster_id: [news.get('clusterId')],
            exclude_ids: [newsId],
            booleans: {
              global_clusters: false
            }
          }
        }
      })

      const searchBody = yield call(mergeDeepKeepLists, fromJS(body), overwrites)
      let result = yield call(Api.fetchSavedSearchNewsFeed, savedSearch.get('id'), searchBody)
      let newNews

      if (result.groups !== undefined) {
        result = fromJS((result.groups.find(g => g.id === groupId) || { news: [] }).news)
        newNews = news.set('clusteredNews', result)
      }

      yield put(Actions.fetchDrilldownNewsFeedClusterStatsSuccess({ group, newNews }))
    }
  } catch (error) {
    yield put(Actions.fetchDrilldownNewsFeedClusterStatsError({ error, group, newsId }))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* executeSavedSearch() {
  try {
    yield put(NewsActions.blockNewsRequest())
    const savedSearch = yield select(Selectors.getDashboardSelectedDrilldownChartSavedSearch)
    const moduleName = savedSearch.get('moduleName')
    const isPlotlights = yield select(Selectors.isPlotlights)

    if (moduleName === NewsPageModules.NEWS_POOL) {
      yield put(NavigationActions.navigate(isPlotlights ? '/app/media_monitoring' : '/app/search_pool'))
    } else {
      yield put(NavigationActions.navigate(`/app/${moduleName}`))
    }

    const { timeout } = yield race({
      selected: take(NewsActions.viewConfigPresetSelected),
      timeout: delay(2000)
    })

    if (!timeout) {
      yield put(SavedSearchesActions.setInitialSearchLoaded(true))

      yield call(resolveSavedSearch, SavedSearchesActions.executeSavedSearch(savedSearch))

      const chart = yield select(Selectors.getDashboardSelectedDrilldownChart)
      const filterData = yield select(Selectors.getDashboardSelectedDrilldownFilterData)
      const secondFilter = yield select(Selectors.getDashboardSelectedDrilldownSecondFilter)

      const fields = [filterFieldMapping[filterData.get('field')] || filterData.get('field')]

      if (secondFilter) {
        fields.push(filterFieldMapping[secondFilter.get('field')] || secondFilter.get('field'))
      }

      const dateType = chart.get('dateType') || savedSearch.get('dateType') || DateTypes.MEDIA_REVIEW

      yield put(FilterActions.changeDateType(dateType))
      yield put(FilterActions.resetFilterFields(fields))

      yield call(drilldown, ChartsActions.drilldown({
        chart,
        d: {
          filterData: filterData.toJS()
        },
        secondFilter: secondFilter ? secondFilter.toJS() : null
      }))

      yield put(Actions.hideDrilldownDialog())
      yield put(NewsActions.unblockNewsRequest())
      yield put(NewsActions.setNewsView(drilldownAutoViews[moduleName] ? drilldownAutoViews[moduleName] : NewsPageViews.NEWS))
      yield put(NewsActions.newsRequestStart())
    }
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  } finally {
    yield put(Actions.executedSavedSearch())
    yield put(NewsActions.unblockNewsRequest())
  }
}

export function* showDrilldownDialog() {
  yield put(Actions.loadDrilldownNewsFeedNewsStart())
}

export function* groupingAndSortingChange() {
  yield put(Actions.loadDrilldownNewsFeedNewsStart())
}

export function* exportExcel({ payload: chart }) {
  yield call(excelExport, chart)
}

export function* exportPowerpoint({ payload: chart }) {
  yield call(powerpointExport, chart)
}

export function* exportDashboardAsPowerpoint() {
  const breakpoint = yield select(Selectors.getBreakpoint)
  let layouts = yield select(Selectors.getSelectedDashboardLayouts)
  let charts = yield select(Selectors.getSelectedDashboardChartsFilled)
  const coverTitle = yield select(Selectors.getSelectedDashboardName)
  const timezone = yield select(Selectors.getTimezone)
  const logo = yield select(Selectors.getLogo)
  const filename = coverTitle.replace(/(\s|\/)+/g, '_').replace(/_+/g, '_').toLowerCase()

  layouts = layouts.get(breakpoint)
  charts = charts
    .get(breakpoint)
    .map(chart => chart.delete('data'))
    // Map the array to an indexed object so that both Chrome and Firefox result in same iteration over indexes and values.
    .reduce((acc, chart, index) => {
      acc.push({ index, chart })

      return acc
    }, [])
    .sort((a, b) => {
      const i = a.index
      const j = b.index

      if (layouts.getIn([i, 'y']) < layouts.getIn([j, 'y'])) {
        return -1
      }

      if (layouts.getIn([i, 'y']) > layouts.getIn([j, 'y'])) {
        return 1
      }

      if (layouts.getIn([i, 'x']) < layouts.getIn([j, 'x'])) {
        return -1
      }

      if (layouts.getIn([i, 'x']) > layouts.getIn([j, 'x'])) {
        return 1
      }

      return 0
    })
    .map(obj => obj.chart)

  yield call(powerpointBatchExport, fromJS(charts), null, {
    cover_title: coverTitle,
    timezone,
    logo,
    filename: `${filename}.pptx`
  })

  yield put(Actions.exportDashboardAsPowerpointSuccess())
}

export function* toggleEditing() {
  const isEditing = yield select(Selectors.getDashboardIsEditing)

  if (isEditing) {
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    yield put(Actions.setPrevious(selectedDashboard))
  }
}

export function* checkManualFullscreen() {
  const device = yield select(Selectors.getDevice)

  if (!device.get('mobile')) {
    const autoRefresh = yield select(Selectors.getDashboardAutoRefresh)
    const isDashboard = yield select(Selectors.isDashboard)
    const fullscreen = yield call(isFullscreen)

    if (isDashboard && fullscreen && !autoRefresh) {
      yield put(Actions.toggleAutoRefresh())
    } else if (isDashboard && !fullscreen && autoRefresh) {
      yield put(Actions.toggleAutoRefresh())
    }
  }
}

export function* startAutoRefresh() {
  for (;;) {
    const { stop } = yield race({
      stop: take(Actions.stopAutoRefresh),
      refresh: delay(300000)
    })

    if (stop) {
      break
    }

    const autoRefresh = yield select(Selectors.getDashboardAutoRefresh)
    const isDashboard = yield select(Selectors.isDashboard)

    if (autoRefresh && isDashboard) {
      yield call(fetchSelectedDashboardData, false)
    }
  }
}

export function* toggleAutoRefresh() {
  try {
    const autoRefresh = yield select(Selectors.getDashboardAutoRefresh)

    if (autoRefresh) {
      yield call(startFullscreen)
    } else {
      yield call(stopFullscreen)
    }
  } catch (error) {
    yield put(AppActions.exception(error))
  }
}

export function* checkChartChange({ payload: { chartBefore } }) {
  const chartAfter = yield select(Selectors.getDashboardSelectedChart)

  let reload = ['firstLevel', 'secondLevel', 'thirdLevel', 'type'].some(level => chartBefore.get(level) !== chartAfter.get(level))
  const multipleSavedSearchesMode = yield select(Selectors.getDashboardSelectedChartMultipleSavedSearchesMode)

  if (multipleSavedSearchesMode && chartAfter.get('firstLevel') !== 'savedSearches') {
    reload = true
    yield put(Actions.setSelectedChart(chartAfter.set('secondLevel', 'savedSearches')))
  }

  if (reload) {
    yield call(loadAggregations)
  }
}

export function* cloneDashboard() {
  try {
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const i18n = yield select(Selectors.getI18n)
    const name = `${selectedDashboard.get('name')} (${i18n.get('cloned_dashboard')})`

    const { id } = yield call(Api.createDashboard, { name_en: name })

    yield put(Actions.setSelectedDashboard(selectedDashboard.merge({
      name,
      id
    })))

    yield call(saveDashboard, {})
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* addNewChartShortcut() {
  yield put(Actions.toggleEditing())
  yield put(Actions.fetchSavedSearchAggregationsStart())
}

export function* setSelectedChartOpt({ payload: { key } }) {
  const optsWithReload = [
    'continentCode',
    'countryId',
    'size',
    'usePercentAsValue',
    'averageByTimespan',
    'replaceSnippetsWithSummaries',
    'widgetContent',
    'customWidgetUrl'
  ]

  if (optsWithReload.indexOf(key) !== -1) {
    yield call(loadAggregations)
  }

  if (['aggregationExcludeBuckets'].indexOf(key) !== -1) {
    yield call(loadAggregations, { withDelay: true })
  }
}

export function* setChart({ payload: { chart, index, breakpoint } }) {
  if (isImageChart(chart)) {
    yield put(Actions.setChartLoading({ index, breakpoint }))

    const uploadedPhoto = yield select(Selectors.getDashboardSelectedChartUploadedPhoto)
    const imageUrl = yield call(uploadPhoto, uploadedPhoto)

    if (uploadedPhoto) {
      yield put(Actions.setChartOpt({
        index,
        breakpoint,
        key: 'imageUrl',
        value: imageUrl
      }))

      yield put(Actions.setChartLoading({ index, breakpoint, loading: false }))
    }
  }

  if (chart.get('loading')) {
    if (index !== null) {
      yield call(fetchChartAggregation, chart, index, breakpoint, true)
    } else {
      const charts = yield select(Selectors.getSelectedDashboardCharts)

      yield call(fetchChartAggregation, chart, charts.get(breakpoint).size - 1, breakpoint, true)
    }
  }
}

export function* changeFacebookAnalyticsPage({ payload: newPageId }) {
  const currentPageId = yield select(Selectors.getDashboardSelectedChartFacebookAnalyticsPageId)
  const currentPage = yield select(Selectors.getStaticFacebookAnalyticsPageById, currentPageId || '')
  const newPage = yield select(Selectors.getStaticFacebookAnalyticsPageById, newPageId)

  let newChart

  if (currentPage) {
    if (currentPage.get('instagram') && !newPage.get('instagram')) {
      newChart = yield call(findFirstFacebookChart)
    } else if (!currentPage.get('instagram') && newPage.get('instagram')) {
      newChart = yield call(findFirstFacebookChart, true)
    }
  } else if (newPage.get('instagram')) {
    newChart = yield call(findFirstFacebookChart, true)
  } else {
    newChart = yield call(findFirstFacebookChart)
  }

  if (newChart) {
    yield put(Actions.setFacebookAnalyticsChart(newChart))
  }

  yield put(Actions.selectFacebookAnalyticsPage(newPage))
}

export function* downloadImage() {
  try {
    yield delay(250)
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const filename = `dashboard_${selectedDashboard.get('id')}.png`
    const bodyStyles = yield call(window.getComputedStyle, document.body)
    const backgroundColor = bodyStyles.getPropertyValue('background-color')

    yield put(AppActions.downloadElementAsImage({
      filename,
      id: `dashboard-${selectedDashboard.get('id')}`,
      opts: {
        backgroundColor,
        scale: 3
      }
    }))

    yield take(AppActions.downloadElementAsImageDone)

    yield put(Actions.downloadImageSuccess())
  } catch (error) {
    yield put(Actions.downloadImageError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* downloadPdf() {
  try {
    yield delay(250)
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const filename = `dashboard_${selectedDashboard.get('id')}.pdf`
    const bodyStyles = yield call(window.getComputedStyle, document.body)
    const backgroundColor = bodyStyles.getPropertyValue('background-color')

    yield put(AppActions.downloadElementAsPdf({
      filename,
      id: `dashboard-${selectedDashboard.get('id')}`,
      opts: {
        backgroundColor,
        backgroundImageUrl: selectedDashboard.getIn(['config', 'backgroundImageUrl']),
        scale: 1
      }
    }))

    yield take(AppActions.downloadElementAsPdfDone)

    yield put(Actions.downloadPdfSuccess())
  } catch (error) {
    yield put(Actions.downloadPdfError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchFetchDashboard() {
  yield takeLatest(Actions.fetchDashboard, fetchDashboard)
}

export function* watchFetchSavedSearchAggregations() {
  yield takeLatest(Actions.fetchSavedSearchAggregationsStart, fetchSavedSearchAggregations)
}

export function* watchCancelEditing() {
  yield takeEvery(Actions.cancelEditing, cancelEditing)
}

export function* watchSaveDashboard() {
  yield takeEvery(Actions.saveDashboardStart, saveDashboard)
}

export function* watchDeleteDashboard() {
  yield takeEvery(Actions.deleteDashboardStart, deleteDashboard)
}

export function* watchSelectSavedSearch() {
  yield takeEvery(Actions.selectSavedSearch, loadAggregations)
  yield takeEvery(Actions.selectDateRange, loadAggregations)
  yield takeEvery(Actions.selectDate, loadAggregations)
  yield takeEvery(Actions.selectDateType, loadAggregations)
  yield takeEvery(Actions.selectDateInterval, loadAggregations)
  yield takeEvery(Actions.selectGoogleAnalyticsChart, loadAggregations)
  yield takeEvery(Actions.selectGoogleAnalyticsView, loadAggregations)
  yield takeEvery(Actions.selectMozChart, loadAggregations)
  yield takeEvery(Actions.selectMozTargets, loadAggregations)
  yield takeEvery(Actions.selectLinkedInAnalyticsChart, loadAggregations)
  yield takeEvery(Actions.selectLinkedInAnalyticsOrganization, loadAggregations)
  yield takeEvery(Actions.selectFacebookAnalyticsChart, loadAggregations)
  yield takeEvery(Actions.selectFacebookAnalyticsPage, loadAggregations)
  yield takeEvery(Actions.selectTwitterAnalyticsChart, loadAggregations)
  yield takeEvery(Actions.selectChartDialogGroupingType, loadAggregations)
  yield takeEvery(Actions.selectChartDialogSortBy, loadAggregations)
  yield takeEvery(Actions.selectThirdLevel, loadAggregations)
  yield takeEvery(Actions.selectFirstLevel, loadAggregations)
  yield takeEvery(Actions.selectDateMode, loadAggregations)
  yield takeEvery(Actions.setSelectedChartMultipleSavedSearchesMode, loadAggregations)
  yield takeEvery(Actions.selectAdditionalSavedSearches, loadAggregations)
}

export function* watchToggleFavorite() {
  yield takeEvery(Actions.toggleFavorite, toggleFavorite)
}

export function* watchLoadDashboard() {
  yield takeEvery(Actions.loadDashboard, loadDashboard)
}

export function* watchLoadMoreFeedChartItems() {
  yield takeEvery(Actions.loadMoreFeedChartItems, loadMoreFeedChartItems)
}

export function* watchLoadMoreNewsFeedNews() {
  yield takeEvery(Actions.loadMoreNewsFeedNewsStart, loadMoreNewsFeedNews)
}

export function* watchFetchNewsFeedClusterStats() {
  yield takeEvery(Actions.fetchNewsFeedClusterStatsStart, fetchNewsFeedClusterStats)
}

export function* watchShowDrilldownDialog() {
  yield takeEvery(Actions.showDrilldownDialog, showDrilldownDialog)
}

export function* watchLoadDrilldownNewsFeedNews() {
  yield takeEvery(Actions.loadDrilldownNewsFeedNewsStart, loadDrilldownNewsFeedNews)
}

export function* watchLoadDrilldownNewsFeedAiAnalysis() {
  yield takeEvery(Actions.loadDrilldownNewsFeedAiAnalysisStart, loadDrilldownNewsFeedAiAnalysis)
}

export function* watchLoadMoreDrilldownNewsFeedNews() {
  yield takeEvery(Actions.loadMoreDrilldownNewsFeedNewsStart, loadMoreDrilldownNewsFeedNews)
}

export function* watchFetchDrilldownNewsFeedClusterStats() {
  yield takeEvery(Actions.fetchDrilldownNewsFeedClusterStatsStart, fetchDrilldownNewsFeedClusterStats)
}

export function* watchLoadMoreSocialMediaAnalyticsFeedPosts() {
  yield takeEvery(Actions.loadMoreSocialMediaAnalyticsFeedPostsStart, loadMoreSocialMediaAnalyticsFeedPosts)
}

export function* watchGroupingAndSortingChange() {
  yield takeEvery(Actions.selectDrilldownGroupingType, groupingAndSortingChange)
  yield takeEvery(Actions.selectDrilldownSortBy, groupingAndSortingChange)
}

export function* watchExecuteSavedSearch() {
  yield takeEvery(Actions.executeSavedSearch, executeSavedSearch)
}

export function* watchExportExcel() {
  yield takeEvery(Actions.exportExcelStart, exportExcel)
}

export function* watchExportPowerpoint() {
  yield takeLatest(Actions.exportPowerpointStart, exportPowerpoint)
}

export function* watchExportDashboardAsPowerpoint() {
  yield takeLatest(Actions.exportDashboardAsPowerpointStart, exportDashboardAsPowerpoint)
}

export function* watchToggleEditing() {
  yield takeEvery(Actions.toggleEditing, toggleEditing)
}

export function* watchStartAutoRefresh() {
  yield takeEvery(Actions.startAutoRefresh, startAutoRefresh)
  yield takeEvery(Actions.startAutoRefresh, checkManualFullscreen)
}

export function* watchToggleAutoRefresh() {
  yield takeEvery(Actions.toggleAutoRefresh, toggleAutoRefresh)
}

export function* watchCheckChartChange() {
  yield takeEvery(Actions.checkChartChange, checkChartChange)
}

export function* watchDateChanges() {
  yield takeEvery(Actions.updateDateRangeOfSelectedDashboard, fetchSelectedDashboardData)
  yield takeEvery(Actions.updateDatesOfSelectedDashboard, fetchSelectedDashboardData)
  yield takeEvery(Actions.updateKpisOfSelectedDashboard, fetchSelectedDashboardData)
}

export function* watchCloneDashboard() {
  yield takeEvery(Actions.cloneDashboard, cloneDashboard)
}

export function* watchAddNewChartShortcut() {
  yield takeEvery(Actions.addNewChartShortcut, addNewChartShortcut)
}

export function* watchSetSelectedChartOpt() {
  yield debounce(500, Actions.setSelectedChartOpt, setSelectedChartOpt)
}

export function* watchSetChart() {
  yield takeEvery(Actions.setChart, setChart)
}

export function* watchChangeFacebookAnalyticsPage() {
  yield takeEvery(Actions.changeFacebookAnalyticsPage, changeFacebookAnalyticsPage)
}

export function* watchDownloadImage() {
  yield takeEvery(Actions.downloadImageStart, downloadImage)
}

export function* watchDownloadPdf() {
  yield takeEvery(Actions.downloadPdfStart, downloadPdf)
}

export default function* saga() {
  yield all([
    watchFetchDashboard(),
    watchFetchSavedSearchAggregations(),
    watchCancelEditing(),
    watchSaveDashboard(),
    watchDeleteDashboard(),
    watchSelectSavedSearch(),
    watchToggleFavorite(),
    watchLoadDashboard(),
    watchLoadMoreFeedChartItems(),
    watchLoadMoreNewsFeedNews(),
    watchFetchNewsFeedClusterStats(),
    watchShowDrilldownDialog(),
    watchLoadDrilldownNewsFeedNews(),
    watchLoadDrilldownNewsFeedAiAnalysis(),
    watchLoadMoreDrilldownNewsFeedNews(),
    watchFetchDrilldownNewsFeedClusterStats(),
    watchLoadMoreSocialMediaAnalyticsFeedPosts(),
    watchGroupingAndSortingChange(),
    watchExecuteSavedSearch(),
    watchExportExcel(),
    watchExportPowerpoint(),
    watchExportDashboardAsPowerpoint(),
    watchToggleEditing(),
    watchStartAutoRefresh(),
    watchToggleAutoRefresh(),
    watchCheckChartChange(),
    watchDateChanges(),
    watchCloneDashboard(),
    watchAddNewChartShortcut(),
    watchSetSelectedChartOpt(),
    watchSetChart(),
    watchChangeFacebookAnalyticsPage(),
    watchDownloadImage(),
    watchDownloadPdf()
  ])
}
