import { put, takeEvery, all, call, throttle, select, delay, race, take } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import moment from 'moment-timezone'

import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'
import * as EmailTemplatesLibraryActions from 'actions/email_templates_library'
import * as NewsActions from 'actions/news'
import * as SavedSearchesActions from 'actions/saved_searches'
import * as NavigationActions from 'actions/navigation'
import * as FilterActions from 'actions/filter'
import { navigate } from 'actions/navigation'

import { downloadBlob } from 'utils/sagas'

const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export function* saveCampaignStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getCDeskCampaignFormData)
    const requestBody = {
      assigned_user_id: formData.get('assignedUserId'),
      end_date: formData.get('endDate'),
      id: formData.get('id'),
      name: formData.get('name'),
      start_date: formData.get('startDate'),
      summary: formData.get('summary'),
      active: formData.get('active'),
      progress: formData.get('progress')
    }

    let result
    let text

    if (formData.get('id')) {
      result = yield call(Api.updateCampaign, formData.get('id'), requestBody)
      text = i18n.get('ccd_campaign_updated')
    } else {
      result = yield call(Api.createCampaign, requestBody)
      text = i18n.get('ccd_campaign_created')
    }

    const calendarOpen = yield select(Selectors.getCDeskShowCalendarDialog)

    if (calendarOpen) {
      const calendarDate = yield select(Selectors.getCDeskCalendarDate)
      yield put(Actions.fetchCalendarEventsStart(calendarDate))
    }

    yield put(Actions.saveCampaignSuccess(result))
    yield put(Actions.fetchCampaignDropdownOptionsStart())

    yield put(AppActions.showAppMessage({
      text,
      success: true
    }))
  } catch (error) {
    yield put(Actions.saveCampaignError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* saveContentFormlessStart({
  payload: {
    id,
    title,
    plannedFor,
    scheduledFor,
    scheduledForJobName,
    scheduledEventsJobName,
    approvalStatus,
    ccdCampaignId
  }
}) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const requestBody = {
      id,
      title,
      planned_for: plannedFor,
      scheduled_for: scheduledFor,
      scheduled_for_job_name: scheduledForJobName,
      scheduled_events_job_name: scheduledEventsJobName,
      approval_status: approvalStatus,
      ccd_campaign_id: ccdCampaignId
    }
    const result = yield call(Api.updateContent, id, requestBody)
    yield put(Actions.saveContentFormlessSuccess(result))
  } catch (error) {
    let msg = i18n.get('failed')

    if (error.response && error.response.statusCode === 409) {
      msg = i18n.get('ccd_content_duplicate_error')
    }

    yield put(Actions.saveContentFormlessError(error))
    yield put(AppActions.showAppMessage({
      text: msg,
      success: false
    }))
  }
}

export function* saveContentStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getCDeskContentFormData)
    const timezone = yield select(Selectors.getTimezone)
    const config = yield select(Selectors.getCDeskConfig)
    const ccdCampaign = formData.get('ccdCampaign')
    const type = formData.getIn(['type', 'label']).toLowerCase()

    let ccdCampaignId = null

    if (ccdCampaign !== null) {
      ccdCampaignId = ccdCampaign.get('value')
    }

    const recipients = formData.get('recipients').map(r => ({
      content_id: r.get('contentId'),
      first_name: r.get('firstName'),
      last_name: r.get('lastName'),
      mail: r.get('mail'),
      contact_id: r.get('contactId'),
      distribution_list_id: r.get('distributionListId')
    }))

    const ccdMediaFiles = formData.get('ccdMediaFiles').map(f => ({
      id: f.get('id')
    }))

    const notes = formData.get('notes').map(n => ({
      text: n.get('text'),
      created_by: n.get('createdBy'),
      created_at: n.get('createdAt')
    }))

    const senderName = formData.get('senderName') || config.get('senderName')
    const senderMail = formData.get('senderMail') || config.get('senderEmail')
    const id = formData.get('id')

    const requestBody = {
      id,
      title: formData.get('title'),
      body: formData.get('body'),
      plain_text: formData.get('plainText'),
      editor_design: formData.get('editorDesign'),
      type,
      subject: formData.get('subject'),
      planned_for: formData.get('plannedFor'),
      scheduled_for: formData.get('scheduledFor'),
      ccd_campaign_id: ccdCampaignId,
      ccd_media_files: ccdMediaFiles,
      recipients,
      timezone,
      sender_mail: senderMail,
      sender_name: senderName,
      domain: config.get('domain'),
      domain_region: config.get('domainRegion'),
      approval_status: formData.get('approvalStatus'),
      notes
    }

    if (type === 'linkedin') {
      requestBody.linked_in_post = JSON.stringify(formData.get('linkedInPost'))
    }

    if (type === 'facebook') {
      requestBody.facebook_post = JSON.stringify(formData.get('facebookPost'))
    }

    let result
    let text

    if (id) {
      result = yield call(Api.updateContent, id, requestBody)
      text = i18n.get('ccd_content_updated')
    } else {
      result = yield call(Api.createContent, requestBody)
      text = i18n.get('ccd_content_created')
    }

    const calendarOpen = yield select(Selectors.getCDeskShowCalendarDialog)

    if (calendarOpen) {
      const calendarDate = yield select(Selectors.getCDeskCalendarDate)
      yield put(Actions.fetchCalendarEventsStart(calendarDate))
    }

    if (payload && payload.switchToContactManagement) {
      yield put(Actions.switchToContactManagement())
    }

    if (payload && payload.switchToEmailTemplatesLibrary) {
      yield put(Actions.switchToEmailTemplatesLibrary())
    }

    if (payload && payload.openEditContentDialog) {
      yield put(Actions.lockContentStart({ id: result.id, openEditContentDialog: true }))
    }

    if (payload && payload.closeEditContentDialog) {
      yield put(Actions.unlockContentStart({ closeEditContentDialog: true }))
    }

    yield put(Actions.saveContentSuccess(result))
    yield put(AppActions.showAppSnackbarMessage({
      text,
      variant: 'success'
    }))
  } catch (error) {
    let msg = i18n.get('failed')

    if (error.response && error.response.statusCode === 409) {
      msg = i18n.get('ccd_content_duplicate_error')
    }

    if (error.response && error.response.statusCode === 403) {
      msg = error.response.message
    }

    yield put(Actions.saveContentError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: msg,
      variant: 'error'
    }))
  }
}

export function* deleteContentStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.deleteContent, payload.id)
    yield put(Actions.deleteContentSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get('content_deleted'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.deleteContentError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* deleteCampaignStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.deleteCampaign, payload.id)
    yield put(Actions.deleteCampaignSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get('ccd_campaign_deleted'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.deleteCampaignError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* cloneContentsFormData() {
  const uploadedMediaFiles = yield select(Selectors.getCDeskUploadedMediaFiles)

  yield put(Actions.fetchCampaignDropdownOptionsStart())

  if (uploadedMediaFiles.isEmpty()) {
    yield put(Actions.fetchMediaFilesStart())
  }
}

export function* openViewContentDialog() {
  yield put(Actions.fetchStatisticsStart({}))
}

export function* openEditContentDialog() {
  const uploadedMediaFiles = yield select(Selectors.getCDeskUploadedMediaFiles)

  yield put(Actions.fetchCampaignDropdownOptionsStart())

  if (uploadedMediaFiles.isEmpty()) {
    yield put(Actions.fetchMediaFilesStart())
  }
}

export function* fetchCampaignDropdownOptionsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const body = {
      select_fields: ['id', 'name', 'active'],
      page: 1,
      limit: 1000
    }

    const result = yield call(Api.searchCampaigns, body)
    yield put(Actions.fetchCampaignDropdownOptionsSuccess(result))
  } catch (error) {
    yield put(Actions.fetchCampaignDropdownOptionsError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* fetchMediaFilesStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const body = {
      page: 1,
      limit: 10
    }

    const result = yield call(Api.searchMediaFiles, body)
    yield put(Actions.fetchMediaFilesSuccess(result))
    yield put(Actions.setMediaFilesInitialFetch(true))
    yield put(Actions.incrementMediaFilesPage())
  } catch (error) {
    yield put(Actions.fetchMediaFilesError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* fetchMoreMediaFilesStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)
  const isInitialFetch = yield select(Selectors.getCDeskMediaFilesInitialFetch)
  const uploadedMediaFiles = yield select(Selectors.getCDeskUploadedMediaFiles)
  const uploadedMediaFilesTotal = yield select(Selectors.getCDeskUploadedMediaFilesTotal)
  const page = yield select(Selectors.getCDeskMediaFilesPage)
  const limit = yield select(Selectors.getCDeskMediaFilesLimit)

  const hasMore = uploadedMediaFilesTotal > page * limit
  const mediaFiles = uploadedMediaFiles
    .filter(mediaFile => mediaFile.get('mimeType').includes('image'))
    .map(mediaFile => ({
      id: mediaFile.get('id'),
      location: mediaFile.get('url'),
      contentType: mediaFile.get('mimeType'),
      width: 500,
      height: 500,
      source: 'user'
    })).toJS()

  try {
    const body = {
      page,
      limit
    }

    if (isInitialFetch && payload.done) {
      yield put(Actions.setMediaFilesInitialFetch(false))
      yield call(payload.done, mediaFiles, { hasMore, page, limit, uploadedMediaFilesTotal })
      yield put(Actions.fetchMoreMediaFilesSuccess())

      return
    }

    const result = yield call(Api.searchMediaFiles, body)

    if (payload.done) {
      const newMediaFiles = mediaFiles.concat(result.mediaFiles
        .filter(mediaFile => mediaFile.mimeType.includes('image'))
        .map(mediaFile => ({
          id: mediaFile.id,
          location: mediaFile.url,
          contentType: mediaFile.mimeType,
          width: 500,
          height: 500,
          source: 'user'
        })))

      yield call(payload.done, newMediaFiles, { hasMore, page, limit, uploadedMediaFilesTotal })
    }

    yield put(Actions.appendMediaFiles(result))
    yield put(Actions.incrementMediaFilesPage())

    yield put(Actions.fetchMoreMediaFilesSuccess())
  } catch (error) {
    yield put(Actions.fetchMoreMediaFilesError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* deleteMediaFileStart({ payload }) {
  const uploadedMediaFiles = yield select(Selectors.getCDeskUploadedMediaFiles)
  const storedFileId = uploadedMediaFiles.find(mediaFile => mediaFile.get('id') === payload.id).get('storedFilesId')

  try {
    const { ccdMediaFile, total, totalSize } = yield call(Api.deleteMediaFile, storedFileId)
    yield put(Actions.deleteMediaFileSuccess({ ccdMediaFile, total }))
    yield put(Actions.setUploadedMediaFilesTotalSize(totalSize))
  } catch (_error) {
    yield put(Actions.deleteMediaFileError())
  }
}

export function* fetchDistributionListDropdownOptionsStart({ payload: { searchString } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const body = {
      filter: [{ field: 'name', value: searchString }],
      page: 1,
      limit: 100
    }

    // TODO: change to search route
    const result = yield call(Api.fetchDistributionLists, body)
    yield put(Actions.fetchDistributionListDropdownOptionsSuccess(result))
  } catch (error) {
    yield put(Actions.fetchDistributionListDropdownOptionsError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* fetchContactDropdownOptionsStart({ payload: { searchString } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const body = {
      filter: [{ field: 'searchable', value: searchString }],
      page: 1,
      limit: 100
    }

    // TODO: change to search route
    const result = yield call(Api.fetchContacts, body)
    yield put(Actions.fetchContactDropdownOptionsSuccess(result))
  } catch (error) {
    yield put(Actions.fetchContactDropdownOptionsError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* uploadEditorImageStart({ payload: { file } }) {
  try {
    /* eslint-disable camelcase */
    const { uploaded_file, ccd_media_files_total_size } = yield call(Api.uploadEditorImage, file)
    yield put(Actions.uploadEditorImageSuccess(uploaded_file))
    yield put(Actions.setUploadedMediaFilesTotalSize(ccd_media_files_total_size))
  } catch (_error) {
    yield put(Actions.uploadEditorImageError())
  }
}

export function* fetchStatisticsStart({ payload }) {
  const content = yield select(Selectors.getCDeskSelectedContent)

  if (content.get('status') !== 'released') {
    return
  }

  const i18n = yield select(Selectors.getI18n)
  const id = content.get('id')
  const earnedMediaAccuracy = payload.earnedMediaAccuracy || '30%'

  try {
    const result = yield call(Api.fetchStatistics, id, earnedMediaAccuracy)
    yield put(Actions.fetchStatisticsSuccess(result))
  } catch (error) {
    // when post cannot be found on social media because it has been deleted there
    // then update the content
    if (error && error.response && error.response.statusCode === 404) {
      if (!content.get('deletedOnSocial')) {
        yield put(Actions.saveDeletedOnSocialForContentStart({ content }))
      }
    } else {
      yield put(AppActions.showAppMessage({
        text: i18n.get('failed'),
        success: false
      }))
    }

    yield put(Actions.fetchStatisticsError(error))
  }
}

export function* saveDeletedOnSocialForContentStart({ payload: { content } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const id = content.get('id')

    // Minimum required fields for patch although we only want to update deleted_on_social
    const requestBody = {
      title: content.get('title'),
      planned_for: content.get('plannedFor'),
      scheduled_for: content.get('scheduledFor'),
      scheduled_for_job_name: content.get('scheduledForJobName'),
      scheduled_events_job_name: content.get('scheduledEventsJobName'),
      ccd_campaign_id: content.getIn(['ccdCampaign', 'id']),
      ccd_media_files: content.get('ccdMediaFiles').map(f => ({ id: f.get('id') })),
      deleted_on_social: true
    }

    const result = yield call(Api.updateContent, id, requestBody)
    yield put(Actions.saveDeletedOnSocialForContentSuccess(result))
  } catch (error) {
    yield put(Actions.saveDeletedOnSocialForContentError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* lockContent({ payload }) {
  const { id } = payload || {}
  const lockOnly = (!payload || (payload && (!payload.openContentNotesDialog && !payload.openEditContentDialog)))
  const i18n = yield select(Selectors.getI18n)
  const contentDeskContentTypes = yield select(Selectors.getStaticCdContentTypes)

  try {
    let contentId

    if (!id && lockOnly) {
      const content = yield select(Selectors.getCDeskSelectedContent)
      contentId = content.get('id')
    } else {
      contentId = id
    }

    if (contentId) {
      const result = yield call(Api.lockContent, contentId)
      const user = yield select(Selectors.getUser)

      yield put(Actions.lockContentSuccess(fromJS(result)))

      if (!lockOnly) {
        const lockedByCurrentUser = result.lockedBy === user.get('id')

        if (lockedByCurrentUser) {
          const ccdCampaign = { value: result.ccdCampaign.id, label: result.ccdCampaign.name }
          const filteredType = contentDeskContentTypes.filter(t => t.get('name').toLowerCase() === result.type).first()

          let type = { value: contentDeskContentTypes.first().get('id'), label: contentDeskContentTypes.first().get('name') }

          if (filteredType) {
            type = { value: filteredType.get('id'), label: filteredType.get('name') }
          }

          const lockedContent = fromJS({
            ...result,
            ...{ ccdCampaign },
            ...{ type }
          })

          if (payload && payload.openContentNotesDialog) {
            yield put(Actions.openContentNotesDialog(lockedContent))
          }

          if (payload && payload.openEditContentDialog) {
            yield put(Actions.openEditContentDialog(lockedContent))
          }
        } else {
          yield put(Actions.openBlockedContentEditDialog())
        }
      }
    }
  } catch (error) {
    yield put(Actions.lockContentError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* unlockContent({ payload }) {
  const unlockOnly = (payload && (!payload.closeEditContentDialog && !payload.closeContentNotesDialog))
  const content = yield select(Selectors.getCDeskSelectedContent)
  const i18n = yield select(Selectors.getI18n)
  const contentId = content.get('id')

  try {
    if (contentId) {
      const result = yield call(Api.unlockContent, contentId)
      yield put(Actions.unlockContentSuccess(fromJS(result)))
    }
  } catch (error) {
    yield put(Actions.unlockContentError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  } finally {
    if (!unlockOnly) {
      if (payload && payload.closeEditContentDialog) {
        yield put(Actions.closeEditContentDialog())
      }

      if (payload && payload.closeContentNotesDialog) {
        yield put(Actions.closeContentNotesDialog())
      }
    }
  }
}

export function* dispatchContentRequestStart({ payload: { test = false, recipient } }) {
  const i18n = yield select(Selectors.getI18n)
  try {
    const formData = yield select(Selectors.getCDeskContentFormData)

    const requestBody = {
      test
    }

    if (recipient) {
      requestBody.recipient = recipient
    }

    yield call(Api.dispatchContent, formData.get('id'), requestBody)
    yield put(Actions.dispatchContentRequestSuccess())
    yield put(AppActions.showAppMessage({
      text: i18n.get('success'),
      success: true
    }))
  } catch (_error) {
    yield put(Actions.dispatchContentRequestError())
    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* switchToContactManagement() {
  yield put(Actions.closeEditContentDialog())
  yield put(Actions.closeCalendarDialog())
  yield put(navigate('/app/contact_management/my_contacts'))
}

export function* switchToEmailTemplatesLibrary() {
  yield put(EmailTemplatesLibraryActions.closePickerDialog())
  yield put(EmailTemplatesLibraryActions.setReadMode(false))
  yield put(Actions.closeEditContentDialog())
  yield put(navigate('/app/content_desk/templates_library'))
}

export function* switchToSearchPool({ payload: { field, value } }) {
  try {
    yield put(NewsActions.blockNewsRequest())

    const isPlotlights = yield select(Selectors.isPlotlights)

    yield put(NavigationActions.navigate(isPlotlights ? '/app/media_monitoring' : '/app/search_pool'))

    const { timeout } = yield race({
      selected: take(NewsActions.viewConfigPresetSelected),
      timeout: delay(2000)
    })

    if (!timeout) {
      yield put(NewsActions.resetNews())
      yield put(FilterActions.resetFilters())

      yield put(FilterActions.setSimilarityFilter({
        field,
        value: fromJS([value.delete('title')])
      }))

      if (field === 'ccdContents' && value.get('releasedAt')) {
        const dateFrom = value.get('releasedAt')
        const dateTo = moment(dateFrom).add(30, 'days').format()

        yield put(FilterActions.changeDate({
          dateFrom,
          dateTo
        }))
      }

      yield put(SavedSearchesActions.setInitialSearchLoaded(true))

      yield put(NewsActions.unblockNewsRequest())
      yield put(NewsActions.newsRequestStart())
    }
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  } finally {
    yield put(NewsActions.unblockNewsRequest())
  }
}

export function* uploadLinkedInMediaFileStart({ payload: { file } }) {
  try {
    if (file) {
      const formData = [
        {
          key: 'file',
          value: file
        }
      ]

      /* eslint-disable camelcase */
      const { uploaded_file, ccd_media_files_total_size } = yield call(Api.uploadMediaFile, formData)
      yield put(Actions.uploadLinkedInMediaFileSuccess(uploaded_file))
      yield put(Actions.setUploadedMediaFilesTotalSize(ccd_media_files_total_size))
    }
  } catch (_error) {
    yield put(Actions.uploadLinkedInMediaFileError())
  }
}

export function* uploadFacebookMediaFileStart({ payload: { file } }) {
  try {
    if (file) {
      const formData = [
        {
          key: 'file',
          value: file
        }
      ]

      /* eslint-disable camelcase */
      const { uploaded_file, ccd_media_files_total_size } = yield call(Api.uploadMediaFile, formData)
      yield put(Actions.uploadFacebookMediaFileSuccess(uploaded_file))
      yield put(Actions.setUploadedMediaFilesTotalSize(ccd_media_files_total_size))
    }
  } catch (_error) {
    yield put(Actions.uploadFacebookMediaFileError())
  }
}

export function* editorAiRequestStart({ payload: { contentType, actionType, language, mood } }) {
  try {
    const sourceText = yield select(Selectors.getCDeskAiSelectedText)
    const currentContentData = yield select(Selectors.getCDeskContentFormData)
    const totalEntries = yield select(Selectors.getCDeskAiRequestResults)

    let currentText = ''
    let result

    if (contentType === 'facebook') {
      currentText = currentContentData.getIn(['facebookPost', 'message'])
    }

    if (contentType === 'linkedIn') {
      currentText = currentContentData.getIn(['linkedInPost', 'commentary'])
    }

    const body = {
      action_type: actionType,
      source_text: sourceText || currentText,
      language,
      mood
    }

    const generatedText = yield call(Api.editorAiRequest, body)

    if (mood) {
      result = {
        resultId: totalEntries.size + 1,
        action: actionType.toLowerCase(),
        text: generatedText,
        mood,
        source: sourceText || currentText
      }
    }

    if (!mood) {
      result = {
        resultId: totalEntries.size + 1,
        action: actionType.toLowerCase(),
        text: generatedText,
        source: sourceText || currentText
      }
    }

    yield put(Actions.editorAiRequestSuccess(result))
  } catch (error) {
    yield put(Actions.editorAiRequestError(error))
  }
}

export function* buildSocialMediaLinkStart({ payload }) {
  try {
    if (payload.socialMedia === 'linkedin') {
      const { title, url, image, host, description } = yield call(Api.fetchOpenGraphMetadata, { url: payload.url })

      let articleContent

      if (image) {
        const file = yield call(Api.uploadFileFromUrl, { url: image, keep_until: moment().add(30, 'days').format() })
        articleContent = { title, url, description, id: file.id, host, imageUrl: file.url }
      } else {
        articleContent = { title, url, description, host }
      }

      yield put(Actions.buildSocialMediaLinkLinkedInSuccess(articleContent))
    }

    if (payload.socialMedia === 'facebook') {
      const result = yield call(Api.fetchFacebookLinkMetadata, { url: payload.url })
      yield put(Actions.buildSocialMediaLinkFacebookSuccess(result))
    }
  } catch (error) {
    if (payload.socialMedia === 'linkedin') {
      yield put(Actions.buildSocialMediaLinkLinkedInError({ url: payload.url }))
    }

    if (payload.socialMedia === 'facebook') {
      yield put(Actions.buildSocialMediaLinkFacebookError({ url: payload.url }))
    }
  }
}

export function* downloadEmailEvents({ payload }) {
  const { events } = payload
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.downloadEmailEvents, { events })
    yield call(downloadBlob, result, 'recipients_events_log.xlsx', excelMimeType)
    yield put(Actions.downloadEmailEventsSuccess())
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('download_in_progress'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.downloadEmailEventsError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('download_failed'),
      variant: 'error'
    }))
  }
}

export function* watchSaveCampaignStart() {
  yield takeEvery(Actions.saveCampaignStart, saveCampaignStart)
}

export function* watchSaveContentFormlessStart() {
  yield takeEvery(Actions.saveContentFormlessStart, saveContentFormlessStart)
}

export function* watchSaveContentStart() {
  yield takeEvery(Actions.saveContentStart, saveContentStart)
}

export function* watchDeleteContentStart() {
  yield takeEvery(Actions.deleteContentStart, deleteContentStart)
}

export function* watchDeleteCampaignStart() {
  yield takeEvery(Actions.deleteCampaignStart, deleteCampaignStart)
}

export function* watchCloneContentsFormData() {
  yield takeEvery(Actions.cloneContentsFormData, cloneContentsFormData)
}

export function* watchOpenViewContentDialog() {
  yield takeEvery(Actions.openViewContentDialog, openViewContentDialog)
}

export function* watchOpenEditContentDialog() {
  yield takeEvery(Actions.openEditContentDialog, openEditContentDialog)
}

export function* watchFetchCampaignDropdownOptionsStart() {
  yield takeEvery(Actions.fetchCampaignDropdownOptionsStart, fetchCampaignDropdownOptionsStart)
}

export function* watchFetchContactDropdownOptionsStart() {
  yield throttle(1000, Actions.fetchContactDropdownOptionsStart, fetchContactDropdownOptionsStart)
}

export function* watchFetchDistributionListDropdownOptionsStart() {
  yield throttle(1000, Actions.fetchDistributionListDropdownOptionsStart, fetchDistributionListDropdownOptionsStart)
}

export function* watchFetchMediaFilesStart() {
  yield throttle(1000, Actions.fetchMediaFilesStart, fetchMediaFilesStart)
}

export function* watchFetchMoreMediaFilesStart() {
  yield throttle(1000, Actions.fetchMoreMediaFilesStart, fetchMoreMediaFilesStart)
}

export function* watchUploadEditorImageStart() {
  yield takeEvery(Actions.uploadEditorImageStart, uploadEditorImageStart)
}

export function* watchDeleteMediaFileStart() {
  yield takeEvery(Actions.deleteMediaFileStart, deleteMediaFileStart)
}

export function* watchFetchStatisticsStart() {
  yield takeEvery(Actions.fetchStatisticsStart, fetchStatisticsStart)
}

export function* watchLockContentFormStart() {
  yield takeEvery(Actions.lockContentStart, lockContent)
}

export function* watchUnlockContentFormStart() {
  yield takeEvery(Actions.unlockContentStart, unlockContent)
}

export function* watchDispatchContentRequestStart() {
  yield takeEvery(Actions.dispatchContentRequestStart, dispatchContentRequestStart)
}

export function* watchSwitchToContactManagement() {
  yield takeEvery(Actions.switchToContactManagement, switchToContactManagement)
}

export function* watchSwitchToEmailTemplatesLibrary() {
  yield takeEvery(Actions.switchToEmailTemplatesLibrary, switchToEmailTemplatesLibrary)
}

export function* watchSwitchToSearchPool() {
  yield takeEvery(Actions.switchToSearchPool, switchToSearchPool)
}

export function* watchUploadLinkedInMediaFileStart() {
  yield takeEvery(Actions.uploadLinkedInMediaFileStart, uploadLinkedInMediaFileStart)
}

export function* watchUploadFacebookMediaFileStart() {
  yield takeEvery(Actions.uploadFacebookMediaFileStart, uploadFacebookMediaFileStart)
}

export function* watchSaveDeletedOnSocialForContentStart() {
  yield takeEvery(Actions.saveDeletedOnSocialForContentStart, saveDeletedOnSocialForContentStart)
}

export function* watchEditorAiRequestStart() {
  yield takeEvery(Actions.editorAiRequestStart, editorAiRequestStart)
}

export function* watchBuildSocialMediaLinkStart() {
  yield takeEvery(Actions.buildSocialMediaLinkStart, buildSocialMediaLinkStart)
}

export function* watchDownloadEmailEvents() {
  yield takeEvery(Actions.downloadEmailEventsStart, downloadEmailEvents)
}

export default function* mainSaga() {
  yield all([
    watchSaveCampaignStart(),
    watchSaveContentFormlessStart(),
    watchSaveContentStart(),
    watchDeleteContentStart(),
    watchDeleteCampaignStart(),
    watchCloneContentsFormData(),
    watchOpenEditContentDialog(),
    watchFetchCampaignDropdownOptionsStart(),
    watchFetchContactDropdownOptionsStart(),
    watchFetchDistributionListDropdownOptionsStart(),
    watchFetchMediaFilesStart(),
    watchFetchMoreMediaFilesStart(),
    watchDeleteMediaFileStart(),
    watchUploadEditorImageStart(),
    watchFetchStatisticsStart(),
    watchLockContentFormStart(),
    watchUnlockContentFormStart(),
    watchOpenViewContentDialog(),
    watchDispatchContentRequestStart(),
    watchSwitchToContactManagement(),
    watchSwitchToEmailTemplatesLibrary(),
    watchSwitchToSearchPool(),
    watchUploadLinkedInMediaFileStart(),
    watchUploadFacebookMediaFileStart(),
    watchSaveDeletedOnSocialForContentStart(),
    watchEditorAiRequestStart(),
    watchBuildSocialMediaLinkStart(),
    watchDownloadEmailEvents()
  ])
}
